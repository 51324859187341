<template>
  <div class="flex w-full flex-col items-center py-7">
    <ChartTitle
      :title
      :title-custom-size
      :download-file-name
      @download-clicked="downloadChart(chartRef, props)"
      ><slot
    /></ChartTitle>
    <v-chart ref="chartRef" :option="option" autoresize :style="chartHeight" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import {
  BAR_CHART_X_AXIS,
  formatChartValue,
  getBarChartHeight,
  getColor,
} from '@/apps/analysis-dashboard/utils';
import ChartTitle from '@/apps/analysis-dashboard/charts/ChartTitle.vue';
import { downloadChart } from '@/utils/chart-download';

const props = defineProps({
  chartData: {},
  title: {
    type: String,
    default: '',
  },
  titleCustomSize: String,
  downloadTitleFontSize: {
    type: Number,
    default: 100,
  },
  downloadFileName: String,
  downloadTitle: String,
  showLegend: {
    type: Boolean,
    default: true,
  },
  showXAxisLabel: {
    type: Boolean,
    default: true,
  },
  yAxisLabelWidth: {
    type: String,
    default: null,
  },
  showStackLabel: {
    type: Boolean,
    default: false,
  },
  multiply: {
    type: Number,
    default: null,
  },
  customMinHeight: {
    type: Number,
  },
  grid: {
    type: Object,
    default: () => ({ bottom: '15%', top: '15%', containLabel: true }),
  },
});

const chartRef = ref(null);

const chartHeight = computed(() => {
  return getBarChartHeight(props.customMinHeight, Object.keys(props.chartData));
});

const option = computed(() => {
  const transformedData = groupItemsByName(props.chartData);

  // Sort the series keys alphabetically
  const sortedSeriesKeys = Object.keys(transformedData).sort((a, b) =>
    a.localeCompare(b),
  );

  const barCategories = Object.keys(props.chartData);

  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' },
      formatter: (params) => {
        let result = '';
        params.forEach((item) => {
          if (item.value === 0) {
            return;
          }
          result += `${item.marker} ${
            item.seriesName
          }: ${item.value.toLocaleString('de-DE')}<br/>`;
        });
        return result;
      },
    },
    legend: {
      // show only items in the legend that are visible in the chart
      data: Object.keys(transformedData)
        .filter((key) => {
          return transformedData[key].some((item) => item.value > 0);
        })
        .sort((a, b) => a.localeCompare(b)),
      bottom: 20,
      itemWidth: 12,
      itemHeight: 12,
      show: props.showLegend,
    },
    grid: props.grid,
    xAxis: BAR_CHART_X_AXIS,
    yAxis: {
      type: 'category',
      data: barCategories,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        color: '#424242',
        fontWeight: '400',
        fontSize: 16,
        fontFamily: 'Inter, sans-serif',
        align: 'right',
        width: props.yAxisLabelWidth,
        overflow: 'truncate',
      },
    },

    series: sortedSeriesKeys.map((key) => {
      return {
        name:
          transformedData[key].length > 0 ? transformedData[key][0].name : '',
        type: 'bar',
        stack: 'stack',
        label: {
          show: props.showStackLabel,
          position: 'right',
          color: '#424242',
          fontWeight: '400',
          fontSize: 16,
          formatter: (params) => {
            if (params.value === 0) return '';
            else return formatChartValue(params.value);
          },
        },
        data: transformedData[key].map((item) =>
          props.multiply ? item.value * props.multiply : item.value,
        ),
        itemStyle: {
          color:
            transformedData[key].length > 0
              ? getColor(transformedData[key][0])
              : '',
        },
        barWidth: 24,
      };
    }),
  };
});

function groupItemsByName(data) {
  const transformedData = {};

  Object.keys(data).forEach((category) => {
    data[category].forEach((item) => {
      if (!transformedData[item.name]) {
        transformedData[item.name] = [];
      }
      transformedData[item.name].push(item);
    });
  });

  return transformedData;
}
</script>
