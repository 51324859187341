<template>
  <div
    class="subtitle-2 flex h-fit w-full items-center justify-center gap-2 py-5 text-infra-gray-highlight"
  >
    <span
      >© {{ new Date().getFullYear() }} LBD-Beratungsgesellschaft mbH
      Berlin</span
    >
    <span>|</span>
    <div class="flex items-center gap-2">
      <a href="https://www.lbd.de/datenschutz/" target="_blank">Datenschutz</a>
      <a href="https://www.lbd.de/impressum/" target="_blank">Impressum</a>
    </div>
  </div>
</template>
