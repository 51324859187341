<template>
  <div class="absolute bottom-5 right-5">
    <div
      class="toggleBarBottomRight standard-elevation-1 flex flex-col bg-white"
    >
      <!-- zoom in-->
      <ButtonEl
        icon="add"
        variant="tertiary"
        color="neutral"
        data-test="zoom-in"
        class="rounded-b-none"
        @click="zoom"
      />
      <!-- zoom out-->
      <ButtonEl
        icon="remove"
        variant="tertiary"
        color="neutral"
        class="rounded-none"
        @click="unzoom"
      />

      <!-- show only when click on control option icon-->
      <div class="extended-controls" :class="{ visible: showExtendedControls }">
        <ButtonEl
          :text="pitchText"
          class="w-10 rounded-none"
          variant="tertiary"
          color="neutral"
          @click="pitch"
        />
        <!-- compass to north-->
        <ButtonEl
          icon="explore"
          class="rounded-none"
          variant="tertiary"
          color="neutral"
          @click="compass"
        />
        <ButtonEl
          icon="satellite_alt"
          class="rounded-none"
          :variant="satelliteIsActive ? 'primary' : 'tertiary'"
          :color="satelliteIsActive ? 'color1' : 'neutral'"
          @click="toggleSatellite"
        />
      </div>
      <ButtonEl
        class="standard-elevation-1 rounded-t-none"
        variant="tertiary"
        icon="map"
        @click="showExtendedControls = !showExtendedControls"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { compassMap, setPitch, unzoomMap, zoomMap } from '@/mapbox/main';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';

export default {
  name: 'MapControls',
  components: { ButtonEl },
  data() {
    return {
      showModalMapSettings: false,
      pitchText: '3D',
      showExtendedControls: false,
    };
  },
  computed: {
    ...mapState('map', ['satelliteIsActive']),
  },
  methods: {
    pitch() {
      this.pitchText = setPitch(this.pitchText);
    },
    zoom() {
      zoomMap();
    },
    unzoom() {
      unzoomMap();
    },
    compass() {
      compassMap();
    },
    toggleSatellite() {
      this.$store.commit('map/TOGGLE_SATELLITE');
    },
  },
};
</script>

<style lang="scss" scoped>
.toggleBarBottomRight {
  width: 40px;
  background: #ffffff;
  border-radius: 8px;
}

.extended-controls {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease-out;
}

.visible {
  max-height: 200px;
}
</style>
