import { defineStore } from 'pinia';

export const useMapStore = defineStore('MapStore', {
  state: () => ({
    showMainMenu: false,
    showProjectMenu: false,
    showMuScSelector: true,
  }),
  actions: {},
});
