import { LAYER_KEY__MUNICIPALITY_LINE } from '@/configs/layers/baseData/constants';

const layerHierarchy = {
  heat: [],
  poly: [],
  line: [],
  fill: [],
  point: [],
  symbol: [],
};

export function setLayerHierarchy(map) {
  // Example of explicitly placing the satellite layer
  if (map.getLayer('satellite')) {
    map.moveLayer('satellite', 'gl-draw-point-heat-source.cold'); // Ensure it's below your drawing layers
  }

  // Move other layers as per the predefined hierarchy
  for (let i = 0; i < Object.values(layerHierarchy).length; i += 1) {
    Object.values(layerHierarchy)[i].forEach((layer) => {
      if (map.getLayer(layer)) {
        map.moveLayer(layer);
      }
    });
  }

  // Ensuring labels are on top
  map.getStyle().layers.forEach((e) => {
    if (e.id.split('-').includes('label') && map.getLayer(e.id)) {
      map.moveLayer(e.id);
    }
  });
}

export function getTileServerUrl(sourceLayer) {
  return `${window.TILESERVER_URL}/ts/tiles/${sourceLayer}/{z}/{x}/{y}.pbf`;
}

function addScenarioSuffix(store, layer) {
  const scenarioSelected = store.state.scenario.scenarioSelected.scenario_id;
  const layers = store.getters['map/getLayersForScenario'];
  if (!layers.includes(layer.layerId)) return layer.sourceLayer;
  if (layer.layerId === LAYER_KEY__MUNICIPALITY_LINE)
    return `${scenarioSelected}_${layer.sourceLayer}`;
  return `${scenarioSelected}_${layer.layerId}`;
}

function addIconsLayer(config, map) {
  if (config.icons) {
    config.icons.forEach((icon) => {
      map.loadImage(icon.path, (error, image) => {
        if (error) throw error;
        if (!map.hasImage(icon.name)) {
          map.addImage(icon.name, image);
        }
      });
    });
  }
}

function addPolygonLayer(config, map, styling) {
  if (config.type === 'polygon') {
    if (config.subType === 'fillExtrusion') {
      layerHierarchy.fill.push(config.layerId);
      map.U.addFillExtrusionLayer(config.layerId, config.id, {
        visibility: config.visibility,
        'fill-extrusion-color': styling.fillExtrusionColor,
        'fill-extrusion-height': styling.fillExtrusionHeight,
        'fill-extrusion-opacity': styling.fillExtrusionOpacity,
        ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
        ...(config.filter && { filter: config.filter }),
      });
    } else {
      layerHierarchy.poly.push(config.layerId);
      map.U.addFillLayer(config.layerId, config.id, {
        'fill-color': styling.fillColor,
        visibility: config.visibility,
        'fill-opacity': styling.fillOpacity,
        ...(styling.fillOutlineColor && {
          'fill-outline-color': styling.fillOutlineColor,
        }),
        ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
        ...(config.filter && { filter: config.filter }),
      });
    }
  }
}

function addPointLayer(config, map, styling) {
  if (config.type === 'point') {
    layerHierarchy.point.push(config.layerId);
    map.U.addCircleLayer(config.layerId, config.id, {
      paint: {
        'circle-color': styling.circleColor,
        'circle-stroke-width': styling.circleStrokeWidth,
        'circle-stroke-color': styling.circleStrokeColor,
        'circle-radius': styling.circleRadius,
      },
      ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
      ...(config.filter && { filter: config.filter }),
      visibility: config.visibility,
    });
  }
}

function addLineLayer(config, map, styling) {
  if (config.type === 'line') {
    layerHierarchy.line.push(config.layerId);
    map.U.addLineLayer(config.layerId, config.id, {
      paint: {
        'line-color': styling.lineColor,
        'line-width': styling.lineWidth,
      },
      visibility: config.visibility,
      ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
      ...(config.filter && { filter: config.filter }),
    });
  }
}

function addSymbolLayer(config, map, styling) {
  if (config.type === 'symbol') {
    layerHierarchy.symbol.push(config.layerId);
    map.U.addSymbolLayer(config.layerId, config.id, {
      layout: styling.layout,
      ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
    });
  }
}

function addHeatmapLayer(config, map, styling) {
  if (config.type === 'heatmap') {
    layerHierarchy.heat.push(config.layerId);
    map.U.addHeatmapLayer(config.layerId, config.id, {
      visibility: config.visibility,
      paint: {
        'heatmap-weight': styling.weight,
        'heatmap-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0,
          1,
          7,
          10,
          12,
          15,
        ],
        'heatmap-color': styling.color,
      },
      ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
    });
  }
}

export function createLayers(map, layers, setGeoJsonData, store, getYear) {
  Object.entries(layers).forEach((layer) => {
    const item = layer[1];
    const config = item.layerConfig;
    let styling;
    if (typeof item.style.default === 'function') {
      styling = item.style.default(getYear);
    } else {
      styling = item.style.default;
    }
    config.sourceLayer = addScenarioSuffix(store, config);
    if (!map.getSource(config.id) && !config.geoJSONSource) {
      map.U.addVector(config.id, getTileServerUrl(config.sourceLayer));
    }
    if (!map.getSource(config.id) && config.geoJSONSource) {
      map.U.addGeoJSON(config.id);
      if (config.dataSourceFromStore) {
        setGeoJsonData(config.id, store.getters[config.dataSourceFromStore]);
      }
    }
    addIconsLayer(config, map);
    addPolygonLayer(config, map, styling);
    addPointLayer(config, map, styling);
    addLineLayer(config, map, styling);
    addSymbolLayer(config, map, styling);
    addHeatmapLayer(config, map, styling);
  });
  setLayerHierarchy(map);
}
