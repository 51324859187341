<template>
  <div class="filter_group">
    <!-- Switch-List -->
    <SwitcheryLayer
      v-if="menuItem.itemClass === 'switch'"
      :menu-item="menuItem"
      v-bind="$attrs"
      :data-test="`item-switch-${menuItem.title}`"
    />
    <!-- / Switch-List -->

    <!-- Modal-List -->
    <SwitcheryModal
      v-else-if="menuItem.itemClass === 'modal'"
      :menu-item="menuItem"
      :data-test="`item-switch-${menuItem.title}`"
    />
    <!-- / Modal-List -->

    <VList
      v-else
      :opened="[open]"
      :class="{
        'border-t': menuItem.title !== 'Wärmeprojekte',
        'border-b-0': menuItem.title === 'Wärmeprojekte',
      }"
      :data-test="`item-switch-${menuItem.title}`"
      class="bg-transparent filter-group-list-custom border-infra-lines p-0 font-medium"
    >
      <ProjectAreas
        v-if="
          menuItem.title === 'Wärmeprojekte' &&
          isInfraModuleActive('projectPlaner')
        "
      >
        <slot />
      </ProjectAreas>
      <VListGroup v-else :value="menuItem.title">
        <template #activator="{ props }">
          <VListItem
            class="p-3"
            v-bind="props"
            @click="emits('emitActivator', menuItem.title)"
          >
            <VListItemTitle class="flex items-center">
              <span class="body-2">{{ menuItem.title }}</span>
              <span v-show="layerCategories > 0" class="count">
                {{ layerCategories }}
              </span>
            </VListItemTitle>
          </VListItem>
        </template>
        <slot />
      </VListGroup>
    </VList>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { isInfraModuleActive } from '@/utils/is-infra-module-active';
import SwitcheryLayer from '@/features/map/layer-panel/input-forms/SwitcheryLayer.vue';
import SwitcheryModal from '@/features/map/layer-panel/input-forms/SwitcheryModal.vue';
import ProjectAreas from '@/features/map/layer-panel/input-forms/ProjectAreas.vue';

const store = useStore();
const props = defineProps({
  menuItem: {
    type: Object,
    default: () => ({}),
  },
  open: {
    type: String,
    default: null,
  },
});
const emits = defineEmits(['emitActivator']);

const getSwitchStates = computed(() => store.getters['map/getSwitchStates']);
const layerCategories = computed(() => {
  let switchesActive = 0;
  props.menuItem.items.forEach((e) => {
    if (getSwitchStates.value[e.switchId] === true) switchesActive += 1;
  });
  return switchesActive;
});
</script>

<style lang="scss">
.filter_group:last-child {
  .filter-group-list-custom {
    @apply border-b;
  }
}
</style>
