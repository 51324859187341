<template>
  <Teleport to="body">
    <VDialog
      v-model="dialogState"
      :persistent="persistent"
      :disabled="disabled"
      transition="fade-transition"
      :no-click-animation="true"
      :width="widthCalc">
      <template #activator="{ props }">
        <div v-bind="props" :class="{ 'w-full': activatorFullWidth }">
          <slot name="activatorItem" class="cursor-pointer" />
        </div>
      </template>

      <VCard
        class="shadow-none"
        :class="[
          {
            'overflow-visible': overflowVisible,
          },
          customClasses,
        ]">
        <VCardText class="p-[20px] flex flex-col gap-5 min-w-[500px]">
          <div class="flex align-center justify-between gap-4">
            <h3 class="text-title-color2">{{ title }}</h3>
            <IconWrapper
              v-if="!hideCloseButton"
              icon="close"
              class="cursor-pointer"
              fill="text-title-color2"
              hover="hover:text-color2"
              @click="closeDialog" />
          </div>
          <div class="flex flex-col gap-5 relative h-full justify-between">
            <div
              :class="overflowVisible ? 'overflow-visible' : 'overflow-y-auto'"
              class="max-h-[80vh] min-h-[26px]">
              <slot name="content" />
            </div>
            <div
              class="flex items-center justify-end gap-3"
              :class="{ 'justify-center': actionsCentered }">
              <slot name="actions" />
            </div>
          </div>
        </VCardText>
      </VCard>
    </VDialog>
  </Teleport>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import IconWrapper from '../IconWrapper/IconWrapper.vue';

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  activatorFullWidth: {
    type: Boolean,
    default: true,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  overflowVisible: {
    type: Boolean,
    default: false,
  },
  fullScreen: {
    type: Boolean,
    default: false,
  },
  persistent: {
    type: Boolean,
    default: false,
  },
  actionsCentered: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  hideCloseButton: {
    type: Boolean,
    default: false,
  },
  customClasses: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const dialogState = ref(props.modelValue);
const windowWidth = ref(window.innerWidth);

function closeDialog() {
  dialogState.value = false;
}

onMounted(() => {
  window.addEventListener('resize', () => {
    windowWidth.value = window.innerWidth;
  });
});

const widthCalc = computed(() => {
  if (props.fullScreen) {
    return windowWidth.value < 1440 ? 'auto' : '1360px';
  }
  return 'auto';
});

watch(
  () => props.modelValue,
  (newValue) => {
    dialogState.value = newValue;
  },
);

watch(dialogState, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>

<style scoped lang="scss">
.fade-transition {
  transition: all 0.3s ease;
}

.v-overlay__scrim {
  @apply bg-canvas;
  opacity: 0.4;
}
</style>
