import { getMap } from '@/mapbox/main';
import DragPoint from '@/mapbox/dragPoint';
import { axios } from '@/utils/axiosHelper';
import cookie from 'vue-cookies';
import NumberField from '@/fieldClasses/NumberField';

export default class HeatSource {
  static OPTIONS = {
    HSO: {
      OPTIONS: null,
      URL: '/api/heatprojects/heat-source/',
    },
    HSY: {
      OPTIONS: null,
      URL: '/api/heatprojects/heating-center/',
    },
    GFP: {
      OPTIONS: null,
      URL: '/api/heatprojects/grid-feed-point/',
    },
  };
  OPTIONS_LOADED = false;
  static DEFAULT_SOURCE = 'UNK';

  energySource = HeatSource.DEFAULT_SOURCE;
  DragPoint = new DragPoint('editHeatSource');

  constructor(
    id = null,
    name = null,
    systemType = 'HSO',
    sourceType = null,
    energySource = null,
    assetType = null,
    heatOutput = null,
    heatGeneration = null,
    powerOutput = null,
    powerGeneration = null,
    emissions = null,
    updatedAt = null,
    coords = null,
  ) {
    if (!HeatSource.OPTIONS_LOADED) {
      HeatSource.OPTIONS_LOADED = true;
      this._fetchOptions();
    }
    this.id = id;
    this.name = name;
    this.systemType = systemType;
    this.sourceType = sourceType;
    this.energySource = energySource;
    this.assetType = assetType;
    // is set in project planer and assign to through relation
    this.generationCost = 80; // [€/MWh]
    this.heatOutput = heatOutput; //[MW]
    this.heatGeneration = heatGeneration; //[MW]
    this.powerOutput = powerOutput; //[MWh]
    this.powerGeneration = powerGeneration; //[MWh]
    this.emissions = emissions; //[t/MWh]
    this.updatedAt = updatedAt;
    this.coords = coords;
    this.DragPoint.coords = coords;
  }

  initialize() {
    this.DragPoint.initialize();
  }

  resetParameter() {
    this.sourceType = null;
    this.energySource = null;
    this.heatOutput = null;
    this.heatGeneration = null;
    this.powerOutput = null;
    this.powerGeneration = null;
    this.emissions = null;
  }

  get systemOptions() {
    return HeatSource.OPTIONS[this.systemType].OPTIONS;
  }

  get systemURL() {
    return HeatSource.OPTIONS[this.systemType].URL;
  }

  get sourceTypeOptions() {
    if (this.systemOptions !== null)
      return this.systemOptions.plant_type.choices;
    return [{ value: '', display_name: '' }];
  }

  get energySourceOptions() {
    const conditions = {
      COA: ['BOI', 'CHP'],
      GAS: ['BOI', 'CHP'],
      OIL: ['BOI', 'CHP'],
      BIO: ['BOI', 'CHP'],
      BGA: ['BOI', 'CHP'],
      H2: ['BOI', 'CHP'],
      ELE: ['BOI', 'PMP'],
      SOL: ['DHU'],
      GEO: ['DHU'],
      H2O: ['DHU'],
      WAS: ['DHU'],
      UNK: ['BOI', 'CHP', 'DHU'],
    };
    const choices = [];
    if (this.systemOptions !== null && this.sourceType) {
      for (const choice of this.systemOptions.energy_source.choices) {
        if (conditions[choice.value].includes(this.sourceType)) {
          choices.push(choice);
        }
      }
      return choices;
    }
    return [{ value: '', display_name: '' }];
  }

  get assetTypeOptions() {
    if (this.systemOptions !== null)
      return this.systemOptions.asset_type.choices;
    return [{ value: '', display_name: '' }];
  }

  get systemTypeOptions() {
    if (this.systemOptions !== null)
      return this.systemOptions.system_type.choices;
    return [{ value: '', display_name: '' }];
  }

  /**
   * Get Object as Feature. Only call when instance is saved and id attribute is set
   */
  get asFeature() {
    return {
      type: 'Feature',
      id: this.id,
      properties: {
        id: this.id,
        plant_type: this.sourceType,
        energy_source: this.energySource,
        asset_type: this.assetType,
        heat_output: this.heatOutput,
        system_type: this.systemType,
      },
      geometry: {
        type: 'Point',
        coordinates: this.coords,
      },
    };
  }

  get sourceTypeDisplayName() {
    if (!this.sourceType) return '';
    const sourceTypeOption = this.sourceTypeOptions.find(
      (e) => e.value === this.sourceType,
    );
    return sourceTypeOption.display_name;
  }

  get energySourceDisplayName() {
    if (!this.energySource) return '';
    const energySourceOption = this.systemOptions.energy_source.choices.find(
      (e) => e.value === this.energySource,
    );
    return energySourceOption.display_name;
  }

  get isValid() {
    // HSO required fields
    let checkProps = [
      this.name,
      this.energySource,
      this.assetType,
      this.heatOutput,
      this.heatGeneration,
      this.systemType,
    ];
    if (this.systemType === 'HSY') {
      checkProps = [
        this.name,
        this.assetType,
        this.heatOutput,
        this.systemType,
      ];
    }
    if (this.systemType === 'GFP') {
      checkProps = [
        this.name,
        this.assetType,
        this.heatOutput,
        this.systemType,
      ];
    }
    return checkProps.findIndex((e) => e === null || e === '') === -1;
  }

  get objectPanelConfig() {
    return {
      id: this.id,
      name: 'Wärmequelle',
      subtitles: [
        { key: 'name', value: this.name, ...this.systemOptions['name'] },
      ],
      enableDelete: true,
      enableWiRe: false,
      enableEdit: true,
      customEditFunction(store) {
        store.commit('SET_MAP_ACTIONS', {
          processId: 'heatSource',
          editObjectId: this.id,
        });
      },
      chips: [
        {
          title: 'Info',
          active: true,
          items: [
            {
              title: 'Info',
              active: true,
              items: [
                {
                  value: this.assetType,
                  unit: '',
                  ...this.systemOptions['asset_type'],
                },
                {
                  value: this.sourceType,
                  unit: '',
                  ...this.systemOptions['plant_type'],
                },
                {
                  value: this.energySource,
                  unit: '',
                  ...this.systemOptions['energy_source'],
                },
                NumberField.fieldData(
                  this.generationCost,
                  {
                    type: 'float',
                    read_only: true,
                    label: 'Erzeugungskosten',
                  },
                  '€/MWh',
                  2,
                ),
                NumberField.fieldData(
                  this.heatOutput,
                  this.systemOptions['thermal_power_mw'],
                  'MW',
                ),
                NumberField.fieldData(
                  this.heatGeneration,
                  this.systemOptions['generated_heat_quantity_mwh'],
                  'MWh/a',
                  2,
                  1,
                  false,
                ),
                NumberField.fieldData(
                  this.powerOutput,
                  this.systemOptions['electrical_power_mw'],
                  'MW',
                  2,
                  1,
                  false,
                ),
                NumberField.fieldData(
                  this.powerGeneration,
                  this.systemOptions['generated_electricity_mwh'],
                  'MWh/a',
                  2,
                  1,
                  false,
                ),
                NumberField.fieldData(
                  this.emissions,
                  this.systemOptions['emissions_t_mwh'],
                  'tCO2/MWh',
                  2,
                  1,
                  false,
                ),
              ],
            },
          ],
        },
      ],
    };
  }

  _fetchOptions() {
    axios({
      url: HeatSource.OPTIONS.HSO.URL,
      method: 'OPTIONS',
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    }).then((resp) => {
      HeatSource.OPTIONS.HSO.OPTIONS = resp.data.actions.POST;
    });
    axios({
      url: HeatSource.OPTIONS.HSY.URL,
      method: 'OPTIONS',
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    }).then((resp) => {
      HeatSource.OPTIONS.HSY.OPTIONS = resp.data.actions.POST;
    });
    axios({
      url: HeatSource.OPTIONS.GFP.URL,
      method: 'OPTIONS',
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    }).then((resp) => {
      HeatSource.OPTIONS.GFP.OPTIONS = resp.data.actions.POST;
    });
  }

  constructPayload() {
    const payload = {
      name: this.name,
      system_type: this.systemType,
      plant_type: this.sourceType,
      thermal_power_mw: this.heatOutput,
      generated_heat_quantity_mwh: this.heatGeneration,
      asset_type: this.assetType,
      geometry: this.DragPoint.geometry,
    };
    // add optional parameter
    if (this.energySource) payload.energy_source = this.energySource;
    if (this.powerOutput) payload.electrical_power_mw = this.powerOutput;
    if (this.powerGeneration)
      payload.generated_electricity_mwh = this.powerGeneration;
    if (this.emissions) payload.emissions_t_mwh = this.emissions;
    return payload;
  }

  destroy() {
    this.DragPoint.removeEventhooks();
    if (getMap().isStyleLoaded() && getMap().getLayer(this.DragPoint.layerId))
      getMap().removeLayer(this.DragPoint.layerId);
  }
}
