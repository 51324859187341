<template>
  <h4 class="text-infra-highlight-500">
    Projektgebiet {{ isEdit ? 'bearbeiten' : 'anlegen' }}
  </h4>

  <div v-if="step === 1" class="flex flex-col gap-2.5">
    <div
      class="standard-elevation-0-dark flex flex-col gap-3 rounded-[6px] p-2"
    >
      <FormValidator v-model="step1isValid">
        <InputEl
          v-model="heatProject.name"
          label="Bezeichnung"
          placeholder="Bitte eingeben"
          :rules="{ required: true, unique: [existingProjectNames] }"
          data-test="project-name"
        />
      </FormValidator>
      <DropDown
        v-model="heatProject.realisationYear"
        :items-data="getScenarioYears"
        :initial-selection="!isEdit"
        :disabled="isEdit"
        label="Realisierungsjahr"
        custom-expand-icon="calendar_month"
      />
      <DropDown
        v-model="heatProject.scenario"
        label="Szenario"
        value="scenario_id"
        :disabled="true"
        items-data-key="name"
        :initial-selection="true"
        :items-data="[getSelectedScenario]"
      />
    </div>
    <div
      v-if="isEdit"
      class="standard-elevation-0-dark flex flex-col gap-[5px] rounded-[6px] p-2.5"
    >
      <div class="caption-1 rounded-[4px] bg-blue-grey-10 p-2 text-left">
        Nach manueller Bearbeitung werden die Parameter der automatisierten
        Netzberechnung außer Kraft gesetzt.
      </div>
    </div>
  </div>
  <div v-else-if="step === 2" class="flex flex-col gap-3">
    <HeatProjectMetaInfo
      :scenario="getSelectedScenario.name"
      :heat-project
      :is-edit="isEdit"
      @update:heat-project-parameter="updateHeatProjectParameters"
    />
    <div
      class="standard-elevation-0-dark flex w-full flex-col gap-2.5 rounded-[6px] bg-white p-2.5"
    >
      <!-- Tabs Header -->
      <TabHeaderCards
        :tabs="tabs"
        :valid-cards="validCards"
        :trigger-active-tab="activeTab"
        :disable-automatic-tab-switching="true"
        @update:active-tab="activeTab = $event"
        @click-tab="handleTabSwitching($event)"
      />

      <!-- Tab Content -->
      <ProjektPlanerTabSingle v-if="activeTab === 0" :title="getTabTitle()">
        <template #inner>
          <ProjectPlanerTabContainer
            v-if="buildingCount === 0"
            :data="{ title: 'Gebäude', body: 0 }"
          />
          <template v-else>
            <p v-if="buildingCount > 10000" class="caption-1 text-red-500">
              Das Projektgebiet ist zu groß (mehr als 10.000 Gebäude). Für
              individuelle Analysen kontaktieren Sie uns bitte unter
              info@lbd.de.
            </p>
            <div class="relative grid grid-cols-3 gap-1 rounded-[2px]">
              <ProjectPlanerTabContainer
                v-for="(data, index) in tabContainerData"
                :key="index"
                :data="data"
              />
            </div>
            <div
              class="flex items-center justify-between rounded-[2px] bg-infra-highlight-25 p-2"
            >
              <CheckboxEl
                v-model="heatProject.public_building_prio"
                :disabled="isEdit"
                class="w-full flex-row-reverse justify-between"
              >
                <div class="caption-1">
                  Öffentl. Liegenschaften prioritär anschliessen
                </div>
              </CheckboxEl>
            </div>
            <FormValidator v-model="step2isValid">
              <div class="flex flex-col bg-infra-highlight-25 p-2.5">
                <InputEl
                  id="minimumBuildingArea"
                  v-model="heatProject.minimumBuildingArea"
                  label="Nur Gebäude mit Grundfläche größer"
                  :rules="{ required: true, min: [0, 'float'] }"
                  :disabled="isEdit"
                  size="m"
                  suffix="m²"
                  input-type="float"
                  class="mb-2 w-full"
                />
                <InputEl
                  id="simultaneityFactorFromField"
                  v-model="heatProject.simultaneityFactor"
                  label="Gleichzeitigkeitsfaktor"
                  :rules="{ required: true, range: [0, 1, 'percent'] }"
                  size="m"
                  :disabled="isEdit"
                  suffix="%"
                  input-type="percent"
                  class="w-full"
                  tooltip-text="Anteil der gleichzeitig auftretetenden Wärmelast aller angeschlossenen Gebäude an der summierten Wärmehöchstlast aller angeschlossenen Gebäude"
                />
              </div>
            </FormValidator>
            <div v-if="buildingCount" class="flex w-full flex-col gap-1">
              <ButtonEl
                text="Gebäudeselektion auf Karte aktualisieren"
                icon="ads_click"
                size="s"
                variant="secondary"
                :disabled="isEdit"
                @click="refreshBuildings()"
              />
              <ButtonEl
                text="Gebäudeselektion aufheben"
                size="s"
                variant="secondary"
                :disabled="isEdit"
                @click="resetBuildings()"
              />
            </div>
          </template>
        </template>
        <template #bottom>
          <div
            v-if="buildingCount === 0"
            class="body-3 rounded-[4px] bg-blue-grey-10 p-2.5"
          >
            Bitte wählen Sie Gebäude durch ... auf der Karte
          </div>
          <div
            v-else
            class="standard-elevation-0-dark rounded-[2px] bg-infra-highlight-25 p-2"
          >
            <SliderEl
              v-if="!isEdit"
              v-model="heatProject.connectionRate"
              :disabled="isEdit"
            >
              <template #top>
                <div class="flex justify-between gap-2">
                  <div class="body-3 flex">
                    <span>Zielnetz-Anschlussgrad</span>
                    <span class="relative">
                      <ToolTipOnHover
                        tooltip-text="Anteil der Gebäude, die an das Zielnetz angeschlossen werden sollen"
                        tooltip-container-classes="absolute top-[-5px] ml-0.5"
                        :icon-size="22"
                      />
                    </span>
                  </div>

                  <div style="color: #0692bc" class="subtitle-2">
                    {{ heatProject.connectionRate * 100 + ' %' }}
                  </div>
                </div>
              </template>
            </SliderEl>
            <div v-else class="justify-space-between flex">
              <div class="subtitle-2">Aktueller Anschlussgrad:</div>
              <div class="subtitle-2 text-infra-highlight-500">
                {{ (currentConnectionRate * 100).toFixed(0) + ' %' }}
              </div>
            </div>
          </div>
        </template>
      </ProjektPlanerTabSingle>
      <ProjektPlanerTabSingle
        v-else-if="activeTab === 1"
        :title="getTabTitle()"
      >
        <template #inner>
          <div
            v-if="heatProject.getHeatSources.length === 0"
            class="body-3 rounded-[4px] bg-blue-grey-10 p-2.5"
          >
            Bitte wählen Sie Wärmequellen bzw. Heizzentralen durch Rechtsklick
            auf der Karte.
          </div>
          <div v-else class="flex flex-col gap-[5px]">
            <div
              v-for="heatSource in heatProject.getHeatSources"
              :key="heatSource.index"
              class="body-2 flex items-center justify-end rounded-[2px] bg-infra-highlight-25 px-2 py-1.5 text-infra-highlight-600"
            >
              <div class="flex items-center gap-1.5">
                <div>{{ heatSource.name }}:</div>
                <InputEl
                  v-model="heatSource.generationCost"
                  :is-required="true"
                  size="m"
                  :min="0"
                  suffix="€/MWh(th)"
                  input-type="float"
                />
              </div>
              <!--              make a close v-icon -->
              <VIcon
                v-if="!isEdit"
                class="ml-1 cursor-pointer text-[16px]"
                @click="
                  heatProject.connectHeatSourceInterface.removeHeatSource(
                    heatSource,
                  )
                "
                >mdi-close
              </VIcon>
            </div>
          </div>
        </template>
      </ProjektPlanerTabSingle>
      <ProjektPlanerTabSingle
        v-else-if="activeTab === 2"
        :title="getTabTitle()"
      >
        <template #inner>
          <DropDown
            v-model="heatProject.materialParameterSet"
            :items-data="materialSet"
            items-data-key="name"
            value="id"
            :disabled="isEdit"
            size="m"
            label="Materialtyp"
            :placeholder="'Bitte wählen...'"
            data-test="material-dropdown"
          />
          <div class="grid grid-flow-col gap-1">
            <InputEl
              v-model="heatProject.preTemperatur"
              :disabled="isEdit"
              size="m"
              class="mr-[6px]"
              :label="'Temperatur\nVorlauf'"
              :suffix="'°C'"
              input-type="float"
            />
            <InputEl
              v-model="heatProject.returnTemperatur"
              :disabled="isEdit"
              size="m"
              :label="'Temperatur\nRücklauf'"
              :suffix="'°C'"
              input-type="float"
            />
            <InputEl
              v-model="heatProject.flowVelocity"
              :disabled="isEdit"
              size="m"
              :label="'Strömungs-\ngeschwindigkeit'"
              :suffix="'m/s'"
              input-type="float"
            />
          </div>
        </template>
      </ProjektPlanerTabSingle>
    </div>
    <div class="flex w-full items-center justify-center">
      <WaterfallChart
        :data="getBarChartData"
        :labels="['Leistung', 'Last', 'Delta']"
      />
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2.5">
    <ButtonEl
      :full-width="true"
      variant="secondary"
      :text="step === 1 ? 'Abbrechen' : 'Zurück'"
      @click="backButtonAction"
    />
    <ButtonEl
      :full-width="true"
      :disabled="disableSaveButton"
      :text="saveButtonText"
      :icon="saveButtonIcon"
      :icon-right="true"
      @click="saveButtonAction"
    />
  </div>
</template>

<script setup>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import HeatProject from '@/features/heat-project/heat-project';
import { barChartData } from '@/features/heat-project/charts/create-chart-data';
import {
  buildingsWithin,
  deleteFeatures,
  draw,
  exitDraw,
} from '@/features/draw/draw';
import {
  SWITCH_ID__GRID_FEED_POINT_INVENTORY,
  SWITCH_ID__GRID_FEED_POINT_POTENTIAL,
  SWITCH_ID__HEAT_SOURCE_INVENTORY,
  SWITCH_ID__HEAT_SOURCE_POTENTIAL,
  SWITCH_ID__HEATING_CENTER_INVENTORY,
  SWITCH_ID__HEATING_CENTER_POTENTIAL,
} from '@/configs/layer-panel/heatSource';
import axios from '@/utils/axiosHelper';
import { LAYERS } from '@/features/heat-project/constants';
import { flyTo, setMaxZoom } from '@/mapbox/main';
import cookie from 'vue-cookies';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import HeatProjectMetaInfo from '@/features/heat-project/components/HeatProjectMetaInfo.vue';
import TabHeaderCards from '@/components/storybook/src/stories/TabHeaderCards/TabHeaderCards.vue';
import ProjektPlanerTabSingle from '@/features/heat-project/components/ProjektPlanerTabSingle.vue';
import ProjectPlanerTabContainer from '@/features/heat-project/components/ProjectPlanerTabContainer.vue';
import CheckboxEl from '@/components/storybook/src/stories/CheckboxEl/CheckboxEl.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import SliderEl from '@/components/storybook/src/stories/SliderEl/SliderEl.vue';
import WaterfallChart from '@/components/storybook/src/stories/charts/WaterfallChart/WaterfallChart.vue';
import TubesIcon from '@/components/storybook/src/assets/icons/TubesIcon.vue';
import HeatWaveIcon from '@/components/storybook/src/assets/icons/HeatWaveIcon.vue';
import HouseIcon from '@/components/storybook/src/assets/icons/HouseIcon.vue';
import ToolTipOnHover from '@/components/storybook/src/stories/toolTipOnHover/ToolTipOnHover.vue';
import center from '@turf/center';
import { points } from '@turf/helpers';
import { cloneDeep } from 'lodash';
import { SWITCH_ID__BUILDINGS } from '@/configs/layer-panel/buildings';
import { resetHighlightedBuildings } from '@/features/heat-project/highlight-buildings';

const store = useStore();
const props = defineProps({ editObjectId: Number });

const heatProject = ref(new HeatProject());
const materialSet = ref([]);

const getScenarioYears = computed(
  () => store.getters['scenario/getScenarioYears'],
);
const isEdit = computed(() => store.getters.getHeatProjectEditing);
const existingProjectNames = computed(() =>
  store.state.heatProject.heatProjectSelectables
    .filter((e) => e.id !== heatProject.value.id)
    .map((e) => e.name),
);

const currentConnectionRate = computed(() => {
  const homeConnections = draw
    .getAll()
    .features.filter((x) => x.properties.type === LAYERS.HOME_CONNECTION_POINT);
  return (
    homeConnections.filter((x) => x.properties.isConnected).length /
    homeConnections.length
  );
});

onMounted(() => {
  heatProject.value.heatProjectParameter.fetchParameterSets();
  store.dispatch('heatProject/GET_HEAT_PROJECT_SELECTABLES');
  axios({
    url: `/api/heatprojects/material-set/`,
    method: 'GET',
  }).then((resp) => {
    materialSet.value = resp.data.filter((e) => e.is_released === true);
  });
  if (
    props.editObjectId !== null &&
    typeof props.editObjectId !== 'undefined'
  ) {
    setMaxZoom(18);
    // If editing a heat project
    store.commit('SET_HEAT_PROJECT_EDIT', true);
    heatProject.value = store.state.heatProject.heatProjects.find(
      (e) => e.id === props.editObjectId,
    );
    pushNetworkToDraw();
    if (heatProject.value.trackLastVersion) {
      heatProject.value.setLastVersionParams(
        heatProject.value.constructUpdatePayload(draw.getAll().features),
      );
    }
    store.commit('map/CHANGE_SWITCH', { active: false, selectAll: true });
  } else {
    store.commit('SET_HEAT_PROJECT_EDIT', false);
    heatProject.value.scenario = getSelectedScenario.value.scenario_id;
    heatProject.value.connectBuildingInterface.scenario =
      getSelectedScenario.value.scenario_id;
    heatProject.value.connectBuildingInterface.initEventHooks();
    heatProject.value.connectHeatSourceInterface.initEventHooks();
    store.commit('map/CHANGE_SWITCH', {
      switches: [
        SWITCH_ID__HEAT_SOURCE_INVENTORY,
        SWITCH_ID__HEAT_SOURCE_POTENTIAL,
        SWITCH_ID__HEATING_CENTER_POTENTIAL,
        SWITCH_ID__HEATING_CENTER_INVENTORY,
        SWITCH_ID__GRID_FEED_POINT_POTENTIAL,
        SWITCH_ID__GRID_FEED_POINT_INVENTORY,
      ],
      active: true,
    });
  }
  heatProject.value.addDrawEventHooks();
  // heatProject.value.markHeatSources();
  heatProject.value.connectBuildingInterface.markAndFetchBuildings();
});

function save() {
  if (isEdit.value) {
    const payload = heatProject.value.constructUpdatePayload(
      draw.getAll().features,
    );
    axios({
      url: '/api/heatprojects/validate-big-gaps',
      method: 'POST',
      data: payload,
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    })
      .then((response) => {
        if (response.data.status === 'success') {
          store
            .dispatch('heatProject/PUT_HEAT_PROJECT', {
              heatProject: heatProject.value,
              payload,
            })
            .then(() => {
              showToast('save');
              store.dispatch('heatProject/GET_HEAT_PROJECT_SELECTABLES');
              draw.deleteAll();
              store.commit('SET_MAP_ACTIONS', null);
            });
        } else {
          showToast('faultySegment');
          const faultySegmentIds = response.data.faulty_segment_ids;
          draw.changeMode('simple_select', { featureIds: faultySegmentIds });
          const featureCenter = center(
            points([
              ...draw.get(faultySegmentIds[0]).geometry.coordinates,
              ...draw.get(faultySegmentIds[1]).geometry.coordinates,
            ]),
          ).geometry.coordinates;
          flyTo({
            zoom: 20,
            lat: featureCenter[1],
            lng: featureCenter[0],
            essential: true,
            maxZoom: 20,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    // validate that there are relevant buildings
    const payload = heatProject.value.constructCreatePayload(
      draw.getAll().features,
    );
    axios({
      url: '/api/heatprojects/validate-relevant-buildings',
      method: 'POST',
      data: payload,
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    })
      .then((response) => {
        if (response.data.status === 'success') {
          store
            .dispatch('heatProject/POST_HEAT_PROJECT', heatProject.value)
            .then(() => {
              store.dispatch('heatProject/GET_HEAT_PROJECT_SELECTABLES');
              showToast('save');
              draw.deleteAll(); // ?
              store.commit('SET_MAP_ACTIONS', null);
            });
        } else {
          console.error('Validation failed:', response.data.message);
          // todo showToast with an error message
        }
      })
      .catch((err) => {
        console.error(err);
        // Handle request error
      });
  }
}

function resetBuildings() {
  heatProject.value.connectBuildingInterface.resetBuildings();
  showOptionsTooltip.value = false;
  showToast('createBuilding');
}

function refreshBuildings() {
  heatProject.value.connectBuildingInterface.getBuildingsWithMinimumArea(
    heatProject.value.minimumBuildingArea,
  );
}

function pushNetworkToDraw() {
  const featureProps = [
    ...heatProject.value.distributionNetworkFeatures,
    ...heatProject.value.connectBuildingInterface.networkFeatures,
    ...heatProject.value.connectBuildingInterface.pointFeatures,
    ...heatProject.value.connectHeatSourceInterface.networkFeatures,
  ];
  featureProps.forEach((c) => {
    delete c.id;
    draw.add(c);
  });
  const heatSources = store.getters['heatProject/getHeatSourcesAsFC'].features;
  for (const heatSource of heatSources) {
    const { properties } = heatSource;
    const HeatSourceIds = heatProject.value.getHeatSourceIds;
    properties.isConnected = HeatSourceIds.includes(properties.id);
    properties.type = LAYERS.HEAT_SOURCE_POINT;
    draw.add(heatSource);
  }
}

function abort() {
  store.commit('SET_MAP_ACTIONS', null);
}

// UI, render and form logic

const step = ref(1);
const step1isValid = ref(false);
const step2isValid = ref(true);
const showOptionsTooltip = ref(false);
const activeTab = ref(0);
const validCards = ref([]);
const tabs = [
  { id: 0, icon: HouseIcon, contentTitle: 'Gebäudeselektion' },
  {
    id: 1,
    icon: HeatWaveIcon,
    contentTitle: 'Standorte und Erzeugungskosten',
  },
  { id: 2, icon: TubesIcon, contentTitle: 'Netz' },
];
const buildingCount = computed(() => {
  return heatProject.value.getBuildingIds.length;
});
const heatSourcesCount = computed(() => {
  return heatProject.value.getHeatSources.length;
});
const tabContainerData = computed(() => {
  const heatLoad = heatProject.value.heatLoadWithFactor.toLocaleString(
    'de-DE',
    { maximumFractionDigits: 1 },
  );
  const demand = heatProject.value.heatDemandWithFactor / 1000;
  const heatDemand = demand.toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return [
    { title: 'Gebäude', body: buildingCount.value.toLocaleString('de-DE') },
    { title: 'Wärmebedarf', body: `${heatDemand} GWh` },
    { title: 'Last', body: `${heatLoad} MW` },
  ];
});
const containerHeight = computed(() => store.getters['map/getContainerHeight']);
const getSelectedScenario = computed(
  () => store.getters['scenario/getSelectedScenario'],
);

const getBarChartData = computed(() =>
  barChartData(
    heatProject.value.heatOutput,
    heatProject.value.heatLoadWithFactor,
  ),
);

const assignedHeatSourcesIsValid = computed(() => {
  let isValid = true;
  const assignHeatSources = heatProject.value.getHeatSources;
  for (const heatSource of assignHeatSources) {
    if (!heatSource.generationCost) isValid = false;
  }
  return isValid;
});

const disableSaveButton = computed(() => {
  if (!props.editObjectId && step.value === 1 && !step1isValid.value) {
    return true;
  } else if (step.value === 2 && activeTab.value === 0 && !step2isValid.value) {
    return true;
  } else if (
    step.value === 2 &&
    activeTab.value === 0 &&
    buildingCount.value > 10000
  ) {
    return true;
  } else if (
    step.value === 2 &&
    activeTab.value === 2 &&
    !heatProject.value.isValid
  ) {
    return true;
  } else if (
    step.value === 2 &&
    activeTab.value === 0 &&
    buildingCount.value === 0
  ) {
    return true;
  } else if (
    step.value === 2 &&
    activeTab.value === 1 &&
    !assignedHeatSourcesIsValid.value
  ) {
    return true;
  }

  return false;
});

const saveButtonText = computed(() => {
  if (
    (step.value === 2 && heatProject.value.isValid) ||
    activeTab.value === 2 ||
    (heatProject.value.materialParameterSet !== null && step.value === 2)
  ) {
    return 'Speichern';
  }
  return 'Weiter';
});

const saveButtonIcon = computed(() => {
  if (
    activeTab.value === 0 &&
    heatProject.value.materialParameterSet !== null &&
    !heatProject.value.isValid &&
    step.value === 2
  ) {
    return null;
  } else if (
    activeTab.value !== 2 &&
    (!heatProject.value.isValid || step.value === 1)
  ) {
    return 'arrow_right_alt';
  }
  return null;
});

function handleTabSwitching(index) {
  if (step2isValid.value && buildingCount.value < 10000) {
    activeTab.value = index;
  }
}

function saveButtonAction() {
  if (step.value === 1 && (props.editObjectId || step1isValid.value)) {
    step.value++;
  } else if (step.value === 2 && heatProject.value.isValid) {
    save();
  } else {
    activeTab.value++;
  }
}

function backButtonAction() {
  if (step.value === 1) {
    abort();
  } else if (step.value === 2) {
    if (activeTab.value > 0) {
      activeTab.value--;
    } else {
      step.value--;
    }
  } else {
    step.value--;
  }
}

watch(
  step,
  (newValue) => {
    if (newValue === 2 && buildingCount.value === 0 && !isEdit.value) {
      showToast('createBuilding');
      buildingsWithin();
    }
  },
  { immediate: true },
);

watch(
  activeTab,
  (newValue) => {
    store.commit('layout/HIDETOAST');
    if (newValue === 1 && !heatSourcesCount.value) {
      showToast('selectHeatSource');
    }
  },
  { immediate: true },
);

watch(
  [
    () => buildingCount.value,
    () => heatSourcesCount.value,
    () => heatProject.value.materialParameterSet,
  ],
  ([newBuildingCount, newHeatSourcesCount, newMaterialParameterSet]) => {
    const newValidCards = [];
    if (newBuildingCount > 0) {
      newValidCards.push(0);
      store.commit('layout/HIDETOAST');
    }
    if (newHeatSourcesCount > 0) newValidCards.push(1);
    if (newMaterialParameterSet !== null) newValidCards.push(2);
    validCards.value = newValidCards.sort();
  },
  { immediate: true },
);

const toasts = [
  {
    name: 'save',
    color: 'infra-highlight-500',
    message: `Die Berechnung kann abhängig von der Größe des Projektgebietes
    bis zu einigen Minuten dauern.`,
    title: 'Berechnung gestartet',
    timeout: 3000,
  },
  {
    name: 'createBuilding',
    color: 'infra-highlight-500',
    message: `Bitte selektieren Sie Gebäude auf der Karte indem Sie mithilfe des
      Polygon-Werkzeuges einen Rahmen aufziehen, oder durch Rechtsklick
      auf Gebäude klicken.\n\n Bei der Selektion mithilfe des Polygon-Werkzeugs
      werden keine Gebäude berücksichtigt, die bereits fernwärmeversorgt sind,
      oder keinen Wärmebedarf haben.`,
    timeout: -1,
  },
  {
    name: 'selectHeatSource',
    color: 'infra-highlight-500',
    message: `Bitte Wärmequelle einblenden und per Rechtsklick
    auf Kartenelement wählen.`,
    timeout: -1,
  },
  {
    name: 'faultySegment',
    color: 'error',
    message:
      'Das von Ihnen übermittelte Netz enthält zu große Lücken zwischen ' +
      'den Segmenten. Das Segment mit der Problemstelle wurde für Sie markiert ' +
      'und in den Mittelpunkt des Kartenausschnitts gebracht.',
    timeout: -1,
  },
];

function showToast(id) {
  const { message, title, timeout, color } = toasts.find(
    (toast) => toast.name === id,
  );
  store.commit('layout/SHOWTOAST', {
    color,
    message,
    title,
    timeout,
  });
}

function getTabTitle() {
  return tabs.find((tab) => tab.id === activeTab.value).contentTitle;
}

function updateHeatProjectParameters(params) {
  heatProject.value.heatProjectParameter = cloneDeep(params);
}

onBeforeUnmount(() => {
  resetHighlightedBuildings();
  store.commit('heatProject/RESET_HEAT_PROJECT');
  heatProject.value.destroy();
  deleteFeatures();
});

onUnmounted(() => {
  exitDraw();
  setMaxZoom();
  store.commit('SET_HEAT_PROJECT_EDIT', false);
  store.commit('map/CHANGE_SWITCH', {
    switches: [SWITCH_ID__BUILDINGS],
    active: true,
  });
  store.commit('layout/HIDETOAST');
});
</script>
