<template>
  <skeleton-loader height="28px" width="40%" />
  <skeleton-loader height="36px" width="65%" class="mt-3" />
  <VExpansionPanels
    v-model="activePanelSkeleton"
    variant="accordion"
    class="scenario-frame-expansion-panel pointer-events-none my-4 w-full gap-1"
  >
    <VExpansionPanel v-for="item in 3" :key="item" :model="item">
      <VExpansionPanelTitle collapse-icon="mdi-plus">
        <skeleton-loader height="24px" width="250px" />
        <template #actions>
          <ExpansionPanelTitleActions />
        </template>
      </VExpansionPanelTitle>
      <VExpansionPanelText>
        <skeleton-loader height="190px" width="100%" />
      </VExpansionPanelText>
    </VExpansionPanel>
  </VExpansionPanels>
</template>

<script setup>
import { ref } from 'vue';
import SkeletonLoader from '@/components/storybook/src/stories/skeletonLoaderItem/skeletonLoader.vue';
import ExpansionPanelTitleActions from '@/features/scenario-frame/components/ExpansionPanelTitleActions.vue';

const activePanelSkeleton = ref(0);
</script>
