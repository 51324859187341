<template>
  <div>
    <div class="flex w-full flex-col gap-5 p-4">
      <div class="flex items-center justify-between gap-4">
        <PersonalAvatar />
        <h2>{{ profile.username }}</h2>
      </div>
      <div class="border-b-2">
        <v-tabs
          v-model="tab"
          class="mb-[-2px]"
          density="compact"
          slider-color="#0692BC"
        >
          <v-tab
            style="margin-left: 0 !important"
            value="one"
            :class="tabStyle"
            :ripple="false"
            >Persönliche Angaben
          </v-tab>
          <v-tab value="two" :class="tabStyle" :ripple="false"
            >Passwort administrieren
          </v-tab>
          <v-tab
            v-if="isSuperUser"
            value="three"
            :class="tabStyle"
            :ripple="false"
            >2FA
          </v-tab>
        </v-tabs>
      </div>

      <v-card-text class="p-0">
        <v-window v-model="tab">
          <v-window-item value="one">
            <PersonalData :profile />
          </v-window-item>
          <v-window-item value="two">
            <PasswordPage />
          </v-window-item>
          <v-window-item value="three">
            <TwofaSettingsPage />
          </v-window-item>
        </v-window>
      </v-card-text>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import PersonalAvatar from '@/components/global/PersonalAvatar.vue';
import PersonalData from '@/views/profile/PersonalData.vue';
import PasswordPage from '@/views/profile/PasswordPage.vue';
import TwofaSettingsPage from '@/views/profile/TwofaSettingsPage.vue';

const store = useStore();

const isSuperUser = computed(() => store.getters['auth/profil'].isSuperUser);
const tab = ref(null);
const profile = computed(() => store.getters['auth/profil']);
const tabStyle =
  'px-0 normal-case text-[18px] hover:text-infra-highlight-500 ml-4 tracking-normal';
</script>
