<template>
  <div v-if="tableData.length < 1" class="my-10 flex justify-center">
    <VProgressCircular
      class="text-title-color1"
      size="60"
      width="7"
      indeterminate
    />
  </div>
  <div v-else>
    <!--  Year values row -->
    <table class="sticky top-0 z-40 bg-default">
      <thead>
        <tr class="text-title-color1">
          <th class="first-year-cell">
            <div class="flex items-center">
              <ButtonEl
                icon="event_repeat"
                variant="tertiary"
                :disabled="page === 1"
                class="mr-5"
                @click="page = 1"
              />
              <ButtonEl
                icon="chevron_left"
                variant="tertiary"
                :disabled="page === 1"
                @click="page = page - 1"
              />
              <h4 class="mx-2 text-title-color1">
                {{ tableData[0].year }} -
                {{ tableData[tableData.length - 1].year }}
              </h4>
              <ButtonEl
                icon="chevron_right"
                variant="tertiary"
                :disabled="page === Math.ceil(resultsCount / 10)"
                @click="page = page + 1"
              />
            </div>
          </th>
          <th v-for="item in tableData" :key="item" class="year-cell">
            <h4>{{ item.year }}</h4>
          </th>
          <!-- On the last page sometimes there are less data entries then before -->
          <th
            v-for="index in placeholdersCount"
            :key="'placeholder-' + index"
            class="year-cell"
          ></th>
        </tr>
      </thead>
    </table>

    <!--  Heat data table -->
    <table class="body-1 standard-elevation-0-dark mb-5">
      <tbody>
        <TabWireKalkulationRow
          v-for="row in heatRows"
          :key="row.dataKey"
          :row-specs="row"
          :data="tableData"
          :placeholders-count
        />
      </tbody>
    </table>

    <!--  Economic data table -->
    <table class="body-1 standard-elevation-0-dark mb-5">
      <tbody>
        <TabWireKalkulationRow
          v-for="row in economicRows"
          :key="row.dataKey"
          :row-specs="row"
          :data="tableData"
          :loading="tableData.length < 1"
          :placeholders-count
        />
      </tbody>
    </table>

    <!--  Cashflow data table -->
    <table class="body-1 standard-elevation-0-dark mb-5">
      <tbody>
        <TabWireKalkulationRow
          v-for="row in cashflowRows"
          :key="row.dataKey"
          :row-specs="row"
          :data="tableData"
          :placeholders-count
        />
      </tbody>
    </table>

    <!--  Investment measures data row -->
    <table class="body-1 standard-elevation-0-dark mb-5">
      <tbody>
        <TabWireKalkulationRow
          :row-specs="investmentMeasuresRow"
          :data="tableData"
          :placeholders-count
        />
      </tbody>
    </table>

    <!--  Cumulative Cashflow data table -->
    <table class="body-1 standard-elevation-0-dark mb-5">
      <tbody>
        <TabWireKalkulationRow
          v-for="row in cumulativeCashflowRows"
          :key="row.dataKey"
          :row-specs="row"
          :data="tableData"
          :placeholders-count
        />
      </tbody>
    </table>

    <!--  Cumulative investment measures data row -->
    <table class="body-1 standard-elevation-0-dark mb-5">
      <tbody>
        <TabWireKalkulationRow
          :row-specs="cumulativeInvestmentMeasuresRow"
          :data="tableData"
          :placeholders-count
        />
      </tbody>
    </table>

    <!--  Specifics data table -->
    <table class="body-1 standard-elevation-0-dark">
      <tbody>
        <TabWireKalkulationRow
          v-for="row in specRows"
          :key="row.dataKey"
          :row-specs="row"
          :data="tableData"
          :placeholders-count
        />
      </tbody>
    </table>
    <div v-if="canDownload" class="mt-5 rounded-md bg-subtle p-[10px]">
      <WiReDownloadContainer
        :wire-id="projectData.id"
        :wire-name="projectData.name"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import axios from 'axios';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import TabWireKalkulationRow from '@/apps/economic-efficiency/edit-tab-items/TabWireKalkulationRow.vue';
import { downloadFile } from '@/utils/axiosHelper';
import { useStore } from 'vuex';
import WiReDownloadContainer from '@/apps/economic-efficiency/WiReDownloadContainer.vue';

const props = defineProps({
  projectData: {
    type: Object,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const canDownload = computed(() => store.getters['auth/wireCanDownload']);

const tableData = ref([]);
const isLoaded = ref(false);
const page = ref(1);
const resultsCount = ref(0);

const placeholdersCount = computed(() => {
  const totalColumns = 10;
  const dataLength = tableData.value.length;
  return dataLength < totalColumns ? totalColumns - dataLength : 0;
});

const heatRows = [
  {
    header: 'FW-Verbrauch',
    unit: 'kWh',
    dataKey: 'heat_demand_kwh',
  },
  {
    header: 'Wärmeverluste',
    unit: 'kWh',
    dataKey: 'heat_losses_kwh',
  },
  {
    header: 'Benötigte Wärmemenge',
    unit: 'kWh',
    dataKey: 'required_heat_kwh',
    rowClass: 'bg-subtle font-semibold',
    headerClass: 'font-semibold',
  },
];

const economicRows = [
  { header: 'Erlöse FW', unit: 'Euro', dataKey: 'revenue_eur' },
  {
    header: 'Erzeugungskosten FW',
    unit: 'Euro',
    dataKey: 'generation_costs_eur',
  },
  {
    header: 'Rohmarge',
    unit: 'Euro',
    dataKey: 'gross_margin',
    rowClass: 'bg-subtle font-semibold',
    headerClass: 'font-semibold',
    helpText: 'Erlöse FW - Erzeugungskosten FW',
  },
];

const cashflowRows = [
  { header: 'Rohmarge', unit: 'Euro', dataKey: 'gross_margin' },
  { header: 'Betriebskosten', unit: 'Euro', dataKey: 'opex' },
  { header: 'Vertriebskosten', unit: 'Euro', dataKey: 'sales_cost' },
  {
    header: 'Operativer Zahlungsstrom',
    unit: 'Euro',
    dataKey: 'operative_cash_flow',
    rowClass: 'bg-subtle font-semibold',
    headerClass: 'font-semibold',
  },
];

const investmentMeasuresRow = {
  header: 'Investitionsmaßnahmen',
  unit: 'Euro',
  dataKey: 'invest_measures',
  rowClass: 'bg-subtle font-semibold',
  headerClass: 'font-semibold',
};

const cumulativeCashflowRows = [
  {
    header: 'Kumulierte Rohmarge',
    unit: 'Euro',
    dataKey: 'cumulative_gross_margin',
  },
  {
    header: 'Kumulierte Betriebskosten',
    unit: 'Euro',
    dataKey: 'cumulative_opex',
  },
  {
    header: 'Kumulierte Vertriebskosten',
    unit: 'Euro',
    dataKey: 'cumulative_sales_cost',
  },
  {
    header: 'Kumulierter operativer Zahlungsstrom',
    unit: 'Euro',
    dataKey: 'cumulative_operative_cash_flow',
    rowClass: 'bg-subtle font-semibold',
    headerClass: 'font-semibold',
  },
];

const cumulativeInvestmentMeasuresRow = {
  header: 'Kumulierte Investitionsmaßnahmen',
  unit: 'Euro',
  dataKey: 'net_invest',
  rowClass: 'bg-subtle font-semibold',
  headerClass: 'font-semibold',
};

const specRows = [
  {
    header: 'Spezifische Erlöse',
    unit: 'ct / kWh',
    dataKey: 'spec_revenue_ct_kwh',
    helpText: 'Erlöse FW / Verbrauch FW',
  },
  {
    header: 'Spezifische Rohmarge',
    unit: 'ct / kWh',
    dataKey: 'spec_gross_margin_ct_kwh',
    helpText: 'Rohmarge / Verbrauch FW',
  },
];

function getTableData() {
  return axios({
    url: '/api/heatprojects/wi-re/wi-re-yearly/',
    method: 'GET',
    params: {
      wire_id: props.projectData.id,
      page: page.value,
    },
  }).then((response) => {
    resultsCount.value = response.data.count;
    tableData.value = response.data.results;
    isLoaded.value = true;
  });
}

function downloadCalculationTable() {
  const url = `/api/heatprojects/wi-re/wi-re/${props.projectData.id}/download-excel/`;
  downloadFile(url);
}

const emit = defineEmits(['update:calculateDisabled']);

onMounted(() => {
  emit('update:calculateDisabled', true);
  getTableData();
});

watch(page, () => {
  getTableData();
});
</script>

<style scoped lang="scss">
table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-radius: 6px;
}

th {
  width: 230px;
  text-align: left;
  padding: 18px 20px 18px 0;
}

.first-year-cell {
  width: 370px;
  padding-left: 0;
}

.year-cell {
  text-align: right;
  width: auto;
  white-space: nowrap;
}
</style>
