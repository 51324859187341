<template>
  <div
    class="flex flex-col gap-2.5 px-2.5 py-[6px]"
    :class="{
      'bg-infra-highlight-25 pb-3': systemTypeSelected === systemType.id,
      'cursor-pointer hover:bg-infra-highlight-50': !disabled,
    }"
    @click="updateSystemType"
  >
    <div class="flex items-center justify-between gap-2">
      <VRadio :disabled="disabled" :density="null" :value="systemType.id">
        <template #label>
          <h6 class="ml-1 mt-0.5 text-infra-highlight-700">
            {{ systemType.label }}
          </h6>
          <ToolTipOnHover
            :tooltip-text="tooltipMap[systemType.label]"
            :icon-size="20"
            class="mb-0.5 ml-0.5"
          />
        </template>
      </VRadio>
      <Component
        :is="icon"
        :class="{ 'opacity-30': disabled }"
        :fill="systemTypeSelected === systemType.id ? '#01799D' : 'initial'"
      />
    </div>
    <!-- required params-->
    <template v-if="systemTypeSelected === systemType.id">
      <div
        class="standard-elevation-0-dark flex flex-col gap-2 rounded-[4px] bg-white p-2.5"
      >
        <slot name="required-params" />
      </div>

      <!-- optional params -->
      <div
        v-if="optionalParams"
        class="standard-elevation-0-dark flex flex-col gap-2 rounded-[4px] bg-white p-2"
      >
        <div>
          <ButtonEl
            variant="secondary"
            :full-width="true"
            :text="
              optionalParameters
                ? 'Optionale Parameter ausblenden'
                : 'Optionale Parameter einblenden'
            "
            :icon="optionalParameters ? 'north' : 'south'"
            size="m"
            @click="optionalParameters = !optionalParameters"
          />
        </div>
        <div v-if="optionalParameters" class="flex flex-col gap-2">
          <slot name="optional-params" />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import ToolTipOnHover from '@/components/storybook/src/stories/toolTipOnHover/ToolTipOnHover.vue';

const props = defineProps({
  systemType: {
    type: Object,
    required: true,
  },
  systemTypeSelected: {
    type: String,
    required: true,
  },
  optionalParams: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: 'EditPointHeatSourceFilled',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['systemTypeSelected']);

const optionalParameters = ref(false);

function updateSystemType() {
  if (!props.disabled) {
    emit('systemTypeSelected', props.systemType.id);
  }
}

const tooltipMap = {
  Wärmequelle:
    'Einzelne Anlage, die direkt Wärme in das Wärmenetz einspeisen kann',
  Heizzentrale:
    'Fasst die Wärme aus mehreren Wärmequellen zusammen und speist diese in das Netz ein',
  Aufspeisepunkt:
    'Punkt für Anschluss von einem neuen Fernwärmenetz an das Bestandsnetz',
};
</script>
