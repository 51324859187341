<template>
  <div class="relative mt-5 flex w-full justify-center">
    <ButtonEl
      class="absolute z-10"
      :icon="geoChartHidden ? 'keyboard_arrow_down' : 'keyboard_arrow_up'"
      :text="geoChartHidden ? 'Karte einblenden' : 'Karte ausblenden'"
      custom-bg-hover-color="hover:bg-default"
      custom-bg-color="bg-default"
      custom-text-color="text-title-color1"
      custom-text-hover-color="hover:text-color1"
      @click="toggleGeoChart"
    />
  </div>
  <div
    v-if="transitionEffectEnded && !geoChartHidden"
    class="relative flex items-center justify-center bg-default p-5 pt-0"
  >
    <div class="h-[550px] w-[500px]">
      <GeoMunicipalityChart
        :selected-municipalities="formatSelectionForChildComponent"
        :available-municipalities="municipalities"
        @update:selected-municipalities="formatSelectionForParentComponent"
        @loaded="loaded = true"
      />
    </div>
    <div v-if="!loaded" class="absolute flex w-full justify-center">
      <VProgressCircular
        class="text-title-color1"
        size="60"
        width="8"
        indeterminate
      />
    </div>
  </div>
  <div v-if="geoChartHidden" class="h-14" />
</template>
<script setup>
import GeoMunicipalityChart from '@/apps/analysis-dashboard/charts/GeoMunicipalityChart.vue';
import { computed, onMounted, ref, watch } from 'vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import axios from '@/utils/axiosHelper';

const props = defineProps({
  municipalities: {
    type: Array,
    default: () => [],
  },
  municipalitySelected: {
    type: String,
    default: null,
  },
  scenarioSelected: {
    type: Number,
    default: null,
  },
});

const loaded = ref(false);
const transitionEffectEnded = ref(false);
const geoChartHidden = ref(
  localStorage.getItem('geochart-on-dashboard-hidden') === 'true',
);

onMounted(() => {
  fetchMunicipalitiesByScenario();
  window.setTimeout(() => (transitionEffectEnded.value = true), 450);
});

const formatSelectionForChildComponent = computed(() => {
  if (props.municipalitySelected !== 'all') {
    return [{ municipality_id: props.municipalitySelected }];
  } else {
    return municipalitiesByScenario.value.map((item) => {
      return { municipality_id: item.municipality_key };
    });
  }
});

const emit = defineEmits(['update:selectedMunicipality']);

function formatSelectionForParentComponent(selectedMunicipality) {
  emit('update:selectedMunicipality', selectedMunicipality.municipality_id);
}

function toggleGeoChart() {
  localStorage.setItem(
    'geochart-on-dashboard-hidden',
    String(!geoChartHidden.value),
  );
  geoChartHidden.value = !geoChartHidden.value;
}

//------ Map Selection always considers selected scenario ------
const municipalitiesByScenario = ref([]);

function fetchMunicipalitiesByScenario() {
  return axios({
    method: 'GET',
    url: `/api/buildingmodel/municipality-selection/${props.scenarioSelected}/`,
  }).then((resp) => {
    municipalitiesByScenario.value = resp.data.data;
  });
}

watch(
  () => props.scenarioSelected,
  () => fetchMunicipalitiesByScenario(),
);
</script>
