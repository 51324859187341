<template>
  <div
    :class="[
      {
        'min-h-[300px]': !small,
        'min-h-[220px]': small,
        isParameter: isParameter,
        parameterIsExpanded: parameterIsExpanded,
        'w-full hover:scale-[1.01]': !isParameter,
        'cursor-pointer px-3 duration-100 hover:bg-blue-grey-10':
          !parameterIsExpanded,
      },
      disabled ? 'pointer-events-none bg-line-neutral' : 'bg-default',
    ]"
    class="mx-auto flex flex-col items-center justify-center rounded-[8px] shadow-none transition-all"
    @click="cardClick"
  >
    <template v-if="isParameter && parameterIsExpanded">
      <div
        class="absolute left-[-89px] flex min-w-[220px] -rotate-90 items-center gap-3 bg-white p-2.5"
      >
        <img class="w-[24px]" :src="cardData.icon" alt="card-img" />
        <h6 class="text-title-color1">
          {{ cardData.title }}
        </h6>
        <VIcon
          icon="mdi-close"
          class="ml-auto text-title-color1"
          @click.stop="parameterIsExpanded = false"
        />
      </div>
      <div
        class="grid min-h-[220px] w-full auto-cols-fr grid-flow-col items-center gap-3 bg-canvas pl-[54px] pr-2.5"
      >
        <template v-for="item in cardData.children" :key="item">
          <div
            v-if="isChildCardActive(item)"
            class="flex h-[200px] w-full cursor-pointer flex-col items-center justify-center rounded-[6px] bg-default px-2 hover:bg-subtle"
            @click="item.action()"
          >
            <img
              class="mb-2 h-[30%] w-[48px]"
              :src="item.icon"
              alt="card-img"
            />
            <div class="flex h-1/2 flex-col items-center gap-1">
              <h4 class="mb-1 text-center text-title-color1">
                {{ item.title }}
              </h4>
              <div class="body-3 text-center text-neutral">
                {{ item.description }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <img
        class="pointer-events-none h-[40%]"
        :class="{
          'w-[64px]': small,
          'w-[96px]': !small,
          'opacity-50': disabled,
        }"
        :src="cardData.icon"
        alt="card-img"
      />
      <div
        :class="{ 'gap-1.5': small, 'gap-2 pt-4': !small }"
        class="pointer-events-none flex h-1/2 flex-col items-center"
      >
        <component
          :is="small ? 'h4' : 'h3'"
          class="mb-1 whitespace-pre-wrap text-center"
          :class="disabled ? 'text-disabled-neutral' : 'text-title-color1'"
          >{{ cardData.title }}
        </component>
        <div
          :class="[
            small ? 'body-3' : 'body-1',
            disabled ? 'text-disabled-neutral' : 'text-neutral',
          ]"
          class="text-center"
        >
          {{ cardData.description }}
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { useModuleStore } from '@/configs/module-store';
import { useListeners } from '@/components/storybook/src/composables/useListeners';

const moduleStore = useModuleStore();

const props = defineProps({
  cardData: {
    type: Object,
  },
  small: {
    type: Boolean,
    default: false,
  },
  isParameter: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const parameterIsExpanded = ref(false);

useListeners({
  click: handleClickOutside,
  keydown: handleKeydown,
});

function handleClickOutside(event) {
  const isParameterElement = event.target.closest('.isParameter');
  // Execute only if parameterIsExpanded is true and the click is outside the .isParameter element
  if (parameterIsExpanded.value && !isParameterElement) {
    parameterIsExpanded.value = false;
  }
}

function handleKeydown(event) {
  if (event.key === 'Escape' && parameterIsExpanded.value) {
    parameterIsExpanded.value = false;
  }
}

function cardClick() {
  if (props.isParameter) {
    parameterIsExpanded.value = true;
  } else {
    props.cardData.action();
  }
}

function isChildCardActive(card) {
  return moduleStore.isActive(card.module);
}
</script>

<style lang="scss" scoped>
.isParameter {
  max-width: 500px;
  width: 100%;
  transition: max-width 0.75s ease; // adjust duration and easing as needed
  overflow: hidden; // hide content during transition
}

.parameterIsExpanded {
  max-width: 100%;
  position: absolute;
  z-index: 100;
}
</style>
