<template>
  <div class="flex w-full flex-col items-center py-7">
    <ChartTitle
      v-if="title"
      :title
      :title-custom-size
      :download-file-name
      @download-clicked="downloadChart(chartRef, props)"
    >
      <slot />
    </ChartTitle>
    <v-chart
      ref="chartRef"
      class="h-full w-full"
      :style="{ 'min-height': customMinHeight }"
      :option="option"
      autoresize
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { sum } from 'lodash';
import { downloadChart } from '@/utils/chart-download';
import { formatChartValue, getColor } from '@/apps/analysis-dashboard/utils';
import ChartTitle from '@/apps/analysis-dashboard/charts/ChartTitle.vue';

const props = defineProps({
  chartData: {
    type: Array,
    default: () => [],
  },
  downloadFileName: String,
  downloadTitleFontSize: {
    type: Number,
    default: 65,
  },
  downloadTitle: String,
  titleInside: String,
  showTitleInside: {
    type: Boolean,
    default: true,
  },
  customTitleInside: {
    type: [String, null],
    default: null,
  },
  title: String,
  chartUnit: {
    type: String,
    default: '',
  },
  chartTop: String,
  titleTop: {
    type: String,
    default: 'center',
  },
  titleInsideLH: {
    type: Number,
    default: 0,
  },
  titleCustomSize: Number,
  multiply: Number,
  customPadding: String,
  customMinHeight: {
    type: String,
    default: '250px',
  },
  customRadius: {
    type: [Array, String],
    default: () => ['60%', '75%'],
  },
  showLabel: {
    type: Boolean,
    default: true,
  },
});

const chartRef = ref(null);

const filterChartData = computed(() => {
  return props.chartData.filter(
    (item) => item.value !== 0 && item.value !== 0.0,
  );
});

const titleText = computed(() => {
  if (props.customTitleInside) {
    return props.customTitleInside;
  } else {
    const applyMultiply = (item) =>
      props.multiply ? item.value * props.multiply : item.value;
    const totalSum = sum(filterChartData.value.map(applyMultiply));
    const formattedSum = Math.round(totalSum).toLocaleString(
      navigator.language,
    );

    return `{a|${formattedSum}}{b| ${props.chartUnit}\n${props.titleInside}}`;
  }
});

const calculatedColor = computed(() => {
  return filterChartData.value.map(getColor);
});

const option = computed(() => {
  const sumValues = sum(filterChartData.value.map((e) => e.value));
  return {
    title: {
      show: props.showTitleInside,
      text: titleText.value,
      left: 'center',
      top: props.titleTop,
      textStyle: {
        lineHeight: 15,
        fontFamily: 'Borna, sans-serif',
        rich: {
          a: {
            color: '#757575',
            fontWeight: 'bold',
            fontSize: 16,
            lineHeight: props.titleInsideLH,
          },
          b: {
            fontSize: 12,
            color: '#424242',
            padding: [2, 0, 0, 0],
            fontFamily: 'Borna, sans-serif',
            fontWeight: 500,
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        radius: props.customRadius,
        top: props.chartTop,
        data: filterChartData.value.map((item) => ({
          ...item,
          emphasis: {
            scale: true,
            itemStyle: {
              color: item.color,
            },
          },
        })),
        label: {
          show: props.showLabel,
          formatter: (value) => {
            const percentage = (value.value / sumValues) * 100;
            return `{a|${value.name}}\n{b|${formatChartValue(percentage)}%}`;
          },
          rich: {
            a: {
              fontSize: 12,
              lineHeight: 20,
              color: '#757575',
              fontWeight: 600,
              fontFamily: 'Inter, sans-serif',
              align: 'center',
              style: 'normal',
            },
            b: {
              fontSize: 12,
              color: '#424242',
              fontFamily: 'Inter, sans-serif',
              align: 'center',
              fontWeight: 600,
            },
          },
        },
        color: calculatedColor.value,
      },
    ],
  };
});
</script>
