import store from '@/store';

export const BAR_CHART_X_AXIS = {
  type: 'value',
  axisLabel: {
    show: false,
  },
  axisLine: {
    show: false,
  },
  axisTick: {
    show: false,
  },
  splitLine: {
    show: false,
  },
};

function toLocaleCustom(val, decimalPlaces) {
  return val.toLocaleString('de-DE', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
}

export function formatChartValue(value, smaller1 = 1, bigger1 = 0) {
  // Prevent displaying 0,0 or 0,00 e.g.
  if (Math.abs(value) < Math.pow(0.1, smaller1)) {
    return `< ${toLocaleCustom(value, smaller1).replace(/.$/, '1')}`;
  } else if (Math.abs(value) < 1) {
    return toLocaleCustom(value, smaller1);
  } else {
    return toLocaleCustom(value, bigger1);
  }
}

export function getBarChartHeight(minHeight, bars) {
  if (minHeight && bars.length !== 0) {
    return 'height:' + bars.length * minHeight + 'px';
  }
  if (minHeight && bars.length === 0) {
    return 'height: 0px';
  }
  return '';
}

export function getColor(item) {
  if (item.pattern) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = 2;
    canvas.height = 2;

    ctx.fillStyle = item.color;
    ctx.fillRect(0, 0, 2, 2);
    ctx.fillStyle = 'white';
    ctx.fillRect(1, 1, 1, 1);

    return {
      image: canvas,
      repeat: 'repeat',
    };
  }
  return item.color;
}

export function preventCheckboxesUnSelected(selected, other) {
  if (!selected && !other) {
    store.commit('layout/SHOWTOAST', {
      color: 'warning',
      message: 'Es muss mindestens eine Checkbox ausgewählt sein.',
      timeout: 3000,
    });
    return true;
  }
  return selected;
}

export function formatNumber(value) {
  return value.toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

function downloadElement(canvas, fileName) {
  const link = document.createElement('a');
  link.download = `${fileName}.png`;
  link.href = canvas.toDataURL('image/png');
  link.click();
}

// ------------- Cards Download utils -------------------

export function downloadCards(cards) {
  const { ctx, canvas } = createCardsCanvasContext();
  const headers = ['Kategorie', 'Wert', 'Einheit'];
  const table = {
    xStartCoordinate: 50,
    yStartCoordinate: 50,
    rows: cards.length + 1, // 1 header + 5 data rows
    cols: headers.length,
    cellWidth: 250,
    cellHeight: 60,
    headers: headers,
  };

  drawTableShadow(ctx, table);
  drawTableHeaders(ctx, table);
  drawTableRows(ctx, table, cards);

  downloadElement(canvas, 'table');
}

function createCardsCanvasContext() {
  const canvas = document.createElement('canvas');
  canvas.setAttribute('width', '850');
  canvas.setAttribute('height', '600');
  const ctx = canvas.getContext('2d');

  ctx.strokeStyle = '#000';
  ctx.textAlign = 'left';
  ctx.textBaseline = 'middle';

  return { ctx, canvas };
}

function drawTableShadow(
  ctx,
  { xStartCoordinate, yStartCoordinate, cols, rows, cellWidth, cellHeight },
) {
  ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
  ctx.shadowBlur = 10;
  ctx.shadowOffsetX = 5;
  ctx.shadowOffsetY = 5;

  // Draw table background (to apply shadow)
  ctx.fillRect(
    xStartCoordinate,
    yStartCoordinate,
    cols * cellWidth,
    rows * cellHeight,
  );

  // Remove shadow for following drawings
  ctx.shadowColor = 'rgba(0, 0, 0, 0)';
}

function drawTableHeaders(
  ctx,
  { xStartCoordinate, yStartCoordinate, cols, cellWidth, cellHeight, headers },
) {
  // Draw header background
  ctx.fillStyle = '#fff';
  ctx.fillRect(
    xStartCoordinate,
    yStartCoordinate,
    cols * cellWidth,
    cellHeight,
  );

  // Draw header text
  ctx.font = 'bold 16px Arial';
  ctx.fillStyle = '#000';
  headers.forEach((headerText, i) => {
    ctx.fillText(
      headerText,
      xStartCoordinate + (i + 0.1) * cellWidth,
      yStartCoordinate + cellHeight / 2,
    );
  });
}

function drawTableRows(
  ctx,
  { xStartCoordinate, yStartCoordinate, cols, cellWidth, cellHeight },
  cards,
) {
  cards.forEach((item, rowIndex) => {
    for (let col = 0; col < cols; col++) {
      const cellX = xStartCoordinate + col * cellWidth;
      const cellY = yStartCoordinate + (rowIndex + 1) * cellHeight;

      if (rowIndex % 2 === 0) {
        ctx.fillStyle = '#f9f9f9';
      } else {
        ctx.fillStyle = '#fff';
      }
      ctx.fillRect(cellX, cellY, cellWidth, cellHeight);

      let text = '';
      switch (col) {
        case 0:
          text = item.title;
          break;
        case 1:
          text = item.value;
          break;
        case 2:
          text = item.unit;
          break;
      }

      ctx.font = '16px Arial';
      ctx.fillStyle = '#000';
      ctx.fillText(
        formatNumber(text),
        xStartCoordinate + (col + 0.1) * cellWidth,
        cellY + cellHeight / 2,
      );
    }
  });
}

// ------------- Sector Download utils -------------------

function drawBySectorTitles(
  ctx,
  { titleFontSize, fullWidth },
  { wired, decentralized },
) {
  // Write title text
  ctx.font = `600 ${titleFontSize}px 'Borna', sans-serif`;
  ctx.textAlign = 'center';
  ctx.fillStyle = '#54608e';
  ctx.fillText('Übersicht je Sektor', fullWidth / 2, titleFontSize * 2);

  // Write subtitle text
  let subTitle = 'Leitungsgebunden und Dezentral';
  if (!wired) subTitle = 'Dezentral';
  if (!decentralized) subTitle = 'Leitungsgebunden';
  ctx.font = `600 ${titleFontSize / 2}px 'Borna', sans-serif`;
  ctx.textAlign = 'center';
  ctx.fillStyle = 'black';
  ctx.fillText(subTitle, fullWidth / 2, titleFontSize * 4);
}

function drawBySectorLegend(ctx, styles, legend) {
  const legendMarginTop = 100;
  const legendItemSize = 50;
  const legendTextOffset = 60;
  const spacingBetweenItems = 350;

  let startX = 100;
  let startY =
    styles.fullHeight -
    styles.legendHeight -
    styles.boxHeight -
    styles.paddingBottom +
    legendMarginTop;

  ctx.font = '40px Arial';
  ctx.textAlign = 'left';

  const sortedLegendItems = legend.toSorted((a) =>
    a.name.includes('Synth') ? 1 : -1,
  );

  sortedLegendItems.forEach((item) => {
    ctx.fillStyle = item.color;
    ctx.fillRect(startX, startY, legendItemSize, legendItemSize);

    ctx.fillStyle = 'black';
    ctx.fillText(
      item.name,
      startX + legendTextOffset,
      startY + legendItemSize - 10,
    );

    startX += spacingBetweenItems;
  });
}

function drawSectorBox(
  ctx,
  startX,
  { startY, columnWidth, boxHeight },
  { title, absoluteValue, percentValue },
) {
  // Draw box
  ctx.fillStyle = 'white';
  ctx.strokeStyle = '#E0E0E0'; // Subtle border
  ctx.lineWidth = 2;
  ctx.fillRect(startX, startY, columnWidth, boxHeight);
  ctx.strokeRect(startX, startY, columnWidth, boxHeight);

  // Draw title
  ctx.fillStyle = 'black';
  ctx.font = 'bold 56px Arial';
  ctx.textAlign = 'center';
  ctx.fillText(title, startX + columnWidth / 2, startY + 100);

  // Draw values
  const valueMarginTop = 210;
  const valueOffsetCenterX = 500;
  ctx.font = '60px Arial';
  ctx.fillText(
    absoluteValue,
    startX + columnWidth / 2 - valueOffsetCenterX,
    startY + valueMarginTop,
  );
  ctx.fillText(
    percentValue,
    startX + columnWidth / 2 + valueOffsetCenterX,
    startY + valueMarginTop,
  );
}

function drawSectorBoxes(
  ctx,
  styles,
  { demandReductions, emissionReductions },
) {
  styles.startY = styles.fullHeight - styles.boxHeight - styles.paddingBottom;
  const startX1 = styles.marginX / 2;
  const startX2 = styles.columnWidth + styles.gap + styles.marginX / 2;
  const texts1 = {
    title: 'Gesamtreduzierung zum Startjahr',
    absoluteValue: demandReductions?.total || '0 GWh',
    percentValue: demandReductions?.percent || '0 %',
  };
  const texts2 = {
    title: 'Gesamtreduzierung zum Startjahr',
    absoluteValue: emissionReductions?.total || '0 t CO₂',
    percentValue: emissionReductions?.percent || '0 %',
  };

  drawSectorBox(ctx, startX1, styles, texts1);
  drawSectorBox(ctx, startX2, styles, texts2);
}

function loadSectorImage(chart) {
  const src = chart.chartRef.getDataURL({
    pixelRatio: 4,
    backgroundColor: '#fff',
  });

  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.src = src;
  });
}

export function downloadSectorSection(charts, legend, checkboxes, reductions) {
  Promise.all([loadSectorImage(charts[0]), loadSectorImage(charts[1])]).then(
    ([img1, img2]) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const styles = {
        titleFontSize: 80,
        titleHeight: 400,
        legendHeight: 300,
        boxHeight: 300,
        columnWidth: img1.width,
        gap: 100,
        paddingBottom: 100,
        marginX: 200,
      };

      canvas.width = styles.columnWidth * 2 + styles.gap + styles.marginX;
      canvas.height =
        Math.max(img1.height, img2.height) +
        styles.titleHeight +
        styles.legendHeight +
        styles.boxHeight +
        styles.paddingBottom;

      // Set the canvas background
      ctx.fillStyle = '#fbfbfb';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      drawBySectorTitles(
        ctx,
        { ...styles, fullWidth: canvas.width },
        checkboxes,
      );
      ctx.drawImage(img1, styles.marginX / 2, styles.titleHeight);
      ctx.drawImage(
        img2,
        styles.columnWidth + styles.marginX / 2 + styles.gap,
        styles.titleHeight,
      );
      drawBySectorLegend(ctx, { ...styles, fullHeight: canvas.height }, legend);
      drawSectorBoxes(
        ctx,
        { ...styles, fullHeight: canvas.height },
        reductions,
      );

      downloadElement(canvas, 'overview_by_sector');
    },
  );
}
