<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <VSlider
    v-model="selected"
    :ticks="Object.assign({}, years)"
    :max="years.length - 1"
    step="1"
    show-ticks="always"
    tick-size="4"
    :ripple="false"
    :hide-details="true"
    class="rounded-[8px] bg-white"
    data-test="year-slider"
  />
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  years: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['change-year']);

const store = useStore();
const selected = ref(0);
const year = computed(() => store.state.map.scenarioYear);

// Initialize the slider based on the store's year value
onMounted(() => {
  if (props.years.length === 0) return;

  // Always try to synchronize with store on mount
  syncWithStore();
});

// Helper function to sync slider with store state
function syncWithStore() {
  // First check if there's any value set in the store
  if (year.value !== undefined && year.value !== null) {
    if (props.years.includes(year.value)) {
      // If the store year is valid, use it
      selected.value = props.years.findIndex((y) => y === year.value);
    } else {
      // If store year exists but is invalid, log a warning and use first year
      console.warn(
        'YearSlider: Store year',
        year.value,
        'not found in available years, defaulting to first year',
      );
      selected.value = 0;
    }
  } else if (props.years.length > 0) {
    // Only if nothing is set in the store, use the first year
    selected.value = 0;
  }
}

// When the slider changes, update the store
watch(selected, (newIndex) => {
  if (
    props.years.length === 0 ||
    newIndex < 0 ||
    newIndex >= props.years.length
  )
    return;

  const newYear = props.years[newIndex];
  if (newYear !== year.value) {
    store.commit('map/SET_SCENARIO_YEAR', newYear);
  }
});

// When the store changes, update the slider
watch(year, (newYear) => {
  if (props.years.length === 0) return;

  // Handle case where newYear is null/undefined
  if (newYear === undefined || newYear === null) {
    // If store year is cleared but we have years available, default to first year
    if (props.years.length > 0) {
      selected.value = 0;
    }
    return;
  }

  if (props.years.includes(newYear)) {
    const newIndex = props.years.findIndex((y) => y === newYear);
    if (selected.value !== newIndex) {
      selected.value = newIndex;
    }
  } else {
    console.warn(
      'YearSlider: Store year',
      newYear,
      'not in available years, not updating slider',
    );
  }
});

// Watch for changes in years prop to ensure sync
watch(
  () => props.years,
  (newYears) => {
    if (newYears.length > 0) {
      syncWithStore();
    }
  },
  { deep: true },
);
</script>
