<template>
  <v-tooltip
    v-if="showDashboardLink"
    text="Zurück zum Dashboard"
    location="bottom"
    offset="14"
  >
    <template #activator="{ props }">
      <div
        class="dashboard-link standard-elevation-1 absolute left-[390px] z-20 cursor-pointer rounded-md bg-white p-2 hover:bg-gray-100"
        v-bind="props"
        @click="navigateToDashboard"
      >
        <img
          src="@/assets/svg/statistics-icon.svg"
          class="h-6"
          alt="statistics"
        />
      </div>
    </template>
  </v-tooltip>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();

const returnToDashboard = computed(() => store.state.map.returnToDashboard);
// Only show dashboard link if we came from a specific dashboard page
const showDashboardLink = computed(
  () => returnToDashboard.value && returnToDashboard.value.path,
);

function navigateToDashboard() {
  if (returnToDashboard.value) {
    // If we have stored return information
    if (returnToDashboard.value.path.includes('/analysis-dashboard')) {
      // For dashboard paths, make sure we include the tab parameter
      const tabName = returnToDashboard.value.tabName || 'community-structure';

      // Extract the base path and existing query parameters
      const [basePath, queryString] = returnToDashboard.value.path.split('?');

      // Parse existing query parameters to preserve them
      const existingQuery = {};
      if (queryString) {
        const searchParams = new URLSearchParams(queryString);
        searchParams.forEach((value, key) => {
          existingQuery[key] = value;
        });
      }

      // Set the active tab in the store to ensure DashboardWrapper shows the correct content
      store.commit('dashboard/SET_ACTIVE_TAB', tabName);

      // Set or update the tab parameter and navigate
      router.push({
        path: basePath,
        query: {
          ...existingQuery,
          tab: tabName,
        },
      });
    } else {
      // For non-dashboard paths, just return to the stored path
      router.push(returnToDashboard.value.path);
    }
  } else {
    // Default fallback to the dashboard
    router.push({
      path: '/analysis-dashboard',
      query: { tab: 'community-structure' },
    });
  }
}
</script>

<style scoped>
.dashboard-link {
  transition: all 0.5s ease;
}
</style>
