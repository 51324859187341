<template>
  <div ref="districtWrapper" class="relative flex w-[250px] gap-2">
    <div
      :class="[
        `standard-elevation-0-dark flex w-[250px] cursor-pointer items-center justify-between gap-1.5 rounded-[6px] px-1.5 py-1`,
        {
          'rounded-b-none': expanded,
        },
      ]"
      data-test="district-select"
      @click="expanded = !expanded"
    >
      <div class="flex flex-col">
        <div class="caption-1 whitespace-nowrap text-color1">
          Stadtteil wählen
        </div>
        <h5 class="whitespace-nowrap text-title-color1">
          {{
            filteredDistricts.find(
              (district) => district.id === setSelectedDistrict,
            )?.name || 'Alle'
          }}
        </h5>
      </div>
      <IconWrapper
        icon="keyboard_arrow_down"
        fill="text-color1"
        :class="[
          {
            'rotate-180': expanded,
          },
          'transform duration-300',
        ]"
      />
    </div>

    <div v-if="expanded" class="district-list-wrapper" @click.stop>
      <InputEl
        v-model="filterText"
        placeholder="Bitte eingeben"
        label="Name des Stadtteils"
        size="m"
      />
      <div
        class="hide-scrollbar flex flex-col gap-0.5 overflow-y-auto"
        :style="{
          'max-height': `${containerHeight - 150}px`,
        }"
      >
        <div
          v-for="item in filteredDistricts"
          :key="item"
          class="district-item cursor-pointer"
          @click="selectDistrict(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import { useListeners } from '@/components/storybook/src/composables/useListeners';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';

const props = defineProps({
  district: {
    type: Number,
    default: null,
  },
  districts: {
    type: Array,
    default: () => [],
  },
});

const selectedDistrict = ref(props.district);
const expanded = ref(false);

const containerHeight = computed(() => store.state.map.containerHeight);
const setSelectedDistrict = computed({
  get() {
    return props.district;
  },
  set(value) {
    selectedDistrict.value = value;
    emit('setDistrict', value);
  },
});

const store = useStore();
const emit = defineEmits(['setDistrict']);

function selectDistrict(districtKey) {
  selectedDistrict.value = districtKey;
  emit('setDistrict', districtKey);
  expanded.value = false;
}

//---------- Filtering and displaying dropdown items------------
const displayDistricts = computed(() => {
  if (selectedDistrict.value !== null) {
    return [{ id: null, name: 'Alle' }, ...props.districts];
  }
  return props.districts;
});

const filterText = ref(null);

const filteredDistricts = computed(() => {
  const items = displayDistricts.value;

  const sortWithAllFirst = (a, b) => {
    if (a.municipality_key === 'all') return -1;
    if (b.municipality_key === 'all') return 1;
    return a.name.localeCompare(b.name);
  };

  if (!filterText.value) {
    return items.sort(sortWithAllFirst);
  }

  return items
    .filter((e) =>
      e.name.toLowerCase().includes(filterText.value.toLowerCase()),
    )
    .sort(sortWithAllFirst);
});

//------ Handle events outside ----------

useListeners({
  click: handleClickOutside,
  keydown: handleKeydown,
});

const districtWrapper = ref(null);

function handleClickOutside(event) {
  if (!districtWrapper.value.contains(event.target)) {
    expanded.value = false;
  }
}

function handleKeydown(event) {
  if (event.key === 'Escape' && !districtWrapper.value.contains(event.target)) {
    expanded.value = false;
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/styles';

.district-list-wrapper {
  @apply left-0 top-[42px] z-10 flex w-full flex-col gap-1 rounded-b-[8px] p-2 pt-0;

  @apply absolute gap-[6px] bg-white;
  @extend .standard-elevation-1;

  &::before {
    @apply absolute left-0 top-[-3px] h-[3px] w-full bg-white;
    content: '';
  }

  .district-item {
    @apply flex h-8 min-w-max cursor-pointer items-center justify-start rounded-[3px] bg-subtle p-2 text-neutral;
    @extend .body-2;

    &.disabled {
      pointer-events: none;
      background-color: white;
      opacity: 0.7;
    }
  }
}
</style>
