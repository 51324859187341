<template>
  <div>
    <div class="flex items-end">
      <TheLabel :label="label" :for-input="id" />
      <span class="relative">
        <ToolTipOnHover
          v-if="tooltipText"
          :tooltip-text="tooltipText"
          tooltip-container-classes="bottom-0.5 left-0.5 absolute"
          :icon-size="20" />
      </span>
    </div>

    <div :class="['input-field-parent', inputWrapperClasses]">
      <div class="flex relative items-center gap-[6px] input-field-wrapper">
        <div class="relative w-full flex items-center">
          <input
            :id="id"
            ref="inputField"
            :value="displayValue"
            :type="type"
            class="input-field"
            :class="inputClasses"
            :placeholder="placeholder"
            :disabled="disabled"
            @wheel.prevent
            @keydown="restrictInput"
            @input="displayValue = $event.target.value"
            @blur="clickOutside" />
          <!-- suffix-->
          <div
            v-if="suffix"
            class="input-suffix"
            :class="suffixClasses"
            @click="emit('iconClick')">
            <span v-if="!suffixIcon">{{ suffix }}</span>
            <Component :is="suffix" v-else />
          </div>
        </div>

        <!-- dropdown-->
        <arrow-down
          v-if="hasDropdown"
          :fill="dropdownFill"
          class="cursor-pointer"
          :class="{ 'rotate-180': isDropdownOpen }"
          @click="emit('showDropdown')" />

        <!-- error text -->
        <span
          v-if="errorText"
          class="absolute bottom-[-17px] right-0 text-xxs text-spot-error whitespace-nowrap">
          {{ errorText }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import TheLabel from '../label/TheLabel.vue';
import arrowDown from '../../assets/icons/arrow-down.vue';
import { useValidation } from '../../composables/formElementValidation';
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue';
import ToolTipOnHover from '../../stories/toolTipOnHover/ToolTipOnHover.vue';

const { isValid, errorText, checkValidationRules, mapValidationRules } =
  useValidation();

const props = defineProps({
  size: {
    type: String,
    default: 'l',
    validate: (value) => ['s', 'm', 'l', 'xl'].includes(value),
  },
  rules: {
    type: Object,
    default: null,
  },
  type: {
    type: String,
    default: 'text',
  },
  placeholder: {
    type: [String, Number],
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  suffix: {
    type: [String, Object],
    default: null,
  },
  suffixClickable: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: null,
  },
  hasDropdown: {
    type: Boolean,
    default: false,
  },
  dropdownFill: {
    type: String,
    default: 'currentColor',
  },
  tooltipIconFill: {
    type: String,
    default: 'currentColor',
  },
  tooltipText: {
    type: String,
    default: null,
  },
  tooltipIcon: {
    type: [String, Object],
    default: 'HelpIcon',
  },
  isDropdownOpen: {
    type: Boolean,
    default: false,
  },
  customBackground: {
    type: String,
    default: null,
  },
  initialInput: {
    type: String,
    default: null,
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
  inputType: {
    type: String,
    default: 'string',
    validator: (value) =>
      ['string', 'integer', 'float', 'percent'].includes(value),
  },
  decimals: {
    type: Number,
    default: 6,
  },
  suffixIcon: {
    type: Boolean,
    default: false,
  },
  applyThousandsSeparator: {
    type: Boolean,
    default: true,
  },
  // Needed for to work with HTML label element
  id: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['showDropdown', 'update:modelValue', 'iconClick']);

const currentInput = ref(props.modelValue);
const isMounted = ref(false);
const keyBoardVals = [
  'Delete',
  'Backspace',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Tab',
];

const inputWrapperClasses = computed(() => {
  return {
    'input-size-s': props.size === 's',
    'input-size-m': props.size === 'm',
    'input-size-l': props.size === 'l',
    'input-size-xl': props.size === 'xl',
    disabled: props.disabled,
  };
});

const inputClasses = computed(() => {
  return {
    [props.customBackground]: props.customBackground,
    invalid: errorText.value,
  };
});

const suffixClasses = computed(() => {
  return {
    'pointer-events-none': !props.suffixClickable,
    'cursor-pointer': props.suffixClickable,
  };
});

const displayValue = computed({
  get() {
    if (currentInput.value === null) {
      return '';
    }
    if (
      (props.inputType === 'float' || props.inputType === 'percent') &&
      typeof currentInput.value === 'number'
    ) {
      return currentInput.value;
    }
    return currentInput.value;
  },
  set(value) {
    if (value === '') {
      currentInput.value = '';
      emit('update:modelValue', null);
      // Set modelValue to null when the input is empty
    } else if (
      (props.inputType === 'float' || props.inputType === 'percent') &&
      value !== '-'
    ) {
      if (value.startsWith(',')) {
        currentInput.value = '0,';
      } else {
        currentInput.value = value;
      }

      // Convert the currentInput into a float and emit
      const floatValue = props.inputType === 'percent' ? 100 : 1;

      emit(
        'update:modelValue',
        formattedStringToNumber(currentInput.value) / floatValue,
      );
    } else if (props.inputType === 'integer' && value !== '-') {
      currentInput.value = value;

      // Convert the currentInput into an integer and emit
      emit('update:modelValue', formattedStringToNumber(currentInput.value));
    } else {
      currentInput.value = value;
      // Emit modelValue as it is
      emit('update:modelValue', currentInput.value);
    }
  },
});

function formattedStringToNumber(formattedString) {
  // Remove the thousands separator
  let stringWithoutThousandSeparator = formattedString.replace(/\./g, '');

  // Replace decimal comma with a decimal point
  stringWithoutThousandSeparator = stringWithoutThousandSeparator.replace(
    /,/,
    '.',
  );

  // Parse the string to a number
  return parseFloat(stringWithoutThousandSeparator);
}

function removeTrailingDotOrComma(input, inputType) {
  if (
    inputType === 'float' ||
    inputType === 'percent' ||
    inputType === 'floatPercent'
  ) {
    return (
      input
        // Remove leading zeros, taking into account all thousand separators
        .replace(/^0+(?=(?:\d{3}\.)*\d+,)/, '')
        // Remove trailing dot or comma
        .replace(/[.,]$/, '')
        // Remove trailing minus
        .replace(/-$/, '')
    );
  } else {
    return (
      input
        // Remove trailing dot or comma
        .replace(/[.,]$/, '')
    );
  }
}

function restrictInput(event) {
  const char = event.key;
  const currentValue = event.target.value;
  const cursorPosition = event.target.selectionStart;
  const selectionEnd = event.target.selectionEnd;
  const isTextSelected = cursorPosition !== selectionEnd;
  const isCtrlOrCmdPressed = event.ctrlKey || event.metaKey; // Checks for Ctrl or Command key
  let regex;

  // Allow control keys like Delete, Backspace, Arrow keys, etc.
  // Also allow cut (Ctrl+X), copy (Ctrl+C), and paste (Ctrl+V) operations.
  if (
    keyBoardVals.includes(char) ||
    (isCtrlOrCmdPressed &&
      (char === 'c' ||
        char === 'C' ||
        char === 'v' ||
        char === 'V' ||
        char === 'x' ||
        char === 'X' ||
        char === 'a' ||
        char === 'A'))
  ) {
    return; // Allow the default action
  }

  if (props.inputType === 'float' || props.inputType === 'percent') {
    // If a comma already exists in the current value, disallow another comma
    if (char === ',' && currentValue.includes(',') && !isTextSelected) {
      event.preventDefault();
      return;
    }

    const parts = currentValue.split(',');

    // Check if the cursor is after the comma
    const commaPosition = currentValue.indexOf(',');

    if (
      cursorPosition > commaPosition && // If cursor is after comma
      parts[1] && // And there is a decimal part
      parts[1].length >= props.decimals && // And the decimal part's length has reached the limit
      !isTextSelected // And no text is selected
    ) {
      event.preventDefault();
      return;
    }

    // Allow both numbers and a comma.
    regex = /^[-0-9,]+$/;
  } else if (props.inputType === 'integer') {
    regex = /^[-0-9]+$/; // Allow multiple digits for integer input
  } else if (props.inputType === 'string') {
    regex = /.*/; // Allow any character for string input
  }

  // Prevent the default action if the character doesn't match the regex
  if (!regex.test(char)) {
    event.preventDefault();
  }
}

function validate(setErrorText) {
  checkValidationRules(
    mapValidationRules(props.rules),
    props.modelValue,
    setErrorText,
  );
  return isValid.value;
}

function clickOutside() {
  if (typeof displayValue.value === 'string') {
    displayValue.value = displayValue.value.trim();
    if (props.inputType === 'float' || props.inputType === 'percent') {
      displayValue.value = removeTrailingDotOrComma(
        displayValue.value,
        'floatPercent',
      );
    }
  }
}

defineExpose({ focusInput });
const inputField = ref(null);

function focusInput() {
  if (inputField.value) {
    inputField.value.focus();
  }
}

const registerValidator = inject('registerValidator', null);
const unregisterValidator = inject('unregisterValidator', null);
const uniqueId = Math.random().toString(36).substring(2, 11);

onMounted(() => {
  isMounted.value = true;
});

onUnmounted(() => {
  if (unregisterValidator) {
    unregisterValidator(uniqueId);
  }
});

function registerValidatorAction() {
  if (registerValidator) {
    registerValidator(uniqueId, validate(isMounted.value));
  } else {
    validate(isMounted.value);
  }
}

watch(
  () => currentInput.value,
  () => {
    if (props.inputType === 'string') {
      currentInput.value = currentInput.value.replace(/ {2,}/g, ' ');
    } else if (props.inputType === 'integer') {
      currentInput.value = removeTrailingDotOrComma(
        currentInput.value,
        'integer',
      );
    }
  },
);

watch(
  () => props.modelValue,
  (newValue) => {
    registerValidatorAction();
    if (newValue === null || typeof newValue === 'undefined') {
      currentInput.value = '';
      return;
    }

    if (props.inputType === 'float' || props.inputType === 'percent') {
      if (typeof newValue === 'number') {
        if (props.inputType === 'percent') {
          currentInput.value = (newValue * 100).toLocaleString('de-DE', {
            maximumFractionDigits: props.decimals,
          });
        } else {
          currentInput.value = newValue.toLocaleString('de-DE', {
            maximumFractionDigits: props.decimals,
          });
        }
      }
    } else if (props.inputType === 'integer') {
      currentInput.value = props.applyThousandsSeparator
        ? newValue.toLocaleString('de-DE')
        : String(newValue);
    } else {
      currentInput.value = newValue;
    }
  },
  { immediate: true },
);
</script>

<style scoped lang="scss">
@use '@/assets/styles';

.input-field-parent {
  @apply text-neutral;
  &.disabled {
    pointer-events: none;
    @apply text-disabled-neutral;
    .input-field {
      @apply bg-subtle text-disabled-neutral border-line-disabled-neutral;
    }
  }

  &.input-size-s {
    @extend .body-2;

    .input-field-wrapper .input-field {
      border-radius: 2px;
      height: 24px;
      padding: 6px;

      &:focus {
        padding: 5px;
      }
    }
  }

  &.input-size-m {
    @extend .body-2;

    .input-field-wrapper .input-field {
      border-radius: 4px;
      height: 32px;
      padding: 8px;

      &:focus {
        padding: 7px;
      }
    }
  }

  &.input-size-l {
    @extend .body-1;

    .input-field-wrapper .input-field {
      border-radius: 6px;
      height: 40px;
      padding: 8px 10px;

      &:focus {
        padding: 8px 9px;
      }
    }
  }

  &.input-size-xl {
    @extend .body-1;

    .input-field-wrapper .input-field {
      border-radius: 8px;
      height: 48px;
      padding: 8px 12px;

      &:focus {
        padding: 8px 11px;
      }
    }
  }

  .input-field {
    width: 100%;
    border-width: 1px;
    outline: none;
    @apply bg-active-area border-line-neutral;
    &.invalid {
      @apply border-red-500;
    }

    &:hover {
      @apply border-line-active-neutral;
    }

    &:focus {
      border-width: 2px;
      @apply border-line-active-neutral;
    }
  }

  .input-suffix {
    @apply absolute right-0 flex items-center justify-end px-2;
  }
}
</style>
