import mapboxgl from 'mapbox-gl';
import U from 'map-gl-utils';
import config from '@/configs';
import { addDraw } from '@/features/draw/draw';
import onLeftClick from './onClick/onclick-main';
import { createLayers, setLayerHierarchy } from './createLayer';
import { initClicked, initMarked } from './onClick/constructorClick';
import {
  highlightBuildings,
  hoverBuildings,
} from '@/features/heat-project/highlight-buildings';
import { LAYER_KEY__HEAT_PROJECT_CENTROID } from '@/configs/layers/heatProject';

mapboxgl.accessToken =
  'pk.eyJ1IjoibWFwZnJlZWxiZCIsImEiOiJjandzem95eW8wa2FhM3lxdjdxdjNlbjliIn0.JVEcWEoquKW1_4p6hBPTNg';

let map;
let hoverProjectActive = false;

export function flyTo(configFlyTo, targetMap = map) {
  targetMap.flyTo({
    zoom: configFlyTo.zoom,
    center: [configFlyTo.lng, configFlyTo.lat],
    essential: true,
    maxZoom: configFlyTo.maxZoom,
  });
}

export function getMap() {
  return map;
}

export function setMaxZoom(
  maxZoom = config.constraints.mapView.mapConstraints.maxZoom,
) {
  map.setMaxZoom(maxZoom);
}

export function getZoom() {
  return map.getZoom();
}

function create3dLayers(map_) {
  map_.addSource('mapbox-dem', {
    type: 'raster-dem',
    url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
    tileSize: 512,
    maxzoom: 14,
  });
  map_.addLayer({
    id: 'sky',
    type: 'sky',
    paint: {
      // set up the sky layer to use a color gradient
      'sky-type': 'gradient',
      // the sky will be lightest in the center and get darker moving radially outward
      // this simulates the look of the sun just below the horizon
      'sky-gradient': [
        'interpolate',
        ['linear'],
        ['sky-radial-progress'],
        0.8,
        'rgba(135, 206, 235, 1.0)',
        1,
        'rgba(0,0,0,0.1)',
      ],
      'sky-gradient-center': [0, 0],
      'sky-gradient-radius': 90,
      'sky-opacity': [
        'interpolate',
        ['exponential', 0.1],
        ['zoom'],
        5,
        0,
        22,
        1,
      ],
    },
  });
}

export function setPitch(pitch) {
  if (pitch === '3D') {
    map.easeTo({ pitch: 60 });
    const terrainmethods = { source: 'mapbox-dem', exaggeration: 1.5 };
    map.setTerrain(terrainmethods);
    return '2D';
  }
  map.setTerrain();
  map.easeTo({ pitch: 0 });
  return '3D';
}

export function zoomMap() {
  map.easeTo({
    zoom: map.getZoom() + 0.5,
    duration: 750,
  });
}

export function unzoomMap() {
  map.easeTo({
    zoom: map.getZoom() - 0.5,
    duration: 750,
  });
}

export function compassMap() {
  const methods = { bearing: 0 };
  map.easeTo(methods);
}

export function removeMarker(targetMap = map) {
  if (targetMap && targetMap.getSource('markerSource')) {
    targetMap.getSource('markerSource').setData({
      type: 'FeatureCollection',
      features: [],
    });
  }
}

export function setMarker(coords, targetMap = map) {
  removeMarker(targetMap);
  targetMap.getSource('markerSource').setData({
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: coords,
        },
      },
    ],
  });
  targetMap.moveLayer('marker');
}

/**
 * set visibility (usually changed through switch in panel 3).
 * @param  {array} layers get all layers and corresponding layer states and visibility
 */
export function setVisibility(layers) {
  for (let i = 0, len = layers.length; i < len; i += 1) {
    if (layers[i].visible) map.U.show(layers[i].layer);
    else map.U.hide(layers[i].layer);
  }
}

/**
 * Change source layer and rerender all layers bound to the source layer
 * @param  {string}  sourceLayer source layer
 * @param  {Object} scenarioIds obj containing previous and new scanrio Ids
 */
export function changeSourceLayer(sourceLayer, scenarioIds) {
  const sourceLayerNew = `${scenarioIds.new}_${sourceLayer}`;
  const sourceLayerPrevious = `${scenarioIds.previous}_${sourceLayer}`;
  const foundLayer = map
    .getStyle()
    .layers.filter((e) => e['source-layer'] === sourceLayerPrevious);
  if (foundLayer.length === 0) return;
  map.U.addVector(
    foundLayer[0].source,
    `${window.TILESERVER_URL}/ts/tiles/${sourceLayerNew}/{z}/{x}/{y}.pbf`,
  );
  for (let i = 0, len = foundLayer.length; i < len; i += 1) {
    map.removeLayer(foundLayer[i].id);
    foundLayer[i]['source-layer'] = sourceLayerNew;
    map.addLayer(foundLayer[i]);
  }
  setLayerHierarchy(map);
}

export function setGeoJsonData(
  sourceLayer,
  featureCollection,
  layer = null,
  move = false,
) {
  map.U.setData(sourceLayer, featureCollection);
  if (move) map.moveLayer(layer);
}

/**
 * set layout depending on layer states (usually changed through radio in panel 3). The state
 * is being watched and whenever any state changes, the layout is set for all visible layers.
 * @param  {array} layers get all layers and corresponding layer states
 * @param  {object} layerConfig man layer layer-panel
 * @param  {string} year year selected in slider
 */
export function setStyle(layers, layerConfig, year, allYears) {
  // If map or map.style is not defined yet, log it and return
  if (!map || typeof map.style === 'undefined') {
    return;
  }

  for (let i = 0, len = layers.length; i < len; i += 1) {
    if (layers[i].visible) {
      let style = layerConfig[layers[i].layer].style[layers[i].state];
      if (typeof style === 'function') style = style(year);
      if (style?.withAllYears) style = style.withAllYears(allYears);

      if (layers[i].layer === 'district_heating_network_cluster') {
        try {
          map.setLayoutProperty(
            layers[i].layer,
            'icon-image',
            style.layout['icon-image'],
          );
        } catch (err) {
          console.error(`Error setting layout for ${layers[i].layer}:`, err);
        }
      } else if (style) {
        try {
          // First try the normal method
          if (map.U && typeof map.U.setProperty === 'function') {
            map.U.setProperty(layers[i].layer, style);
          }
          // Fallback: try direct mapbox methods for paints and layouts
          else {
            // Apply paint properties
            if (style.paint) {
              Object.entries(style.paint).forEach(([property, value]) => {
                try {
                  map.setPaintProperty(layers[i].layer, property, value);
                } catch (err) {
                  console.error(
                    `Error setting paint property ${property} for ${layers[i].layer}:`,
                    err,
                  );
                }
              });
            }

            // Apply layout properties
            if (style.layout) {
              Object.entries(style.layout).forEach(([property, value]) => {
                try {
                  map.setLayoutProperty(layers[i].layer, property, value);
                } catch (err) {
                  console.error(
                    `Error setting layout property ${property} for ${layers[i].layer}:`,
                    err,
                  );
                }
              });
            }
          }
        } catch (err) {
          console.error('Error setting style for layer:', layers[i].layer, err);
        }
      }
    }
  }
}

/**
 * set filter (usually changed through radio in panel 3). The state
 * is being watched and whenever any state changes, the filters are set.
 * @param  {String} layerKey
 * @param  {Array} filter
 */
export function setFilter(layerKey, filter) {
  if (typeof map.style === 'undefined') return;
  map.setFilter(layerKey, filter);
}

/**
 * fit map to boundingbox
 * @param  {Array} bbox
 */
export function fitBBox(bbox) {
  map.fitBounds(bbox);
}

let lastExecutionTime = 0;

/**
 * initialize map on URL load.
 * @param  {object} store vuex store
 * @param  {object} methods methods in map
 * @param  {object} layers get all layers and corresponding layer states
 * @param  {number} getYear year selected in slider
 */
export function createMap(store, methods, layers, getYear) {
  map = new mapboxgl.Map(config.constraints.mapView.mapConstraints);
  map.addControl(new mapboxgl.AttributionControl(), 'bottom-left');
  U.init(map, mapboxgl);
  addDraw(map);
  initMarked(map);
  const clicked = initClicked(map);
  const getLayersToClick = store.getters['map/getLayersToClick'];

  map.on('load', () => {
    store.commit('map/MAP_LOADED', true);

    create3dLayers(map);
    createLayers(map, layers, setGeoJsonData, store, getYear);
    methods.initialMapStyleLoaded(true);
    methods.commitYearFilter();
    methods.emitMunicipalityFilter();
    methods.applyFilters(store.getters['map/getAppliedFilters']);
    store.commit('map/RESET_LAYER_STATES');
    methods.toggleEnableMapClick();
  });

  map.on('click', (e) => {
    removeMarker();
    onLeftClick(map, e, methods, clicked, getLayersToClick, store);
  });

  map.on('zoom', () => {
    if (map.getZoom() < 11) {
      store.commit('layout/SHOWTOAST', {
        color: 'infra-highlight-500',
        message:
          'Bei niedriger Zoomstufe werden nur Gemeinden und Stadtteile angezeigt.',
        timeout: 1000,
      });
    }
    // highlight buildings on map zoom, but only once every 2 seconds, as it is expensive
    const heatProjects = store.state.heatProject.heatProjects;
    if (map.getZoom() > 13 && heatProjects.length) {
      const currentTime = Date.now();
      if (currentTime - lastExecutionTime >= 2000) {
        highlightBuildings(true);
        lastExecutionTime = currentTime;
      }
    }
  });

  map.on('mousemove', LAYER_KEY__HEAT_PROJECT_CENTROID, (e) => {
    if (e.features.length > 0 && !hoverProjectActive) {
      hoverBuildings(true, e.features[0].id);
      hoverProjectActive = true;
    }
  });
  map.on('mouseleave', LAYER_KEY__HEAT_PROJECT_CENTROID, (_) => {
    hoverProjectActive = false;
    hoverBuildings(false);
  });

  return map;
}

export function toggleSatellite(isActive) {
  const satLayerId = 'satellite';
  if (!isActive) {
    if (getMap().getSource('satellite')) {
      map.removeLayer(satLayerId);
      map.removeSource(satLayerId);
    }
  } else {
    map.addLayer({
      id: satLayerId,
      source: {
        type: 'raster',
        url: 'mapbox://mapbox.satellite',
        tileSize: 256,
      },
      type: 'raster',
      visibility: false,
      style: null,
    });
    setLayerHierarchy(map);
  }
}

export function destroyMap() {
  map.remove();
}
