<template>
  <tr :class="rowSpecs.rowClass">
    <th :class="rowSpecs.headerClass">
      <div class="header-content">
        <span class="header-text">
          {{ rowSpecs.header }}
        </span>
        <ToolTipOnHover
          v-if="rowSpecs.helpText"
          class="ml-0.5"
          :tooltip-text="rowSpecs.helpText"
        />
      </div>
    </th>
    <td class="unit-cell">{{ rowSpecs.unit }}</td>
    <td
      v-for="item in data"
      :key="item"
      :class="item[rowSpecs.dataKey] < 0 ? 'text-error' : 'text-neutral'"
    >
      <span>{{ formatNumber(item[rowSpecs.dataKey]) }}</span>
    </td>
    <!-- On the last page sometimes there are less data entries then before -->
    <td v-for="index in placeholdersCount" :key="'placeholder-' + index"></td>
  </tr>
</template>
<script setup>
import ToolTipOnHover from '@/components/storybook/src/stories/toolTipOnHover/ToolTipOnHover.vue';

const props = defineProps({
  rowSpecs: {
    type: Object,
    default: () => {},
  },
  data: {
    type: Array,
    default: () => [],
  },
  placeholdersCount: {
    type: Number,
  },
});

function formatNumber(number) {
  if (Math.abs(number) < 100) {
    return number.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return number.toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}
</script>

<style scoped lang="scss">
@use '@/assets/styles';

tr {
  border-bottom: solid 1px;
  @apply border-line-neutral;
}

tr:first-child {
  border-top: none;
}

tr:last-child {
  border-bottom: none;
}

th,
td {
  padding: 18px 20px 18px 0;
}

th {
  font-weight: normal;
  text-align: left;
  padding-left: 20px;
  position: relative;
  width: 285px;
  max-width: 285px; /* Needed to prevent overlapping with left td cell */
}

td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}

td:hover {
  overflow: visible;
}

/* That full text is shown on hover */
td:hover > span {
  position: relative;
  @apply standard-elevation-1 bg-active-area;
  border-radius: 1px;
}

.header-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 255px; /* Needed to enforce the ellipsis */
  display: inline-block;
}

/* That full text is shown on hover */
.header-text:hover {
  padding-right: 6px;
  display: inline-flex;
  max-width: fit-content;
  @apply standard-elevation-1 bg-active-area;
  border-radius: 1px;
}

.header-content {
  display: inline-flex;
  align-items: center;
}

.unit-cell {
  width: 85px;
  text-align: left;
}
</style>
