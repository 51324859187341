<template>
  <div class="by-sector-container rounded-xl bg-subtle px-10 pb-20">
    <div class="section-title col-span-2">
      <div class="flex items-center gap-1">
        <h4 class="text-title-color2">Übersicht nach Sektor</h4>
        <MapLink
          :layer-activation="[
            {
              switchId: 'LC_BUILDINGS',
              layerState: 'heating_technology',
            },
            {
              switchId: 'LC_BLOCK',
              layerState: 'sector',
            },
          ]"
        />
      </div>
      <IconWrapper
        icon="download"
        hover="hover:text-success"
        class="cursor-pointer"
        size="28"
        @click="
          downloadSectorSection(
            [
              { ...charts[0], chartRef: chartRefs[0] },
              { ...charts[1], chartRef: chartRefs[1] },
            ],
            energyBalance.chartLegendData,
            { wired, decentralized },
            {
              demandReductions: energyBalance.calculatedDemandSavings,
              emissionReductions: energyBalance.calculatedEmissionsSavings,
            },
          )
        "
      />
    </div>
    <div class="col-span-2 flex justify-self-center pt-7">
      <CheckboxEl
        v-model="wired"
        class="mr-5"
        @update:model-value="
          wired = preventCheckboxesUnSelected($event, decentralized)
        "
      >
        <div class="caption-1">Gas- und Wärmenetz</div>
      </CheckboxEl>
      <CheckboxEl
        v-model="decentralized"
        @update:model-value="
          decentralized = preventCheckboxesUnSelected($event, wired)
        "
      >
        <div class="caption-1">Dezentral</div>
      </CheckboxEl>
    </div>
    <BarChartStackedVertical
      v-for="(chart, i) in charts"
      :key="chart.title"
      :chart-title="chart.title"
      :chart-data="chart.data"
      :multiply="chart.multiply"
      class="row-start-3 row-end-4 mb-10 h-full rounded-lg bg-default"
      :class="chart.classes"
      x-axis-key-name="sector"
      :bar-width="40"
      :show-legend="false"
      :y-split-line="true"
      :y-axis-bounds="chart.yAxisBounds"
      grid-top="15%"
      grid-bottom="2%"
      grid-left="2%"
      grid-right="2%"
      :decimal-places="1"
      @chart-ref="chartRefs[i] = $event"
    />
    <div class="col-span-2 row-start-4 row-end-5 flex flex-wrap justify-center">
      <div
        v-for="item in energyBalance.chartLegendData"
        :key="item.name"
        class="mr-6 flex items-center text-nowrap"
      >
        <div class="mr-1.5 h-4 w-4" :style="{ background: item.color }"></div>
        {{ item.name }}
      </div>
    </div>
    <div class="col-start-1 col-end-2 row-start-5 row-end-6">
      <TotalReduction
        :base-value="energyBalance.demandChartData"
        :chart-data="energyBalance.demandSavingsData"
        :multiply="1000000"
        @values="energyBalance.calculatedDemandSavings = $event"
      />
    </div>
    <div class="col-start-2 col-end-3 row-start-5 row-end-6">
      <TotalReduction
        chart-unit="t CO₂"
        :base-value="energyBalance.emissionsChartData"
        :chart-data="energyBalance.emissionsSavingsData"
        @values="energyBalance.calculatedEmissionsSavings = $event"
      />
    </div>
    <div
      class="standard-elevation-0-dark col-span-2 mt-5 rounded-lg bg-default"
    >
      <div
        class="flex cursor-pointer items-center justify-between rounded-t-lg p-3"
        :class="tableOpen ? 'border-b border-b-line-neutral' : ''"
        @click="tableOpen = !tableOpen"
      >
        <div class="flex items-center text-title-color2">
          <h5>Tabellarische Übersicht</h5>
          <ToolTipOnHover
            :custom-activator="true"
            tooltip-text="Tabelle als CSV-Datei herunterladen"
            ><template #activator>
              <IconWrapper
                icon="download"
                :size="24"
                class="ml-1"
                @click="downloadTableCSV"
              />
            </template>
          </ToolTipOnHover>
          <ToolTipOnHover
            :custom-activator="true"
            tooltip-text="Einträge sortieren: Die Energieträger werden absteigend anhand der Anzahl ihrer 0-Werte sortiert."
            ><template #activator>
              <IconWrapper
                icon="sort"
                :size="24"
                class="ml-1"
                @click="sortTableItems"
              />
            </template>
          </ToolTipOnHover>
        </div>
        <IconWrapper
          :icon="tableOpen ? 'unfold_less' : 'unfold_more'"
          fill="text-title-neutral"
          type="round"
        />
      </div>
      <div v-if="tableOpen">
        <div
          v-for="([sectionTitle, section], iOuter) of tableItems"
          :key="sectionTitle"
          class="table-section body-2 col-span-2 text-neutral"
          :class="
            iOuter < tableItems.length - 1 ? 'border-b border-line-neutral' : ''
          "
        >
          <template v-if="iOuter === 0">
            <div
              v-for="(header, i) in tableHeaders"
              :key="header"
              class="text-title-neutral"
              :class="i < 4 ? 'border-r border-line-neutral' : ''"
            >
              <h6>{{ header }}</h6>
            </div>
          </template>
          <template v-for="(row, iInner) in section" :key="iInner">
            <div
              class="border-r border-line-neutral"
              :class="`${getBackground(iInner, section, iOuter)} ${getBorderRadius(iOuter, 'bl')}`"
            >
              {{ iInner === 0 ? sectionTitle : '' }}
            </div>
            <div
              class="border-r border-line-neutral"
              :class="getBackground(iInner, section, iOuter)"
            >
              {{ row.sector }}
            </div>
            <div
              class="border-r border-line-neutral"
              :class="getBackground(iInner, section, iOuter)"
            >
              {{ formatValue(row.share_of_heat_demand_kwh * 100) }}
            </div>
            <div
              class="border-r border-line-neutral"
              :class="getBackground(iInner, section, iOuter)"
            >
              {{ formatValue(row.sum_heat_demand_kwh / 1000) }}
            </div>
            <div
              :class="`${getBackground(iInner, section, iOuter)} ${getBorderRadius(iOuter, 'br')}`"
            >
              {{ formatValue(row.sum_co2_emissions) }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import CheckboxEl from '@/components/storybook/src/stories/CheckboxEl/CheckboxEl.vue';
import BarChartStackedVertical from '@/apps/analysis-dashboard/charts/BarChartStackedVertical.vue';
import TotalReduction from '@/apps/analysis-dashboard/charts/TotalReduction.vue';
import { computed, reactive, ref, watch } from 'vue';
import {
  downloadSectorSection,
  preventCheckboxesUnSelected,
} from '@/apps/analysis-dashboard/utils';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import { downloadFile } from '@/utils/axiosHelper';
import ToolTipOnHover from '@/components/storybook/src/stories/toolTipOnHover/ToolTipOnHover.vue';
import EnergyBalanceBySector from '@/apps/analysis-dashboard/classes/EnergyBalanceBySector';
import MapLink from '@/apps/analysis-dashboard/components/MapLink.vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  dataByYear: {
    type: Object,
    default: () => {},
  },
  scenarioId: Number,
});

const wired = ref(true);
const decentralized = ref(true);
const tableOpen = ref(false);

let energyBalance = reactive(new EnergyBalanceBySector(props.data));

watch(
  [() => props.dataByYear, wired, decentralized],
  ([newData, newWired, newDecentralized]) => {
    energyBalance.updateData(newData, newWired, newDecentralized);
  },
  { immediate: true },
);

watch(
  () => props.data,
  (newData) => {
    energyBalance.updateRawData(newData);
    energyBalance.updateData(
      props.dataByYear,
      wired.value,
      decentralized.value,
    );
  },
);

const charts = computed(() => [
  {
    chartRef: null,
    title: 'Endenergieverbrauch in Tsd. MWh',
    data: energyBalance.demandChartData,
    classes: 'col-start-1 col-end-2',
    multiply: 0.000001,
    yAxisBounds: energyBalance.yAxisBoundsDemand,
  },
  {
    chartRef: null,
    title: 'Emissionen in Tsd. Tonnen CO₂-Äquivalente',
    data: energyBalance.emissionsChartData,
    classes: 'col-start-2 col-end-3',
    multiply: 0.001,
    yAxisBounds: energyBalance.yAxisBoundsEmission,
  },
]);

const chartRefs = reactive([null, null]);

// ---------------- Table logic ---------------------

const tableHeaders = [
  'Energieträger',
  'Sektor',
  'Anteil(%)',
  'Energiemenge(MWh)',
  'THG.Emission(t CO₂e)',
];

const tableItems = ref(
  Object.entries(props.dataByYear.table_per_heating_carrier_and_sector[0]),
);

function countNonZeroValues(entry) {
  const filterNonZeroValues = (value) =>
    typeof value === 'number' && value !== 0;
  const countValues = (count, sector) =>
    count + Object.values(sector).filter(filterNonZeroValues).length;

  return entry[1].reduce((count, sector) => countValues(count, sector), 0);
}

function sortTableItems(e) {
  e.stopPropagation();
  tableItems.value.sort(
    (a, b) => countNonZeroValues(b) - countNonZeroValues(a),
  );
}

function formatValue(val) {
  return val.toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function getBackground(iInner, items, iOuter) {
  const isEvenLength = items.length % 2 === 0;
  const isEvenIndex = iInner % 2 === 0;
  const isOuterEven = iOuter % 2 === 0;

  if (isEvenLength) {
    return isEvenIndex ? 'bg-subtle' : 'bg-default';
  }

  if (isOuterEven) {
    return isEvenIndex ? 'bg-subtle' : 'bg-default';
  } else {
    return isEvenIndex ? 'bg-default' : 'bg-subtle';
  }
}

function getBorderRadius(i, borderRadiusType) {
  return i === tableItems.value.length - 1
    ? `rounded-${borderRadiusType}-lg`
    : '';
}

function downloadTableCSV(ev) {
  ev.stopPropagation();
  const url = `/api/dashboard_energybalance/scenarios/download_table/${props.scenarioId}`;
  downloadFile(url);
}

watch(
  () => props.dataByYear,
  (newData) => {
    tableItems.value = Object.entries(
      newData.table_per_heating_carrier_and_sector[0],
    );
  },
  { deep: true },
);
</script>

<style lang="scss" scoped>
.by-sector-container {
  display: grid;
  grid-template: 100px 60px 600px 80px 100px / 1fr 1fr;
  gap: 20px 40px;
}

.table-section {
  display: grid;
  grid-template: repeat(5, 50px) / 1fr 1.5fr 1fr 1fr 1fr;
  gap: 0;

  div {
    padding: 20px;
  }
}
</style>
