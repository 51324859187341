<template>
  <div class="new-scenario min-h-fit">
    <ButtonEl
      v-if="!showScenario"
      icon="add"
      :disabled="!dataLoaded"
      icon-fill="text-inverted-color1"
      text="Erstelle neues Szenario"
      data-test="create-scenario"
      @click="initScenarioCreateProcess()"
    />

    <!-- scenario form input mask-->
    <div v-if="showScenario" class="mt-10 flex flex-col gap-2">
      <div class="flex justify-between">
        <h3 class="text-title-color2">
          {{ headerStartText }}
        </h3>
        <div class="flex items-center gap-2.5">
          <template v-if="!readOnly">
            <ButtonEl
              color="success"
              variant="secondary"
              text="Stand zwischenspeichern"
              data-test="save-progress-button"
              icon="save_as"
              :disabled="!isValid"
              :mdi="true"
              @click="submitScenario(scenarioProgress)"
            />
            <ButtonEl
              color="success"
              :disabled="!isValid"
              text="Berechnung starten"
              data-test="calculate-scenario-button"
              icon="table_view"
              :mdi="true"
              @click="submitScenario(2)"
            />
          </template>
          <ButtonEl
            :mdi="true"
            icon="close"
            icon-type="round"
            variant="tertiary"
            color="color2"
            @click="closeScenarioForm"
          />
        </div>
      </div>

      <div class="flex gap-2.5">
        <div class="w-full rounded-[4px] bg-subtle px-10 pt-5">
          <ScenarioTabStepper
            :key="tabStepperKey"
            :tabs="scenarioTabs"
            :valid-tabs
            :read-only
            @update:active-tab="activeTab = $event"
          >
            <!-- tab 1: Basisdaten-->
            <div
              v-if="activeTab === 0"
              :class="{ flex: activeTab === 0 }"
              class="gap-5 py-5"
            >
              <div class="flex w-full flex-col gap-5">
                <div class="flex w-[35%] flex-col gap-5">
                  <InputEl
                    v-model="scenario.scenarioName"
                    label="Name neues Szenario"
                    placeholder="Name"
                    :disabled="readOnly"
                    :rules="{ required: true, unique: [getScenarioNames] }"
                    data-test="scenario-name-input"
                  />
                  <div class="grid grid-cols-2 gap-2.5">
                    <DropDown
                      v-model="scenario.baseYear"
                      value="id"
                      items-data-key="year"
                      label="Datensatz für Startjahr"
                      :disabled="readOnly"
                      :initial-selection="true"
                      :items-data="baseYears"
                      data-test="base-year-drop-down"
                      :rules="{ required: true }"
                    />
                    <InputEl
                      v-model="scenario.targetYear"
                      label="Zieljahr der Klimaneutralität"
                      input-type="integer"
                      :disabled="readOnly"
                      :rules="{ required: true, range: [2023, 2050] }"
                      :apply-thousands-separator="false"
                      data-test="target-year-input"
                    />
                  </div>
                  <InputEl
                    v-for="field in fullUsageHoursFields"
                    :key="field.key"
                    v-model="scenario[field.key]"
                    :label="field.label"
                    :disabled="readOnly"
                    input-type="integer"
                    :rules="{ required: true }"
                    :data-test="field.dataTestId"
                  />
                </div>
              </div>
            </div>
            <!-- tab 2: Gebietsauswahl-->
            <div
              v-if="activeTab === 1"
              :class="{ flex: activeTab === 1 }"
              class="gap-5"
            >
              <MunicipalitySelectionTab
                :scenario
                :read-only
                @initialize-districts="initializeDistricts($event)"
              />
            </div>
            <!-- tab 3: Szenariorahmen-->
            <div
              v-if="activeTab === 2"
              :class="{ flex: activeTab === 2 }"
              class="flex-col gap-5"
            >
              <ScenarioFrameTab
                :active-tab
                :scenario
                :read-only
                :available-items
                @set-frame-for-municipalities="
                  setFrameForMunicipalities($event)
                "
              />
            </div>

            <!-- tab 4: Effizienzmaßnahmen-->
            <div
              v-if="activeTab === 3"
              :class="{ flex: activeTab === 3 }"
              class="flex-col gap-5"
            >
              <EfficiencyMeasuresTab
                :active-tab
                :scenario
                :read-only
                :scenario-district-options="getScenarioDistrictOptions"
                :district-parameterizations
                :available-items
                @set-efficiency-measures="setDistrictParameter($event)"
              />
            </div>

            <!-- tab 5: Technologie-->
            <div
              v-if="activeTab === 4"
              :class="{ flex: activeTab === 4 }"
              class="flex-col gap-5"
            >
              <TechnologyTab
                :active-tab
                :scenario
                :read-only
                :scenario-technology-options="getScenarioTechnologyOptions"
                :district-parameterizations
                :available-items
                @set-technology="setDistrictParameter($event)"
              />
            </div>
            <!-- tab 6: Optionale Parameter-->
            <div
              v-if="activeTab === 5"
              :class="{ flex: activeTab === 5 }"
              class="flex-col gap-5"
            >
              <OptionalParameterTab
                :active-tab
                :scenario
                :read-only
                :scenario-district-options="getScenarioDistrictOptions"
                :district-parameterizations
                :available-items
                @set-optional-parameter="setDistrictParameter($event)"
                @set-heat-network-thresholds="setHeatNetworkThresholds($event)"
              />
            </div>
          </ScenarioTabStepper>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Scenario from '@/apps/scenarios/classes/scenario';
import DistrictParameterization from '@/apps/scenarios/classes/district-parameterization';
import {
  fullUsageHoursFields,
  scenarioTabs,
} from '@/apps/scenarios/scenario-create-data';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { axios } from '@/utils/axiosHelper';
import cookie from 'vue-cookies';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import ScenarioTabStepper from '@/apps/scenarios/components/ScenarioTabStepper.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import MunicipalitySelectionTab from '@/apps/scenarios/components/tab-contents/MunicipalitySelectionTab.vue';
import ScenarioFrameTab from '@/apps/scenarios/components/tab-contents/ScenarioFrameTab.vue';
import EfficiencyMeasuresTab from '@/apps/scenarios/components/tab-contents/EfficiencyMeasuresTab.vue';
import TechnologyTab from '@/apps/scenarios/components/tab-contents/TechnologyTab.vue';
import OptionalParameterTab from '@/apps/scenarios/components/tab-contents/OptionalParameterTab.vue';

const router = useRouter();
const props = defineProps({
  dataLoaded: Boolean,
  expandedScenario: {
    type: Object,
    default: () => {},
  },
});
const emit = defineEmits(['scenarioCreated', 'reset', 'scenarioFormOpen']);
const store = useStore();

const getScenarioDistrictOptions = computed(
  () => store.getters['scenario/getScenarioDistrictOptions'],
);
const getScenarioTechnologyOptions = computed(() => {
  return getScenarioDistrictOptions.value.heating_technologies.child.choices;
});
const getScenarioNames = computed(() => {
  const names = store.getters['scenario/getScenarioNames'];
  return names.filter((name) => name !== props.expandedScenario.name);
});
const readOnly = computed(() => props.expandedScenario?.progress === 0);

const headerStartText = computed(() => {
  const scenario = props.expandedScenario;

  if (readOnly.value && scenario?.scenario_id) {
    return `Szenario "${scenario.name}" ansehen`;
  } else if (scenario?.scenario_id) {
    return `Szenario "${scenario.name}" bearbeiten`;
  } else {
    return 'Neues Szenario erstellen';
  }
});

// API interaction
const scenarioProgress = 4;
const scenario = ref(new Scenario());
const showScenario = ref(false);
const baseYears = ref([]);

onMounted(async () => {
  axios({ url: '/api/buildings/base-years/', method: 'GET' }).then((r) => {
    baseYears.value = r.data.years;
  });
});

function initScenarioCreateProcess() {
  activeTab.value = 0;
  scenario.value = new Scenario();
  loadScenarioState();
  showScenario.value = true;
  emit('scenarioFormOpen', showScenario.value);
  nextTick(() => {
    document
      .querySelector('.new-scenario')
      .scrollIntoView({ behavior: 'smooth' });
  });
}

function initializeDistricts(municipalityKeys) {
  return axios({
    url: '/api/buildingmodel/district-selection/options/',
    headers: {
      'X-CSRFToken': cookie.get('csrftoken'),
    },
    method: 'POST',
    data: {
      municipality_keys: municipalityKeys,
    },
  }).then((r) => {
    const districtData = r.data.data;
    const districtIds = new Set(districtData.map((e) => e.id));
    districtParameterizations.value = districtParameterizations.value.filter(
      (m) => districtIds.has(m.district),
    );
    const existingIds = new Set(
      districtParameterizations.value.map((m) => m.district),
    );
    for (const item of districtData) {
      if (!existingIds.has(item.id)) {
        const dist = new DistrictParameterization(
          item.id,
          item,
          getScenarioDistrictOptions.value,
        );
        districtParameterizations.value.push(dist);
      }
    }
  });
}

function submitScenario(scenarioProgress) {
  const payload = {
    ...scenario.value.payload,
    parameterization_by_district: districtParameterizations.value.map(
      (e) => e.getPayload,
    ),
  };
  if (scenarioProgress) {
    payload.progress = scenarioProgress;
  }
  if (props.expandedScenario.scenario_id) {
    axios({
      url: `/api/scenarios/${props.expandedScenario.scenario_id}/`,
      method: 'PUT',
      data: payload,
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    }).then((resp) => {
      districtParameterizations.value = [];
      emit('scenarioCreated', resp.data.scenario_id);
      closeScenarioForm();
    });
  } else {
    store.dispatch('scenario/POST_SCENARIO', payload).then((resp) => {
      districtParameterizations.value = [];
      emit('scenarioCreated', resp.data.scenario_id);
      closeScenarioForm();
    });
  }
}

function closeScenarioForm() {
  emit('reset');
  showScenario.value = false;
  emit('scenarioFormOpen', showScenario.value);
  document.querySelector('.single-page-scroll-wrapper').scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

// load scenario state
watch(
  () => props.expandedScenario,
  (expandedScenario) => {
    if (typeof expandedScenario.scenario_id !== 'undefined') {
      forceRerenderOfCreateMask();
      initScenarioCreateProcess();
    } else {
      districtParameterizations.value = [];
    }
  },
);

function loadDistrictParameterization() {
  axios({
    url: '/api/parameterization-by-district/',
    method: 'GET',
    params: { scenario_id: props.expandedScenario.scenario_id },
  }).then((r) => {
    const districtData = r.data;
    districtParameterizations.value = [];
    for (const district of districtData) {
      const dist = new DistrictParameterization(
        district.district,
        district,
        getScenarioDistrictOptions.value,
        district.id,
      );
      dist.initFromRequest(district);
      districtParameterizations.value.push(dist);
    }
  });
}

function loadMunicipalityParameter() {
  axios({
    url: '/api/scenario-to-municipality/',
    method: 'GET',
    params: { scenario_id: props.expandedScenario.scenario_id },
  }).then((resp) => {
    scenario.value.municipalities.removeAll();
    for (const municipality of resp.data) {
      municipality.municipality_id = municipality.municipality;
      scenario.value.municipalities.add(municipality);
    }
  });
}

function loadScenarioState() {
  if (typeof props.expandedScenario.scenario_id !== 'undefined') {
    loadDistrictParameterization();
    loadMunicipalityParameter();
    scenario.value.scenarioName = props.expandedScenario.name;
    scenario.value.baseYear = props.expandedScenario.base_year;
    scenario.value.targetYear = props.expandedScenario.target_year;
    scenario.value.fullUsageHoursResidential =
      props.expandedScenario.full_usage_hours_residential;
    scenario.value.fullUsageHoursCommercial =
      props.expandedScenario.full_usage_hours_commercial;
    scenario.value.fullUsageHoursIndustrial =
      props.expandedScenario.full_usage_hours_industrial;
    scenario.value.fullUsageHoursPublic =
      props.expandedScenario.full_usage_hours_public;
    scenario.value.fullUsageHoursOther =
      props.expandedScenario.full_usage_hours_other;
    scenario.value.dominantTechnologyVeryLikelyThreshold =
      props.expandedScenario.dominant_technology_very_likely_threshold * 100;
    scenario.value.dominantTechnologyLikelyThreshold =
      props.expandedScenario.dominant_technology_likely_threshold * 100;
    scenario.value.dominantTechnologyNotLikelyThreshold =
      props.expandedScenario.dominant_technology_not_likely_threshold * 100;
  }
}

const tabStepperKey = ref(0);

function forceRerenderOfCreateMask() {
  tabStepperKey.value += 1;
}

// --------------------------------------------------------- district params
const districtParameterizations = ref([]);

function setDistrictParameter(updateDistricts) {
  for (const districtParameter of districtParameterizations.value) {
    const districtId = districtParameter.district;
    const updateParameter = updateDistricts[districtId];
    if (typeof updateParameter !== 'undefined') {
      Object.keys(updateParameter).forEach((key) => {
        districtParameter[key] = updateParameter[key];
      });
    }
  }
}

function setHeatNetworkThresholds(updatedValues) {
  scenario.value.dominantTechnologyVeryLikelyThreshold =
    updatedValues.dominantTechnologyVeryLikelyThreshold;
  scenario.value.dominantTechnologyLikelyThreshold =
    updatedValues.dominantTechnologyLikelyThreshold;
  scenario.value.dominantTechnologyNotLikelyThreshold =
    updatedValues.dominantTechnologyNotLikelyThreshold;
}

// scenario frame selection tab
function setFrameForMunicipalities(payload) {
  scenario.value.municipalities.updateScenarioFrames(
    payload.municipalityIds,
    payload.scenarioFrame.id,
    payload.scenarioFrame.name,
  );
}

// preparation for filtering
const availableItems = computed(() => {
  return {
    counties: reduceForFiltering('county'),
    municipalities: reduceForFiltering('municipality'),
  };
});

function reduceForFiltering(key) {
  let uniqueById = {};

  return districtParameterizations.value.reduce((result, item) => {
    if (!uniqueById[item[`${key}_id`]]) {
      uniqueById[item[`${key}_id`]] = {
        [`county_id`]: item[`county_id`],
        [`county_name`]: item[`county_name`],
        [`municipality_id`]: item[`municipality_id`],
        [`municipality_name`]: item[`municipality_name`],
      };
      return [...result, uniqueById[item[`${key}_id`]]];
    }
    return result;
  }, []);
}

// ------------------------------------------------------- validation for tabs
const activeTab = ref(0);
const validTabs = ref([0, 1, 2, 3, 4, 5]);

const isValid = computed(() => {
  const scenarioIsValid = scenario.value.isValid;
  const paramsIsValid = districtParameterizations.value
    .map((p) => p.isValid)
    .every((v) => v === true);
  return scenarioIsValid && paramsIsValid;
});

function isTabBaseValid() {
  return scenario.value.isBaseDataValid;
}

function isTabMunicipalitySelectionValid() {
  return scenario.value.isMunicipalitiesValid;
}

function isTabScenarioFrameValid() {
  return scenario.value.isMunicipalitiesFramesValid;
}

function hasAtLeastOneDistrict() {
  return districtParameterizations.value.length > 0;
}

function isTabEfficiencyValid() {
  return (
    hasAtLeastOneDistrict() &&
    districtParameterizations.value.every(
      (item) => item.refurbishment_value && item.refurbishment_rate,
    )
  );
}

function isTabTechnologiesValid() {
  return (
    hasAtLeastOneDistrict() &&
    districtParameterizations.value.every(
      (item) =>
        item.heating_technologies && item.heating_technologies?.length > 0,
    )
  );
}

// If districtParameterizations entry is added, tab validation should also be triggered
watch([activeTab, districtParameterizations], () => {
  // A map of tab indices to their respective validation functions
  const validationMap = {
    0: isTabBaseValid,
    1: isTabMunicipalitySelectionValid,
    2: isTabScenarioFrameValid,
    3: isTabEfficiencyValid,
    4: isTabTechnologiesValid,
  };

  // Iterate over each tab validation rule
  Object.entries(validationMap).forEach(([tabIndex, isValidFunction]) => {
    const index = parseInt(tabIndex); // Ensure the key is treated as a number
    const isValid = isValidFunction(); // Execute the validation function

    if (!isValid && validTabs.value.includes(index)) {
      // If the tab is not valid and currently marked as valid, remove it
      validTabs.value = validTabs.value.filter((tab) => tab !== index);
    } else if (isValid && !validTabs.value.includes(index)) {
      // If the tab is valid and not currently marked as valid, add it
      validTabs.value.push(index);
    }
  });
});
</script>
