<template>
  <button
    :name="name"
    :class="classes"
    :disabled="disabled"
    @click="handleClick">
    <IconWrapper
      v-if="icon"
      :icon="icon"
      :type="iconType"
      :fill="iconFill"
      :size="iconSizes[size]" />
    <span v-if="text" :class="textClasses">{{ text }}</span>
  </button>
</template>

<script setup>
import { computed } from 'vue';
import { usePreventDoubleClick } from '../../composables/preventDoubleClick';
import IconWrapper from '../IconWrapper/IconWrapper.vue';

const props = defineProps({
  text: {
    type: [String, Boolean],
    default: null,
  },
  name: {
    type: String,
    default: '',
  },
  color: {
    type: String,
    validator: (value) =>
      ['color1', 'color2', 'neutral', 'success', 'warning', 'error'].includes(
        value,
      ),
    default: 'color1',
  },
  variant: {
    type: String,
    validator: (value) => ['primary', 'secondary', 'tertiary'].includes(value),
    default: 'primary',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    validator: (value) => ['s', 'm', 'l', 'xl'].includes(value),
    default: 'l',
  },
  icon: {
    type: [Object, String],
    default: null,
  },
  iconType: {
    type: String,
    default: null,
    validator: (value) =>
      ['filled', 'outlined', 'sharp', 'two-tone', 'round', 'rounded'].includes(
        value,
      ),
  },
  iconFill: {
    type: String,
    default: 'currentColor',
  },
  iconRight: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  customBgColor: {
    type: String,
    default: null,
  },
  customTextColor: {
    type: String,
    default: null,
  },
  customBgHoverColor: {
    type: String,
    default: null,
  },
  customTextHoverColor: {
    type: String,
    default: null,
  },
  customFontSize: {
    type: String,
    default: null,
  },
});

const iconSizes = {
  s: 12,
  m: 16,
  l: 24,
  xl: 32,
};

const textClasses = computed(() => {
  return {
    [`text-${props.customFontSize}`]: props.customFontSize,
  };
});

const classes = computed(() => {
  const defaultClasses = {
    disabled: props.disabled,
    'pointer-events-none': justClicked.value,
    'button-size-s': props.size === 's',
    'button-size-m': props.size === 'm',
    'button-size-l': props.size === 'l',
    'button-size-xl': props.size === 'xl',
    'icon-right': props.iconRight,
    'icon-with-text': props.icon && props.text,
    'icon-no-text': props.icon && !props.text,
    'w-full': props.fullWidth,
  };

  const customColorClasses = {
    [props.customBgColor]: props.customBgColor,
    [props.customTextColor]: props.customTextColor,
    [props.customBgHoverColor]: props.customBgHoverColor,
    [props.customTextHoverColor]: props.customTextHoverColor,
  };

  const definedColorClasses = {
    'button--color1': props.color === 'color1',
    'button--color2': props.color === 'color2',
    'button--neutral': props.color === 'neutral',
    'button--success': props.color === 'success',
    'button--warning': props.color === 'warning',
    'button--error': props.color === 'error',
    primary: props.variant === 'primary',
    secondary: props.variant === 'secondary',
    tertiary: props.variant === 'tertiary',
  };

  if (
    props.customBgColor ||
    props.customBgHoverColor ||
    props.customTextColor
  ) {
    return {
      ...customColorClasses,
      ...defaultClasses,
    };
  } else {
    return {
      ...definedColorClasses,
      ...defaultClasses,
    };
  }
});

function handleClick(e) {
  if (!props.disabled) {
    onClick(e);
  }
}

const emits = defineEmits(['click']);
const { onClick, justClicked } = usePreventDoubleClick(emits);
</script>

<style lang="scss" scoped>
@use './ButtonEl.scss';
</style>
