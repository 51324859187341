<template>
  <ButtonEl
    icon="add"
    icon-fill="text-inverted-color1"
    text="Neues Materialset erstellen"
    @click="showDialog = true"
  />
  <VuetifyDialog
    v-model="showDialog"
    title="Neues Materialset anlegen"
    :overflow-visible="true"
    :activator-full-width="false"
  >
    <template #content>
      <FormValidator v-model="nameIsValid">
        <InputEl
          v-model="name"
          label="Name Materialset"
          placeholder="Bitte Eingeben"
          class="w-[275px]"
          :rules="{ required: true, unique: [materialNames] }"
          data-test="materialset-name-create"
        />
        <InputEl
          v-model="referenceYear"
          label="Referenzjahr"
          input-type="integer"
          :apply-thousands-separator="false"
          placeholder="Bitte Eingeben"
          class="w-[275px]"
          :rules="{ required: true, min: 1900, max: 2100 }"
          data-test="materialset-referenz-jahr"
        />
        <InputEl
          v-model="heatSubstationEurHa"
          label="Kosten Hausanschlussstation"
          input-type="float"
          suffix="€/HA"
          placeholder="Bitte Eingeben"
          class="w-[275px]"
          :rules="{ required: true }"
          data-test="materialset-kosten-ha"
        />
        <InputEl
          v-model="workingLifePipesYear"
          label="Lebensdauer Rohre"
          input-type="integer"
          suffix="Jahre"
          placeholder="Bitte Eingeben"
          class="w-[275px]"
          :rules="{ required: true }"
          data-test="materialset-lebensdauer-rohre"
        />
        <DropDown
          v-model="basedOnMaterialId"
          :items-data="materialSelect"
          items-data-key="name"
          value="id"
          :initial-selection="false"
          :clearable="true"
          label="Das Materialset basiert auf (optional):"
          class="w-[275px]"
        />
      </FormValidator>
    </template>
    <template #actions>
      <ButtonEl
        variant="secondary"
        text="Abbrechen"
        @click="showDialog = false"
      />
      <ButtonEl
        text="Anlegen"
        :disabled="!nameIsValid"
        @click="
          createMaterialset();
          showDialog = false;
        "
      />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import { axios } from '@/utils/axiosHelper';
import { ref } from 'vue';
import cookie from 'vue-cookies';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';

const emit = defineEmits(['addMaterialSet']);
const props = defineProps({ materialNames: Array, materialSelect: Array });

const nameIsValid = ref(false);
const showDialog = ref(false);
const name = ref(null);
const referenceYear = ref(null);
const heatSubstationEurHa = ref(null);
const workingLifePipesYear = ref(null);
const basedOnMaterialId = ref(null);

function createMaterialset() {
  const data = {
    name: name.value,
    reference_year: referenceYear.value,
    heat_substation_eur_ha: heatSubstationEurHa.value,
    working_life_pipes_year: workingLifePipesYear.value,
  };
  if (basedOnMaterialId.value) {
    data.based_on_id = basedOnMaterialId.value;
  }
  axios({
    url: '/api/heatprojects/material-set/',
    method: 'POST',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    data,
  }).then((r) => {
    emit('addMaterialSet', r.data);
  });
}
</script>
