export const byBlockTableheaders = [
  {
    title: 'Block',
    key: 'building_block_id',
    width: '92px',
    align: 'end',
  },
  {
    title: 'PLZ',
    key: 'postcode',
    width: '175px',
  },
  {
    title: 'Endenergieverbrauch nach Energieträger (MWh)',
    key: 'demand_by_heating_carrier',
  },
  {
    title: 'Anzahl der Wärmeerzeuger',
    key: 'buildings_by_heating_technology',
  },
];
