<template>
  <div class="relative flex h-screen w-full">
    <LayerPanel
      v-if="loaded && !getResults"
      v-show="showMainMenu || showProjectMenu"
    />
    <div class="mapView relative h-full w-full">
      <MapViewMapActions />

      <template v-if="loaded">
        <BaseMap
          :active-process-options="getActiveProcessOptions"
          @apply-municipality-filter="applyMunicipalityFilter($event)"
        />

        <div class="top-controls absolute w-full">
          <ControlBar />
          <MuniScenSelectorWrapper
            :reduce-scenario-info="true"
            @change-municipality="applyMunicipalityFilter($event)"
            @change-scenario="changeScenario($event)"
          />
          <LegendControls
            :show-legend="showLegend"
            @toggle-legend-state="showLegend = !showLegend"
          />
          <DashboardLink />
        </div>
        <div class="bottom-controls absolute w-full">
          <TimeMachine data-test="time-machine" :years="scenario.years" />
          <MapControls v-bind="$attrs" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, onBeforeUnmount, onMounted, ref, toRefs } from 'vue';
import axios from '@/utils/axiosHelper';
import { isInfraModuleActive } from '@/utils/is-infra-module-active';
import LayerPanel from '@/features/map/layer-panel/LayerPanel.vue';
import BaseMap from '@/features/map/BaseMap.vue';
import ControlBar from '@/features/map/control-options/components/ControlBar.vue';
import MuniScenSelectorWrapper from '@/components/municipality-scenario-selector/MuniScenSelectorWrapper.vue';
import LegendControls from '@/features/map/control-options/components/LegendControls.vue';
import TimeMachine from '@/features/map/control-options/components/TimeMachine.vue';
import MapControls from '@/features/map/control-options/components/MapControls.vue';
import DashboardLink from '@/features/map/control-options/components/DashboardLink.vue';
import { fitBBox, flyTo, removeMarker } from '@/mapbox/main';
import { useMapStore } from '@/features/map/store/map-store';
import MapViewMapActions from './components/MapViewMapActions.vue';

const { showMainMenu, showProjectMenu } = toRefs(useMapStore());

const store = useStore();
const router = useRouter();

const showLegend = ref(!!store.state.map.returnToDashboard?.tabName);
const loaded = ref(false);

const getActiveProcessOptions = computed(
  () => store.getters.getActiveProcessOptions,
);

const getResults = computed(() => {
  return store.getters['search/getResults'];
});

const comingFromDashboard = computed(
  () => store.state.map.returnToDashboard?.tabName,
);

onMounted(async () => {
  try {
    await initializeMap();

    if (comingFromDashboard.value) {
      store.commit('map/SET_SLIDER', true);
    }
  } catch (error) {
    console.error('Error during map initialization:', error);
  }
});

async function initializeMap() {
  loaded.value = false;
  store.commit('map/RESET_CONFIGS');

  const scenarioId = getScenarioIdFromURL() || getScenarioIdFromLocalStorage();

  if (scenarioId) {
    await changeScenario(scenarioId);
  } else {
    router.push('/');
    return;
  }
  const promises = [await store.dispatch('map/GET_COORDINATES')];

  if (isInfraModuleActive('projectPlaner')) {
    promises.push(
      await store.dispatch('heatProject/GET_HEAT_SOURCE'),
      await store.dispatch('heatProject/GET_HEATING_CENTER'),
      await store.dispatch('heatProject/GET_GRID_FEED_POINT'),
    );
  }
  await Promise.all(promises);
  animateControls();
  loaded.value = true;
}

function getScenarioIdFromURL() {
  const scenarioFromURL = Number(router.currentRoute.value.params.scenarioID);
  return isNaN(scenarioFromURL) ? null : scenarioFromURL;
}

function getScenarioIdFromLocalStorage() {
  const scenario = localStorage.getItem('scenario');
  return scenario ? Number.parseInt(scenario) : null;
}

const centroid = ref(null);

function flyToMunicipality(municipality) {
  return axios({
    method: 'GET',
    url: '/api/buildingmodel/municipality/centroid',
    params: { municipality_key: municipality },
  }).then((resp) => {
    centroid.value = resp.data;
    const config = {
      zoom: municipality === 'all' ? 6.5 : 11,
      lng: centroid.value.lng,
      lat: centroid.value.lat,
      maxZoom: 15,
    };
    flyTo(config);
  });
}

function flyToBBox() {
  return axios({
    method: 'GET',
    url: `/api/buildingmodel/total_area/${scenario.value.id}/`,
  }).then((resp) => {
    const bbox = resp.data.bbox;
    fitBBox(bbox);
  });
}

function animateControls() {
  setTimeout(() => {
    const bottomControls = document.querySelector('.bottom-controls');
    bottomControls.classList.add('bottom-controls-active');
    const topControls = document.querySelector('.top-controls');
    topControls.classList.add('top-controls-active');
  }, 500);
}

function applyMunicipalityFilter(municipalityKey) {
  const filterLayers = store.getters['map/getLayersForMunicipalityFilter'];
  const scenarioLayers = store.getters['map/getLayersForScenario'];
  if (municipalityKey && municipalityKey !== 'all') {
    store.commit('map/ADD_FILTER', {
      layerKeys: filterLayers,
      filter: {
        id: 'municipalityFilter',
        filter: [
          'any',
          ['!', ['has', 'municipality_id']],
          ['==', municipalityKey, ['get', 'municipality_id']],
        ],
      },
    });
    flyToMunicipality(municipalityKey);
  } else {
    // Filter non scenario dependent Layers
    const multi = filterLayers.filter((e) => !scenarioLayers.includes(e));
    const remove = filterLayers.filter((e) => scenarioLayers.includes(e));
    store.commit('map/REMOVE_FILTER', {
      layerKeys: remove,
      filterId: 'municipalityFilter',
    });
    store.commit('map/ADD_FILTER', {
      layerKeys: multi,
      filter: {
        id: 'municipalityFilter',
        filter: [
          'any',
          ['!', ['has', 'municipality_id']],
          ['in', ['get', 'municipality_id'], ['literal', municipalities.value]],
        ],
      },
      filterId: 'municipalityFilter',
    });
    flyToBBox();
  }
  removeMarker();
}

const municipalities = ref(null);
const scenario = ref(null);

async function changeScenario(scenarioId) {
  const scenarioResp = await axios({
    method: 'GET',
    url: `/api/scenarios/${scenarioId}/`,
  });
  const municipalitiesResp = await axios({
    method: 'GET',
    url: `/api/buildingmodel/municipality-selection/${scenarioId}/`,
  });
  municipalities.value = municipalitiesResp.data.data.map(
    (e) => e.municipality_key,
  );
  scenario.value = scenarioResp.data;
  store.commit('scenario/SET_SCENARIO', scenarioResp.data);

  // Only set base year if not coming from dashboard
  if (!store.state.map.returnToDashboard?.tabName) {
    store.commit('map/SET_SCENARIO_YEAR', scenarioResp.data.base_year_year);
  }
}

onBeforeUnmount(() => {
  showMainMenu.value = false;
  showProjectMenu.value = false;
  store.commit('map/SET_SLIDER', false);
});
</script>

<style lang="scss" scoped>
.bottom-controls {
  bottom: -100px;
  transition: bottom 1s ease;
}

.bottom-controls-active {
  bottom: 0;
}

.top-controls {
  top: -100px;
  transition: top 1s ease;
}

.top-controls-active {
  top: 20px;
}
</style>
