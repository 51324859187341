<template>
  <div class="flex h-fit max-h-[68px] min-w-[68px] items-center justify-center">
    <div v-if="logoUrl">
      <img
        v-show="imageLoaded"
        :src="logoUrl"
        alt="logo-client"
        class="standard-elevation-1 w-full rounded-[2px] object-contain"
        @load="imageLoaded = true"
        @error="imageLoaded = false"
      />
    </div>
    <IconGenericWappen v-else class="mt-0.5" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import IconGenericWappen from '@/components/municipality-scenario-selector/IconGenericWappen.vue';

const store = useStore();
const logoUrl = computed(() => store.getters.getLogoUrl);
const imageLoaded = ref(false);

onMounted(() => {
  store.dispatch('GET_LOGO_URL');
});
</script>
