<template>
  <div class="flex w-full justify-between">
    <div class="flex items-center">
      <img
        src="@/assets/svg/wire-tile-icon.svg"
        class="mr-6 h-[64px] w-[64px]"
        alt="money-group-icon"
      />
      <div class="text-[32px] font-normal text-title-color2">
        Wirtschaftlichkeit
      </div>
    </div>
    <div v-if="!editMode" class="flex w-fit items-end gap-2">
      <DropDown
        v-model="heatProjectSelected"
        :items-data="combinedHeatProjectsData"
        items-data-key="combined_name"
        value="id"
        placeholder="Bitte auswählen"
        label="Projektgebiet - Szenario"
        class="mr-2 min-w-[400px]"
        :clearable="true"
        data-test="dropdown-wire"
      />
      <WiReCreate
        v-if="isLoaded"
        :calculation-disabled="heatProjectSelected === null"
        :heat-project="heatProjectSelected"
        :premises="premiseData"
        :default-start-year="defaultStartYear"
        :can-add-wire
        @created="updateWireData"
      />
    </div>
  </div>

  <template v-if="!editMode">
    <!-- projektgebiet calculation -->
    <div
      class="flex w-full items-center justify-between gap-1 pt-5 text-title-color2"
    >
      <h3>Übersicht der erstellten Wirtschaftlichkeitsrechnungen</h3>
      <ButtonEl icon="restart_alt" variant="secondary" @click="getWiReData" />
    </div>
    <div class="flex items-end gap-5">
      <InputEl
        v-model="nameFilter"
        label="Name"
        text="Name"
        placeholder="Namen eingeben"
        class="w-[300px]"
      />
      <DropDown
        v-model="statusSelected"
        label="Freigabestatus"
        :items-data="[...Object.values(statusMapping)]"
        class="w-[300px]"
        items-data-key="label"
      />
      <ButtonEl
        text="Filter anwenden"
        icon="filter_list"
        icon-type="round"
        class="h-10"
        variant="secondary"
        @click="filterWires"
      />
    </div>
    <VuetifyDataTable
      :headers="EconomicEfficiencyTableHeaders"
      :item-data="filteredWiRes"
      :total-items="totalWiReItems"
      :open-page-options-to-top="true"
      class="standard-elevation-1 overflow-hidden rounded-[4px]"
      @update:options="getWiReData"
    >
      <template #created_at="{ item }">
        <div class="flex items-center justify-start gap-1">
          <span>{{
            new Date(item.created_at).toLocaleDateString('de-DE', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          }}</span>
          <span
            v-if="
              item.scenario_updated ||
              item.premises_updated ||
              item.project_updated
            "
            class="overline-3 mr-4 cursor-pointer rounded bg-spot-error p-1 text-inverted-neutral"
            >veraltet
            <VTooltip activator="parent" location="start">
              <span v-if="item.premises_updated">
                Prämissenset wurden aktualisiert.<br
              /></span>
              <span v-else-if="item.scenario_updated">
                Szenario wurde aktualisiert.</span
              >
              <span v-else-if="item.project_updated">
                Projekt wurde aktualisiert.</span
              >
            </VTooltip>
          </span>
        </div>
      </template>
      <template #projectArea="{ item }">
        {{ getProjectName(item.heat_project) }}
      </template>
      <template #progress="{ item }">
        <IconWrapper
          :icon="progressIcons[item.progress].name"
          :fill="progressIcons[item.progress].color"
          type="filled"
          class="mx-auto"
          :size="16"
          :data-test="progressIcons[item.progress].testId"
        />
      </template>
      <!-- Todo: Implement dynamic Freigabe status-->
      <template #released="{ item }">
        {{ statusMapping[item.release_status].label }}
      </template>
      <template #expanded-row="{ item }">
        <WiReTableExpandable
          :loading="item.progress === 2"
          :project-data="item"
        />
      </template>
      <template #functions="{ item }">
        <VProgressCircular
          v-if="item.progress === 2"
          class="text-title-color1"
          size="18"
          width="2"
          indeterminate
        />
        <div v-else class="flex items-center justify-end gap-4">
          <IconWrapper
            icon="segment"
            fill="text-title-color1"
            hover="hover:text-color1"
            type="filled"
            class="cursor-pointer"
            @click="enableEditMode(item)"
          />
          <IconWrapper
            v-if="canDownload"
            icon="download"
            fill="text-title-color1"
            hover="hover:text-color1"
            type="filled"
            class="cursor-pointer"
            @click="downloadItemWiRe = item"
          />
          <IconWrapper
            v-if="canDeleteWire && !(item.release_status === 2)"
            icon="delete_forever"
            fill="text-title-color1"
            hover="hover:text-color1"
            type="filled"
            class="cursor-pointer"
            @click="toggleDelete(item.id)"
          />
        </div>
        <VuetifyDialog
          v-if="deleteItemWiRe === item.id"
          v-model="deleteItemWiRe"
          :title="`Wirtschaftlichkeitsrechner &quot;${item.name}&quot; wirklich
            löschen?`"
        >
          <template #content>
            Dieser Vorgang kann nicht rückgängig gemacht werden.
          </template>
          <template #actions>
            <ButtonEl
              variant="secondary"
              text="Abbrechen"
              @click="deleteDialog = false"
            />
            <ButtonEl
              text="Löschen"
              color="error"
              variant="secondary"
              @click="deleteWiReItem(item.id)"
            />
          </template>
        </VuetifyDialog>
      </template>
    </VuetifyDataTable>
    <VuetifyDialog
      v-if="downloadItemWiRe"
      v-model="downloadItemWiRe"
      :title="`Wirtschaftlichkeitsrechnung ${downloadItemWiRe.name} herunterladen`"
    >
      <template #content>
        <WiReDownloadContainer
          :wire-id="downloadItemWiRe.id"
          :wire-name="downloadItemWiRe.name"
        />
      </template>
    </VuetifyDialog>
  </template>
  <WiReEditProject
    v-else-if="editMode && isLoaded"
    :project-data="projectDataSelected"
    :default-start-year="defaultStartYear"
    :start-tab="wireIdFromURL && 'Freigabe'"
    @update:project-data="updateWireData"
    @go-back="editMode = false"
  />
  <VProgressCircular
    v-else
    class="m-auto text-title-color1"
    size="60"
    width="10"
    indeterminate
  />
</template>

<script setup>
import { axios } from '@/utils/axiosHelper';
import { computed, onBeforeMount, ref, watch } from 'vue';
import cookie from 'vue-cookies';
import { EconomicEfficiencyTableHeaders } from './table-data';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import WiReCreate from '@/apps/economic-efficiency/WiReCreate.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import VuetifyDataTable from '@/components/storybook/src/stories/vuetifyDataTable/VuetifyDataTable.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import WiReTableExpandable from '@/apps/economic-efficiency/WiReTableExpandable.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import WiReEditProject from '@/apps/economic-efficiency/WiReEditProject.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import cloneObject from '@/utils/objectUtils';
import { statusMapping } from '@/apps/economic-efficiency/status-data';
import WiReDownloadContainer from '@/apps/economic-efficiency/WiReDownloadContainer.vue';

const store = useStore();
const router = useRouter();

const isLoaded = ref(false);
const editMode = ref(false);
const animateSpin = ref(false);
const defaultStartYear = ref(2023);
const nameFilter = ref('');
const statusSelected = ref(4);
const downloadItemWiRe = ref(null);

const previousPath = computed(
  () => router.currentRoute.value.meta.previousPath,
);
const canAddWire = computed(() => store.getters['auth/wireCanAddWire']);
const canDeleteWire = computed(() => store.getters['auth/wireCanDeleteWire']);
const wireIdFromURL = computed(() => {
  const wireFromURL = router.currentRoute.value.params.wireId;
  if (wireFromURL === '' || isNaN(Number(wireFromURL))) return null;
  return Number(wireFromURL);
});

onBeforeMount(async () => {
  try {
    await Promise.all([getWiReData(), getHeatProjectsData()]);
    // Logic after getHeatProjectsData succeeds
    if (previousPath.value === '/map') {
      heatProjectSelected.value = store.state.heatProject.heatProjects[0].id;
    } else if (previousPath.value === '/') {
      heatProjectSelected.value = null;
    }

    if (canAddWire.value) {
      await getPremisesData();
    }

    // Logic after both getPremisesData and getWiReData succeed
    isLoaded.value = true;

    // Check progress of wires and refresh for certain states
    wiReData.value.forEach((wire) => {
      if (wire.progress === 2) {
        checkAndUpdateProgress(wire.id);
      }
    });

    // For redirected cases with wire in url directly display it
    if (wireIdFromURL.value) {
      const project = wiReData.value.find(
        (wire) => wire.id === wireIdFromURL.value,
      );
      enableEditMode(project);
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
});

const wiReData = ref([]);
const filteredWiRes = ref([]);
const premiseData = ref([]);
const heatProjectsData = ref([]);
const heatProjectSelected = ref(null);
const canDownload = computed(() => store.getters['auth/wireCanDownload']);

const combinedHeatProjectsData = computed(() => {
  return heatProjectsData.value.map((item) => {
    return {
      ...item,
      combined_name: `${item.name} - ${item.scenario__name}`,
    };
  });
});

const totalWiReItems = ref(null);
const page = ref(1);
const itemsPerPage = ref(10);
const progressIcons = {
  0: {
    name: 'task_alt',
    color: 'text-spot-success',
    testId: 'progress-success',
  },
  1: {
    name: 'error',
    color: 'text-spot-error',
    testId: 'progress-error',
  },
  2: {
    name: 'watch_later',
    color: 'text-spot-warning',
    testId: 'progress-wait',
  },
};

function getWiReData(options = {}) {
  animateSpin.value = true;

  const params = {
    page: options.page || page.value,
    page_size: options.itemsPerPage || itemsPerPage.value,
    heat_project_id: heatProjectSelected.value
      ? heatProjectSelected.value
      : null,
    sortBy: options.sortBy?.[0]?.key,
    sortOrder: options.sortBy?.[0]?.order,
  };

  return axios({
    url: '/api/heatprojects/wi-re/wi-re/',
    method: 'GET',
    params: params,
  })
    .then((response) => {
      totalWiReItems.value = response.data.count;
      wiReData.value = response.data.results;
      filteredWiRes.value = cloneObject(wiReData.value);
      filterWires();

      setTimeout(() => {
        animateSpin.value = false;
      }, 750);
    })
    .catch((error) => {
      console.error('Failed to fetch data: ', error);
      animateSpin.value = false;
    });
}

function getPremisesData() {
  return axios({
    url: '/api/heatprojects/wi-re/premises-overview',
    method: 'GET',
  }).then((response) => {
    premiseData.value = response.data.premises;
  });
}

function getHeatProjectsData() {
  return axios({
    url: '/api/heatprojects/overview/',
    method: 'GET',
  }).then((response) => {
    heatProjectsData.value = response.data.projects;
  });
}

const projectDataSelected = ref();

function enableEditMode(item) {
  // Hausanschlussleitung values should be displayed aggregated in one field
  item.building_pipe_costs_euro_m =
    item.building_pipe_costs_euro_m +
    item.building_pipe_underground_costs_euro_m;
  item.building_pipe_underground_costs_euro_m = 0;

  projectDataSelected.value = item;
  editMode.value = true;
}

const deleteDialog = ref(false);
const deleteItemWiRe = ref(null);

function toggleDelete(id) {
  deleteItemWiRe.value = id;
  deleteDialog.value = true;
}

function getProjectName(id) {
  const project = heatProjectsData.value.find((project) => project.id === id);
  return project.name;
}

function updateRecalculatedWire(wire) {
  wiReData.value = wiReData.value.map((item) => {
    if (item.id === wire.id) {
      return wire;
    }
    return item;
  });
  filteredWiRes.value = cloneObject(wiReData.value);
  filterWires();
}

function deleteWiReItem(id) {
  return axios({
    url: `/api/heatprojects/wi-re/wi-re/${id}/`,
    method: 'DELETE',
    headers: {
      'X-CSRFToken': cookie.get('csrftoken'),
    },
  }).then(() => {
    getWiReData();
    deleteItemWiRe.value = null;
  });
}

async function updateWireData(promise) {
  const response = await promise;
  if (response.status === 201) {
    wiReData.value.unshift(response.data);
    filteredWiRes.value = cloneObject(wiReData.value);
    filterWires();
  }
  if (response.status === 200 || response.status === 201) {
    editMode.value = false;
    checkAndUpdateProgress(response.data.id);
  }
}

// We call this function recursively until the progress key is settled
// progress = 2, means still calculating
// progress = 0, means success
function checkAndUpdateProgress(id) {
  // Ensure id is defined before proceeding
  if (typeof id === 'undefined') return;

  const indexOfWire = wiReData.value.findIndex(
    (wireElement) => wireElement.id === id,
  );

  // Check if the index was found before attempting to set the progress property
  if (indexOfWire !== -1) {
    wiReData.value[indexOfWire].progress = 2;

    axios({
      url: `/api/heatprojects/wi-re/wi-re/${id}/`,
      method: 'GET',
      skipLoading: true,
    })
      .then((response) => {
        // Ensure the response is valid and id is defined before recursion or update
        if (response.data.progress === 2) {
          setTimeout(() => checkAndUpdateProgress(id), 5000);
        } else {
          updateRecalculatedWire(response.data);
        }
      })
      .catch((error) => {
        console.error(
          'Error during GET request in checkAndUpdateProgress:',
          error,
        );
      });
  }
}

function updateDefaultStartYear(scenarioId) {
  axios({
    url: `/api/scenarios/${scenarioId}/`,
    method: 'GET',
  })
    .then((response) => {
      defaultStartYear.value = response.data.base_year_year;
    })
    .catch((err) => {
      console.log(err);
    });
}

function ensureStartYearIntegrity(selectedProjectId) {
  const heatProject = combinedHeatProjectsData.value.find((heatProject) => {
    return heatProject.id === selectedProjectId;
  });

  if (!heatProject) {
    defaultStartYear.value = null;
  } else {
    updateDefaultStartYear(heatProject.scenario_id);
  }
}

function filterWires() {
  const filterText = (nameFilter.value || '').trim().toLowerCase();
  filteredWiRes.value = wiReData.value.filter((item) => {
    const passesTextFilter = item.name.toLowerCase().includes(filterText);
    const passesStatusFilter =
      item.release_status === statusSelected.value ||
      statusSelected.value === 4;

    return passesTextFilter && passesStatusFilter;
  });
}

watch(projectDataSelected, (newProjectData) => {
  ensureStartYearIntegrity(newProjectData.heat_project);
});

watch(heatProjectSelected, (newProjectSelectedId) => {
  if (isLoaded.value) {
    getWiReData();
  }
  ensureStartYearIntegrity(newProjectSelectedId);
});

watch(
  wireIdFromURL,
  (newVal) => {
    editMode.value = Boolean(newVal);
  },
  { immediate: true },
);
</script>

<style lang="scss">
.functions-item-custom {
  @apply flex cursor-pointer items-center justify-center opacity-40 hover:opacity-100;
}
</style>
