import HeatProject from '@/features/heat-project/heat-project';
import HeatSource from '@/features/heat-project/heat-source/heat-source';
import _ from 'lodash';

export default {
  ADD_HEAT_SOURCE: (state, payload) => {
    state.heatSources.push(
      new HeatSource(
        payload.id,
        payload.name,
        payload.system_type,
        payload.plant_type,
        payload.energy_source,
        payload.asset_type,
        payload.thermal_power_mw,
        payload.generated_heat_quantity_mwh,
        payload.electrical_power_mw,
        payload.generated_electricity_mwh,
        payload.emissions_t_mwh,
        payload.updated_at,
        payload.geometry.coordinates,
      ),
    );
  },
  UPDATE_HEAT_SOURCE: (state, payload) => {
    const heatSource = new HeatSource(
      payload.id,
      payload.name,
      payload.system_type,
      payload.plant_type,
      payload.energy_source,
      payload.asset_type,
      payload.thermal_power_mw,
      payload.generated_heat_quantity_mwh,
      payload.electrical_power_mw,
      payload.generated_electricity_mwh,
      payload.emissions_t_mwh,
      payload.updated_at,
      payload.geometry.coordinates,
    );
    const index = state.heatSources.findIndex((e) => e.id === heatSource.id);
    state.heatSources.splice(index, 1);
    state.heatSources.push(heatSource);
  },
  REMOVE_HEAT_SOURCE: (state, heatSourceId) => {
    const index = state.heatSources.findIndex((e) => e.id === heatSourceId);
    state.heatSources.splice(index, 1);
  },
  REMOVE_HEAT_PROJECT: (state, heatProjectId) => {
    const index = state.heatProjects.findIndex((e) => e.id === heatProjectId);
    const indexSelectables = state.heatProjectSelectables.findIndex(
      (e) => e.id === heatProjectId,
    );
    state.heatProjects.splice(index, 1);
    state.heatProjectSelectables.splice(indexSelectables, 1);
  },
  ADD_HEAT_PROJECT: (state, payload) => {
    const heatProject = new HeatProject();
    heatProject.initFromRequest(
      payload,
      _.isEmpty(payload.last_version_params),
    );
    state.heatProjects.push(heatProject);
  },
  RESET_HEAT_PROJECT: (state) => {
    state.heatProjects = [];
  },
  SET_HEAT_PROJECT_SELECTABLES: (state, projects) => {
    state.heatProjectSelectables = projects;
  },
  SET_PIPE_SELECTABLES: (state, pipes) => {
    state.pipeSelectables = pipes;
  },
  SET_HEAT_PROJECTS: (state, data) => {
    state.heatProjectData = data.results;
  },
  SET_LOAD_PROJECTS_ON_MAPLOAD: (state, projectIds) => {
    state.loadProjectsOnMapLoad = projectIds;
  },
};
