<template>
  <div class="flex items-center">
    <img
      src="@/assets/svg/premises-icon.svg"
      class="mr-3 w-[64px]"
      alt="icon"
    />
    <h2>Prämissen</h2>
  </div>

  <template v-if="isLoaded">
    <!-- premises list table -->
    <VuetifyDataTable
      class="standard-elevation-1 overflow-hidden rounded-[4px]"
      :headers="headers"
      :item-data="premisesData"
      :show-pagination="premisesData.length > 10"
      :show-expand="false"
    >
      <template #created_at="{ item }">
        {{
          new Date(item.created_at).toLocaleDateString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
        }}
      </template>

      <template #progress="{ item }">
        <div class="flex justify-center">
          <img :src="getIconSource(item.progress)" alt="progress" />
        </div>
      </template>

      <template #functions="{ item }">
        <IconWrapper
          :icon="item.progress === 0 ? 'segment' : 'edit'"
          fill="text-title-color1"
          :type="item.progress === 0 ? 'filled' : null"
          :class="[
            'ml-auto',
            {
              'pointer-events-none opacity-40': editItem,
              'cursor-pointer opacity-80 hover:opacity-100': !editItem,
            },
          ]"
          data-test="edit-scenario-frame"
          @click="editPremise(item.id)"
        />
      </template>
    </VuetifyDataTable>
    <PremisesEdit
      v-if="editItem !== null"
      :edit-premises-id="editItem"
      @abort="hideEditMode"
      @update-base-data="getPremises"
    />
    <PremisesCreate
      v-else
      :scenario-frames="scenarioFramesBaseData"
      @edit="editPremise($event)"
      @add="getPremises()"
    />
  </template>
</template>

<script setup>
import { axios } from '@/utils/axiosHelper';
import { onMounted, ref } from 'vue';
import PremisesCreate from './PremisesCreate.vue';
import PremisesEdit from './PremisesEdit.vue';
import PremiseSet from './premise-set';
import CheckGreenIcon from '@/assets/svg/check-green.svg';
import ProgressIcon from '@/assets/svg/progress-icon.svg';
import VuetifyDataTable from '@/components/storybook/src/stories/vuetifyDataTable/VuetifyDataTable.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';

const headers = [
  {
    title: 'Name',
    key: 'name',
    align: 'start',
    width: '400px',
  },
  {
    title: 'Erstellt am',
    key: 'created_at',
    width: '200px',
    align: 'left',
  },
  {
    title: 'Status',
    key: 'progress',
    align: 'center',
    width: '100px',
    sortable: false,
  },
  {
    title: '',
    key: 'functions',
    width: '100%',
    align: 'end',
    sortable: false,
  },
];

const premisesData = ref([]);
const premisesOptions = ref([]);
const isLoaded = ref(false);
const dialog = ref(false);
const editItem = ref(null);
const scenarioFramesBaseData = ref([]);

onMounted(async () => {
  await fetchFrameBaseData();
  await getPremises();
  await PremiseSet.fetchOptions();
});

function getPremises() {
  return axios({
    url: '/api/heatprojects/wi-re/premises/',
    method: 'GET',
  }).then((r) => {
    premisesData.value = r.data.results;
    getPremisesOptions();
  });
}

function getPremisesOptions() {
  return axios({
    url: '/api/heatprojects/wi-re/premises/',
    method: 'OPTIONS',
  }).then((r) => {
    premisesOptions.value = r.data.actions.POST;
    isLoaded.value = true;
  });
}

function editPremise(premiseId) {
  editItem.value = premiseId;
}

function hideEditMode() {
  editItem.value = null;
}

function getIconSource(progress) {
  switch (progress) {
    case 0:
      return CheckGreenIcon;
    case 1:
      return ProgressIcon;
  }
}

function updatePremisesData(event) {
  premisesData.value = premisesData.value.map((item) => {
    if (item.id === event.id) {
      return event;
    }
    return item;
  });
}

function fetchFrameBaseData() {
  axios({ url: '/api/scenario-frames/base-data/', method: 'GET' }).then((r) => {
    scenarioFramesBaseData.value = r.data.frames;
  });
}
</script>
