import MultiselectFeatures from '@/features/utilities/multi-select-feature';
import { LAYER_KEY__BLOCK } from '@/configs/layers/baseData/constants';
import { BLOCKS_WITHIN_PRIORITY_AREA } from '@/features/draw/constants';

export default class BlockExpansionConstraints {
  /**
   * Class for setting priority areas for building blocks
   */
  constructor() {
    this.priorityAreaType = null;
    this.MultiSelect = new MultiselectFeatures(
      'api/buildingmodel/within/building-blocks/',
      'id',
      LAYER_KEY__BLOCK,
      BLOCKS_WITHIN_PRIORITY_AREA,
    );
  }

  /**
   * create payload to set constraints in backend
   */
  get payload() {
    return {
      building_block: Array.from(this.MultiSelect.IDs),
      priority_area_type: this.priorityAreaType,
    };
  }

  get isValid() {
    return this.MultiSelect.IDs.size > 0 && this.priorityAreaType;
  }

  /**
   * add map interaction eventhooks
   */
  initMultiSelect() {
    this.MultiSelect.initEventHooks();
  }

  /**
   * remove map interaction event hook.
   * Should be called when process is finished
   */
  removeMultiSelect() {
    this.MultiSelect.destroy();
  }
}
