<template>
  <div class="standard-elevation-1 overflow-hidden rounded-[4px]">
    <skeleton-loader-list v-if="!dataLoaded" class="skeleton-custom-height" />
    <VuetifyDataTable
      v-else
      :headers="dataHeaders"
      :item-data="scenarios"
      item-value="scenario_id"
      :show-expand="false"
      :open-page-options-to-top="true"
    >
      <template #date="{ item }">
        <div class="flex items-center justify-start gap-1">
          <span
            class="body-2 whitespace-nowrap"
            :class="{ 'font-bold': item.building_data_changed }"
          >
            {{
              `${new Date(item.created_at).toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })} von ${item.created_by}`
            }}</span
          >
          <span
            v-if="item.building_data_changed"
            class="bg-red body-2 mr-4 rounded p-[7px] text-white"
            >veraltet
            <VTooltip activator="parent" location="start">
              <span v-if="item.building_data_changed">
                Gebäudedaten wurden aktualisiert.<br
              /></span>
            </VTooltip>
          </span>
        </div>
      </template>

      <template #updated="{ item }">
        <div class="flex items-center justify-start gap-1">
          <span
            class="body-2 whitespace-nowrap"
            :class="{ 'font-bold': item.building_data_changed }"
          >
            {{
              `${new Date(item.updated_at).toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}`
            }}</span
          >
        </div>
      </template>

      <template #progress="{ item }">
        <div
          v-if="item"
          :class="getProgressClass(item.progress)"
          class="flex w-fit justify-start rounded-md p-1"
        >
          <IconWrapper
            :icon="getProgressIcon(item.progress)"
            :fill="getProgressFill(item.progress)"
            type="filled"
            :size="16"
          />
          <span
            v-if="[21, 22].includes(item.progress)"
            class="subtitle-3 pl-1 font-bold text-spot-warning"
          >
            {{ item.progress_percentage || 0 }}%
          </span>
          <VTooltip activator="parent" location="start" width="205px"
            >{{ getProgressTooltip(item.progress) }}
          </VTooltip>
        </div>
      </template>
      <template #functions="{ item }">
        <div class="flex items-center justify-end gap-1.5">
          <div
            v-if="item.progress === 0"
            class="flex items-center justify-center opacity-70 hover:opacity-100"
          >
            <IconWrapper
              icon="map"
              class="cursor-pointer"
              fill="text-title-color1"
              @click="showScenarioMap(item.scenario_id)"
            />
            <VTooltip activator="parent" location="start"
              >Gehe zu: Karte
            </VTooltip>
          </div>
          <div
            v-if="item.progress === 0"
            class="flex items-center justify-center opacity-70 hover:opacity-100"
          >
            <IconWrapper
              icon="insert_chart"
              class="cursor-pointer"
              fill="text-title-color1"
              @click="showScenarioDashboard(item.scenario_id)"
            />

            <VTooltip activator="parent" location="start"
              >Gehe zu: Dashboard
            </VTooltip>
          </div>
          <div
            v-if="item.progress === 0 || item.progress === 1"
            class="flex items-center justify-center opacity-70 hover:opacity-100"
          >
            <IconWrapper
              icon="restart_alt"
              class="cursor-pointer"
              fill="text-title-color1"
              type="filled"
              @click="updateScenario(item)"
            />
            <VTooltip activator="parent" location="start"
              >Szenario erneut rechnen
            </VTooltip>
          </div>
          <div
            v-if="item.progress === 4"
            class="flex items-center justify-center opacity-70 hover:opacity-100"
          >
            <IconWrapper
              icon="edit"
              class="cursor-pointer"
              fill="text-title-color1"
              :data-test="'edit-pen-scenario-' + item.scenario_id"
              @click="openConfirmDialog(item)"
            />
            <VTooltip activator="parent" location="start"
              >Bearbeitung fortsetzen
            </VTooltip>
          </div>
          <div
            v-if="item.progress === 0 || item.progress === 1"
            class="flex items-center justify-center opacity-70 hover:opacity-100"
          >
            <IconWrapper
              icon="segment"
              class="cursor-pointer"
              fill="text-title-color1"
              type="filled"
              :data-test="'read-only-icon-' + item.scenario_id"
              @click="openConfirmDialog(item)"
            />
            <VTooltip activator="parent" location="start"
              >Szenario ansehen
            </VTooltip>
          </div>
          <ScenarioDelete v-if="item.progress !== 2" :scenario-item="item" />
        </div>
        <VuetifyDialog
          v-if="scenarioId === item.id"
          v-model="confirmDialogOpen"
          :activator-full-width="false"
          :actions-centered="true"
          :title="`Öffnen des Szenarios &quot;${item.name}&quot;`"
        >
          <template #content>
            <div class="my-5 w-[650px]">
              Mögliche aktuelle Eingaben in dem Szenario-Eingabe-Formular gehen
              durch das Öffnen dieses Szenarios verloren. Möchten Sie das
              Szenario öffnen?
            </div>
          </template>
          <template #actions>
            <ButtonEl
              variant="secondary"
              text="Auswahl Abbrechen"
              @click="cancelOpening"
            />
            <ButtonEl
              text="Szenario öffnen"
              @click="() => confirmOpening(item)"
            />
          </template>
        </VuetifyDialog>
      </template>
      <template #expanded-row>
        <div class="flex flex-col gap-4 py-4">
          <div v-if="!expandedDataLoaded">
            <div class="flex justify-center">
              <span class="body-2"> Daten werden geladen </span>
            </div>
          </div>
        </div>
      </template>
    </VuetifyDataTable>
  </div>
</template>

<script setup>
import ScenarioDelete from '@/apps/scenarios/components/ScenarioDelete.vue';
import SkeletonLoaderList from '@/components/skeleton-loader/skeletonLoaderList.vue';
import { useStore } from 'vuex';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import VuetifyDataTable from '@/components/storybook/src/stories/vuetifyDataTable/VuetifyDataTable.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';

const router = useRouter();
const emit = defineEmits(['scenarioUpdated', 'toggleScenario']);
const props = defineProps({
  dataLoaded: Boolean,
  scenarios: Array,
  scenarioFormOpen: Boolean,
});
const store = useStore();
const expandedDataLoaded = ref(false);

const dataHeaders = [
  {
    title: 'Name',
    key: 'name',
    align: 'start',
    sortable: false,
    width: '15%',
  },
  {
    title: 'Erstellt am',
    key: 'date',
    align: 'start',
    sortable: false,
    width: '375px',
  },
  {
    title: 'Aktualisiert am',
    key: 'updated',
    align: 'start',
    sortable: false,
    width: '180px',
  },
  {
    title: 'Zieljahr Klimaneutralität',
    key: 'target_year',
    align: 'start',
    sortable: false,
    width: '160px',
  },
  {
    title: 'Status',
    key: 'progress',
    align: 'start',
    width: '100px',
  },
  {
    title: 'Funktionen',
    key: 'functions',
    sortable: false,
    align: 'center',
    width: '175px',
  },
];

function getProgressIcon(progress) {
  switch (progress) {
    case 0:
      return 'task_alt';
    case 1:
      return 'error';
    case 4:
      return 'motion_photos_pause';
    case 21:
      return 'location_city';
    case 22:
      return 'map';
    default:
      return 'watch_later';
  }
}

function getProgressClass(progress) {
  switch (progress) {
    case 0:
      return 'bg-fill-success';
    case 1:
      return 'bg-fill-error';
    default:
      return 'bg-fill-warning';
  }
}

function getProgressFill(progress) {
  switch (progress) {
    case 0:
      return 'text-spot-success';
    case 1:
      return 'text-spot-error';
    default:
      return 'text-spot-warning';
  }
}

function getProgressTooltip(progress) {
  switch (progress) {
    case 0:
      return 'Berechnung der Kartenelemente und Stadtteile erfolgreich abgeschlossen';
    case 1:
      return 'Fehler bei der Berechnung';
    case 4:
      return 'Der Stand wurde zwischengespeichert';
    case 21:
      return 'Berechnung für Stadtteile läuft';
    case 22:
      return 'Berechnung der Kartenelemente läuft';
    case 10:
      return 'Berechnung in Warteschlange';
    default:
      return 'Status unbekannt';
  }
}

function showScenarioDashboard(scenarioID) {
  localStorage.setItem('denominator', 'scenario');
  localStorage.setItem('municipality', 'all');
  localStorage.setItem('scenario', scenarioID);
  router.push({ name: 'Dashboards' });
}

function showScenarioMap(scenarioID) {
  localStorage.setItem('denominator', 'scenario');
  localStorage.setItem('municipality', 'all');
  localStorage.setItem('scenario', scenarioID);
  router.push({ name: 'mapView', params: { scenarioID } });
}

function updateScenario(scenario) {
  store.dispatch('scenario/PUT_SCENARIO', scenario).then(() => {
    emit('scenarioUpdated', scenario.scenario_id);
  });
}

// Dialog
const confirmDialogOpen = ref(false);
const scenarioId = ref(null);

function openConfirmDialog(scenario) {
  if (props.scenarioFormOpen) {
    confirmDialogOpen.value = true;
    scenarioId.value = scenario.id;
  } else {
    emit('toggleScenario', scenario);
  }
}

function cancelOpening() {
  confirmDialogOpen.value = false;
}

function confirmOpening(item) {
  emit('toggleScenario', item);
  confirmDialogOpen.value = false;
}
</script>

<style scoped>
.skeleton-custom-height {
  min-height: calc(100vh - 382px);
}
</style>
