<template>
  <div>
    <div @click="showDialog = true">
      <slot />
    </div>
    <VuetifyDialog
      v-model="showDialog"
      :persistent="true"
      title="Datenfelder zurücksetzen"
    >
      <template #content>
        <div class="p-1">
          <div class="standard-elevation-1 overflow-hidden rounded-[4px] p-1">
            <div class="-m-1 bg-blue-grey-100 p-1">
              <v-checkbox
                v-model="selectAll"
                color="#455A64"
                :ripple="false"
                density="compact"
                :indeterminate="
                  columnsSelected.length ? selectAll === false : null
                "
                indeterminate-icon="mdi-minus"
                :hide-details="true"
                :label="`Alle ${writableFields.length} Felder ${
                  selectAll ? 'abwählen' : 'auswählen'
                }`"
                :class="{
                  'intermediate-checkbox': columnsSelected.length && !selectAll,
                }"
              >
                <template #label="item">
                  <h6 class="ml-2 text-blue-grey-600">{{ item.label }}</h6>
                </template>
              </v-checkbox>
            </div>

            <div class="mt-1">
              <v-checkbox
                v-for="item in writableFields"
                :key="item.key"
                v-model="columnsSelected"
                color="#01799D"
                :ripple="false"
                density="compact"
                :hide-details="true"
                :label="item.label"
                :value="item.key"
              >
                <template #label="subItem">
                  <div class="body-2 ml-2 text-blue-grey-600">
                    {{ subItem.label }}
                  </div>
                </template>
              </v-checkbox>
            </div>
          </div>
        </div>
      </template>
      <template #actions>
        <ButtonEl
          text="Abbrechen"
          variant="secondary"
          @click="showDialog = false"
        />
        <ButtonEl
          text="Daten zurücksetzen"
          color="error"
          variant="secondary"
          @click="resetData()"
        />
      </template>
    </VuetifyDialog>
    <VuetifyDialog
      v-model="updateHeatDemandDialog"
      :activator-full-width="false"
      :title="`Wärmebedarf anpassen? `"
    >
      <template #content>
        Die Gebäude-Parameter können die Höhe der Wärmebedarfsschätzung
        beeinflussen.<br />
        Möchten sie den aktuellen Bedarf durch einen neuen Schätzwert
        überschreiben?
      </template>
      <template #actions>
        <ButtonEl
          variant="secondary"
          text="Wärmebedarf behalten"
          @click="performResetData(false)"
        />
        <ButtonEl
          text="Wärmebedarf überschreiben"
          color="error"
          variant="secondary"
          @click="performResetData(true)"
        />
      </template>
    </VuetifyDialog>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { panels } from '@/features/building-versioning/components/building-update-panels';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';

const showDialog = ref(false);
const updateHeatDemandDialog = ref(false);
const emit = defineEmits(['updateBuildings']);

const props = defineProps({
  writableFields: {
    type: Array,
    default: () => [],
  },
});

const columnsSelected = ref([...props.writableFields.map((item) => item.key)]);

const selectAll = computed({
  get() {
    return columnsSelected.value.length === props.writableFields.length;
  },
  set(value) {
    columnsSelected.value = value
      ? [...props.writableFields.map((item) => item.key)]
      : [];
  },
});

function resetData() {
  let heatDemandEffected = false;
  const fields = panels.flatMap((panel) =>
    panel.fields
      .filter((field) => field.effectsHeatDemand)
      .map((field) => field.dbColumnKey),
  );
  for (const selected of columnsSelected.value) {
    if (fields.includes(selected)) heatDemandEffected = true;
  }
  if (heatDemandEffected) updateHeatDemandDialog.value = true;
  else performResetData(false);
}

function performResetData(useHeatDemandEstimation = false) {
  emit('updateBuildings', {
    fields: columnsSelected.value,
    useHeatDemandEstimation,
  });
  showDialog.value = false;
  updateHeatDemandDialog.value = false;
}
</script>
