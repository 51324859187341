<template>
  <VExpansionPanels
    v-model="activePanel"
    variant="accordion"
    class="scenario-frame-expansion-panel w-full gap-1"
  >
    <VExpansionPanel v-for="name in panelTitles" :key="name" :value="name">
      <VExpansionPanelTitle collapse-icon="mdi-plus">
        <h5>{{ name }}</h5>
        <template #actions>
          <ExpansionPanelTitleActions />
        </template>
      </VExpansionPanelTitle>
      <VExpansionPanelText>
        <div v-if="hasYear(name)" class="flex flex-col gap-4">
          <div class="grid auto-cols-fr grid-flow-col gap-3">
            <h6
              v-for="(year, index) in yearData(name).years"
              :key="year"
              class="w-fit text-blue-grey-300"
            >
              {{ index === 0 ? `Ist (${year})` : year }}
            </h6>
          </div>

          <div class="flex flex-col gap-3">
            <div
              v-for="item in Object.keys(yearData(name).year_data)"
              :key="item"
              class="flex flex-col gap-2"
            >
              <div class="grid auto-cols-fr grid-flow-col gap-3">
                <div
                  v-for="(subItem, subIndex) in Object.values(
                    yearData(name).year_data[item],
                  ).filter((item) => !('sharedItems' in item))"
                  :key="subItem"
                  class="flex flex-col justify-end gap-2"
                >
                  <div
                    v-if="subIndex === 0"
                    class="caption-1 whitespace-nowrap text-infra-highlight-900"
                  >
                    {{ `${item} (${subItem.unit})` }}
                  </div>
                  <div class="flex items-center gap-1">
                    <InputEl
                      v-if="
                        showShares &&
                        subItem.key === 'emission_factor_district_heating'
                      "
                      :model-value="aggregatedSharedValues.totals[subIndex]"
                      input-type="float"
                      class="w-fit"
                      :disabled="true"
                    />
                    <InputEl
                      v-else
                      v-model="subItem.value"
                      class="w-fit"
                      :input-type="
                        subItem.unit === '%' ? 'percent' : subItem.type
                      "
                      :disabled="readOnly"
                      :rules="{ required: true }"
                    />
                    <div
                      v-if="
                        Object.values(yearData(name).year_data[item]).filter(
                          (item) => 'sharedItems' in item,
                        ).length && subIndex === 0
                      "
                      class="flex items-center gap-0.5"
                    >
                      <IconWrapper
                        :class="[
                          { 'rotate-180': showShares },
                          'cursor-pointer duration-300',
                        ]"
                        icon="keyboard_arrow_down"
                        @click="showShares = !showShares"
                      />
                      <ToolTip
                        tooltip-text="Ausklappen für Anteile Fernwärme"
                        tooltip-icon-fill="grey"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="filteredSharedItems(name, item).length && showShares">
                <div class="my-2 grid auto-cols-fr grid-flow-col gap-3">
                  <div
                    v-for="percent in aggregatedSharedValues.percents"
                    :key="percent"
                  >
                    <div class="flex items-center gap-1">
                      <p class="body-2">
                        {{ percent.formatted }}
                      </p>
                      <VTooltip v-if="percent.sum > 1">
                        <template #activator="{ props }">
                          <div
                            v-bind="props"
                            class="bg-red mb-[2px] h-3 w-3 cursor-pointer rounded-full"
                          ></div>
                        </template>
                        <div>Summe ist größer als 100%</div>
                      </VTooltip>
                    </div>
                    <p class="body-3">
                      {{ percent.unKnownFormatted }}
                    </p>
                  </div>
                </div>
                <div
                  v-for="filterItem in filteredSharedItems(name, item)"
                  :key="filterItem.id"
                  class="flex flex-col gap-2"
                >
                  <div
                    v-for="sharedItemKey in Object.keys(filterItem.sharedItems)"
                    :key="sharedItemKey"
                    class="caption-1 flex flex-col gap-1 text-infra-highlight-900"
                  >
                    <div class="grid auto-cols-fr grid-flow-col gap-3">
                      <div
                        v-for="(subItem, index) in filterItem.sharedItems[
                          sharedItemKey
                        ]"
                        :key="index"
                        class="flex flex-col gap-1.5"
                      >
                        <InputEl
                          v-model="subItem.value"
                          class="mt-auto flex w-fit flex-col justify-end"
                          :label="
                            index === 0
                              ? `${sharedItemKey} (${subItem.unit})`
                              : null
                          "
                          :input-type="
                            subItem.unit === '%' ? 'percent' : subItem.type
                          "
                          :disabled="readOnly"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="hasPower(name)" class="flex flex-col gap-4">
          <div v-if="powerDataHasRevenue(name)">
            <div class="flex flex-col gap-3">
              <div
                v-for="(category, categoryIndex) in groupedRevenueData(name)"
                :key="categoryIndex"
                class="flex flex-col gap-2"
              >
                <div class="caption-2 text-blue-grey-900">
                  {{ category.title }}
                </div>
                <div class="grid auto-cols-fr grid-flow-col gap-3">
                  <InputEl
                    v-for="item in category.data"
                    :key="item"
                    v-model="item.value"
                    :label="item.power_kw + ' kW'"
                    :input-type="item.unit === '%' ? 'percent' : item.type"
                    :disabled="readOnly"
                    :rules="{ required: true }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              v-if="powerData(name)[0].info_data.length"
              class="flex flex-col gap-4"
            >
              <div class="flex w-full flex-col items-start gap-3">
                <h6 class="text-blue-grey-300">
                  Annahmen für den Vollkostenvergleich aus Sicht der
                  Hauseigentümer:innen
                </h6>
                <div class="grid w-full grid-cols-4 gap-2">
                  <InputEl
                    v-for="item in powerData(name)[0].info_data"
                    :key="item"
                    v-model="item.value"
                    :suffix="item.unit"
                    :label="item.label"
                    :input-type="item.unit === '%' ? 'percent' : item.type"
                    :disabled="readOnly"
                    :rules="
                      item.key.includes('life_expectancy')
                        ? { required: true, min: 1 }
                        : { required: true }
                    "
                  />
                </div>
              </div>
              <div class="flex w-full flex-col items-start gap-3">
                <h6 class="text-blue-grey-300">
                  Spezifische Investitionen für Hauseigentümer:innen
                </h6>
                <div class="grid grid-cols-6 gap-2">
                  <InputEl
                    v-for="item in sortedPowerItems(name)"
                    :key="item"
                    v-model="item.value"
                    class="mt-auto"
                    :suffix="item.unit"
                    :label="item.power_kw + ' kW'"
                    :input-type="item.unit === '%' ? 'percent' : item.type"
                    :disabled="readOnly"
                    :rules="
                      item.key.includes('life_expectancy')
                        ? { required: true, min: 1 }
                        : { required: true }
                    "
                  />
                </div>
              </div>
            </div>
            <div v-else class="flex flex-col gap-4">
              <div
                v-for="(categoryData, category) in groupedFixedVariableData(
                  name,
                )"
                :key="category"
                class="flex flex-col gap-2"
              >
                <h6 class="text-blue-grey-300">
                  {{ category === 'fixed' ? 'Fix' : 'Variabel' }}
                </h6>
                <div class="flex flex-col gap-3">
                  <div
                    v-for="(group, key) in categoryData"
                    :key="key"
                    class="flex flex-col gap-2"
                  >
                    <div class="grid auto-cols-fr grid-flow-col gap-3">
                      <div
                        v-for="(item, indexGroupedFixedVar) in group"
                        :key="item.key"
                        class="flex flex-col justify-end gap-2"
                      >
                        <div
                          v-if="indexGroupedFixedVar === 0"
                          class="caption-2 whitespace-nowrap text-blue-grey-900"
                        >
                          {{
                            (key === 'fee_grid_usage'
                              ? category === 'fixed'
                                ? 'Fixe Nutzungsentgelte'
                                : 'Variable Nutzungsentgelte'
                              : category === 'fixed'
                                ? 'Sonstige fixe Kosten'
                                : 'Sonstige variable Kosten') +
                            (item.unit ? ' (' + item.unit + ')' : '')
                          }}
                        </div>
                        <InputEl
                          v-model="item.value"
                          :label="item.power_kw + ' kW'"
                          :input-type="
                            item.unit === '%' ? 'percent' : item.type
                          "
                          :disabled="readOnly"
                          :rules="{ required: true }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="grid grid-cols-2 gap-x-4 gap-y-3">
          <template v-for="field in getPanelData(name)" :key="field">
            <DropDown
              v-if="field.type === 'choice'"
              v-model="field.value"
              :label="field.label"
              :initial-selection="true"
              :disabled="readOnly"
              items-data-key="display_name"
              :items-data="field.choices"
              @update:model-value="
                field.key === 'bkz_calculation_method' && (bkzChoice = $event)
              "
            />
            <InputEl
              v-else-if="renderField(field)"
              v-model="field.value"
              :label="field.label"
              :input-type="field.unit === '%' ? 'percent' : field.type"
              :suffix="field.unit"
              :disabled="readOnly"
              :rules="
                field.key.includes('working_life')
                  ? { required: true, min: 1 }
                  : { required: true }
              "
            />
          </template>
        </div>
      </VExpansionPanelText>
    </VExpansionPanel>
  </VExpansionPanels>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import ExpansionPanelTitleActions from '@/features/scenario-frame/components/ExpansionPanelTitleActions.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import ToolTip from '@/components/storybook/src/stories/toolTip/ToolTip.vue';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  tab: {
    type: String,
    required: true,
  },
  readOnly: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['sharedValuesValid']);

const bkzChoice = ref(null);

const showShares = ref(false);

let activePanel = ref(0);

const panelTitles = computed(() => Object.keys(props.data));
const getPanelData = (panel) => props.data[panel];

const hasYear = (panel) => {
  return props.data[panel].some((item) => 'year' in item);
};

const powerDataHasRevenue = (panel) => {
  return sortedPowerItems(panel).some((item) => item.key.includes('revenue'));
};

const yearData = (panel) => {
  const data = props.data[panel];
  const years = [...new Set(data.map((item) => item.year))].sort(
    (a, b) => a - b,
  );

  let labelGrouped = {};
  let shareItems = {}; // Initialize as an object for label grouping

  data.forEach((item) => {
    const label = item.label;
    const key = item.key;
    if (key.includes('share')) {
      if (!shareItems[label]) {
        shareItems[label] = [];
      }
      shareItems[label].push(item);
      shareItems[label].sort((a, b) => a.year - b.year);
    } else {
      if (label in labelGrouped) {
        labelGrouped[label].push(item);
      } else {
        labelGrouped[label] = [item];
      }
    }
  });

  for (let label in labelGrouped) {
    labelGrouped[label].sort((a, b) => a.year - b.year);
  }

  // If there are share items, append them to 'emission_factor_district_heating' groups
  for (let label in labelGrouped) {
    if (
      labelGrouped[label].some(
        (item) => item.key === 'emission_factor_district_heating',
      )
    ) {
      // Here, only push the shareItems that match the current label
      if (shareItems) {
        labelGrouped[label].push({ sharedItems: shareItems });
      }
    }
  }

  return {
    years: years,
    year_data: labelGrouped,
  };
};

const hasPower = (panel) => {
  return props.data[panel].some((item) => 'power_kw' in item);
};

const powerData = (panel) => [
  {
    info_data: props.data[panel].filter((item) => !('power_kw' in item)),
    power_kw: props.data[panel].filter((item) => 'power_kw' in item),
  },
];

const sortedPowerItems = (name) => {
  return powerData(name)[0].power_kw.sort((a, b) => a.power_kw - b.power_kw);
};

function renderField(field) {
  const exclusionMap = {
    1: [
      'construction_subsidy_eur_per_heat_load_kw',
      'construction_subsidy_eur_per_building_connection_length_m',
    ],
    2: [
      'construction_subsidy_eur_per_unit_residential',
      'construction_subsidy_eur_per_building_connection_length_m',
    ],
    3: [
      'construction_subsidy_eur_per_unit_residential',
      'construction_subsidy_eur_per_heat_load_kw',
    ],
  };

  return !exclusionMap[bkzChoice.value]?.includes(field.key);
}

function filteredSharedItems(name, item) {
  return Object.values(yearData(name).year_data[item]).filter(
    (item) => 'sharedItems' in item,
  );
}

function groupedFixedVariableData(panel) {
  const rawData = sortedPowerItems(panel);

  const result = {
    fixed: {
      fee_grid_usage: [],
      other: [],
    },
    variable: {
      fee_grid_usage: [],
      other: [],
    },
  };

  rawData.forEach((item) => {
    const category = item.key.includes('fixed') ? 'fixed' : 'variable';
    const type = item.key.includes('fee_grid_usage')
      ? 'fee_grid_usage'
      : 'other';
    result[category][type].push(item);
  });

  return result;
}

function groupedRevenueData(panel) {
  const rawData = sortedPowerItems(panel);

  const consumptionBasedRevenueData = [];
  const annualRevenueData = [];
  const oneTimeRevenueData = [];

  rawData.forEach((item) => {
    if (item.key.includes('consumption_based_revenue')) {
      consumptionBasedRevenueData.push(item);
    } else if (item.key.includes('annual_revenue')) {
      annualRevenueData.push(item);
    } else if (item.key.includes('one_time_revenue')) {
      oneTimeRevenueData.push(item);
    }
  });

  const generateTitle = (data) => {
    if (data.length > 0) {
      return `${data[0].label} (${data[0].unit})`;
    }
    return '';
  };

  return {
    consumptionBasedRevenue: {
      title: generateTitle(consumptionBasedRevenueData),
      data: consumptionBasedRevenueData,
    },
    annualRevenue: {
      title: generateTitle(annualRevenueData),
      data: annualRevenueData,
    },
    oneTimeRevenue: {
      title: generateTitle(oneTimeRevenueData),
      data: oneTimeRevenueData,
    },
  };
}

const aggregatedSharedValues = ref({ totals: [], percents: [] });
const sharedValuesValid = ref(true);

function aggregateSharedValues() {
  const emissionsTabName = 'Emissionsfaktoren';
  const sharedItemsKey = 'Fernwärme';
  const unKnownKey = 'Unbekannt';

  const data = yearData(emissionsTabName);
  const sharedItems = Object.values(
    data.year_data[sharedItemsKey][data.years.length].sharedItems,
  );
  const resultTotals = {
    totals: [],
    percents: [],
  };
  sharedValuesValid.value = true;

  for (let yearIndex = 0; yearIndex < data.years.length; yearIndex++) {
    const { fieldTotal, fieldPercentSum } = calculateFieldTotals(
      sharedItems,
      data,
      yearIndex,
    );

    const unknownPercent = fieldPercentSum < 1 ? 1 - fieldPercentSum : 0;
    const unKnownMultiplier = data.year_data[unKnownKey][yearIndex].value;
    const unknownTotal = unKnownMultiplier * unknownPercent;
    const finalTotal = Math.round(fieldTotal + unknownTotal);

    const currentYear = data.years[yearIndex];
    const percentFormatted = getColumnHeading(fieldPercentSum, currentYear);
    const unKnownPercentFormatted = `(${(unknownPercent * 100).toLocaleString('de-DE')}% Unbekannt)`;

    resultTotals.totals.push(finalTotal);
    resultTotals.percents.push({
      sum: fieldPercentSum,
      formatted: percentFormatted,
      unKnownFormatted: unKnownPercentFormatted,
    });

    if (fieldPercentSum > 1) sharedValuesValid.value = false;
    if (showShares.value) {
      data.year_data[sharedItemsKey][yearIndex].value = finalTotal;
    }
  }

  aggregatedSharedValues.value = resultTotals;
}

function getColumnHeading(sum, year) {
  const sumFormatted = (sum * 100).toLocaleString('de-DE');
  return sum === 0 ? year : `${year}: ${[sumFormatted]} %`;
}

function calculateFieldTotals(sharedItems, data, yearIndex) {
  let fieldTotal = 0;
  let fieldPercentSum = 0;

  sharedItems.forEach((sharedFactor) => {
    const factorData = sharedFactor[yearIndex];
    const percentage = factorData.value;
    // Extract the corresponding field name by removing 'Anteil'
    const totalFieldName = factorData.label.replace('Anteil ', '');

    if (percentage) {
      const totalValue = data.year_data[totalFieldName][yearIndex].value;
      fieldTotal += percentage * totalValue;
      fieldPercentSum += percentage;
    }
  });

  return { fieldTotal, fieldPercentSum };
}

const emissionFactorsString = computed(() =>
  JSON.stringify(props.data['Emissionsfaktoren']),
);

// We could also watch props.data['Emissionsfaktoren'] Then we would need
// to apply {deep: true}. That would trigger more executions, then needed.
watch(
  emissionFactorsString,
  () => {
    if (props.tab === 'Allgemein' && emissionFactorsString.value) {
      aggregateSharedValues();
    }
  },
  {
    immediate: true,
  },
);

watch(
  [sharedValuesValid, showShares],
  () => {
    if (!showShares.value) emit('sharedValuesValid', true);
    else emit('sharedValuesValid', sharedValuesValid.value);
  },
  {
    immediate: true,
  },
);

watch(
  () => props.tab,
  () => {
    activePanel.value = 0;
  },
);
</script>

<style lang="scss">
// expansion Panel custom style
.scenario-frame-expansion-panel {
  .v-expansion-panel__shadow {
    @apply shadow-none;
  }

  .v-expansion-panel::after {
    @apply border-none;
  }

  .v-expansion-panel-title {
    width: 100% !important;
    padding: 13px 17px 13px 10px;
    border-radius: 4px;
    @apply bg-blue-grey-50;
    h5 {
      @apply text-blue-grey-500;
    }

    &.v-expansion-panel-title--active {
      border-radius: 4px 4px 0 0;
      min-height: 40px !important;
    }
  }

  .v-expansion-panel-title__overlay {
    opacity: 0;
  }

  .v-expansion-panel-text {
    border-radius: 0 0 4px 4px;
    @apply bg-blue-grey-25;
    .v-expansion-panel-text__wrapper {
      padding: 20px 10px;
    }
  }

  .dashed-arrow-custom {
    position: relative;

    &:before {
      position: absolute;
      right: -16px;
      top: 50%;
      transform: translateY(-50%) rotate(270deg);
      display: flex;
      align-items: center;
      justify-content: center;
      content: '';
      background-image: url('@/assets/svg/arrow-right-grey.svg');
      width: 24px;
      height: 24px;
    }
  }
}
</style>
