<template>
  <div class="flex h-screen flex-col bg-canvas">
    <BreadcrumbNavigation class="single-page-wrapper breadcrumb-container" />
    <div v-if="isAnimated" class="simple-bar-container">
      <div class="simple-bar standard-elevation-1 rounded-[8px] bg-default" />
    </div>
    <div
      v-show="!isAnimated"
      ref="singlePageScrollWrapper"
      style="max-height: calc(100vh - 85px)"
      class="single-page-scroll-wrapper hide-scrollbar overflow-y-auto"
      @scroll="handleScroll"
    >
      <div
        class="single-page-wrapper content-container standard-elevation-1 relative flex flex-col items-start gap-5 rounded-[8px] bg-default p-5"
        style="min-height: calc(100vh - 141px)"
      >
        <slot />
      </div>
      <FooterSection />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import BreadcrumbNavigation from '@/components/navigation/BreadcrumbNavigation.vue';
import FooterSection from '@/components/footer/FooterSection.vue';

const isAnimated = ref(true);
const singlePageScrollWrapper = ref(null);

const store = useStore();

onMounted(() => {
  animateContainer();
});

function handleScroll() {
  if (singlePageScrollWrapper.value) {
    const lastScrollPosition = singlePageScrollWrapper.value.scrollTop;
    store.commit('dashboard/SET_LAST_SCROLL_POSITION', lastScrollPosition);
  }
}

function animateContainer() {
  setTimeout(() => {
    const simpleBar = document.querySelector('.simple-bar');
    simpleBar.classList.add('simple-box');
    showContent();
  }, 5);
}

function showContent() {
  setTimeout(() => {
    isAnimated.value = false;
  }, 400);
}
</script>

<style scoped lang="scss">
.single-page-wrapper {
  &.content-container {
    @media (max-width: 1440px) {
      @apply mx-5;
    }
  }

  @media (min-width: 1441px) {
    max-width: 1360px;
    @apply mx-auto w-full;
    &.breadcrumb-container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.simple-bar-container {
  @media (max-width: 1440px) {
    @apply mx-5;
  }

  @media (min-width: 1441px) {
    max-width: 1360px;
    @apply mx-auto w-full;
  }
}

.simple-bar {
  height: 57px;
  transition: height ease 0.4s;
}

.simple-box {
  height: calc(100vh - 135px);
}

:deep(.v-application) {
  @apply bg-canvas;
}
</style>
