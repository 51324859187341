<template>
  <template v-if="showForSpecificDashboard">
    <div class="flex flex-col gap-2.5 py-2">
      <h4 class="text-blue-grey-500">Zeitpunkt bestimmen</h4>
      <div class="h-[45px]">
        <YearSlider
          class="px-0"
          :years="years"
          :class="{
            'pointer-events-none':
              !getEnergyData && dashboardSelected === 'energy-balance',
          }"
        />
      </div>
    </div>
    <Teleport v-if="!showSlider" to="#app">
      <div
        class="absolute left-0 top-[80px] z-[10000] flex h-[65px] w-full border-t border-blue-grey-100 bg-infra-background shadow-xl"
      >
        <div class="teleport-slider-wrapper w-full px-5">
          <div class="my-2 grid grid-cols-2 items-center gap-12">
            <div class="flex w-full max-w-[400px] gap-10">
              <div class="flex flex-col">
                <span
                  class="scenario-list-scrollToView mb-1 text-[13px] font-medium text-black"
                  >Szenario:</span
                >
                <div>{{ scenarioName }}</div>
              </div>
              <div v-if="selectedDistrictName" class="flex flex-col">
                <span
                  class="scenario-list-scrollToView mb-1 text-[13px] font-medium text-black"
                  >Stadtteil:</span
                >
                <div>{{ selectedDistrictName }}</div>
              </div>
            </div>
            <div>
              <YearSlider
                :years="years"
                class="px-0"
                :class="{
                  'pointer-events-none':
                    !getEnergyData && dashboardSelected === 'energy-balance',
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </template>
</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import YearSlider from '@/features/year-slider/YearSlider.vue';

const store = useStore();
const props = defineProps({
  getEnergyData: Array,
  dashboardSelected: String,
  selectedDistrictName: String,
  scenarioName: String,
  years: Array,
});
const emit = defineEmits(['change-year']);
const showSlider = ref(true);

const showForSpecificDashboard = computed(
  () =>
    props.dashboardSelected === 'energy-balance' ||
    props.dashboardSelected === 'spatial-evaluation' ||
    props.dashboardSelected === 'district-heating-potentials',
);
const getLastScrollPosition = computed(
  () => store.getters['dashboard/getLastScrollPosition'],
);

function updateSliderVisibility() {
  const scrollLimits = {
    default: 870,
    'community-structure': 180,
    'chronological-evaluation': 180,
    'spatial-evaluation': 390,
    potentials: 180,
  };

  // Use the specific scroll limit for the current dashboard selection, or the default if not specified
  const scrollLimit =
    scrollLimits[props.dashboardSelected] || scrollLimits['default'];
  showSlider.value = getLastScrollPosition.value <= scrollLimit;
}

// Execute on scroll position change
watch(
  () => getLastScrollPosition.value,
  () => {
    updateSliderVisibility();
  },
);

// Execute on mount
onMounted(() => {
  updateSliderVisibility();
});
</script>

<style lang="scss" scoped>
.teleport-slider-wrapper {
  @media (min-width: 1441px) {
    max-width: 1360px;
    @apply mx-auto;
  }
  @media (max-width: 1440px) {
    @apply mx-5;
  }
}
</style>
