<template>
  <div class="flex flex-col gap-2">
    <h4 class="w-full">Download als:</h4>
    <RadioButtonGroup
      v-model="selectedDownloadType"
      :items-data="downloadTypes"
      items-data-key="name"
      value="name"
    />
    <ButtonEl
      v-if="!isDownloading"
      :disabled="!selectedDownloadType"
      text="Herunterladen"
      @click="
        () =>
          downloadTypes.find((x) => x.name === selectedDownloadType).onClick()
      "
    />
    <VProgressCircular
      v-else
      class="w-full text-center text-title-color1"
      size="30"
      width="4"
      indeterminate
    />
  </div>
</template>

<script setup>
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import { ref } from 'vue';
import RadioButtonGroup from '@/components/storybook/src/stories/RadioButtonGroup/RadioButtonGroup.vue';
import { axios } from '@/utils/axiosHelper';

const props = defineProps({ wireId: Number, wireName: String });
const selectedDownloadType = ref(null);
const isDownloading = ref(false);

const downloadTypes = [
  { name: 'Excel', onClick: downloadExcel },
  { name: 'PDF', onClick: downloadPdf },
];

function downloadPdf() {
  isDownloading.value = true;
  axios({
    method: 'GET',
    url: `/api/ppt-gen/wire/${props.wireId}/`,
    responseType: 'blob',
  })
    .then((res) => {
      const regExpFilename = /filename\*=utf-8''(?<filename>.*)/;
      const originalFilename = decodeURIComponent(
        regExpFilename.exec(res.headers['content-disposition'])?.groups
          ?.filename ?? 'Report.pdf',
      );
      const filename = `${props.wireName}_${originalFilename}`;
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {
      isDownloading.value = false;
    });
}

function downloadExcel() {
  isDownloading.value = true;
  axios({
    method: 'GET',
    url: `/api/heatprojects/wi-re/wi-re/${props.wireId}/download-excel/`,
    responseType: 'blob',
  })
    .then((res) => {
      const blobUrl = URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');

      const regExpFilename = /filename\*=utf-8''(?<filename>.*)/;
      const originalFilename = decodeURIComponent(
        regExpFilename.exec(res.headers['content-disposition'])?.groups
          ?.filename ?? 'Report.xlsx',
      );
      const filename = `${props.wireName}_${originalFilename}`;
      link.href = blobUrl;
      // setAttribute to 'download': Direct download vs. opening a new tab
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    })
    .catch((error) => {
      console.error('Error downloading file:', error);
    })
    .finally(() => {
      isDownloading.value = false;
    });
}
</script>
