<template>
  <div class="grid grid-cols-2 gap-5 rounded-[4px]">
    <InputEl
      v-model="scenarioFrameName.value"
      :disabled="readOnly"
      :label="'Name ' + label"
      placeholder="Bitte eingeben"
      :rules="{ required: true }"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';

const props = defineProps({
  scenarioFrameName: Object,
  readOnly: {
    type: Boolean,
    required: true,
  },
  label: {
    type: String,
  },
});

const emit = defineEmits(['updateName']);

const scenarioFrameName = computed({
  get: () => props.scenarioFrameName,
  set: (value) => {
    emit('updateName', value);
  },
});
</script>
<style lang="scss"></style>
