<template>
  <div class="flex flex-col gap-2.5">
    <div class="grid auto-cols-auto grid-flow-col">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="flex h-[46px] cursor-pointer flex-col items-center justify-between"
        :class="{
          'tab--active': activeTab === index,
        }"
        :style="{ minWidth: calculateMinWidth(tab.label) }"
        @click="setActiveTab(index)"
      >
        <div
          class="flex items-center gap-[5px] p-2"
          :class="tabFillColor(index, true)"
        >
          <IconWrapper :icon="tab.icon" type="round" />
          <Component
            :is="activeTab === index ? 'h5' : 'div'"
            :class="{ 'body-1': activeTab !== index }"
            class="whitespace-nowrap leading-5"
            :data-test="tab.testId"
          >
            {{ tab.label }}
          </Component>
        </div>

        <div
          class="bottom-line h-1.5 w-full"
          :class="[
            index === 0 ? 'rounded-l-[4px]' : '',
            index === tabs.length - 1 ? 'rounded-r-[4px]' : '',
            tabFillColor(index, false),
          ]"
        ></div>
      </div>
    </div>
    <div class="py-5">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';

const props = defineProps({
  tabs: {
    type: Array,
    default: () => [],
  },
  validTabs: {
    type: Array,
    default: () => [],
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:activeTab']);
const activeTab = ref(0);
const tabsHasBeenClicked = ref([0]);

watch(activeTab, (newValue) => {
  emit('update:activeTab', newValue);
});

function setActiveTab(index) {
  activeTab.value = index;

  if (!tabsHasBeenClicked.value.includes(index)) {
    tabsHasBeenClicked.value.push(index);
  }
}

function tabFillColor(index, isText) {
  // Define color sets for text and line separately
  const colorSets = {
    text: {
      active: 'text-button-primary-default-color1', // Color for active tab text
      valid: 'text-spot-success', // Color for clicked and valid tab text
      invalid: 'text-spot-error', // Color for clicked and invalid tab text
      default: 'text-title-neutral', // Default color for unclicked tab text
    },
    line: {
      active: 'bg-button-primary-default-color1', // Color for active tab line
      valid: 'bg-spot-success', // Color for clicked and valid tab line
      invalid: 'bg-spot-error', // Color for clicked and invalid tab line
      default: 'bg-title-neutral', // Default color for unclicked tab line
    },
  };

  // If readOnly is true, return the default color
  if (props.readOnly) {
    return isText ? colorSets.text.default : colorSets.line.default;
  }

  // Select appropriate color set based on `isText`
  const selectedColorSet = isText ? colorSets.text : colorSets.line;

  // Determine and return the color
  if (activeTab.value === index) {
    return selectedColorSet.active; // Active color
  } else if (tabsHasBeenClicked.value.includes(index)) {
    return props.validTabs.includes(index)
      ? selectedColorSet.valid
      : selectedColorSet.invalid; // Valid vs. Invalid color for clicked tabs
  } else {
    return selectedColorSet.default; // Default color for unclicked tabs
  }
}

function calculateMinWidth(label) {
  const baseWidth = 55; // Minimum width to account for padding and icons
  const characterWidth = 8; // Average width per character, adjust based on your font and design

  // Calculate width based on the label length and add base width
  return `${baseWidth + label.length * characterWidth}px`;
}
</script>
