<template>
  <div class="by-map-container rounded-xl bg-subtle px-10 pb-20">
    <div id="block-title" class="section-title col-span-2">
      <h4 class="text-title-color2">Energieträger je Baublock</h4>
    </div>
    <DropDown
      v-model="selectedMapDistrict"
      class="w-60"
      label="Stadtteil"
      :items-data="districts.filter(filterDistricts)"
      items-data-key="name"
      value="id"
      @update:model-value="changeDistrict"
    />
    <div id="map-parent" class="relative col-span-2 overflow-hidden">
      <div
        id="map-energy-carrier-by-building-block"
        class="h-full w-full"
        @wheel.stop
      ></div>

      <div
        id="labels-container"
        style="
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
        "
      ></div>
    </div>
    <VuetifyDataTable
      id="block-table"
      :headers="byBlockTableheaders"
      :item-data="filteredTableData"
      :total-items="totalBlockItems"
      :show-expand="false"
      class="standard-elevation-0-dark col-span-2 overflow-hidden rounded-lg"
      :highlight-row="{
        key: 'building_block_id',
        value: blockMap.byFeatureId?.[latestFeatureId],
        color: '#60bdff',
      }"
      :open-page-options-to-top="true"
      :page-from-parent="tablePage"
      @clicked-row-data="updateHighlighting($event.item.building_block_id)"
      @update:options="getBlockData"
    >
      <template #building_block_id="{ item }">
        <div class="flex h-full items-start justify-end pt-4">
          {{ item.building_block_id }}
        </div>
      </template>
      <template #postcode="{ item }">
        <div class="flex h-full items-start pt-4">
          {{ item.postcode || '-' }}
        </div>
      </template>
      <template #demand_by_heating_carrier="{ item }">
        <DashboardEnergyBalanceByBlockTableChart
          :table-data="filteredTableData"
          :table-entry="item"
          data-key="demand_by_heating_carrier"
          :multiplier="0.001"
        />
      </template>
      <template #buildings_by_heating_technology="{ item }">
        <DashboardEnergyBalanceByBlockTableChart
          :table-data="filteredTableData"
          :table-entry="item"
          data-key="buildings_by_heating_technology"
        />
      </template>
    </VuetifyDataTable>
  </div>
</template>
<script setup>
import { nextTick, reactive, ref, watch } from 'vue';
import initMap, {
  highlightBlockById,
} from '@/apps/analysis-dashboard/init-map';
import { axios } from '@/utils/axiosHelper';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import VuetifyDataTable from '@/components/storybook/src/stories/vuetifyDataTable/VuetifyDataTable.vue';
import DashboardEnergyBalanceByBlockTableChart from '@/apps/analysis-dashboard/components/DashboardEnergyBalance/DashboardEnergyBalanceByBlockTableChart.vue';
import { BlockMap } from '@/apps/analysis-dashboard/classes/BlockMap';
import { byBlockTableheaders } from '@/apps/analysis-dashboard/data';

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  scenarioId: Number,
  districts: Array,
  selectedMunicipality: String,
  selectedDistrict: Number,
  year: Number,
});

const selectedMapDistrict = ref(null);
const mapInstance = ref(null);
const latestFeatureId = ref('');
const centroid = ref(null);

async function getCentroid(districtKey) {
  return axios({
    method: 'GET',
    url: '/api/buildingmodel/district/centroid/',
    params: { district_key: districtKey },
  }).then((resp) => {
    centroid.value = resp.data;
  });
}

function setLatestFeatureId(id) {
  latestFeatureId.value = `${id}`;
  changePageIfNecessary();
  if (id) {
    scrollToHighlightedRow();
  }
}

function scrollToHighlightedRow(attempt = 1, maxAttempts = 4) {
  const blockId = String(blockMap.byFeatureId[latestFeatureId.value]);
  const matchingTds = Array.from(
    document.querySelectorAll('#block-table tr > td:first-child'),
  ).filter((td) => td.textContent.includes(blockId));

  if (matchingTds.length > 0) {
    matchingTds[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
  } else {
    setTimeout(() => scrollToHighlightedRow(attempt + 1, maxAttempts), 1000);
  }
}

async function changeDistrict(district) {
  if (!district) {
    return;
  }

  try {
    await getCentroid(district);
    if (centroid.value) {
      flyToDistrict(centroid.value);
    }
  } catch (error) {
    console.error('Error getting district centroid:', error);
  }
}

function flyToDistrict(coordinates) {
  if (!coordinates || !coordinates.lng || !coordinates.lat) {
    return;
  }

  if (mapInstance.value) {
    mapInstance.value.flyTo({
      center: coordinates,
      zoom: 15,
    });
  }
}

function filterDistricts(item) {
  if (props.selectedDistrict === null) {
    return true;
  } else {
    return item.id === props.selectedDistrict;
  }
}

function determineDistrict(districts, selectedDistrict) {
  if (!districts || !districts.length) return null;
  return selectedDistrict === null ? districts[0].id : selectedDistrict;
}

function buildMapFilters(selectedDistrict, selectedMunicipality) {
  const filters = ['all'];

  if (selectedMunicipality !== 'all') {
    filters.push(['==', ['get', 'municipality_id'], selectedMunicipality]);
  }

  if (selectedDistrict !== null) {
    filters.push(['==', ['get', 'district_id'], selectedDistrict]);
  }

  return filters;
}

// Change position and filters for map depending on selected district and municipality
async function waitForDimensions(mapContainer) {
  if (mapContainer.clientWidth === 0 || mapContainer.clientHeight === 0) {
    await new Promise((resolve) => requestAnimationFrame(resolve));
    return waitForDimensions(mapContainer);
  }
}

watch(
  [
    () => props.districts,
    () => props.selectedDistrict,
    () => props.selectedMunicipality,
  ],
  async ([districts, newDistrict, newMunicipality]) => {
    // Add safety check to prevent errors when districts is not ready
    if (!districts || !districts.length) {
      return;
    }

    try {
      const filters = buildMapFilters(newDistrict, newMunicipality);
      const districtToUse = determineDistrict(districts, newDistrict);

      if (districtToUse) {
        selectedMapDistrict.value = districtToUse;
        await getCentroid(districtToUse);
      } else {
        return;
      }

      const mapContainer = document.getElementById(
        'map-energy-carrier-by-building-block',
      );
      if (!mapContainer) {
        return;
      }

      await waitForDimensions(mapContainer);

      if (mapInstance.value) {
        await mapInstance.value.remove();
      }

      mapInstance.value = initMap(
        getBlocksByMapView,
        setLatestFeatureId,
        props.scenarioId,
        filters,
      );

      if (centroid.value) {
        flyToDistrict(centroid.value);
      }
    } catch (error) {
      console.error('Error initializing map:', error);
    }
  },
  { immediate: true, deep: true },
);

// -------------- Table logic --------------------

const totalBlockItems = ref(null);
const tablePage = ref(1);
const itemsPerPage = ref(10);
const tableData = ref([]);
const filteredTableData = ref([]);
let blockMap = reactive({});

function getBlocksByMapView(blocks) {
  // Add safety check for block data
  if (!blocks || blocks.length === 0) {
    console.log('No block data available');
    tableData.value = [];
    filteredTableData.value = [];
    return;
  }

  try {
    blockMap = new BlockMap(blocks);
    getBlockData();
  } catch (error) {
    console.error('Error processing block data:', error);
    tableData.value = [];
    filteredTableData.value = [];
  }
}

function getBlockData(options = {}) {
  if (options.page) tablePage.value = options.page;
  if (options.itemsPerPage) itemsPerPage.value = options.itemsPerPage;

  // Check if we have block IDs to request
  if (!blockMap.requestIds || blockMap.requestIds.length === 0) {
    console.log('No block IDs available for data request');
    return Promise.resolve();
  }

  return axios({
    url: `/api/dashboard_buildingblock/scenarios/${props.scenarioId}
          ?building_block_ids=${blockMap.requestIds.join()}&page=${tablePage.value}
          &per_page=${itemsPerPage.value}`,
    method: 'GET',
  })
    .then((resp) => {
      totalBlockItems.value = resp.data.pagination.total_items;
      tableData.value = resp.data.yearlyresult;
      filteredTableData.value = resp.data.yearlyresult.filter(
        (item) => item.year === props.year,
      );

      changePageIfNecessary();
    })
    .catch((error) => {
      console.error('Error fetching block data:', error);
      tableData.value = [];
      filteredTableData.value = [];
    });
}

function changePageIfNecessary() {
  if (!latestFeatureId.value) return;

  const latestBlockId = blockMap.byFeatureId[latestFeatureId.value];
  const blockFound = filteredTableData.value.some(
    (item) => item.building_block_id === Number(latestBlockId),
  );

  if (blockFound) return;

  const blockIndex = blockMap.requestIds.findIndex(
    (item) => item === String(latestBlockId),
  );

  if (blockIndex === -1) return;

  tablePage.value = Math.floor(blockIndex / itemsPerPage.value) + 1;
}

function updateHighlighting(id) {
  const featureId = blockMap.byBlockId[id];
  setLatestFeatureId(featureId);
  highlightBlockById(mapInstance.value, featureId);
  nextTick(() => {
    document
      .querySelector('#block-title')
      .scrollIntoView({ behavior: 'smooth' });
  });
}

watch(
  () => props.year,
  (newYear) => {
    filteredTableData.value = tableData.value.filter(
      (item) => item.year === newYear,
    );
  },
);
</script>

<style lang="scss" scoped>
.by-map-container {
  display: grid;
  grid-template: 100px 60px 800px / 1fr 1fr;
  gap: 20px 40px;
}
:deep(.feature-label) {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #333;
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 12px;
  transform: translate(-50%, -50%); /* Centers the label over the point */
  white-space: nowrap;
}
</style>
