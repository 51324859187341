<template>
  <v-dialog width="auto" v-model="showDialog">
    <v-card
      max-width="400"
      prepend-icon="mdi-update"
      title="2FA aktivieren"
      class="d-flex align-center justify-center"
    >
      Scannen Sie bitte folgenden Code mit Ihrer Authentifizierungs-App der
      Wahl:
      <div v-if="twoFaUrl !== ''">
        <qrcode-vue :value="twoFaUrl" :size="200" level="H" render-as="svg" />
      </div>
      <template v-slot:actions>
        <v-btn class="ms-auto" text="OK" @click="showDialog = false"></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup>
import axios from 'axios';
import cookie from 'vue-cookies';
import { ref, watch } from 'vue';
import QrcodeVue from 'qrcode.vue';

const showDialog = defineModel();
const twoFaData = ref();
const twoFaUrl = ref('');

watch(showDialog, async (newShowDialog) => {
  if (newShowDialog === true) {
    twoFaData.value = (
      await axios({
        method: 'POST',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
        url: '/api/totp-device/',
      })
    ).data;
    twoFaUrl.value = twoFaData.value.url;
  }
});
</script>

<style scoped></style>
