<template>
  <div
    class="standard-elevation-1 flex w-max items-center rounded-[8px] bg-white p-2"
  >
    <input
      v-model="location"
      type="text"
      placeholder="Suchbegriff"
      class="w-full bg-infra-$gray-highlight focus:outline-none"
      @input="
        searchLocation();
        searchState(true);
      "
    />
    <div
      v-if="!location.length"
      class="pointer-events-none flex h-6 w-6 items-center justify-center"
    >
      <ZoomIcon />
    </div>
    <div
      v-else
      class="flex h-6 w-6 cursor-pointer items-center justify-center"
      @click="closeSearchbar(false)"
    >
      <VIcon>mdi-close</VIcon>
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters } from 'vuex';
import { removeMarker } from '@/mapbox/main';
import ZoomIcon from '@/components/storybook/src/assets/icons/ZoomIcon.vue';

export default {
  name: 'SearchBar',
  components: { ZoomIcon },
  data() {
    return {
      location: '',
    };
  },
  computed: {
    ...mapGetters({
      showSearch: 'search/showSearch',
    }),
  },
  watch: {
    showSearch(val) {
      if (!val) {
        this.location = '';
      }
    },
  },
  methods: {
    searchLocation: debounce(function () {
      this.$store.dispatch('search/FETCH_PLACES', this.location);
    }, 500),
    searchState(payload) {
      this.$store.dispatch('search/DISPLAY_SEARCHBAR', payload);
    },
    closeSearchbar(payload) {
      this.$store.dispatch('search/DISPLAY_SEARCHBAR', payload);
      this.$store.commit('search/RESET_PLACES', null);
      removeMarker();
      this.location = '';
    },
  },
};
</script>
