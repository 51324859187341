<template>
  <div v-if="loading" class="my-10 flex justify-center">
    <VProgressCircular
      class="text-title-color1"
      size="30"
      width="4"
      indeterminate
    />
  </div>
  <div v-else-if="hasCalculationError" class="flex justify-center">
    Es gab ein Problem bei der Berechnung
  </div>
  <div v-else class="flex flex-col gap-3 px-1 py-5">
    <div
      class="standard-elevation-1 flex items-center justify-between gap-1.5 rounded-[6px] bg-default p-3.5 text-title-color2"
    >
      <div class="flex gap-[5px]">
        <HeatWaveIcon />
        <div class="body-1 text-title-color2">Gesamtpotential</div>
      </div>
      <h5>{{ projectData.total_heat_load_kw.toLocaleString('de-DE') }} kW</h5>
    </div>

    <div class="flex flex-col gap-2.5">
      <h5 class="pb-2.5 pt-5 text-title-color2">
        Nettobarwert, Sensitivität Trassenpreise bzw. Absatzmengen
      </h5>

      <!--table-->
      <WiReTableSensitivity :project-data="projectData" />

      <!--cards-->
      <div class="grid grid-cols-4 gap-2.5">
        <div
          v-for="(card, index) in cards"
          :key="index"
          class="standard-elevation-1 flex flex-col items-center justify-between gap-6 rounded-[3px] p-6"
          :class="card.background"
        >
          <div class="flex flex-col items-center justify-between gap-2.5">
            <IconWrapper
              :icon="card.icon"
              :size="32"
              fill="text-title-color2"
              type="filled"
            />
            <div class="body-1 text-color2">
              {{ card.title }}
            </div>
          </div>
          <h4 class="text-color2">
            {{
              card.value
                ? card.value + (card.suffix ? ' ' + card.suffix : '')
                : '--'
            }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import HeatWaveIcon from '@/components/storybook/src/assets/icons/HeatWaveIcon.vue';
import WiReTableSensitivity from '@/apps/economic-efficiency/WiReTableSensitivity.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';

const props = defineProps({
  projectData: {
    type: Object,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:calculateDisabled']);

onMounted(() => {
  emit('update:calculateDisabled', true);
});

const hasCalculationError = computed(() => props.projectData.progress === 1);

const cards = [
  {
    title: 'Amortisationsdauer',
    icon: 'timeline',
    value: props.projectData.amortization_period,
    suffix: 'Jahre',
    background:
      props.projectData.amortization_period < 20 &&
      props.projectData.amortization_period
        ? 'bg-button-secondary-hover-success'
        : 'bg-button-secondary-hover-error',
  },
  {
    title: 'Amortisation im Jahr',
    icon: 'area_chart',
    value: props.projectData.year_of_breakeven,
    background: 'bg-default',
  },
  {
    title: 'IRR',
    icon: 'waterfall_chart',
    value: props.projectData.irr
      ? (props.projectData.irr * 100).toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : null,
    suffix: '%',
    background: 'bg-default',
  },
  {
    title: 'Value Spread',
    icon: 'difference',
    value: props.projectData.value_spread
      ? (props.projectData.value_spread * 100).toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : null,
    suffix: '%',
    background: 'bg-default',
  },
];
</script>
