<template>
  <div class="m-2 w-full bg-white p-2.5">
    <p class="subtitle-3 mb-2 text-color2">Filter nach {{ radioItem.title }}</p>
    <FormValidator v-model="isFormValid" class="relative mb-4">
      <div>
        <div class="flex">
          <InputEl
            v-model="minValue"
            input-type="integer"
            :rules="{ required: true, min: 0 }"
            label="von"
            size="m"
            placeholder="Bitte Untergrenze angeben"
            class="mb-2 w-full"
            :suffix="radioItem.unit"
          />
          <div class="w-8" />
        </div>
        <div class="relative flex w-full items-end gap-2 pr-1">
          <InputEl
            v-model="maxValue"
            :rules="maxValue !== Infinity ? { required: true, min: 0 } : {}"
            input-type="integer"
            label="bis"
            size="m"
            class="w-full"
            :placeholder="
              maxActive ? 'Bitte Obergrenze angeben' : 'Keine Obergrenze'
            "
            :disabled="!maxActive"
            :suffix="radioItem.unit"
          />
          <CheckboxEl
            class="relative bottom-2"
            :model-value="maxActive"
            @click="toggleMaxActive"
          />
        </div>
        <p
          v-if="showComparisonError"
          class="absolute bottom-[-24px] text-xxs text-spot-error"
        >
          "von"-Wert muss kleiner als "bis"-Wert sein
        </p>
      </div>
    </FormValidator>
    <div class="flex gap-2.5">
      <ButtonEl
        text="Zurücksetzen"
        size="m"
        variant="tertiary"
        class="w-full"
        @click="removeExtraFilter(radioItem)"
      />
      <ButtonEl
        text="Anwenden"
        size="m"
        variant="secondary"
        class="w-full"
        :disabled="isApplyFilterEnabled"
        @click="applyExtraFilter(radioItem)"
      />
    </div>
  </div>
</template>
<script setup>
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { isNullOrUndefined } from '@/utils/generalUtils';
import { getCustomFilterPayload } from '@/features/map/helpers';
import CheckboxEl from '@/components/storybook/src/stories/CheckboxEl/CheckboxEl.vue';

const props = defineProps({
  radioItem: {
    type: Object,
    default: () => ({}),
  },
  yearDependantFilterData: {
    type: Object,
    default: () => ({}),
  },
});

const store = useStore();

const minValue = ref(0);
const maxValue = ref(Infinity);
const maxActive = ref(false);
const isFormValid = ref(false);

const isApplyFilterEnabled = computed(
  () =>
    (maxActive.value && maxValue.value < minValue.value) || !isFormValid.value,
);
const showComparisonError = computed(
  () =>
    maxActive.value &&
    !isNullOrUndefined(minValue.value) &&
    !isNullOrUndefined(maxValue.value) &&
    maxValue.value < minValue.value,
);
const year = computed(() => store.state.map.scenarioYear);

onMounted(() => {
  const { extraFilter, onLayers } = props.radioItem;
  const layerFilters = props.yearDependantFilterData[onLayers];

  if (layerFilters?.[extraFilter]) {
    minValue.value = layerFilters[extraFilter].minValue;
    maxValue.value = layerFilters[extraFilter].maxValue;
  }
  if (layerFilters?.[extraFilter]?.maxValue === Infinity) {
    maxActive.value = false;
  }
});

function applyExtraFilter(item) {
  const filterData = {
    minValue: minValue.value,
    maxValue: maxValue.value,
    feature: props.radioItem.extraFilter,
    onLayers: item.onLayers,
  };
  const payload = getCustomFilterPayload(year.value, filterData);

  store.commit('map/ADD_FILTER', payload);
  store.commit('map/ADD_YEAR_DEPENDANT_FILTER_DATA', {
    layerKeys: item.onLayers,
    data: { ...filterData },
  });
}

function removeExtraFilter(item) {
  minValue.value = 0;
  maxValue.value = maxActive.value ? 0 : Infinity;

  store.commit('map/REMOVE_FILTER', {
    layerKeys: item.onLayers,
    filterId: `${props.radioItem.extraFilter}_filter`,
  });
  store.commit('map/REMOVE_YEAR_DEPENDANT_FILTER_DATA', {
    layerKeys: item.onLayers,
    filterId: `${props.radioItem.extraFilter}`,
  });
}

function toggleMaxActive() {
  maxActive.value = !maxActive.value;
  if (maxActive.value) {
    setMaxValueAfterEnablement();
  } else {
    maxValue.value = Infinity;
  }
}

function setMaxValueAfterEnablement() {
  const { extraFilter, onLayers } = props.radioItem;
  const layerFilters = props.yearDependantFilterData[onLayers];
  if (layerFilters?.[extraFilter]) {
    maxValue.value = layerFilters[extraFilter].maxValue;
  } else {
    maxValue.value = 0;
  }
}
</script>
