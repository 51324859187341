<template>
  <div class="absolute left-5 z-[100] flex h-10 gap-5">
    <div class="flex w-[360px] gap-[5px]">
      <div class="standard-elevation-1 flex rounded-[8px]">
        <ToHome :class="{ 'rounded-r-none': !showMuScSelector }" />
        <transition name="fade-horizontal" mode="out-in">
          <ButtonEl
            v-if="!showMuScSelector"
            icon="pin_drop"
            variant="tertiary"
            class="rounded-l-none"
            icon-type="round"
            @click="showMuScSelector = true"
          />
        </transition>
      </div>
      <div class="standard-elevation-1 flex rounded-[8px]">
        <ButtonEl
          data-test="main-menu-toggle"
          icon="segment"
          :variant="toggleVariant('mainMenu')"
          icon-type="round"
          :class="{ 'rounded-r-none': isInfraModuleActive('projectPlaner') }"
          @click="toggleMenu('main')"
        />
        <ButtonEl
          v-if="isInfraModuleActive('projectPlaner')"
          icon="account_tree"
          :variant="toggleVariant('projectPlaner')"
          class="rounded-l-none border-l border-l-line-disabled-neutral"
          data-test="project-areas"
          :disabled="isHeatProjectEdit"
          @click="toggleMenu('project')"
        />
      </div>

      <SearchBar />

      <MapActionsDialog />
    </div>
    <MapActions data-test="map-action" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import { isInfraModuleActive } from '@/utils/is-infra-module-active';
import ToHome from '@/features/map/control-options/components/ToHome.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import SearchBar from '@/features/map/control-options/search/SearchBar.vue';
import MapActionsDialog from '@/features/map/control-options/components/MapActionsDialog.vue';
import MapActions from '@/features/map/map-actions/MapActions.vue';
import { useMapStore } from '@/features/map/store/map-store';

const { showMainMenu, showProjectMenu, showMuScSelector } =
  toRefs(useMapStore());

const store = useStore();

const isHeatProjectEdit = computed(() => store.getters.getHeatProjectEditing);

function toggleMenu(menuType) {
  const targetMenu = menuType === 'main' ? showMainMenu : showProjectMenu;
  const otherMenu = menuType === 'main' ? showProjectMenu : showMainMenu;

  otherMenu.value = false;
  targetMenu.value = !targetMenu.value;
}

function toggleVariant(type) {
  if (type === 'mainMenu') {
    return showProjectMenu.value
      ? 'tertiary'
      : showMainMenu.value
        ? 'primary'
        : 'tertiary';
  } else if (type === 'projectPlaner') {
    return showProjectMenu.value ? 'primary' : 'tertiary';
  }
}
</script>

<style scoped lang="scss">
.fade-horizontal-enter-active,
.fade-horizontal-leave-active {
  transition: all 0.5s ease;
  overflow: hidden;
}

.fade-horizontal-enter-from,
.fade-horizontal-leave-to {
  width: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.fade-horizontal-enter-to,
.fade-horizontal-leave-from {
  width: 40px;
  padding-left: initial !important;
  padding-right: initial !important;
}
</style>
