<template>
  <v-chart ref="chartRef" :option="option" class="h-96" autoresize />
</template>

<script setup>
import { ref, computed, onMounted, watchEffect } from 'vue';
import { formatChartValue } from '@/apps/analysis-dashboard/utils';

const props = defineProps({
  chartData: Object,
  chartTitle: String,
  downloadTitle: String,
  multiply: Number,
  customMinHeight: Boolean,
  showTotal: {
    type: Boolean,
    default: true,
  },
  xAxisKeyName: {
    type: String,
    default: 'year',
  },
  barWidth: {
    type: Number,
    default: 50,
  },
  showLegend: {
    type: Boolean,
    default: true,
  },
  ySplitLine: {
    type: Boolean,
    default: false,
  },
  gridBottom: {
    type: String,
    default: '15%',
  },
  gridTop: {
    type: String,
    default: '20%',
  },
  gridLeft: {
    type: String,
    default: '10%',
  },
  gridRight: {
    type: String,
    default: '10%',
  },
  yAxisBounds: {
    type: Object,
    default: () => ({ min: null, max: null }),
  },
  decimalPlaces: {
    type: Number,
    default: 0,
  },
});

const chartRef = ref(null);

const emits = defineEmits(['chartRef']);

onMounted(() => emits('chartRef', chartRef.value));

const option = computed(() => {
  const rawData = props.chartData;
  const xAxisData = rawData.map((d) => d[props.xAxisKeyName]);
  const seriesData = rawData.reduce((acc, d) => {
    d.data.forEach((ht) => {
      const existingSeries = acc.find((s) => s.name === ht.name);
      if (existingSeries) {
        existingSeries.data.push(
          props.multiply ? ht.value * props.multiply : ht.value,
        );
      } else {
        acc.push({
          name: ht.name,
          type: 'bar',
          stack: 'total',
          data: [props.multiply ? ht.value * props.multiply : ht.value],
          itemStyle: {
            color: ht.color,
          },
          barWidth: props.barWidth,
        });
      }
    });
    return acc;
  }, []);

  return {
    title: {
      text: props.chartTitle,
      textStyle: {
        color: '#54608E',
        textAlign: 'center',
        lineHeight: 20,
        fontFamily: 'Inter, sans-serif',
        fontWeight: 500,
        fontSize: 16,
      },
      left: 'center',
      top: '25px',
    },
    grid: {
      bottom: props.gridBottom,
      top: props.gridTop,
      left: props.gridLeft,
      right: props.gridRight,
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params) => {
        let result = '';
        let total = 0;
        let nonZeroCount = 0; // Counter for non-zero items

        const under1DecimalPlaces = props.decimalPlaces + 1;

        params.forEach((item) => {
          if (item.value !== 0) {
            result += `${item.marker} ${item.seriesName}:
                ${formatChartValue(item.value, under1DecimalPlaces, props.decimalPlaces)}<br/>`;
            total += item.value;
            nonZeroCount++; // Increment only for non-zero values
          }
        });

        // Check if there are more than one non-zero items and showTotal is true
        if (props.showTotal && nonZeroCount > 1 && total > 0) {
          result += `<b>Total:
               ${formatChartValue(total, under1DecimalPlaces, props.decimalPlaces)}</b>`;
        }

        return result;
      },
    },
    legend: {
      data: seriesData
        .filter((s) => s.data.some((d) => d > 0))
        .map((s) => s.name),
      bottom: 0,
      itemWidth: 12,
      itemHeight: 12,
      show: props.showLegend,
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        show: true,
        formatter: (value) => value.toLocaleString('de-DE'),
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: props.ySplitLine,
      },
      min: props.yAxisBounds.min,
      max: props.yAxisBounds.max,
    },
    series: seriesData,
  };
});
</script>
