import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { infraModules } from '@/configs/infra-modules';
import IconMapMarkRounded from '@/components/storybook/src/assets/icons/custom/map-actions/IconMapMarkRounded.vue';
import { markRaw } from 'vue';

const state = {
  logoUrl: null,
  editHeatProjectNetwork: false,
  processOptions: [
    {
      title: 'Projektgebiet anlegen',
      id: 'heatProject',
      drawButtons: ['polygon'],
      icon: 'account_tree',
      disabled: false,
      editObjectId: null,
      active: false,
      module: infraModules.projectPlaner,
    },
    {
      title: 'Standort anlegen',
      id: 'heatSource',
      icon: 'add_location_alt',
      disabled: false,
      editObjectId: null,
      active: false,
      module: infraModules.projectPlaner,
    },
    {
      title: 'Vorranggebiet zuweisen',
      id: 'AssignPriorityAreaToBlock',
      icon: markRaw(IconMapMarkRounded),
      disabled: false,
      editObjectId: null,
      active: false,
    },
    {
      title: 'Messwerkzeug',
      id: 'measure',
      icon: 'design_services',
      disabled: false,
      active: false,
    },
  ],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
