<template>
  <TaskDetails v-if="showTaskDetails" />
  <div v-else class="relative flex w-full flex-col gap-5">
    <div class="border-b-2">
      <VTabs v-model="activeTab" class="-mb-0.5" density="compact">
        <VTab
          v-for="tab in filteredTabs"
          :key="tab.routeName"
          class="h-auto px-2 text-[18px] normal-case tracking-normal hover:text-title-color1"
          :value="tab.routeName"
          :data-test="tab.dataTest"
          :ripple="false"
          @click="navigateToTab(tab.routeName)"
          >{{ tab.title }}
        </VTab>
      </VTabs>
    </div>
    <VWindow class="px-0">
      <VWindowItem
        v-for="windowItem in tabs"
        :key="windowItem.value"
        :value="windowItem.value"
        :transition="false"
        :reverse-transition="false"
      >
        <RouterView />
      </VWindowItem>
    </VWindow>
  </div>
</template>

<script setup>
import { computed, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import TaskDetails from '@/apps/task-manager/TaskDetails.vue';

const store = useStore();
const router = useRouter();
const activeTab = ref();

const showTaskDetails = computed(
  () => store.getters['building/getShowTaskDetails'],
);

const tabs = [
  {
    title: 'Task Manager',
    routeName: 'TaskManager',
    dataTest: null,
    requireSuperUser: true,
  },
  {
    title: 'Gebäudedaten aktualisieren',
    routeName: 'BuildingUpdate',
    dataTest: null,
  },
  {
    title: 'Gebäudedaten zurücksetzen',
    routeName: 'BuildingReset',
    dataTest: 'building-version-tab-button',
  },
  {
    title: 'Eigene Analyseergebnisse',
    routeName: 'CustomAnalysis',
    dataTest: 'custom-analysis-tab',
  },
];

const isSuperuser = computed(
  () => store.getters['auth/profil']?.isSuperUser ?? false,
);

const filteredTabs = computed(() =>
  tabs.filter((tab) => !tab.requireSuperUser || isSuperuser.value),
);

onUnmounted(() => {
  store.commit('building/RESET_TASK_VALUES');
});

function navigateToTab(value) {
  if (value === 'TaskManager') {
    router.push({ name: value, query: { category: 'Upload' } });
  } else {
    router.push({ name: value });
  }
}

// functionality so that back button works smoothly on DataSpace
watch(
  router.currentRoute,
  (currentRoute) => {
    const currentTab = filteredTabs.value.find(
      (tab) => tab.routeName === currentRoute.name,
    );

    activeTab.value = currentTab.routeName;
  },
  {
    immediate: true,
  },
);
</script>

<style scoped lang="scss">
.v-btn {
  @apply text-title-neutral;
}

.v-tab--selected {
  @apply text-title-color1;
}

.v-window {
  overflow: visible;
}
</style>
