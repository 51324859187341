<template>
  <div class="mt-7 flex w-full flex-col gap-5">
    <div class="flex items-center justify-between gap-2 text-title-color2">
      <h3 v-if="!isReleased">
        {{ `Materialset "${editItem.name}" bearbeiten` }}
      </h3>
      <h3 v-else>
        {{ `Materialset "${editItem.name}"` }}
      </h3>
      <IconWrapper
        icon="close"
        class="cursor-pointer"
        fill="text-title-color2"
        @click="emit('abort')"
      />
    </div>

    <template v-if="!isReleased">
      <TextBox class="text-neutral" size="m">
        Dieses Materialset ist derzeit nicht freigegeben und kann daher nicht in
        der Projektplanung verwendet werden. Sobald Sie die Bearbeitung
        abgeschlossen haben, klicken Sie bitte oben in der tabellarischen
        Übersicht auf "Freigeben".
      </TextBox>

      <div class="flex items-end gap-2.5">
        <FormValidator v-model="nameIsValid">
          <InputEl
            v-model="name"
            label="Name Materialset"
            placeholder="Bitte Eingeben"
            class="w-[275px]"
            :disabled="isReleased"
            :rules="{
              required: true,
              unique: [materialNames.filter((e) => e !== editItem.name)],
            }"
          />
        </FormValidator>
        <ButtonEl
          text="Speichern"
          variant="secondary"
          color="success"
          :disabled="!nameIsValid || newMaterialName === editItem.name"
          @click="updateMaterial()"
        />
      </div>
    </template>

    <div
      v-if="editItem"
      class="standard-elevation-1 new-material-set-custom overflow-hidden rounded-[4px]"
    >
      <VuetifyDataTable
        :headers="tableHeadersNewMaterialset"
        :item-data="tableData.data.sort((a, b) => a.id - b.id)"
        :show-pagination="false"
        :expand-on-row-click="true"
        :set-expanded="[expandedPipe]"
        expanded-background="bg-subtle"
        @expanded="setExpandedPipe"
      >
        <template #inner_diameter="{ item }">
          <InputEl
            v-if="expandedPipe === item.id"
            v-model="
              tableData.data.find((e) => e.id === item.id).inner_diameter
            "
            size="m"
            :input-type="props.pipeOptions.inner_diameter.type"
            :suffix="props.pipeOptions.inner_diameter.unit"
            :disabled="isReleased"
            data-test="inner-diameter-input"
            custom-background="bg-active-area"
            @click.stop
          />
          <div v-else class="flex items-center justify-start pr-0.5">
            <span>{{
              item.inner_diameter
                ? item.inner_diameter.toLocaleString('de-DE') +
                  ' ' +
                  props.pipeOptions.inner_diameter.unit
                : ''
            }}</span>
          </div>
        </template>

        <template #outer_diameter="{ item }">
          <InputEl
            v-if="expandedPipe === item.id"
            v-model="
              tableData.data.find((e) => e.id === item.id).outer_diameter
            "
            size="m"
            :input-type="props.pipeOptions.outer_diameter.type"
            :suffix="props.pipeOptions.outer_diameter.unit"
            :disabled="isReleased"
            data-test="outer-diameter-input"
            custom-background="bg-active-area"
            @click.stop
          />
          <div v-else class="flex items-center justify-start">
            <span>{{
              item.outer_diameter
                ? item.outer_diameter.toLocaleString('de-DE') +
                  ' ' +
                  props.pipeOptions.outer_diameter.unit
                : ''
            }}</span>
          </div>
        </template>
        <template #description="{ item }">
          <InputEl
            v-if="expandedPipe === item.id"
            v-model="tableData.data.find((e) => e.id === item.id).description"
            size="m"
            :disabled="isReleased"
            custom-background="bg-infra-highlight-25"
            data-test="pipe-description-input"
            @click.stop
          />
          <div v-else class="flex items-center justify-start">
            <span>{{ item.description || '' }}</span>
          </div>
        </template>
        <template #applicability="{ item }">
          <div class="flex items-center justify-start">
            <span>{{ getApplicabilityLabel(item.applicability) }}</span>
          </div>
        </template>
        <template #expanded-row="{ item }">
          <div class="flex gap-7 pb-2 pt-4">
            <div class="flex flex-col gap-2">
              <div class="grid grid-cols-2 gap-x-8 gap-y-2">
                <InputEl
                  v-for="input in Object.keys(filteredPipeOptions)"
                  :key="input"
                  v-model="tableData.data.find((e) => e.id === item.id)[input]"
                  size="m"
                  class="w-[314px]"
                  :suffix="pipeOptions[input].unit"
                  input-type="float"
                  :label="pipeOptions[input].label"
                  :disabled="isReleased"
                  :data-test="pipeOptions[input].label.replace(/\s+/g, '_')"
                />
              </div>

              <RadioButtonGroup
                v-model="
                  tableData.data.find((e) => e.id === item.id).applicability
                "
                items-data-key="display_name"
                :items-data="props.pipeOptions.applicability.choices"
                :disabled="isReleased"
                data-test="radio_group"
              />
            </div>

            <div class="ml-auto mt-auto flex gap-2">
              <ButtonEl
                v-if="draftPipes.includes(item.id)"
                size="m"
                text="Abbrechen"
                variant="secondary"
                @click="abortNewPipe(item.id)"
              />
              <ButtonEl
                v-if="!isReleased"
                size="m"
                text="Speichern"
                :disabled="!isPipeEdited"
                variant="secondary"
                color="success"
                data-test="save-btn"
                @click="modifyPipe(item.id)"
              />
            </div>
          </div>
        </template>
        <template #functions="{ item }">
          <div class="flex justify-center gap-[16px]">
            <div
              v-if="!draftPipes.includes(item.id) && !isReleased"
              class="delete-item flex items-center justify-center opacity-80 hover:opacity-100"
              @mouseenter="toggleEditClass($event)"
              @mouseleave="toggleEditClass($event)"
              @click.stop="
                deleteItem = item.id;
                dialog = true;
              "
            >
              <IconWrapper
                icon="delete_forever"
                fill="text-title-color1"
                type="filled"
                class="cursor-pointer"
              />
            </div>
          </div>
          <VuetifyDialog
            v-if="deleteItem === item.id"
            v-model="dialog"
            :activator-full-width="false"
            title="Rohr wirklich löschen?"
          >
            <template #content>
              Dieser Vorgang kann nicht rückgängig gemacht werden.
            </template>
            <template #actions>
              <ButtonEl
                variant="secondary"
                text="Abbrechen"
                @click="dialog = false"
              />
              <ButtonEl
                text="Löschen"
                color="error"
                variant="secondary"
                @click="deletePipe(item.id)"
              />
            </template>
          </VuetifyDialog>
        </template>
      </VuetifyDataTable>

      <div
        v-if="!isReleased"
        class="button-1 flex cursor-pointer items-center gap-2 bg-canvas p-4 text-title-neutral opacity-80 hover:opacity-100"
        @click="addPipe"
      >
        <IconWrapper icon="add" fill="text-title-neutral" />
        Rohr hinzufügen
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref, watch } from 'vue';
import { tableHeadersNewMaterialset } from './table-headers';
import { axios } from '@/utils/axiosHelper';
import cookie from 'vue-cookies';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import TextBox from '@/components/storybook/src/stories/textBox/TextBox.vue';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import VuetifyDataTable from '@/components/storybook/src/stories/vuetifyDataTable/VuetifyDataTable.vue';
import RadioButtonGroup from '@/components/storybook/src/stories/RadioButtonGroup/RadioButtonGroup.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';

// Props
const props = defineProps({
  editItem: {
    type: Object,
    default: null,
  },
  pipeOptions: {
    type: Object,
    default: null,
  },
  materialNames: {
    type: Array,
    default: () => [],
  },
});

// Refs
const tableData = reactive({ data: [] });
const newMaterialName = ref(null);
const nameIsValid = ref(false);
const expandedPipe = ref();
const draftPipes = ref([]);
const dialog = ref(false);
const deleteItem = ref();

const emit = defineEmits(['abort', 'updateMaterialSet', 'isAddingPipe']);

const name = computed({
  get() {
    return props.editItem.name;
  },
  set(value) {
    newMaterialName.value = value;
  },
});
const isReleased = computed(() => props.editItem.is_released);
const isPipeEdited = computed(() => {
  if (!expandedPipe.value) return false;

  const currentPipe = tableData.data.find((p) => p.id === expandedPipe.value);
  if (!currentPipe) return false;

  // Direct comparison for all fields except 'description'
  const fieldsToCheck = Object.keys(currentPipe).filter(
    (key) => key !== 'description',
  );
  const isOtherFieldsEdited = fieldsToCheck.some(
    (key) => currentPipe[key] !== originalPipeState.value[key],
  );

  // Check 'description' separately, allowing it to be null or an empty string
  const isDescriptionEdited =
    currentPipe.description !== originalPipeState.value.description;

  // Ensure all fields except 'description' are not null or undefined
  const areOtherFieldsValid = fieldsToCheck.every(
    (key) => currentPipe[key] !== null && currentPipe[key] !== undefined,
  );

  return (isOtherFieldsEdited || isDescriptionEdited) && areOtherFieldsValid;
});

const filteredPipeOptions = computed(() => {
  // Check if props.pipeOptions is null or undefined
  if (!props.pipeOptions) {
    return {};
  }

  const keysToExclude = [
    'id',
    'inner_diameter',
    'outer_diameter',
    'material',
    'applicability',
    'description',
  ];

  return Object.keys(props.pipeOptions)
    .filter((key) => !keysToExclude.includes(key))
    .reduce((obj, key) => ({ ...obj, [key]: props.pipeOptions[key] }), {});
});

// Watchers
watch(
  () => props.editItem,
  (value) => {
    if (!value) return;
    tableData.data = value.pipe_set;
    newMaterialName.value = value.name;
  },
  { immediate: true },
);

let originalPipeState = ref({});

watch(expandedPipe, (newValue) => {
  if (newValue) {
    const pipeData = tableData.data.find((p) => p.id === newValue);
    originalPipeState.value = pipeData
      ? JSON.parse(JSON.stringify(pipeData))
      : {};

    emit('isAddingPipe', true);
  } else if (newValue === null) {
    emit('isAddingPipe', false);
  }
});

function addPipe() {
  const newPipe = {
    id:
      //get the highest id and add 1
      tableData.data.length
        ? tableData.data.reduce(
            (max, p) => (p.id > max ? p.id : max),
            tableData.data[0].id,
          ) + 1
        : 1,
    inner_diameter: null,
    outer_diameter: null,
    applicability: null,
    thermal_conductivity: null,
    friction_coefficient: null,
    cost_pipe: null,
    cost_underground_construction: null,
    cost_installation: null,
    description: null,
    material: props.editItem.id,
  };

  tableData.data.push(newPipe);
  expandedPipe.value = newPipe.id;
  draftPipes.value.push(newPipe.id);
}

function updateMaterial() {
  axios({
    url: `/api/heatprojects/material-set/${props.editItem.id}/`,
    method: 'PATCH',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    data: { name: newMaterialName.value },
  }).then((r) => {
    emit('updateMaterialSet', r.data);
  });
}

function modifyPipe(id) {
  // if id is contained in draftPipes, it is a new pipe
  if (!draftPipes.value.includes(id)) {
    axios({
      url: `/api/heatprojects/pipes/${id}/`,
      method: 'PATCH',
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      data: tableData.data.find((e) => e.id === id),
    }).then(() => {
      emit('updateMaterialSet');
      // close pipe
      expandedPipe.value = null;
    });
  } else {
    const localId = id;
    // filter pipe from tableData.data and remove id
    const pipe = Object.fromEntries(
      Object.entries(tableData.data.find((e) => e.id === id)).filter(
        ([key]) => key !== 'id',
      ),
    );
    axios({
      url: '/api/heatprojects/pipes/',
      method: 'POST',
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      data: pipe,
    }).then((r) => {
      emit('updateMaterialSet');
      // modify local id with id from backend
      tableData.data.find((e) => e.id === localId).id = r.data.id;
      // remove id from draftPipes
      draftPipes.value.splice(draftPipes.value.indexOf(localId), 1);
      // close pipe
      expandedPipe.value = null;
    });
  }
}

function abortNewPipe(id) {
  // filter pipe from tableData.data and remove id
  tableData.data.splice(
    tableData.data.findIndex((e) => e.id === id),
    1,
  );
  // remove id from draftPipes
  draftPipes.value.splice(draftPipes.value.indexOf(id), 1);

  // reset expanded pipe
  expandedPipe.value = null;
}

// Call this function whenever you need to check for changes

function deletePipe(id) {
  axios({
    url: `/api/heatprojects/pipes/${id}/`,
    method: 'DELETE',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  }).then(() => {
    const findIndex = tableData.data.findIndex((e) => e.id === id);
    tableData.data.splice(findIndex, 1);
  });
}

function setExpandedPipe(id) {
  expandedPipe.value = id[0] ? id[0] : null;
}

function getApplicabilityLabel(val) {
  const choices = props.pipeOptions.applicability.choices;
  let applicability = val;
  if (!choices.map((e) => e.value).includes(val)) {
    applicability = 2;
  }
  return choices.find((e) => e.value === applicability).display_name;
}

function toggleEditClass(event) {
  const deleteItem = event.target;

  // Find the nearest '.edit-pipe-custom' relative to 'deleteItem'
  const editPipeCustom = deleteItem
    .closest('.v-data-table__tr')
    .querySelector('.edit-pipe-custom');

  if (editPipeCustom) {
    editPipeCustom.classList.toggle('delete-active');
  }
}
</script>

<style lang="scss" scoped>
.new-material-set-custom {
  .v-data-table__tr:hover .edit-pipe-custom:not(.delete-active) {
    opacity: 100 !important;
  }
}
</style>
