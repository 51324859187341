import { LAYERS } from '@/features/heat-project/constants';
import booleanIntersects from '@turf/boolean-intersects';
import buffer from '@turf/buffer';
import { GeoJsonMultiLineString } from '@/features/draw/utilities';
import MapboxGlDraw from '@mapbox/mapbox-gl-draw';
import Point from '@mapbox/point-geometry';

const BoxSelect = Object.assign({}, MapboxGlDraw.modes.simple_select);

BoxSelect.onSetup = function (opts) {
  // turn the opts into state.
  const state = {
    dragMoveLocation: null,
    boxSelectStartLocation: null,
    boxSelectElement: undefined,
    boxSelecting: true,
    canBoxSelect: true,
    dragMoving: false,
    canDragMove: false,
    initialDragPanState: this.map.dragPan.isEnabled(),
    initiallySelectedFeatureIds: opts.featureIds || [],
  };

  this.setSelected(
    state.initiallySelectedFeatureIds.filter(
      (id) => this.getFeature(id) !== undefined,
    ),
  );
  this.fireActionable();

  this.setActionableState({
    combineFeatures: true,
    uncombineFeatures: true,
    trash: true,
  });

  return state;
};

BoxSelect.onDrag = function (state, e) {
  return this.whileBoxSelect(state, e);
};

BoxSelect.dragMove = function (state, e) {
  // Dragging when drag move is enabled
  state.dragMoving = true;
  e.originalEvent.stopPropagation();

  const delta = {
    lng: e.lngLat.lng - state.dragMoveLocation.lng,
    lat: e.lngLat.lat - state.dragMoveLocation.lat,
  };
  // only move home connection points in order to not fuck up the whole network
  const homeConnectionPoints = this.getSelected().filter(
    (e) => e.properties.type === LAYERS.HOME_CONNECTION_POINT,
  );

  const homeConnectionNetwork = this.map.queryRenderedFeatures({
    layers: [
      'gl-draw-line-deactive.cold',
      'gl-draw-line-active.cold',
      'gl-draw-line-active.hot',
    ],
    filter: ['==', ['get', 'user_type'], LAYERS.HOME_CONNECTION],
  });

  const homeNetworkIds = [];
  // get all related home connection lines and set point state to not connected
  for (const homeConnection of homeConnectionPoints) {
    const { properties } = homeConnection;
    if (properties.isConnected) {
      const connection = homeConnectionNetwork.find(
        (e) => e.properties.user_estate_id === properties.estate_id,
      );
      homeNetworkIds.push(connection.properties.id);
      homeConnection.setProperty('isConnected', false);
    }
  }
  // deselect and delete all related home connection lines
  this.deselect(homeNetworkIds);
  this.deleteFeature(homeNetworkIds);
  MapboxGlDraw.lib.moveFeatures(homeConnectionPoints, delta);

  state.dragMoveLocation = e.lngLat;
};

BoxSelect.onTrash = function () {
  const pointFeatures = [];
  const homeConnectionFeatures = [];
  const heatSourceConnectionFeatures = [];
  for (const feature of this._ctx.api.getAll().features) {
    if (feature.properties.type === LAYERS.HOME_CONNECTION) {
      homeConnectionFeatures.push(feature);
    }
    if (feature.properties.type === LAYERS.HEAT_SOURCE_CONNECTION) {
      heatSourceConnectionFeatures.push(feature);
    }
    if (
      [LAYERS.HOME_CONNECTION_POINT, LAYERS.HEAT_SOURCE_POINT].includes(
        feature.properties.type,
      )
    ) {
      pointFeatures.push(feature);
    }
  }
  // push all home connection lines to selected in store in order to delete them
  const distNetCoords = [];
  let distNetIsSelected = false;
  for (const selected of this.getSelected()) {
    if (selected.properties.type === LAYERS.DISTRIBUTION_NETWORK) {
      distNetIsSelected = true;
      distNetCoords.push(selected.coordinates);
    }
  }
  if (distNetIsSelected) {
    // add buffer since turf algorithms are not precise enough for
    const distNetBuffered = buffer(
      GeoJsonMultiLineString(distNetCoords),
      0.0001,
    );
    for (const homeConnection of homeConnectionFeatures) {
      if (booleanIntersects(distNetBuffered, homeConnection)) {
        this.select(homeConnection.id);
      }
    }
    for (const heatSourceConnection of heatSourceConnectionFeatures) {
      if (booleanIntersects(distNetBuffered, heatSourceConnection)) {
        this.select(heatSourceConnection.id);
      }
    }
  }
  for (const selected of this.getSelected()) {
    let type = null;
    let property = null;
    let target_property = null;
    if (selected.properties.type === LAYERS.HOME_CONNECTION) {
      type = LAYERS.HOME_CONNECTION_POINT;
      property = 'estate_id';
      target_property = 'estate_id';
    }
    if (selected.properties.type === LAYERS.HEAT_SOURCE_CONNECTION) {
      type = LAYERS.HEAT_SOURCE_POINT;
      property = 'heat_source_id';
      target_property = 'id';
    }
    if (type !== null) {
      const pointFeature = pointFeatures.find(
        (e) =>
          e.properties.type === type &&
          e.properties[target_property] === selected.properties[property],
      );
      this._ctx.api.setFeatureProperty(pointFeature.id, 'isConnected', false);
    }
    // deselect all building connection point since they can not be deleted
    if (
      selected.properties.type === LAYERS.HOME_CONNECTION_POINT ||
      selected.properties.type === LAYERS.HEAT_SOURCE_POINT
    ) {
      this.deselect(selected.id);
    }
  }
  this.deleteFeature(this.getSelectedIds());
  this.fireActionable();
};

BoxSelect.onMouseDown = function (state, e) {
  return this.startBoxSelect(state, e);
};

function mouseEventPoint(mouseEvent, container) {
  const rect = container.getBoundingClientRect();
  return new Point(
    mouseEvent.clientX - rect.left - (container.clientLeft || 0),
    mouseEvent.clientY - rect.top - (container.clientTop || 0),
  );
}

BoxSelect.onTouchEnd = BoxSelect.onMouseUp = function (state, e) {
  const bbox = [
    state.boxSelectStartLocation,
    mouseEventPoint(e.originalEvent, this.map.getContainer()),
  ];
  const featuresInBox = this.featuresAt(null, bbox, 'click');
  const idsToSelect = this.getUniqueIds(featuresInBox).filter(
    (id) => !this.isSelected(id),
  );
  if (idsToSelect.length) {
    this.select(idsToSelect);
    idsToSelect.forEach((id) => this.doRender(id));
    this.updateUIClasses({ mouse: MapboxGlDraw.constants.cursors.MOVE });
  }
  this.stopExtendedInteractions(state);
  this.changeMode(MapboxGlDraw.constants.modes.SIMPLE_SELECT, {
    featureIds: idsToSelect,
  });
};

export default BoxSelect;
