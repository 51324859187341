import { mergeFeatureDataAndLayerConfig } from '@/features/map/info-panel/panel-data/panel-data-calculator';
import {
  LAYER_KEY__BKG_RASTER,
  LAYER_KEY__BLOCK,
  LAYER_KEY__COUNTY,
  LAYER_KEY__CUSTOM_AGGREGATION,
  LAYER_KEY__DISTRICT,
  LAYER_KEY__FEDERAL_STATE,
  LAYER_KEY__GOVERNMENT_DISTRICT,
  LAYER_KEY__HEAT_DENSITY_STREETS,
  LAYER_KEY__MUNICIPALITY,
} from '@/configs/layers/baseData/constants';
import { LAYER_KEY__ESTATE } from '@/configs/layers/estate';
import { fetchData } from './utils';

export class AggregationPanel {
  layerConfigs = {
    [LAYER_KEY__BLOCK]: { url: 'buildingBlock' },
    [LAYER_KEY__DISTRICT]: { url: 'district' },
    [LAYER_KEY__MUNICIPALITY]: { url: 'municipality' },
    [LAYER_KEY__CUSTOM_AGGREGATION]: { url: 'customAggregation' },
    [LAYER_KEY__ESTATE]: { url: 'estate' },
    [LAYER_KEY__COUNTY]: { url: 'county' },
    [LAYER_KEY__GOVERNMENT_DISTRICT]: { url: 'governmentDistrict' },
    [LAYER_KEY__FEDERAL_STATE]: { url: 'federalState' },
    [LAYER_KEY__BKG_RASTER]: { url: 'bkgRaster' },
    [LAYER_KEY__HEAT_DENSITY_STREETS]: { url: 'streetsegment' },
  };

  layerIds = Object.keys(this.layerConfigs);

  async requestFeatureData(feature, scenarioId) {
    const featureId = feature.properties.id;
    const layerId = feature.layer.id;
    const resp = await fetchData(
      `/api/scenarios/${scenarioId}/${this.layerConfigs[layerId].url}/${featureId}`,
    );
    if (resp === null) return resp;
    const featureData = resp.data;
    featureData.layerId = layerId;
    return featureData;
  }

  calculatePanelData(featureData, scenarioYear, store) {
    let mainResult = {
      ...featureData.buildings,
      id: featureData.id,
      name: featureData.name,
      ...featureData.potential_solarthermal_free,
      ...featureData.potential_pv_free,
      ...featureData.potential_solarthermal_roof,
      ...featureData.potential_pv_roof,
      ...featureData.potential_biomass,
      ...featureData.potential_waste_heat,
      ...featureData.potential_wind,
    };

    if (featureData.layerId === LAYER_KEY__BLOCK) {
      mainResult = { ...mainResult, ...featureData.priority_area };
    }

    if (featureData.layerId === LAYER_KEY__DISTRICT) {
      mainResult = {
        ...mainResult,
        ...featureData.expansion_limit,
        ...featureData.expansion_plan,
        ...featureData.priority_area,
      };
    }

    if (
      [
        LAYER_KEY__DISTRICT,
        LAYER_KEY__FEDERAL_STATE,
        LAYER_KEY__GOVERNMENT_DISTRICT,
        LAYER_KEY__COUNTY,
        LAYER_KEY__MUNICIPALITY,
      ].includes(featureData.layerId)
    ) {
      mainResult = {
        ...mainResult,
        ...featureData.heat,
        ...featureData.gas,
        ...featureData.power,
        ...featureData.sewage,
      };
    }

    if (
      [LAYER_KEY__BLOCK, LAYER_KEY__CUSTOM_AGGREGATION].includes(
        featureData.layerId,
      )
    ) {
      mainResult = { ...mainResult, ...featureData.target_year_result };
    }

    const panelConfig =
      store.state.map.layerConfigs[featureData.layerId].sidebar;

    const something = mergeFeatureDataAndLayerConfig(
      mainResult,
      featureData.yearlyresult,
      scenarioYear,
      panelConfig,
      store.state.scenario.scenarioSelected.name,
      featureData.options,
    );
    return something;
  }
}
