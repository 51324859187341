import { LAYER_KEY__BUILDINGS } from '../layers/buildings';
import {
  LAYER_KEY__ESTATE,
  LAYER_KEY__ESTATE_CONNECTION,
} from '@/configs/layers/estate';
import { infraModules } from '@/configs/infra-modules';

export const SWITCH_ID__BUILDINGS = 'LC_BUILDINGS';
export const SWITCH_ID__ESTATE = 'LC_ESTATE';

export default {
  title: 'Gebäude',
  items: [
    {
      active: true,
      title: 'Gebäude',
      onLayers: [LAYER_KEY__BUILDINGS],
      switchId: SWITCH_ID__BUILDINGS,
      itemClass: 'switch',
      radioGroups: [
        {
          visible: true,
          activated: 0,
          radios: [
            {
              title: 'Keine Einfärbung',
              onLayers: ['buildingmodel_buildingaggregate'],
              layerState: 'default',
            },
            {
              title: 'Sektor',
              onLayers: ['buildingmodel_buildingaggregate'],
              layerState: 'sector',
            },
            {
              title: 'Heiztechnologie',
              onLayers: ['buildingmodel_buildingaggregate'],
              layerState: 'heating_technology',
              module: infraModules.scenarios,
            },
            {
              title: 'Wärmebedarf',
              onLayers: ['buildingmodel_buildingaggregate'],
              layerState: 'heat_demand',
              module: infraModules.districtHeating,
            },
            {
              title: 'Spezifischer Wärmebedarf',
              onLayers: ['buildingmodel_buildingaggregate'],
              layerState: 'specific_heat_demand',
            },
          ],
        },
      ],
    },
    {
      active: false,
      title: 'Flurstücke',
      onLayers: [LAYER_KEY__ESTATE],
      switchId: SWITCH_ID__ESTATE,
      itemClass: 'switch',
    },
    {
      active: false,
      title: 'Flurstück-Anschluss',
      onLayers: [LAYER_KEY__ESTATE_CONNECTION],
      switchId: LAYER_KEY__ESTATE_CONNECTION,
      itemClass: 'switch',
    },
  ],
};
