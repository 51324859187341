export class BlockMap {
  constructor(blocks) {
    if (!blocks || !Array.isArray(blocks)) {
      console.error('Invalid blocks data provided to BlockMap');
      this.byBlockId = {};
      this.byFeatureId = {};
      this.requestIds = [];
      return;
    }

    this.byBlockId = blocks.reduce((acc, cv) => {
      if (cv && cv.properties && cv.properties.id && cv.id) {
        acc[cv.properties.id] = cv.id;
      }
      return acc;
    }, {});

    this.byFeatureId = blocks.reduce((acc, cv) => {
      if (cv && cv.properties && cv.properties.id && cv.id) {
        acc[cv.id] = cv.properties.id;
      }
      return acc;
    }, {});

    this.requestIds = Object.keys(this.byBlockId);
  }
}
