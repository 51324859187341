<template>
  <h4 class="text-infra-highlight-500">
    {{ editObjectId ? 'Standort bearbeiten' : 'Standort hinzufügen' }}
  </h4>

  <!-- anlagentyp, bestand or potenzial -->
  <div class="standard-elevation-1 flex flex-col gap-1.5 rounded-[8px] p-2">
    <InputEl
      v-model="heatSource.name"
      label="Bezeichnung"
      placeholder="Bitte eingeben"
      data-test="location-name"
      :rules="{ required: true, unique: [checkNameIsUnique] }"
    />

    <RadioButtonGroup
      v-model="heatSource.assetType"
      :small="true"
      items-data-key="display_name"
      data-test="location-radio-group"
      :items-data="heatSource.assetTypeOptions"
    />
  </div>

  <VRadioGroup
    v-if="heatSource.assetType !== null"
    v-model="heatSource.systemType"
    hide-details
    class="standard-elevation-0-dark hide-scrollbar flex flex-col overflow-y-auto rounded-[6px]"
    :disable="editObjectId"
  >
    <!-- Waermequelle -->
    <EnergySourceWrapper
      :system-type-selected="heatSource.systemType"
      :system-type="{
        id: heatSource.systemTypeOptions[0].value,
        label: heatSource.systemTypeOptions[0].display_name,
      }"
      :disabled="!!editObjectId"
      :optional-params="true"
      @system-type-selected="changeSystemType($event, editObjectId)"
    >
      <template #required-params>
        <DropDown
          v-model="heatSource.sourceType"
          :items-data="heatSource.sourceTypeOptions"
          items-data-key="display_name"
          placeholder="Bitte wählen"
          size="m"
          label="Art der Wärmequelle"
          :rules="{ required: true }"
          @update:model-value="
            heatSource.energySource = heatSource.energySourceOptions[0].value
          "
        />

        <InputEl
          v-model="heatSource.heatOutput"
          input-type="float"
          label="Leistung thermisch"
          size="m"
          placeholder="Bitte eingeben"
          suffix="MWth"
          :rules="{ required: true }"
        />

        <InputEl
          v-model="heatSource.heatGeneration"
          input-type="float"
          label="Erzeugte Wärmemenge"
          size="m"
          placeholder="Bitte eingeben"
          suffix="MWh"
          :rules="{ required: true }"
        />
      </template>

      <template #optional-params>
        <DropDown
          v-model="heatSource.energySource"
          :items-data="heatSource.energySourceOptions"
          items-data-key="display_name"
          placeholder="Bitte wählen"
          label="Primärenergieträger"
          :initial-selection="false"
          size="m"
          :clearable="true"
        />

        <InputEl
          v-model="heatSource.powerOutput"
          input-type="float"
          label="Leistung elektrisch"
          size="m"
          placeholder="Bitte eingeben"
          suffix="MWel"
        />
        <InputEl
          v-model="heatSource.powerGeneration"
          input-type="float"
          label="Erzeugte Strommenge"
          size="m"
          placeholder="Bitte eingeben"
          suffix="MWh"
        />
        <InputEl
          v-model="heatSource.emissions"
          input-type="float"
          label="Emissionen"
          size="m"
          placeholder="Bitte eingeben"
          suffix="t/MWh"
        />
      </template>
    </EnergySourceWrapper>
    <!-- Heizzentrale -->
    <EnergySourceWrapper
      :system-type-selected="heatSource.systemType"
      :system-type="{
        id: heatSource.systemTypeOptions[1].value,
        label: heatSource.systemTypeOptions[1].display_name,
      }"
      data-test="heizzentrale-radio"
      :disabled="!!editObjectId"
      @system-type-selected="changeSystemType($event)"
    >
      <template #required-params>
        <InputEl
          v-model="heatSource.heatOutput"
          input-type="float"
          label="Leistung thermisch"
          size="m"
          placeholder="Bitte eingeben"
          suffix="MWth"
          data-test="heizzentrale-leistung-thermisch"
          :rules="{ required: true }"
        />
      </template>
    </EnergySourceWrapper>

    <!-- Aufspeisepunkt -->
    <EnergySourceWrapper
      :disabled="!!editObjectId"
      :system-type-selected="heatSource.systemType"
      :system-type="{
        id: heatSource.systemTypeOptions[2].value,
        label: heatSource.systemTypeOptions[2].display_name,
      }"
      icon="EditLineIcon"
      @system-type-selected="changeSystemType($event)"
    >
      <template #required-params>
        <InputEl
          v-model="heatSource.heatOutput"
          input-type="float"
          label="Freie Kapazität"
          size="m"
          placeholder="Bitte eingeben"
          suffix="MWth"
          :rules="{ required: true }"
        />
      </template>
    </EnergySourceWrapper>
  </VRadioGroup>

  <!-- action buttons -->
  <div v-if="editObjectId" class="grid grid-cols-2 gap-2">
    <ButtonEl variant="secondary" text="Abbrechen" @click="abort()" />
    <ButtonEl
      :disabled="!heatSource.isValid"
      color="success"
      variant="secondary"
      text="Speichern"
      @click="save()"
    />
  </div>
  <div v-else class="grid grid-cols-2 gap-2.5">
    <ButtonEl
      :full-width="true"
      variant="secondary"
      text="Abbrechen"
      @click="abort()"
    />

    <ButtonEl
      :disabled="!heatSource.isValid"
      :full-width="true"
      text="Speichern"
      @click="save()"
    />
  </div>
</template>

<script setup>
import HeatSource from '@/features/heat-project/heat-source/heat-source';
import { useStore } from 'vuex';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import _ from 'lodash';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import RadioButtonGroup from '@/components/storybook/src/stories/RadioButtonGroup/RadioButtonGroup.vue';
import EnergySourceWrapper from '@/features/heat-project/components/EnergySourceWrapper.vue';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';

const store = useStore();

const props = defineProps({
  editObjectId: {
    type: Number,
    default: null,
  },
});

const heatSource = ref(new HeatSource());
const heatSources = computed(() => store.state.heatProject.heatSources);
const checkNameIsUnique = computed(() => {
  return heatSources.value
    .filter((e) => e.id !== heatSource.value.id)
    .map((e) => e.name);
});

onMounted(() => {
  if (props.editObjectId) {
    const existingHeatSource = heatSources.value.find(
      (e) => e.id === props.editObjectId,
    );
    if (existingHeatSource) {
      //initialize heatSource as independent copy of existing heatSource object
      heatSource.value = _.cloneDeep(existingHeatSource);
    }
  } else {
    heatSource.value = new HeatSource();
    heatSource.value.initialize();
    showToast();
  }
});

onUnmounted(() => {
  heatSource.value.destroy();
  store.commit('layout/HIDETOAST');
});

function showToast() {
  store.commit('layout/SHOWTOAST', {
    color: 'infra-highlight-500',
    message: 'Bitte Wärmequelle per Ziehen des Markers positionieren.',
    timeout: -1,
  });
}

function changeSystemType(systemType) {
  heatSource.value.systemType = systemType;
}

function save() {
  if (heatSource.value.id) {
    store.dispatch('heatProject/PUT_HEAT_SOURCE', heatSource.value);
  } else {
    store.dispatch('heatProject/POST_HEAT_SOURCE', heatSource.value);
  }
  store.commit('SET_MAP_ACTIONS', null);
}

function abort() {
  store.commit('SET_MAP_ACTIONS', null);
}

watch(
  () => heatSource.value.systemType,
  () => {
    if (!props.editObjectId) {
      heatSource.value.resetParameter();
    }
  },
);
</script>
