<template>
  <FormValidator v-model="isFormValid" class="flex w-[400px] flex-col gap-3">
    <InputEl
      v-model="passwordOld"
      :rules="{ required: true }"
      label="Altes Passwort"
      :type="showPassword ? 'text' : 'password'"
      placeholder="Bitte Passwort eingeben"
      :suffix="showPassword ? EyeCrossedIcon : EyeIcon"
      :suffix-icon="true"
      :suffix-clickable="true"
      class="w-full"
      @icon-click="showPassword = !showPassword"
    />
    <InputEl
      v-model="passwordNew"
      :rules="{
        required: true,
        length: true,
        uppercase: true,
        number: true,
        specialChar: true,
      }"
      :type="showPassword ? 'text' : 'password'"
      :suffix="showPassword ? EyeCrossedIcon : EyeIcon"
      :suffix-icon="true"
      :suffix-clickable="true"
      label="Neues Passwort"
      class="mt-0.5 w-full"
      @icon-click="showPassword = !showPassword"
    />
    <InputEl
      v-model="passwordConfirm"
      :rules="{
        required: true,
        match: passwordNew,
      }"
      :type="showPassword ? 'text' : 'password'"
      :suffix="showPassword ? EyeCrossedIcon : EyeIcon"
      :suffix-icon="true"
      :suffix-clickable="true"
      label="Neues Passwort bestätigen"
      class="mt-0.5 w-full"
      @icon-click="showPassword = !showPassword"
    />
    <ButtonEl
      class="w-fit"
      :disabled="!isFormValid"
      text="Speichern"
      @click="submit"
    />
  </FormValidator>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import EyeCrossedIcon from '@/components/storybook/src/assets/icons/EyeCrossedIcon.vue';
import EyeIcon from '@/components/storybook/src/assets/icons/EyeIcon.vue';

const store = useStore();
const router = useRouter();

const isFormValid = ref(true);
const passwordOld = ref('');
const passwordNew = ref('');
const passwordConfirm = ref('');
const showPassword = ref(false);

const submit = () => {
  update(passwordOld.value, passwordNew.value);
};

const update = (passwordOld, passwordNew) => {
  store
    .dispatch('auth/PATCH_PASSWORD', { passwordOld, passwordNew })
    .then(() => {
      router.push('/profile');
      passwordOld.value = '';
      passwordNew.value = '';
      passwordConfirm.value = '';
    });
};
</script>
