export default {
  SET_ENERGY_DATA: (state, payload) => {
    state.energyData = payload;
  },

  SET_DISTRICT_HEATING_POTENTIALS_DATA: (state, payload) => {
    state.districtHeatingPotentialsData = payload;
  },

  SET_COMMUNITY_DATA: (state, payload) => {
    state.communityData = payload;
  },

  SET_POTENTIAL_DATA: (state, payload) => {
    state.potentialData = payload;
  },

  SET_DASHBOARD4_FILTER_DATA: (state, payload) => {
    state.dashboard4filterData = payload;
  },
  SET_DASHBOARD3_GROUP_BY: (state, payload) => {
    state.dashboard3groupBy = payload;
  },
  SET_LAST_SCROLL_POSITION: (state, payload) => {
    state.lastScrollPosition = payload;
  },
  SET_FILTER_DROPDOWN: (state, payload) => {
    state.showFilterDropdown = payload;
  },
  SET_ACTIVE_TAB: (state, payload) => {
    state.activeTab = payload;
  },
};
