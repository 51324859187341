<template>
  <div
    v-if="activeProcessId"
    class="absolute bottom-[80px] top-[80px] z-10 w-[360px] overflow-visible"
    :style="panelPositions['mapActionActive']"
  >
    <div
      class="standard-elevation-2 hide-scrollbar max-h-full overflow-y-scroll rounded-md bg-white"
      data-test="heat-project-form"
    >
      <div class="flex flex-col gap-2.5 p-2.5">
        <HeatProjectForm
          v-if="activeProcessId === 'heatProject'"
          :edit-object-id="activeProcessEditId"
        />
        <HeatSourceForm
          v-else-if="activeProcessId === 'heatSource'"
          :edit-object-id="activeProcessEditId"
        />
        <AssignPriorityArea
          v-else-if="activeProcessId === 'AssignPriorityAreaToBlock'"
        />
        <MeasuringTool v-else-if="activeProcessId === 'measure'" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { usePanel } from '@/composables/usePanel';
import { computed, watch } from 'vue';
import HeatProjectForm from '@/features/heat-project/HeatProjectForm.vue';
import HeatSourceForm from '@/features/heat-project/heat-source/HeatSourceForm.vue';
import AssignPriorityArea from '@/features/assign-priority-area/AssignPriorityArea.vue';
import { useStore } from 'vuex';
import MeasuringTool from '@/features/map/measure/MeasuringTool.vue';

const store = useStore();

const activeProcess = computed(() => store.getters.getActiveProcessOptions);
const activeProcessId = computed(() => activeProcess.value?.id);
const activeProcessEditId = computed(() => activeProcess.value?.editObjectId);

const { panelPositions, addPanel, removePanel } = usePanel(
  'mapActionActive',
  true,
);

watch(
  () => activeProcessId.value,
  (newValue) => {
    if (newValue) {
      addPanel();
    } else {
      removePanel();
    }
  },
);
</script>
