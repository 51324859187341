<template>
  <div
    class="flex min-h-screen w-full flex-col justify-center bg-cover"
    :style="{
      background: `url(${backgroundImage}) lightgray 50% / cover no-repeat`,
    }"
  >
    <LandingPageHeader />
    <div
      class="hide-scrollbar overflow-y-auto"
      style="max-height: calc(100vh - 150px)"
    >
      <div
        class="mx-auto flex w-full max-w-[1200px] flex-col px-5"
        style="min-height: calc(100vh - 150px)"
      >
        <div class="transition-container my-auto flex h-fit flex-col gap-2.5">
          <template v-for="row in cardRows" :key="row">
            <div
              :class="{
                'standard-elevation-2 flex flex-col gap-5 rounded-[20px] bg-elevated p-5':
                  perspectiveSet === 'true',
              }"
            >
              <MuniScenSelectorWrapper
                v-if="row === 1"
                :on-hub="true"
                :show-selector="
                  (perspectiveSet !== 'true' && row === 1) ||
                  (row === 1 && showSelector)
                "
                @scenario-count="scenarioCount = $event"
                @perspective-set="perspectiveSet = 'true'"
              />
              <LandingPageCardRow
                v-if="perspectiveSet === 'true'"
                :card-data="cardData.filter((e) => e.row === row)"
                :small-card="row === 2"
                :scenario-count
                @animation-finished="showSelector = true"
              />
            </div>
          </template>
        </div>
        <FooterSection class="text-inverted-neutral" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import backgroundImage from '@/assets/img/background_authentication.webp';

import { rawCardData } from './raw-card-data';
import LandingPageHeader from '@/apps/landing-page/components/LandingPageHeader.vue';
import MuniScenSelectorWrapper from '@/components/municipality-scenario-selector/MuniScenSelectorWrapper.vue';
import LandingPageCardRow from '@/apps/landing-page/components/LandingPageCardRow.vue';
import FooterSection from '@/components/footer/FooterSection.vue';
import { useMapStore } from '@/features/map/store/map-store';

const router = useRouter();
const { showMuScSelector } = toRefs(useMapStore());

const cardData = rawCardData.map(attachNavigate);
const scenarioCount = ref(0);
const cardRows = computed(() => {
  const rows = new Set(cardData.map((card) => card.row));
  return Array.from(rows).sort();
});
const showSelector = ref(false);
const perspectiveSet = ref(localStorage.getItem('perspectiveSet'));

onMounted(() => {
  showMuScSelector.value = true;
});

function attachNavigate(card) {
  return {
    ...card,
    action: () => router.push({ name: card.routeName }),
    children: card.children?.map(attachNavigate),
  };
}
</script>
