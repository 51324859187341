import { onUnmounted, computed } from 'vue';
import { usePanelStore } from '@/store/panel-store';

export function usePanel(panelName, preventImmediateAdd = false) {
  const panelStore = usePanelStore();

  // Add panel immediately when composable is used
  if (!preventImmediateAdd) {
    panelStore.addPanel(panelName);
  }

  // Cleanup on component unmount
  onUnmounted(() => {
    panelStore.removePanel(panelName);
  });

  return {
    panelPositions: computed(() => panelStore.panelPositions),
    togglePanel: () => panelStore.togglePanel(panelName),
    addPanel: () => panelStore.addPanel(panelName),
    removePanel: () => panelStore.removePanel(panelName),
  };
}
