import mapboxgl from 'mapbox-gl';
import config from '@/configs';
import U from 'map-gl-utils';
import { getTileServerUrl } from '@/mapbox/createLayer';
import { LAYER_KEY__BLOCK } from '@/configs/layers/baseData/constants';
import { LAYER_KEY__BUILDINGS } from '@/configs/layers/buildings';
import centroid from '@turf/centroid';

let featureId = null;
let lastFeatures = null;
const layerBlocks = LAYER_KEY__BLOCK;
const layerBuildings = LAYER_KEY__BUILDINGS;
let sourceLayerBlocks = null;
let sourceLayerBuildings = null;

export default function initMap(
  getBlocks,
  setIdForTableHighlight,
  scenarioId,
  initialFilter,
) {
  const map = new mapboxgl.Map({
    ...config.constraints.mapView.mapConstraints,
    container: 'map-energy-carrier-by-building-block',
  });
  sourceLayerBlocks = `${scenarioId}_${LAYER_KEY__BLOCK}`;
  sourceLayerBuildings = `${scenarioId}_${LAYER_KEY__BUILDINGS}`;

  U.init(map, mapboxgl);

  map.scrollZoom.disable();
  map.doubleClickZoom.disable();

  map.on('load', () => {
    lastFeatures = new Set();
    addBuildingLayer(map, initialFilter);
    addBlockLayer(map, initialFilter);
    clearLabelsContainer();
  });

  // Update labels after the map finishes moving or zooming
  map.on('moveend', () => {
    const uniqueFeatures = getUniqueFeatures(map);
    const currentFeatureSet = new Set(uniqueFeatures.map((f) => f.id));

    // Prevent new request if features on map haven't changed
    if (!areFeatureSetsEqual(lastFeatures, currentFeatureSet)) {
      updateHTMLLabels(map, uniqueFeatures);
      getBlocks(uniqueFeatures);
      lastFeatures = currentFeatureSet;
      setIdForTableHighlight('');
    }
  });

  // Continuously reposition labels as the map moves
  map.on('move', () => {
    repositionLabels(map);
  });

  map.on('click', (e) => {
    highlightBlockByMapClick(map, e, setIdForTableHighlight);
  });

  return map;
}

function addBlockLayer(map, initialFilter) {
  map.U.addVector(layerBlocks, getTileServerUrl(sourceLayerBlocks));

  map.U.addLineLayer(layerBlocks + '-outline', layerBlocks, {
    visibility: 'visible',
    'line-color': '#888888',
    'line-width': 2,
    'source-layer': sourceLayerBlocks,
    ...(initialFilter && { filter: initialFilter }),
  });

  map.U.addFillExtrusionLayer(layerBlocks, layerBlocks, {
    visibility: 'visible',
    'fill-extrusion-color': [
      'case',
      ['boolean', ['feature-state', 'clicked'], false],
      '#60bdff',
      'rgba(0,0,0,0)',
    ],
    'fill-extrusion-opacity': 1,
    'source-layer': sourceLayerBlocks,
    ...(initialFilter && { filter: initialFilter }),
  });
}

function addBuildingLayer(map, initialFilter) {
  map.U.addVector(layerBuildings, getTileServerUrl(sourceLayerBuildings));
  map.U.addFillExtrusionLayer(layerBuildings, layerBuildings, {
    visibility: 'visible',
    'fill-extrusion-color': '#888888',
    'fill-extrusion-height': ['get', 'height_m'],
    'fill-extrusion-opacity': 1,
    'source-layer': sourceLayerBuildings,
    ...(initialFilter && { filter: initialFilter }),
  });
}

function getUniqueFeatures(map) {
  const visibleFeatures = map.queryRenderedFeatures({ layers: [layerBlocks] });
  // We use a Map keyed by feature.id to ensure uniqueness
  const featuresMap = new Map();
  visibleFeatures.forEach((feature) => {
    featuresMap.set(feature.id, feature);
  });
  return Array.from(featuresMap.values());
}

function clearLabelsContainer() {
  const container = document.getElementById('labels-container');
  if (container) {
    container.innerHTML = '';
  }
}

function getLabelPosition(feature) {
  const geoJsonFeature = {
    type: 'Feature',
    geometry: feature.geometry,
  };

  const point = centroid(geoJsonFeature);
  return point.geometry.coordinates;
}

/** Updates the HTML overlay labels with the current features. **/
function updateHTMLLabels(map, features) {
  clearLabelsContainer();
  const container = document.getElementById('labels-container');
  if (!container) return;

  features.forEach((feature) => {
    const position = getLabelPosition(feature);
    const pixelPos = map.project(position);

    const label = document.createElement('div');
    label.className = 'feature-label';
    label.innerText = feature.properties.id;

    // Store the feature's geometry for repositioning
    // using JSON so we can parse it later
    label.setAttribute('data-geometry', JSON.stringify(feature.geometry));
    label.style.position = 'absolute';
    label.style.left = `${pixelPos.x}px`;
    label.style.top = `${pixelPos.y}px`;

    container.appendChild(label);
  });
}

/** Reposition labels as the map moves/zooms. **/
function repositionLabels(map) {
  const labelsContainer = document.getElementById('labels-container');
  if (!labelsContainer) return;

  Array.from(labelsContainer.children).forEach((label) => {
    const geometry = JSON.parse(label.getAttribute('data-geometry'));
    const geoJsonFeature = { type: 'Feature', geometry };
    const position = centroid(geoJsonFeature).geometry.coordinates;

    const pixelPos = map.project(position);
    label.style.left = `${pixelPos.x}px`;
    label.style.top = `${pixelPos.y}px`;
  });
}

function highlightBlockByMapClick(map, e, setIdForTableHighlight) {
  removePossibleOldHighlighting(map);

  const feature = map.queryRenderedFeatures(e.point, {
    layers: [layerBlocks],
  })[0];

  if (feature) {
    highlightLatestFeature(map, feature.id);
    setIdForTableHighlight(feature.id);
  }
}

export function highlightBlockById(map, blockId) {
  removePossibleOldHighlighting(map);
  highlightLatestFeature(map, parseInt(blockId));
}

function removePossibleOldHighlighting(map) {
  if (featureId) {
    map.removeFeatureState({
      source: layerBlocks,
      sourceLayer: sourceLayerBlocks,
      id: featureId,
    });
    featureId = null;
  }
}

function highlightLatestFeature(map, id) {
  featureId = id;
  map.setFeatureState(
    { source: layerBlocks, sourceLayer: sourceLayerBlocks, id: featureId },
    { clicked: true },
  );
}

function areFeatureSetsEqual(setA, setB) {
  if (setA.size !== setB.size) return false;
  for (let item of setA) {
    if (!setB.has(item)) return false;
  }
  return true;
}
