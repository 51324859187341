<template>
  <div
    v-if="
      userPermissions?.canRequestRelease &&
      !userPermissions?.canChangeRelease &&
      (releaseStatusLabel === 'Offen' || releaseStatusLabel === 'Abgelehnt')
    "
    class="flex w-full flex-col items-end gap-5"
  >
    <InputEl
      :model-value="userName"
      label="Freigabe anfordern durch"
      :rules="{ required: true }"
      class="w-full"
      disabled
    />
    <DatePicker
      :model-value="requestedDate"
      label="Freigabe anfordern am"
      class="w-full"
      disabled
    />
    <ButtonEl
      text="Freigabe anfordern"
      class="mt-2.5 w-fit"
      @click="requestDialog = true"
    />
    <VuetifyDialog v-model="requestDialog" title="Freigabe anfordern?">
      <template #content>
        <p>
          Nicht gespeicherte Änderungen in den anderen Reitern gehen verloren.
        </p>
        <p>Trotzdem Freigabe anfordern?</p>
      </template>
      <template #actions>
        <ButtonEl
          variant="secondary"
          text="abbrechen"
          @click="requestDialog = false"
        />
        <ButtonEl text="anfordern" @click="requestRelease" />
      </template>
    </VuetifyDialog>
  </div>

  <!-- INPUTS TO PROCESS RELEASE REQUEST -->
  <div
    v-if="
      userPermissions.canChangeRelease && releaseStatusLabel === 'Angefordert'
    "
    class="flex w-full flex-col items-end gap-5"
  >
    <InputEl
      v-model="userName"
      label="Freigabe durch"
      :rules="{ required: true }"
      class="w-full"
      disabled
    />
    <DatePicker
      v-model="requestedDate"
      label="Freigabe am"
      class="w-full"
      disabled
    />
    <TextAreaEl
      v-model="releaseComment"
      label="Kommentar"
      class="w-full"
      text-area-classes="h-20 !bg-active-area"
    />
    <div class="mt-2.5 flex gap-2.5">
      <ButtonEl
        text="Ablehnen"
        class="w-fit"
        color="error"
        variant="secondary"
        icon="close"
        @click="decideOnReleaseRequest(3)"
      />
      <ButtonEl
        text="Genehmigen"
        class="w-fit"
        icon="check"
        @click="decideOnReleaseRequest(2)"
      />
    </div>
  </div>
</template>
<script setup>
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import DatePicker from '@/components/storybook/src/stories/datePicker/DatePicker.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import cookie from 'vue-cookies';
import { axios } from '@/utils/axiosHelper';
import TextAreaEl from '@/components/storybook/src/stories/textAreaEl/TextAreaEl.vue';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';

const props = defineProps({
  userPermissions: {
    type: Object,
  },
  projectData: {
    type: Object,
    default: () => ({}),
  },
  releaseStatusLabel: {
    type: String,
  },
});

const emit = defineEmits(['update:data']);

const store = useStore();

const releaseComment = ref(props.projectData.release_comment || '');
const requestedDate = ref(Date.now());
const requestDialog = ref(false);

const userName = computed(() => {
  const profile = store.getters['auth/profil'];
  return profile.fullName || profile.username;
});

function requestRelease() {
  axios({
    url: `/api/heatprojects/wi-re/wi-re-release_status-update/${props.projectData.id}/`,
    method: 'PATCH',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    data: { release_status: 1 },
  }).then((response) => {
    emit('update:data', response.data);
  });
}

function decideOnReleaseRequest(statusCode) {
  axios({
    url: `/api/heatprojects/wi-re/wi-re-release_status-update/${props.projectData.id}/`,
    method: 'PATCH',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    data: {
      release_status: statusCode,
      release_comment: releaseComment.value,
    },
  }).then((response) => {
    emit('update:data', response.data);
  });
}

watch(releaseComment, (newVal) => {
  emit('update:data', { release_comment: newVal });
});
</script>
