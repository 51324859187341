<template>
  <div
    class="z-100 absolute left-0 right-0 top-0 h-fit min-h-full rounded-[8px] bg-white p-5 pt-[10px]"
  >
    <div class="h-full">
      <div class="mb-5 flex justify-between pt-2">
        <h3 class="text-title-color2">TaskID: {{ taskId }}</h3>
        <IconWrapper
          icon="close"
          class="cursor-pointer"
          @click="store.commit('building/TOGGLE_SHOW_TASK_DETAILS')"
        />
      </div>
      <div class="w-full p-1">
        <div
          v-if="task"
          class="grid grid-cols-2 gap-4 rounded bg-blue-grey-50 p-4"
        >
          <p><strong>Tasktyp:</strong> {{ task.type_name }}</p>
          <p>
            <strong>Erstellt:</strong>
            {{ formatISODateAndTime(task.created_at) }}
          </p>
          <p><strong>Status:</strong> {{ task.state_name }}</p>
          <p>
            <strong>Wartezeit:</strong>
            {{
              subtractISODate(
                task.created_at,
                task.started_at,
                'noch nicht verfügbar',
              )
            }}
          </p>
          <p>
            <strong>Celery task id:</strong>
            {{ task.celery_task_id }}
          </p>
          <p>
            <strong>Rechenzeit:</strong>
            {{
              subtractISODate(
                task.started_at,
                task.finished_at,
                'noch nicht verfügbar',
              )
            }}
          </p>
          <p></p>
          <p v-if="task.finished_at">
            <strong>Beendet:</strong>
            {{ formatISODateAndTime(task.finished_at) }}
          </p>
        </div>
        <div
          v-if="task.description"
          class="description-container mt-2 rounded bg-blue-grey-50 p-4"
        >
          <p><strong>Zusätzliche Information:</strong></p>
          <p>{{ task.description }}</p>
        </div>
        <div v-if="task.exception" class="mb-12 mt-10">
          <h3 class="mb-2">Traceback:</h3>
          <div
            class="traceback-container rounded border border-red-200 bg-red-50 p-4"
          >
            <p
              v-for="(line, index) in formattedTraceback"
              :key="index"
              class="hover:bg-red-200"
            >
              {{ line }}
            </p>
          </div>
        </div>
      </div>
      <ButtonEl
        class="absolute bottom-5 right-5"
        text="Abbrechen"
        @click="store.commit('building/TOGGLE_SHOW_TASK_DETAILS')"
      />
    </div>
  </div>
</template>

<script setup>
import { axios } from '@/utils/axiosHelper';
import { computed, onMounted, ref } from 'vue';
import { formatISODateAndTime, subtractISODate } from '@/utils/formatUtils';
import { useStore } from 'vuex';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';

const store = useStore();

const task = ref({});
const formattedTraceback = ref([]);

const taskId = computed(() => store.getters['building/getTaskId']);

function getItem() {
  return axios({
    url: '/api/managed-tasks/' + taskId.value,
    method: 'GET',
    params: {},
  }).then((response) => {
    task.value = response.data;
    if (task.value.exception) {
      formattedTraceback.value = task.value.exception.split('\n');
    }
  });
}

onMounted(getItem);
</script>

<style scoped lang="scss">
.traceback-container {
  font-family: 'Courier New', monospace;
  white-space: pre-wrap;
}

.description-container {
  white-space: pre-wrap;
}
</style>
