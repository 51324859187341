<template>
  <v-tooltip :text="mapLinkAttributes.tooltipText" location="top">
    <template #activator="{ props }">
      <button
        :class="`${mapLinkAttributes.cursor} ${customClass} map-link-button flex items-center justify-center rounded-md p-1`"
        v-bind="props"
        @click="mapLinkAttributes.action()"
      >
        <IconWrapper
          icon="map"
          type="filled"
          :hover="mapLinkAttributes.hoverColor"
          :fill="mapLinkAttributes.color"
        />
      </button>
    </template>
  </v-tooltip>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { layerPanelConfig } from '@/configs/layer-panel';
import { computed, nextTick } from 'vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';

const props = defineProps({
  // Each item can be either:
  // 1. An object with switchId and layerState properties (Example: { switchId: 'LC_SOLAR_THERMAL_ROOF', layerState: 'power' })
  // 2. An object with just onLayers property to match against layerPanelConfig (Example: { onLayers: ['potential_distribution_network'] })
  layerActivation: {
    type: Array,
    required: true,
  },
  customClass: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const mapLinkAttributes = computed(() => {
  const { disabled } = props;
  return {
    tooltipText: disabled ? 'Keine Daten verfügbar' : 'Auf Karte anzeigen',
    cursor: disabled ? 'cursor-default' : 'cursor-pointer',
    hoverColor: disabled ? 'text-disabled-neutral' : 'text-color1',
    color: disabled ? 'text-disabled-neutral' : 'text-title-color1',
    action: disabled ? () => {} : navigateToMap,
  };
});

const router = useRouter();
const store = useStore();

async function navigateToMap() {
  // Get current dashboard state for accurate return navigation

  // 1. Get the current tab from the route query or the store
  let currentTab = router.currentRoute.value.query.tab;

  // If no tab in route query, try to get active tab from DashboardWrapper's state
  if (!currentTab && store.state.dashboard && store.state.dashboard.activeTab) {
    currentTab = store.state.dashboard.activeTab;
  }

  // Default to 'community-structure' if all else fails
  currentTab = currentTab || 'community-structure';

  // Store return information for the map
  store.commit('map/SET_MAP_DASHBOARD_LINK', {
    returnToDashboard: {
      path: router.currentRoute.value.fullPath,
      tabName: currentTab,
    },
  });

  // Reset any previous map initialization state
  store.commit('map/SET_MAP_INITIALIZED', false);

  // First, navigate to the map view and wait for navigation to complete
  await router.push({ path: '/map' });

  // After navigation, wait for the Mapbox map to be fully initialized
  // Create a promise that resolves when map is initialized
  await new Promise((resolve) => {
    // Check if map is already initialized
    if (store.state.map.mapInitialized) {
      resolve();
      return;
    }

    // Set up a watcher to detect when map becomes initialized
    const unwatch = store.watch(
      (state) => state.map.mapInitialized,
      (isInitialized) => {
        if (isInitialized) {
          unwatch(); // Remove the watcher once we detect initialization
          resolve();
        }
      },
    );
  });

  // Process each layer activation config
  if (props.layerActivation && props.layerActivation.length > 0) {
    // Reset all layer states to ensure we're starting fresh
    store.commit('map/RESET_LAYER_STATES');

    // Process each layer activation config in original order
    props.layerActivation.forEach((config) => {
      // Handle case where config only has onLayers
      if (config.onLayers && !config.switchId) {
        // Find the switch configuration that matches these onLayers
        const switchConfig = findSwitchConfigByOnLayers(config.onLayers);
        if (switchConfig) {
          // Use the found switch configuration
          emitChangeSwitch(switchConfig, true);
          return;
        } else {
          console.warn(
            'No switch found with matching onLayers:',
            config.onLayers,
          );
          return;
        }
      }

      // Handle traditional case with switchId
      const { switchId, layerState } = config;
      if (!switchId) {
        console.warn('Missing switchId in config:', config);
        return;
      }

      // 1. Find the switch configuration in layerPanelConfig
      const switchConfig = findSwitchConfig(switchId);
      if (!switchConfig) {
        console.warn('Switch not found in layerPanelConfig:', switchId);
        return;
      }

      // 2. Find the radio that matches the layerState (before activating the switch)
      let targetRadioIndex = -1;
      let targetRadioItem = null;

      if (
        layerState &&
        switchConfig.radioGroups &&
        switchConfig.radioGroups.length > 0
      ) {
        const radioGroup = switchConfig.radioGroups[0]; // Use the first radio group

        if (radioGroup && radioGroup.radios) {
          targetRadioIndex = radioGroup.radios.findIndex(
            (radio) => radio.layerState === layerState,
          );

          if (targetRadioIndex >= 0) {
            targetRadioItem = radioGroup.radios[targetRadioIndex];
          } else {
            console.warn(
              `No radio found with layerState "${layerState}" for switch "${switchId}"`,
            );
          }
        }
      }

      // 3. Activate the switch (this might reset radio selections if resetRadioOnActivate is true)
      emitChangeSwitch(switchConfig, true);

      // 4. Apply the radio state AFTER switch activation and after UI update cycle
      if (targetRadioIndex >= 0 && targetRadioItem) {
        // Use nextTick to ensure we apply our radio selection after the UI has processed the switch activation
        nextTick(() => {
          // Make sure the radio group is visible
          if (switchConfig.radioGroups && switchConfig.radioGroups[0]) {
            switchConfig.radioGroups[0].visible = true;
          }

          // Apply via store mutation to ensure reactivity
          store.commit('map/SET_RADIO_ACTIVATED', {
            switchId: switchId,
            radioGroupIndex: 0,
            activatedIndex: targetRadioIndex,
          });

          // Apply the layer state directly to ensure it's applied
          if (targetRadioItem.onLayers) {
            store.commit('map/SET_LAYER_STATE', {
              layerKeys: targetRadioItem.onLayers,
              layerState: targetRadioItem.layerState,
            });
          }

          // Apply any filters if present
          if (targetRadioItem.filter && targetRadioItem.layerKeys) {
            store.commit('map/ADD_FILTER', {
              layerKeys: targetRadioItem.layerKeys,
              filter: targetRadioItem.filter,
            });
          }
        });
      }
    });
  }
}

// Replicated from SwitcheryLayer.vue
function emitChangeSwitch(switchItem, active) {
  // First handle turning off other switches if needed
  if (switchItem.turnOffSwitches && active) {
    store.commit('map/CHANGE_SWITCH', {
      switches: switchItem.turnOffSwitches,
      active: false,
    });
    store.commit('map/CACHE_SWITCH', switchItem);
  }

  // Then activate this switch
  store.commit('map/CHANGE_SWITCH', {
    switches: [switchItem.switchId],
    active,
  });

  // And set layer visibility
  if (switchItem.onLayers) {
    store.commit('map/SET_LAYER_VIS', {
      onLayers: switchItem.onLayers,
      active,
      onActivateSetLayout: active && switchItem.onActivateSetLayout,
    });
  }
}

// Helper function to find switch configuration in layerPanelConfig
function findSwitchConfig(switchId) {
  // Traverse the layerPanelConfig to find the switch with the given ID
  for (const section of layerPanelConfig.sections) {
    for (const group of section.groups || []) {
      for (const item of group.items || []) {
        if (item.switchId === switchId) {
          return item;
        }
      }
    }
  }
  return null;
}

// Helper function to find switch configuration by onLayers
function findSwitchConfigByOnLayers(targetOnLayers) {
  // Ensure targetOnLayers is an array and has content
  if (!Array.isArray(targetOnLayers) || targetOnLayers.length === 0) {
    return null;
  }

  // Traverse the layerPanelConfig to find a switch with matching onLayers
  for (const section of layerPanelConfig.sections) {
    for (const group of section.groups || []) {
      for (const item of group.items || []) {
        // Check if the item's onLayers exactly matches the target onLayers
        if (
          Array.isArray(item.onLayers) &&
          item.onLayers.length === targetOnLayers.length &&
          item.onLayers.every((layer) => targetOnLayers.includes(layer))
        ) {
          return item;
        }
      }
    }
  }
  return null;
}
</script>

<style scoped>
.map-link-button {
  transition: all 0.2s ease;
}
</style>
