<template>
  <div class="flex w-full items-center justify-between gap-1 p-3 pt-0">
    <div class="flex flex-col gap-[1px]">
      <InfoEntries
        v-for="subtitle in subtitles"
        :key="subtitle.index"
        :subtitle="subtitle"
      />
    </div>
    <MoreOptions v-if="feature.source === 'heatSourceInventory'">
      <div
        class="standard-elevation-1 absolute right-0 top-0 z-10 flex flex-col overflow-hidden whitespace-nowrap rounded-[4px] border-[1px] border-blue-grey-900 bg-white hover:bg-infra-highlight-25"
      >
        <div
          class="body-3 flex cursor-pointer flex-col items-start justify-between"
        >
          <div class="info-panel-more-options">
            <span @click="emit('open-dialog')">löschen</span>
          </div>
        </div>
      </div>
    </MoreOptions>
  </div>
</template>

<script setup>
import InfoEntries from '@/features/map/info-panel/InfoEntries.vue';
import MoreOptions from '@/components/storybook/src/stories/moreOptions/MoreOptions.vue';

const props = defineProps({ subtitles: Array, feature: Object });
const emit = defineEmits(['open-dialog']);
</script>
