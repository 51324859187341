<template>
  <div
    class="flex flex-col"
    :class="{
      'pointer-events-none': disabled,
    }">
    <the-label :label="label" />
    <VueDatePicker
      v-model="date"
      :placeholder="placeholder"
      auto-apply
      :close-on-auto-apply="true"
      :class="{
        small: small,
        [customBackground]: customBackground,
        disabled: disabled,
      }"
      :style="{
        '--custom-bg': customBackground,
      }"
      locale="de"
      format="dd.MM.yyyy"
      :clearable="clearable"
      :enable-time-picker="false"
      :cancel-text="cancelText"
      :select-text="selectText"
      :position="position">
      <template #input-icon>
        <IconWrapper
          icon="calendar_month"
          :fill="disabled ? 'text-disabled-neutral' : 'text-core-color1'"
          type="round"
          :size="small ? 16 : 24"
          custom-inner-icon-classes="absolute" />
      </template>
    </VueDatePicker>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import TheLabel from '../label/TheLabel.vue';
import IconWrapper from '../IconWrapper/IconWrapper.vue';

export default {
  components: { IconWrapper, VueDatePicker, TheLabel },
  props: {
    modelValue: {
      type: [String, Number, Date],
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: 'abbrechen',
    },
    selectText: {
      type: String,
      default: 'auswählen',
    },
    position: {
      type: String,
      default: 'right',
    },
    placeholder: {
      type: String,
      default: null,
    },
    customBackground: {
      type: String,
      default: 'bg-active-area',
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    date: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style lang="scss">
@use '@/assets/styles';
@use '../button/ButtonEl.scss';

.dp__input_icon {
  left: unset;
  right: 0;
}

.dp__action_row {
  justify-content: center;

  .dp__selection_preview {
    display: none;
  }

  .dp__action_buttons {
    margin-left: unset;
    gap: 5px;
  }
}

.dp__calendar_header_item {
  @extend .body-1;
  @apply font-medium;
}

.dp__calendar {
  .dp__today {
    @apply border-button-primary-default-color1;
  }

  .dp__active_date {
    @apply bg-button-primary-default-color1 border-button-primary-default-color1;
  }
}

.dp__overlay_container {
  @extend .body-1;

  .dp__overlay_cell_active {
    @apply bg-button-primary-default-color1 border-button-primary-default-color1;
  }
}

.dp__main {
  border-radius: 6px;

  .dp__input_wrap {
    .dp__pointer {
      height: 40px;
      padding-left: 10px;
      border-radius: 6px;
      background-color: var(--custom-bg);
      @apply border-line-neutral;
      @extend .body-1;
    }

    .dp__clear_icon {
      margin-right: 20px !important;
    }

    .dp__input_icon {
      padding-right: 10px;
    }
  }

  &.disabled {
    .dp__input_wrap {
      .dp__pointer {
        @apply bg-subtle text-disabled-neutral border-0;
      }

      .dp__input_icon {
        @apply text-disabled-neutral;
      }
    }
  }

  &.small {
    .dp__input_wrap {
      .dp__pointer {
        height: 32px;
        padding-left: 8px;
        @extend .body-2;
      }

      .dp__clear_icon {
        margin-right: 16px !important;
      }
    }

    .dp__input_icon {
      padding-right: 8px;
    }
  }
}

.dp__menu {
  border-radius: 6px;
}

.dp__menu_inner {
  @extend .body-1;
}
</style>
