<template>
  <div class="flex h-full flex-col justify-center rounded-lg bg-default">
    <h5 class="flex flex-col items-center text-neutral">
      Gesamtreduzierung zum Startjahr
    </h5>
    <div class="mt-4 flex justify-around">
      <h4 class="text-neutral">
        {{ formattedTotalReduction }}
      </h4>
      <h4 class="text-neutral">{{ formattedPercentReduction }}</h4>
    </div>
  </div>
</template>
<script setup>
import { computed, watch } from 'vue';

const props = defineProps({
  chartData: {
    type: Array,
    default: () => [],
  },
  chartUnit: {
    type: String,
    default: 'GWh',
  },
  baseValue: {
    type: Array,
    default: () => [],
  },
  multiply: {
    type: Number,
    default: 1,
  },
});

const emit = defineEmits(['values']);

const base = computed(() => sumBaseValues(props.baseValue));
const totalReduction = computed(() => sumValues(props.chartData));
const formattedTotalReduction = computed(
  () => formatValue(totalReduction.value) + ' ' + props.chartUnit,
);
const formattedPercentReduction = computed(() => {
  if (base.value === 0) return '0 %';
  const reduction = totalReduction.value / base.value;
  return formatValue(reduction * 100) + ' %';
});

function sumBaseValues(data) {
  return data.reduce((acc, cv) => acc + sumValues(cv.data), 0);
}

function sumValues(data) {
  return data.reduce((acc, cv) => acc + cv.value / props.multiply, 0);
}

function formatValue(value) {
  return value.toLocaleString('de-DE', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  });
}

watch(formattedPercentReduction, (newPercentReduction) => {
  emit('values', {
    percent: newPercentReduction,
    total: formattedTotalReduction.value,
  });
});
</script>
