<template>
  <VDialog v-model="dialog" width="auto">
    <template #activator="{ props }">
      <div
        class="flex items-center justify-center opacity-70 hover:opacity-100"
        v-bind="props"
      >
        <IconWrapper
          icon="delete_forever"
          class="cursor-pointer"
          fill="text-title-color1"
        />
        <VTooltip activator="parent" location="start"
          >Szenario löschen
        </VTooltip>
      </div>
    </template>

    <VCard>
      <div class="flex flex-col p-5">
        <div class="w-full p-5">
          Szenario <b>{{ scenarioItem.name }}</b> wirklich löschen?
        </div>
        <div class="flex items-center justify-between px-5">
          <ButtonEl :primary="true" text="Abbrechen" @click="dialog = false" />
          <ButtonEl :primary="false" text="Löschen" @click="deleteScenario()" />
        </div>
      </div>
    </VCard>
  </VDialog>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';

const props = defineProps({ scenarioItem: Object });
const store = useStore();

const dialog = ref(false);

function deleteScenario() {
  const scenarioId = props.scenarioItem.scenario_id;
  dialog.value = false;
  store.dispatch('scenario/DELETE_SCENARIO', scenarioId);
}
</script>
