<template>
  <AuthFormWrapper
    :title="title"
    :error="newPasswordError"
    error-message="Es ist ein Problem aufgetreten. Bitte versuchen Sie es später erneut."
  >
    <!-- form tag needed for autofill functionality -->
    <FormValidator v-model="isFormValid">
      <InputEl
        v-model="newPassword"
        :rules="{
          required: true,
          length: true,
          uppercase: true,
          number: true,
          specialChar: true,
        }"
        :type="showPassword ? 'text' : 'password'"
        :suffix="showPassword ? EyeCrossedIcon : EyeIcon"
        :suffix-icon="true"
        :suffix-clickable="true"
        label="Neues Passwort"
        class="mt-0.5 w-full"
        @icon-click="showPassword = !showPassword"
        @keyup.enter="confirmPasswordReset"
        @update:model-value="newPasswordError = false"
      />
      <ButtonEl
        class="mt-4"
        :full-width="true"
        text="Neues Passwort festlegen"
        :disabled="!isFormValid || isSubmitting"
        @click="confirmPasswordReset"
      />
    </FormValidator>
  </AuthFormWrapper>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import AuthFormWrapper from '@/apps/auth/AuthFormWrapper.vue';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import EyeCrossedIcon from '@/components/storybook/src/assets/icons/EyeCrossedIcon.vue';
import EyeIcon from '@/components/storybook/src/assets/icons/EyeIcon.vue';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const isSubmitting = ref(false);
const newPassword = ref('');
const isFormValid = ref(false);

// form error
const newPasswordError = ref(false);

// show password field
const showPassword = ref(false);
const status = ref(null);

const title = computed(() => t('reset.title'));

function confirmPasswordReset() {
  if (isFormValid.value) {
    isSubmitting.value = true;
    resetPassword();
  }
}

function resetPassword() {
  const { token, uidb64 } = route.query;
  axios({
    url: '/api/reset-password/',
    data: {
      new_password: newPassword.value,
      token,
      uidb64,
    },
    method: 'POST',
  })
    .then(() => {
      router.push('/login');
    })
    .catch(() => {
      isSubmitting.value = false;
      newPasswordError.value = true;
    });
}
</script>
