const decentralizedKeys = {
  Kohle: true,
  Heizöl: true,
  Biomasse: true,
  Umweltwärme: true,
  Strom: true,
};

const wiredKeys = {
  'Synthetische Gase': true,
  Erdgas: true,
  Wärmenetz: true,
};

export default class EnergyBalanceBySector {
  filterKeys = [];

  demandChartData = [];
  demandSavingsData = [];
  calculatedDemandSavings = null;
  emissionsChartData = [];
  emissionsSavingsData = [];
  calculatedEmissionsSavings = null;

  yAxisBoundsDemand = { min: null, max: null };
  yAxisBoundsEmission = { min: null, max: null };
  chartLegendData = [];

  constructor(rawData) {
    this.rawData = rawData;
  }

  updateRawData(data) {
    this.rawData = data;
  }

  updateData(dataByYear, wired, decentralized) {
    this.filterKeys = this.getFilterKeys(wired, decentralized);

    this.demandChartData = this.getDataBySector(dataByYear, 'demand');
    this.emissionsChartData = this.getDataBySector(dataByYear, 'emissions');
    this.chartLegendData = this.getLegendData();

    this.demandSavingsData = this.getSavingsData(dataByYear, 'demand');
    this.emissionsSavingsData = this.getSavingsData(dataByYear, 'emissions');

    this.yAxisBoundsDemand = this.setYAxisBounds('demand', 0.000001);
    this.yAxisBoundsEmission = this.setYAxisBounds('emissions', 0.001);
  }

  getFilterKeys(wired, decentralized) {
    let keys = { ...decentralizedKeys, ...wiredKeys, Unbekannt: true };
    if (wired && !decentralized) keys = wiredKeys;
    if (!wired && decentralized) keys = decentralizedKeys;
    return keys;
  }

  getDataBySector(data, key) {
    return [
      {
        data: data[`${key}_of_sector_RESIDENTIAL_by_ht`].filter((item) =>
          this.filterByKeys(item),
        ),
        sector: 'Private\nHaushalte',
      },
      {
        data: data[`${key}_of_sector_COMMERCIAL_by_ht`].filter((item) =>
          this.filterByKeys(item),
        ),
        sector: 'Gewerbe,\nHandel,\nDienstl.',
      },
      {
        data: data[`${key}_of_sector_INDUSTRIAL_by_ht`].filter((item) =>
          this.filterByKeys(item),
        ),
        sector: 'Industrie',
      },
      {
        data: data[`${key}_of_sector_PUBLIC_by_ht`].filter((item) =>
          this.filterByKeys(item),
        ),
        sector: 'Öffentliche\nEinrichtungen',
      },
      {
        data: data[`${key}_of_sector_OTHER_by_ht`].filter((item) =>
          this.filterByKeys(item),
        ),
        sector: 'Sonstige',
      },
    ];
  }

  // Todo: Don't create legend items for not existing data (0 everywhere)
  getLegendData() {
    if (!this.demandChartData.length) return [];
    return this.demandChartData[0].data.map((item) => ({
      name: item.name,
      color: item.color,
    }));
  }

  getSavingsData(data, key) {
    return data
      ? data[`${key}_savings_by_ht`].filter((item) => this.filterByKeys(item))
      : null;
  }

  filterByKeys(item) {
    return this.filterKeys[item.name];
  }

  setYAxisBounds(keyModifier, multiplier) {
    const stackTotals = new Set();
    const keys = [
      `${keyModifier}_of_sector_RESIDENTIAL_by_ht`,
      `${keyModifier}_of_sector_COMMERCIAL_by_ht`,
      `${keyModifier}_of_sector_INDUSTRIAL_by_ht`,
      `${keyModifier}_of_sector_PUBLIC_by_ht`,
      `${keyModifier}_of_sector_OTHER_by_ht`,
    ];

    this.rawData.forEach((el) => {
      keys.forEach((key) => {
        const filtered = el[key].filter((item) => this.filterByKeys(item));
        // Calculate the sum for this sector
        const sectorTotal = filtered.reduce(
          (sum, item) => sum + item.value * multiplier,
          0,
        );
        stackTotals.add(sectorTotal);
      });
    });

    const computedMin = 0; // For stacked bar charts, typically start at 0
    const computedMax = Math.max(...stackTotals);

    return {
      min: computedMin,
      max: this.getNiceAxisMax(computedMax),
    };
  }

  getNiceAxisMax(maxValue) {
    const paddingFactor = maxValue < 100 ? 0.1 : 0.02;
    const paddedValue = maxValue * (1 + paddingFactor);

    // Determine the order of magnitude of the original max value:
    const exponent = Math.floor(Math.log10(maxValue));
    const base = Math.pow(10, exponent);

    // List of multipliers to create "nice" numbers.
    const multipliers = [1, 1.2, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10];

    // Find the smallest multiplier * base that is >= paddedValue.
    for (const multiplier of multipliers) {
      if (multiplier * base >= paddedValue) {
        return multiplier * base;
      }
    }

    return 10 * base;
  }
}
