<template>
  <div class="relative left-[-25px] flex w-full p-4">
    <div class="relative top-[-20px] flex w-[100px] items-start justify-start">
      <DoughnutChart
        class="py-0"
        custom-min-height="100px"
        custom-radius="80%"
        :show-label="false"
        :show-title-inside="false"
        :chart-data="tableEntry[dataKey]"
      />
    </div>
    <div class="ml-5 h-full w-full">
      <div
        v-for="legend in tableEntry[dataKey].filter((el) => el.value !== 0)"
        :key="legend.name"
        class="[&:not(:last-child)]:mb-3"
      >
        <div class="flex items-center">
          <div
            class="mr-1 h-3 w-3 flex-shrink-0"
            :style="{ background: legend.color }"
          ></div>
          <p class="caption-1">
            {{ legend.name }}
          </p>
        </div>
        <div class="flex">
          <p class="caption-2">
            {{
              legendData[tableEntry.building_block_id][
                legend.name
              ].absolute.toLocaleString('de-DE', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            }}
          </p>
          <p class="caption-1 ml-2">
            ({{
              legendData[tableEntry.building_block_id][
                legend.name
              ].percent.toLocaleString('de-DE', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            }}%)
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import DoughnutChart from '@/apps/analysis-dashboard/charts/DoughnutChart.vue';
import { computed } from 'vue';

const props = defineProps({
  tableData: Array,
  tableEntry: Object,
  dataKey: {
    type: String,
    default: '',
  },
  multiplier: {
    type: Number,
    default: 1,
  },
});

const legendData = computed(() =>
  props.tableData.reduce((acc, cv) => {
    const items = cv[props.dataKey];
    let total = 0;

    items.forEach((item) => (total += item.value));
    acc[cv.building_block_id] = getFinalSubItem(items, total);
    return acc;
  }, {}),
);

function getFinalSubItem(arr, total) {
  return arr.reduce((innerAcc, innerCv) => {
    innerAcc[innerCv.name] = {
      name: innerCv.name,
      color: innerCv.color,
      absolute: innerCv.value * props.multiplier,
      percent: total ? (innerCv.value / total) * 100 : 0,
    };
    return innerAcc;
  }, {});
}
</script>
