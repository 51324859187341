<template>
  <div class="flex gap-5 pt-1">
    <div class="flex flex-wrap gap-10">
      <div class="w-100 flex flex-row gap-8">
        <div class="flex basis-1/2">
          <div class="w-100 standard-elevation-1 rounded-[6px] p-4">
            <h3 class="mb-3 text-title-color2">EIGENE ANALYSEERGEBNISSE</h3>
            <SkeletonLoaderList v-if="!tilesetsLoaded" :amount-rows="4" />
            <VuetifyDataTable
              v-else-if="tilesetsList.length"
              :headers="tilesetsListHeaders"
              :item-data="tilesetsList"
              :show-expand="false"
              :expand-on-row-click="false"
              data-test="custom-analysis-list"
            >
              <template #name="{ item }">
                <div
                  v-if="!isEqual(editNameDialogItem, item)"
                  class="flex items-center gap-2"
                  @mouseenter="editNameMouseoverItem = item"
                  @mouseleave="editNameMouseoverItem = null"
                >
                  <span data-test="custom-analysis-name">{{ item.name }}</span>
                  <ButtonEl
                    v-if="item === editNameMouseoverItem"
                    size="l"
                    variant="secondary"
                    class="rounded-full"
                    icon-type="sharp"
                    icon="edit"
                    data-test="custom-analysis-edit-name-button"
                    @click="
                      editNameDialogItem = item;
                      editName = item.name;
                    "
                  />
                </div>
                <FormValidator v-else v-model="isEditNameFormValid">
                  <div class="flex items-center gap-2">
                    <InputEl
                      v-model="editName"
                      :rules="{
                        required: true,
                        unique: [existingNames.filter((e) => e !== item.name)],
                        maxLength: 40,
                      }"
                      data-test="custom-analysis-edit-name-input"
                      small
                    />
                    <div class="flex">
                      <ButtonEl
                        size="l"
                        variant="secondary"
                        class="bg-transparent rounded-full"
                        icon="cancel"
                        @click="
                          editName = '';
                          editNameDialogItem = null;
                        "
                      />
                      <ButtonEl
                        :disabled="!isEditNameFormValid"
                        size="l"
                        variant="primary"
                        class="bg-transparent rounded-full"
                        icon="save"
                        data-test="custom-analysis-edit-name-confirm-button"
                        @click="updateTilesetName"
                      />
                    </div>
                  </div>
                </FormValidator>
              </template>
              <template #created_at="{ item }">
                <span>{{
                  new Date(item.created_at).toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })
                }}</span>
              </template>
              <template #status="{ item }">
                <div class="flex justify-center">
                  <VTooltip :text="item.status.description">
                    <template #activator="{ props }">
                      <div v-bind="props">
                        <IconWrapper
                          v-if="item.status.id === 1"
                          fill="orange-icon"
                          icon="clock_loader_20"
                        />
                        <IconWrapper
                          v-else-if="item.status.id === 2"
                          fill="green-icon"
                          icon="check_circle"
                        />
                        <IconWrapper
                          v-else-if="item.status.id === 3"
                          fill="grey-icon"
                          icon="auto_delete"
                        />
                        <IconWrapper
                          v-else-if="item.status.id === 4"
                          fill="red-icon"
                          icon="error"
                        />
                      </div>
                    </template>
                  </VTooltip>
                </div>
              </template>
              <template #functions="{ item }">
                <div class="flex justify-end">
                  <ButtonEl
                    class="rounded-full"
                    variant="secondary"
                    icon="upload"
                    data-test="custom-analysis-update-file"
                    @click="
                      uploadUpdateFileItem = item;
                      uploadUpdateFileDialogOpen = true;
                    "
                  />
                  <ButtonEl
                    class="rounded-full"
                    variant="secondary"
                    icon="delete"
                    data-test="custom-analysis-delete"
                    @click="
                      itemToDelete = item;
                      deleteDialogOpen = true;
                    "
                  />
                </div>
              </template>
            </VuetifyDataTable>
            <span v-else data-test="custom-analysis-list-empty"
              >Es existieren noch keine eigenen Analyseergebnisse. Nutzen Sie
              das Formular auf der rechten Seite, um eigene Ergebnisse
              hinzuzufügen</span
            >
          </div>
        </div>
        <div class="flex basis-1/2">
          <div
            class="w-100 standard-elevation-1 flex flex-col gap-2 rounded-[6px] p-4"
          >
            <h3 class="mb-3 text-title-color2">
              EIGENE ANALYSEERGEBNISSE HOCHLADEN
            </h3>
            <FormValidator v-model="isAddFormValid">
              <TheLabel
                label="Analyseergebnisse als GeoJSON auswählen"
                for-input="fileInput"
              />
              <UploadField
                id="fileInput"
                v-model="file"
                name="fileInput"
                label="Datei auswählen"
                label-color="text-text-neutral"
                background-color="bg-active-area"
                :rules="{
                  required: true,
                  fileExtension: 'geojson',
                }"
                data-test="custom-analysis-add-upload-field"
              />
              <InputEl
                id="nameInput"
                v-model="name"
                background-color="bg-active-area"
                label="Titel der Analyseergebnisse"
                data-test="custom-analysis-add-name-field"
                :rules="{
                  required: true,
                  unique: [existingNames],
                  maxLength: 40,
                }"
              />
              <ButtonEl
                :disabled="!isAddFormValid || !file"
                text="Analyseergebnisse hochladen"
                class="w-100 mt-2"
                data-test="custom-analysis-add-button"
                @click="addTileset"
              />
            </FormValidator>
          </div>
        </div>
      </div>

      <div class="flex basis-full">
        <div class="w-100 standard-elevation-1 rounded-[6px] p-4">
          <h3 class="mb-3 text-title-color2">UPLOAD-ÜBERSICHT</h3>
          <VDataTableServer
            :items="tableData"
            :headers="tableHeaders"
            :items-length="tableData.length"
            :loading="tableLoading"
            :hover="true"
            item-key="id"
            class="h-fit"
            @update:options="getTableData"
          />
        </div>
      </div>
    </div>
  </div>
  <VuetifyDialog
    v-if="itemToDelete"
    v-model="deleteDialogOpen"
    title="Löschvorgang bestätigen"
    @close="itemToDelete = null"
  >
    <template #content>
      <div class="flex flex-col gap-5">
        <p>
          Sind Sie sich sicher, dass Sie {{ itemToDelete.name }} löschen wollen?
        </p>
        <div class="flex gap-3">
          <ButtonEl
            text="Löschen"
            data-test="custom-analysis-confirm-delete-button"
            @click="deleteTileset()"
          />
          <ButtonEl text="Abbrechen" variant="secondary" />
        </div>
      </div>
    </template>
  </VuetifyDialog>
  <VuetifyDialog
    v-if="uploadUpdateFileItem"
    v-model="uploadUpdateFileDialogOpen"
    title="Analyseergebnis aktualisieren"
    @close="uploadUpdateFileItem = null"
  >
    <template #content>
      <div class="flex flex-col gap-5">
        <p>
          Wählen Sie eine Datei zum aktualiseren Ihres Analyseergebnisses mit
          dem Namen {{ uploadUpdateFileItem.name }}.
        </p>
        <FormValidator v-model="isUpdateFileFormValid">
          <TheLabel
            label="Analyseergebnisse als GeoJSON auswählen"
            for-input="fileInput"
          />
          <UploadField
            id="fileInput"
            v-model="uploadUpdateFile"
            name="fileInput"
            label="Datei auswählen"
            label-color="text-text-neutral"
            background-color="bg-active-area"
            data-test="custom-analysis-edit-upload-field"
            :rules="{
              required: true,
              fileExtension: 'geojson',
            }"
          />
          <div class="flex gap-3">
            <ButtonEl
              :disabled="!isUpdateFileFormValid"
              text="Änderungen anwenden"
              data-test="custom-analysis-edit-upload-confirm-button"
              @click="updateTilesetFile()"
            />
            <ButtonEl
              text="Abbrechen"
              variant="secondary"
              @click="
                uploadUpdateFileItem = null;
                uploadUpdateFileDialogOpen = false;
              "
            />
          </div>
        </FormValidator>
      </div>
    </template>
  </VuetifyDialog>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch, computed } from 'vue';
import { axios } from '@/utils/axiosHelper';
import TheLabel from '@/components/storybook/src/stories/label/TheLabel.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import SkeletonLoaderList from '@/components/skeleton-loader/skeletonLoaderList.vue';
import UploadField from '@/components/storybook/src/stories/uploadField/UploadField.vue';
import { formatISODateAndTime } from '@/utils/formatUtils';
import cookie from 'vue-cookies';
import VuetifyDataTable from '@/components/storybook/src/stories/vuetifyDataTable/VuetifyDataTable.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import { isEqual } from 'lodash';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';

const tilesetsLoaded = ref(false);
const tilesetsList = ref([]);
const tilesetsListHeaders = ref([
  {
    title: 'Name',
    key: 'name',
    align: 'start',
  },
  {
    title: 'Erstellt am',
    key: 'created_at',
    width: '140px',
    align: 'left',
  },
  {
    title: 'Status',
    key: 'status',
    width: '100px',
    align: 'center',
    sortable: false,
  },
  {
    title: 'Funktionen',
    key: 'functions',
    width: '100px',
    align: 'end',
    sortable: false,
  },
]);

const deleteDialogOpen = ref(false);
const itemToDelete = ref(null);

const uploadUpdateFileDialogOpen = ref(false);
const uploadUpdateFileItem = ref(null);
const uploadUpdateFile = ref(null);
const isUpdateFileFormValid = ref(false);

const name = ref('');
const file = ref(null);
const isAddFormValid = ref(false);

const editName = ref('');
const editNameMouseoverItem = ref(null);
const editNameDialogItem = ref(null);
const isEditNameFormValid = ref(false);

const tableHeaders = ref([
  { title: 'Tasktyp', key: 'type_name' },
  { title: 'Status', key: 'detailed_state' },
  { title: 'Erzeugt', key: 'created_at' },
]);
const tableOptions = ref({
  itemsPerPage: 10,
  page: 1,
});
const amountTableData = ref(0);
const tableData = ref([]);
const tableLoading = ref(true);

const existingNames = computed(() => {
  return tilesetsList.value.map((e) => e.name);
});

let intervalId;
onMounted(() => {
  intervalId = setInterval(() => {
    getTableData();
  }, 2_000);
  getTilesets();
  getTableData();
});

onUnmounted(() => clearInterval(intervalId));

watch(
  tableData,
  (oldVal, newVal) => {
    if (isEqual(oldVal, newVal)) {
      getTilesets(true);
    }
  },
  { deep: true },
);

function addTileset() {
  const formData = new FormData();
  formData.append('name', name.value);
  formData.append('file', file.value);
  return axios({
    url: '/api/customtilesets/',
    method: 'POST',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    data: formData,
  }).then(() => {
    name.value = '';
    file.value = null;
    getTilesets(true);
  });
}

function getTilesets(skipLoading = false) {
  tilesetsLoaded.value = skipLoading;
  return axios({
    url: '/api/customtilesets/',
    method: 'GET',
  }).then((response) => {
    tilesetsList.value = response.data.results;
    tilesetsLoaded.value = true;
  });
}

function getTableData() {
  return axios({
    url: '/api/custom-analysis-tasks/',
    method: 'GET',
    params: {
      page: tableOptions.value.page,
      'page-size': tableOptions.value.itemsPerPage,
    },
    skipLoading: true,
  }).then((response) => {
    amountTableData.value = response.data.count;
    tableData.value = response.data.results.map((task) => {
      task.created_at = formatISODateAndTime(task.created_at);
      return task;
    });
    tableLoading.value = false;
  });
}

function deleteTileset() {
  return axios({
    url: `/api/customtilesets/${itemToDelete.value.id}/`,
    method: 'DELETE',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
  }).then(() => {
    deleteDialogOpen.value = false;
    itemToDelete.value = null;
    getTilesets(true);
  });
}

function updateTilesetName() {
  if (editName.value === editNameDialogItem.value.name) {
    editNameDialogItem.value = null;
    editName.value = '';
    return;
  }
  const formData = new FormData();
  formData.append('name', editName.value);
  return axios({
    url: `/api/customtilesets/${editNameDialogItem.value.id}/`,
    method: 'PUT',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    data: formData,
  }).then(() => {
    getTilesets(true);
  });
}

function updateTilesetFile() {
  const formData = new FormData();
  formData.append('file', uploadUpdateFile.value);
  return axios({
    url: `/api/customtilesets/${uploadUpdateFileItem.value.id}/`,
    method: 'PUT',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    data: formData,
  }).then(() => {
    uploadUpdateFileDialogOpen.value = false;
    uploadUpdateFileItem.value = null;
    uploadUpdateFile.value = null;
    getTilesets(true);
  });
}
</script>

<style lang="scss">
.orange-icon {
  color: #f59e0b;
}

.green-icon {
  color: #65a30d;
}

.grey-icon {
  color: #6b7280;
}

.red-icon {
  color: #dc2626;
}
</style>
