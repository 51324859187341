import cookie from 'vue-cookies';
import { axios } from '@/utils/axiosHelper';
import { getMarked } from '@/mapbox/onClick/constructorClick';
import { getMap } from '@/mapbox/main';
import { LAYER_KEY__BUILDINGS } from '@/configs/layers/buildings';
import { GeoJsonLineString, GeoJsonPoint } from '@/features/draw/utilities';
import { LAYERS } from '@/features/heat-project/constants';

export class connectBuildingInterface {
  networkFeatures = [];
  pointFeatures = [];
  estateBuildingMapper = {};
  buildingByConnectionRate = {};
  buildingIds = new Set();
  heatLoad = 0; // [MW]
  heatDemand = 0; //  [MWh]

  constructor(scenario, year) {
    this.scenario = scenario;
    this.year = year;
  }

  initData(estateConnections, pipesDiameters, projectId) {
    // set building network and point features
    for (const estate of estateConnections) {
      this.estateBuildingMapper[estate.id] = estate.building_aggregate;
      const isConnected = estate.is_connected;
      if (isConnected) {
        for (const building of estate.building_aggregate) {
          this.buildingIds.add(building);
          // push buildings with connection rate in order to only colorize
          // connected buildings when connection slider is being used
          this.buildingByConnectionRate[building] =
            estate.selected_at_connection_rate;
        }
        const inner_diameter = pipesDiameters.find(
          (e) => e.id === estate.pipe_determined,
        ).inner_diameter;
        this.networkFeatures.push(
          GeoJsonLineString(
            estate.line_string.coordinates,
            {
              id: projectId,
              estate_id: estate.id,
              type: LAYERS.HOME_CONNECTION,
              pipe_determined: estate.pipe_determined,
              pipe_edited: estate.pipe_edited,
              inner_diameter,
              connectionRate: estate.selected_at_connection_rate,
            },
            estate.id,
          ),
        );
      }
      this.pointFeatures.push(
        GeoJsonPoint(
          estate.point.coordinates,
          {
            id: projectId,
            estate_id: estate.id,
            isConnected,
            type: LAYERS.HOME_CONNECTION_POINT,
            connectionRate: estate.selected_at_connection_rate,
          },
          estate.id,
        ),
      );
    }
  }

  get getBuildingIds() {
    return Array.from(this.buildingIds);
  }

  get municipalitySelected() {
    const municipality = localStorage.getItem('municipality');
    if (municipality && municipality !== 'all') return municipality;
    return null;
  }

  buildingCountByConnectionRate(connectionRate) {
    let count = 0;
    for (const key in this.buildingByConnectionRate) {
      if (this.buildingByConnectionRate[key] <= connectionRate) {
        count++;
      }
    }
    return count;
  }

  get isValid() {
    return this.heatLoad > 0;
  }

  fetchHeatDemand(minimumBuildingArea = 0) {
    const buildings = this.getBuildingIds;
    if (buildings.length > 0) {
      axios({
        url: `/api/scenarios/${this.scenario}/buildings/${this.year}/aggregate`,
        method: 'POST',
        headers: { 'X-CSRFToken': cookie.get('csrftoken') },
        data: {
          building_ids: buildings,
          minimum_area: minimumBuildingArea,
        },
      }).then((resp) => {
        this.heatLoad = resp.data.heat_demand_kw / 1000;
        this.heatDemand = resp.data.heat_demand_kwh / 1000;
      });
    }
  }

  /**
   * Retrieve polygon from draw object, add building IDs to heat project
   * where building is in polygon and remove polygon from draw object
   */
  getBuildingsWithin(feature, minimumBuildingArea = 0) {
    axios({
      url: '/api/buildingmodel/buildings-within/',
      method: 'POST',
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      data: {
        polygon: feature.geometry,
        minimum_area: minimumBuildingArea,
        scenario: this.scenario,
        municipality: this.municipalitySelected,
      },
    }).then((resp) => {
      const alkisIds = new Set(resp.data.alkis_ids);
      alkisIds.forEach((e) => this.buildingIds.add(e));
      const features = getMap().queryRenderedFeatures({
        layers: [LAYER_KEY__BUILDINGS],
      });
      const intersection = features.filter((f) =>
        alkisIds.has(f.properties.alkis_id),
      );
      getMarked().setFeatureState(intersection, true);
      this.fetchHeatDemand(minimumBuildingArea);
    });
  }

  /**
   * Filter all selected buildings by greater than minimum area
   */
  getBuildingsWithMinimumArea(minimumBuildingArea = 0) {
    axios({
      url: '/api/buildingmodel/buildings-with-minimum-area/',
      method: 'POST',
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      data: {
        alkis_ids: this.getBuildingIds,
        minimum_area: minimumBuildingArea,
        scenario: this.scenario,
        municipality: this.municipalitySelected,
      },
    }).then((resp) => {
      getMarked().resetFeatureState();
      const alkisIds = new Set(resp.data.alkis_ids);
      this.buildingIds = alkisIds;
      const features = getMap().queryRenderedFeatures({
        layers: [LAYER_KEY__BUILDINGS],
      });
      const intersection = features.filter((f) =>
        alkisIds.has(f.properties.alkis_id),
      );
      getMarked().setFeatureState(intersection, true);
      this.fetchHeatDemand(minimumBuildingArea);
    });
  }
  /**
   * is executed when draw create event is triggered in order to synchronise
   * heat source objects with editing on map
   */
  connectViaDraw(feature) {
    const buildingIds = this.estateBuildingMapper[feature.properties.estate_id];
    for (const building of buildingIds) {
      this.buildingIds.add(building);
    }
  }

  /**
   * is executed when draw delete event is triggered in order to synchronise
   * heat project object with editing on map
   */
  disconnectViaDraw(feature) {
    const buildingIds = this.estateBuildingMapper[feature.properties.estate_id];
    for (const building of buildingIds) {
      this.buildingIds.delete(building);
    }
  }
  /**
   * add building ID to project or remove if already present
   * @param  {String} Bid
   */
  addBuilding(Bid) {
    if (this.buildingIds.has(Bid)) this.buildingIds.delete(Bid);
    else this.buildingIds.add(Bid);
    this.fetchHeatDemand();
  }

  /**
   * Mark buildings on map and fetch heat demand.
   * Is called in project update process
   */
  markAndFetchBuildings() {
    const buildingIds = this.getBuildingIds;
    const features = getMap().queryRenderedFeatures({
      layers: [LAYER_KEY__BUILDINGS],
    });
    features.forEach((e) => {
      if (buildingIds.includes(e.properties.alkis_id)) {
        getMarked().setFeatureState([e], true);
      }
    });
    this.fetchHeatDemand();
  }

  /**
   * reset buildings, heaload and marked features on map
   */
  resetBuildings() {
    this.heatLoad = 0;
    this.buildingIds = new Set();
    getMarked().resetFeatureState();
  }

  /**
   * init eventhooks for map interaction to select buildings.
   */
  initEventHooks() {
    // onContextBuilding needs to be reassigned
    // with bound context in order to take the event hook off
    this.onContextBuilding = this.onContextBuilding.bind(this);
    getMap().on('contextmenu', this.onContextBuilding);
  }

  /**
   * remove eventhooks for map interaction
   */
  removeEventHooks() {
    getMap().off('contextmenu', this.onContextBuilding);
  }

  /**
   * function bound to right-click event.
   * Queries building layer and adds or removes features from building ids.
   * @param  {Object} e
   */
  onContextBuilding(e) {
    const features = getMap().queryRenderedFeatures(e.point, {
      layers: [LAYER_KEY__BUILDINGS],
    });
    if (features.length > 0) {
      const feature = features[0];
      this.addBuilding(feature.properties.alkis_id);
      getMarked().reverseFeatureState(feature);
    }
  }

  /**
   * construct PUT payload for heat project calc API.
   */
  constructUpdatePayload(feature, homeConnections) {
    const estate_id = (f) => f.properties.estate_id;
    const connection = homeConnections.find(
      (e) => estate_id(e) === estate_id(feature),
    );

    const estate = {
      point: feature.geometry,
      id: estate_id(feature),
      is_connected: Boolean(connection),
    };

    if (estate.is_connected) {
      estate.line_string = connection.geometry;
      estate.pipe_edited = connection.properties.pipe_edited;
    }
    return estate;
  }
}
