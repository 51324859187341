<template>
  <v-dialog width="auto" v-model="showDialog">
    <v-card
      max-width="400"
      prepend-icon="mdi-update"
      text="Wollen Sie wirklich die den zweiten Faktor für die Authentifizierung entfernen? Sie werden anschließend nur noch nach Ihrem Passwort gefragt."
      title="2FA entfernen"
    >
      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          text="Abbrechen"
          @click="showDialog = false"
        ></v-btn>
        <v-btn class="ms-auto" text="Entfernen" @click="disableTwofa"></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup>
import axios from 'axios';
import cookie from 'vue-cookies';

const showDialog = defineModel();

async function disableTwofa() {
  await axios({
    method: 'DELETE',
    headers: { 'X-CSRFToken': cookie.get('csrftoken') },
    url: '/api/totp-device/',
  });
  showDialog.value = false;
}
</script>

<style scoped></style>
