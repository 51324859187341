<template>
  <div
    class="oew_subnavi side-bar-custom hide-scrollbar nav-drawer standard-elevation-1 absolute left-5 top-[80px] z-10 h-fit w-[360px] rounded-[8px] bg-white p-0 pt-2.5"
    :class="{ 'overflow-y-auto': !showProjectMenu }"
    :style="{
      maxHeight: containerHeight + 'px',
    }"
  >
    <h4 class="px-3 text-infra-highlight-500">
      {{ showProjectMenu ? 'Projektgebiete' : 'Kartenansicht' }}
    </h4>
    <div
      v-for="(section, index) in allowedLayerPanelConfig.sections"
      v-show="section.show"
      :key="index"
      class="mt-5 flex-col font-medium"
      :class="{ flex: section.show }"
    >
      <h5
        v-if="section.title && section.id !== 'heatProject'"
        class="mb-2 px-3 text-infra-highlight-800"
      >
        {{ section.title }}
      </h5>

      <LayerPanelItem
        v-for="(group, groupIndex) in section.groups"
        v-show="group.items.length > 0"
        :key="groupIndex"
        :menu-item="group"
        class="nav-menu-item"
        :open="open"
        :data-test="`item-section-${group.title}`"
        @emit-activator="setOpenedActivator($event)"
      >
        <template v-if="group.items">
          <LayerPanelItem
            v-for="(item, itemIndex) in group.items"
            :key="itemIndex"
            :data-test="`item-group-${item.title}`"
            :menu-item="item"
            subgroup
            small
          >
            <template v-if="item.items">
              <LayerPanelItem
                v-for="(level3Item, level3Index) in item.items"
                :key="level3Index"
                :menu-item="level3Item"
                small
              />
            </template>
          </LayerPanelItem>
        </template>
      </LayerPanelItem>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import LayerPanelItem from '@/features/map/layer-panel/LayerPanelItem.vue';
import { computed, ref, watch, toRefs } from 'vue';
import { useModuleStore } from '@/configs/module-store';
import { useMapStore } from '@/features/map/store/map-store';

const { showProjectMenu } = toRefs(useMapStore());

const open = ref(null);

const moduleStore = useModuleStore();

function setOpenedActivator(title) {
  open.value = open.value === title ? null : title;
}

const store = useStore();

const layerPanelConfig = computed(() => {
  return store.state.map.layerPanelConfig;
});

const containerHeight = computed(() => store.state.map.containerHeight);

const allowedLayerPanelConfig = computed(() => {
  if (!layerPanelConfig.value) return { sections: [] };

  return {
    sections: filterSections(layerPanelConfig.value.sections),
  };
});

function filterSections(sections) {
  return getAllowed(sections).map((section) => ({
    ...section,
    show: section.show,
    groups: filterGroups(section.groups),
  }));
}

function filterGroups(groups) {
  return getAllowed(groups).map((group) => ({
    ...group,
    items: filterItems(group.items),
  }));
}

function filterItems(items) {
  return getAllowed(items).map((item) => ({
    ...item,
    radioGroups: item.radioGroups != null ? item.radioGroups : undefined,
  }));
}

function getAllowed(items) {
  return items.filter((item) => moduleStore.isActive(item.module));
}

watch(
  () => showProjectMenu.value,
  (val) => {
    store.commit('map/SET_LAYER_PANEL_SECTIONS_VISIBILITY', val);
  },
);
</script>
