<template>
  <div
    style="width: 366px"
    class="dataPanelExpandablesWrapper flex justify-center self-center"
  >
    <div class="h-full max-w-sm flex-col" style="width: 366px">
      <VExpansionPanels v-model="activeExpandableIndex" variant="accordion">
        <VExpansionPanel
          v-for="(expandable, index) in panelData.chips[activeChipIndex].items"
          :key="index"
          data-test="info-panel-expansion-panel"
          :class="
            panelData.enableDelete
              ? 'last:rounded-b-none'
              : 'last:rounded-b-[8px]'
          "
        >
          <VExpansionPanelTitle
            class="body-2 rounded-none pl-[11px] pr-[6px]"
            :data-test="expandable.title"
          >
            {{ expandable.title }}
          </VExpansionPanelTitle>
          <VExpansionPanelText class="bg-infra-highlight-25">
            <!-- text content start-->
            <template
              v-if="expandable.items.filter((e) => !e.panelItemType).length > 0"
            >
              <div
                v-for="item in expandable.items.filter((e) => !e.panelItemType)"
                :key="item.title"
                :class="[
                  'box-shadow-custom relative flex cursor-default items-center text-xs font-medium text-black hover:bg-white',
                  item.class === 'powerplant' ? 'px-2' : 'px-3',
                ]"
              >
                <InfoPanelItem
                  v-if="typeof item.type !== 'undefined'"
                  :item="item"
                />
              </div>
            </template>
            <!-- text content end-->

            <!-- charts start-->
            <template
              v-if="expandable.items.filter((e) => e.panelItemType).length > 0"
            >
              <VCarousel class="carousel-custom" height="fit-content">
                <VCarouselItem
                  v-for="item in expandable.items.filter(
                    (e) => e.panelItemType,
                  )"
                  :key="item.title"
                >
                  <DoughnutChart
                    v-if="item.panelItemType === 'pieChart'"
                    class="h-72 w-full"
                    :title="item.title"
                    :chart-data="chartData(item)"
                    :title-inside="item.titleInside"
                    :chart-unit="item.unit"
                    :title-custom-size="13"
                  />
                </VCarouselItem>
              </VCarousel>
              <template
                v-for="item in expandable.items.filter((e) => e.panelItemType)"
                :key="item.title"
              >
                <div
                  v-if="item.panelItemType === 'heatProjectChart'"
                  class="flex w-full justify-center"
                >
                  <WaterfallChart
                    :data="item.barChartData"
                    :width="366"
                    :labels="['Leistung', 'Last', 'Delta']"
                  />
                </div>
                <div
                  v-else-if="item.panelItemType === 'heatProjectDoughnutChart'"
                  class="flex w-full justify-center"
                >
                  <DoughnutChartNetLengths :chart-data="item.chartData" />
                </div>
              </template>
            </template>
            <!-- charts end-->
          </VExpansionPanelText>
        </VExpansionPanel>
      </VExpansionPanels>
      <div v-if="hasWireButton()" class="p-3">
        <ButtonEl
          text="Wirtschaftlichkeitsrechner"
          variant="secondary"
          :icon-right="true"
          icon="open_in_new"
          class="w-full"
          @click="$router.push({ name: 'EconomicEfficiency' })"
        />
      </div>
      <div v-if="panelData.enableEdit || panelData.enableDelete">
        <div
          class="flex w-full gap-2.5 p-3"
          :class="{
            'pt-0': hasWireButton(),
          }"
        >
          <ButtonEl
            v-if="panelData.enableEdit"
            class="w-[100%]"
            text="bearbeiten"
            @click="edit()"
          />
          <ButtonEl
            v-if="panelData.enableDelete"
            color="error"
            class="w-[100%]"
            variant="secondary"
            text="löschen"
            @click="deleteDialog = true"
          />
        </div>
        <VuetifyDialog
          v-model="deleteDialog"
          :title="`Möchten sie das Objekt wirklich löschen?`"
        >
          <template #content>
            <p v-if="panelData.customDeleteWarning">
              {{ panelData.customDeleteWarning }}
            </p>
            <p>Dieser Vorgang kann nicht rückgängig gemacht werden.</p>
          </template>
          <template #actions>
            <ButtonEl
              variant="secondary"
              text="Abbrechen"
              @click="deleteDialog = false"
            />
            <ButtonEl
              text="Löschen"
              color="error"
              variant="secondary"
              @click="deleteObject()"
            />
          </template>
        </VuetifyDialog>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useModuleStore } from '@/configs/module-store';
import { infraModules } from '@/configs/infra-modules';
import InfoPanelItem from '@/features/map/info-panel/InfoPanelItem.vue';
import DoughnutChart from '@/apps/analysis-dashboard/charts/DoughnutChart.vue';
import WaterfallChart from '@/components/storybook/src/stories/charts/WaterfallChart/WaterfallChart.vue';
import DoughnutChartNetLengths from '@/features/heat-project/charts/DoughnutChartNetLengths.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import { useMapStore } from '@/features/map/store/map-store';

const { showMainMenu } = toRefs(useMapStore());
const moduleStore = useModuleStore();

const store = useStore();

const activeExpandableIndex = ref(0);
const deleteDialog = ref(false);
const emit = defineEmits(['close-info-panel']);
const props = defineProps({
  panelData: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  activeChipIndex: {
    type: Number,
  },
  feature: {
    type: Object,
  },
});

function hasWireButton() {
  return (
    moduleStore.isActive(infraModules.wire) &&
    props.feature.source === 'heatProjectCentroid'
  );
}

watch(
  () => props.activeChipIndex,
  () => {
    activeExpandableIndex.value = 0;
  },
);

watch(
  () => props.panelData,
  (newPanelData, oldPanelData) => {
    if (newPanelData.name !== oldPanelData.name) {
      activeExpandableIndex.value = 0;
    }
  },
);

function edit() {
  props.panelData.customEditFunction(store);
  showMainMenu.value = false;
}

function deleteObject() {
  props.panelData.customDeleteFunction(store);
  deleteDialog.value = false;
  emit('close-info-panel');
}

function chartData(item) {
  const data = [];
  if (!item.value) return data;
  item.value.forEach((e) => {
    data.push({
      color: e.color,
      name: e.name,
      value: item.multiply ? e.value * item.multiply : e.value,
    });
  });
  return data;
}
</script>

<style lang="scss">
.dataPanelExpandablesWrapper {
  border-radius: 0 0 8px 8px;

  .v-input__details {
    display: none;
  }

  .box-shadow-custom {
    &:last-child {
      margin-bottom: -2px;
    }
  }

  .v-field__append-inner {
    padding-top: 0;
  }

  .v-expansion-panel-text__wrapper {
    @apply p-0;
  }

  .v-expansion-panel-title {
    @apply px-4 py-2;
    min-height: 50px !important;
  }

  .v-expansion-panel-title__overlay {
    opacity: 0;
  }

  .v-expansion-panel-title {
    min-height: 44px !important;
  }

  .v-expansion-panel-title--active {
    background-color: white;
  }

  .v-expansion-panel-title__icon {
    .mdi-chevron-up {
      background-image: url('@/assets/svg/minus-blue.svg');
      background-repeat: no-repeat;
      //background-size: 16px;
      background-position: center center;
    }

    .mdi-chevron-up:before {
      display: none;
    }

    .mdi-chevron-down {
      background-image: url('@/assets/svg/plus-blue.svg');
      background-repeat: no-repeat;
      //background-size: 10px;
      background-position: center center;
    }

    .mdi-chevron-down:before {
      display: none;
    }
  }

  .carousel-custom {
    .v-window__container {
      height: unset !important;
      overflow: hidden;

      .v-responsive {
        display: flex !important;
      }
    }

    .v-window__controls {
      .v-window__left,
      .v-window__right {
        margin-top: auto;
        z-index: 10;
        box-shadow: none;
        width: 40px;
        height: 55px;

        .v-btn__content {
          background: white;
          border-radius: 6px;
          height: 24px;
          width: 24px;

          &:hover {
            @apply bg-infra-highlight-100;
          }
        }
      }
    }

    .v-btn__content {
      .mdi-circle {
        border-radius: 50%;
        background-color: #0692bc;
        opacity: 30%;
        font-size: 12px;

        &:before {
          display: none;
        }
      }

      .mdi-chevron-left:before,
      .mdi-chevron-right:before {
        display: none;
      }

      .mdi-chevron-left {
        background-image: url('@/assets/svg/arrow-left-blue.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: center center;
      }

      .mdi-chevron-right {
        background-image: url('@/assets/svg/arrow-right-blue.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: center center;
      }
    }

    .v-ripple__container {
      display: none;
    }

    .v-btn--active > .v-btn__overlay,
    .v-btn[aria-haspopup='menu'][aria-expanded='true'] > .v-btn__overlay {
      opacity: 0;
    }

    .v-carousel__controls {
      background: transparent;
      height: 55px;
      left: 50% !important;
      transform: translateX(-50%) !important;
      gap: 2px;

      .v-btn {
        width: 16px;
        height: 16px;

        &.v-btn--active {
          .mdi-circle {
            opacity: 100%;
          }
        }
      }
    }
  }
}
</style>
