<template>
  <div v-if="dataByYear" class="flex flex-col justify-center gap-4">
    <DashboardEnergyBalanceBySector
      :data
      :data-by-year="dataByYear"
      :scenario-id="scenarioId"
    />
    <div class="by-buildings-container gap-5 rounded-xl bg-subtle px-10 pb-20">
      <div class="section-title col-span-3">
        <div class="flex items-center gap-1">
          <h4 class="text-title-color2">Versorgte Gebäude</h4>
          <MapLink
            :layer-activation="[
              {
                switchId: 'LC_BUILDINGS',
                layerState: 'heating_technology',
              },
              {
                switchId: 'LC_BLOCK',
                layerState: 'heating_technology',
              },
            ]"
          />
        </div>
      </div>
      <DoughnutChart
        title="nach Energieträger"
        class="mt-3 h-[350px] rounded-lg bg-default"
        download-title="Gebäude nach Energieträger"
        download-file-name="gebaeude_nach_energietraeger"
        :chart-data="energyBalance.chartBuildingsByHCData"
        :custom-title-inside="energyBalance.chartBuildingsByHCTitleInside"
        :title-inside-l-h="16"
        title-top="75"
      />
      <BarChart
        title="nach Wärmeerzeuger"
        class="mt-3 h-[350px] rounded-lg bg-default"
        download-title="Gebäude nach Wärmeerzeuger"
        download-file-name="gebaeude_nach_waermeerzeuger"
        :chart-data="energyBalance.chartBuildingsByHTData"
        y-axis-label-width="150"
        :custom-min-height="38"
      />
      <BarChart
        title="Änderung Wärmeerzeuger zum Startjahr"
        class="mt-3 h-[350px] rounded-lg bg-default"
        download-title="Gebäude: Änderung Wärmeerzeuger zum Startjahr"
        download-file-name="gebaeude_nach_waerme_erzeuger_startjahr"
        :chart-data="energyBalance.chartBuildingsSavingByHTData"
        y-axis-label-width="150"
        :custom-min-height="38"
      />
    </div>
    <div class="by-energy-container rounded-xl bg-subtle px-10 pb-20">
      <div class="section-title col-span-2">
        <div class="flex items-center gap-1">
          <h4 class="text-title-color2">Endenergieverbrauch</h4>
          <MapLink
            :layer-activation="[
              {
                switchId: 'LC_BUILDINGS',
                layerState: 'heating_technology',
              },
              {
                switchId: 'LC_BLOCK',
                layerState: 'heat_demand',
              },
            ]"
          />
        </div>
      </div>
      <div class="col-span-2 flex justify-self-center pt-5">
        <CheckboxEl
          v-model="wired"
          class="mr-5"
          @update:model-value="
            wired = preventCheckboxesUnSelected($event, decentralized)
          "
        >
          <div class="caption-1">Gas- und Wärmenetz</div>
        </CheckboxEl>
        <CheckboxEl
          v-model="decentralized"
          class="mr-5"
          @update:model-value="
            decentralized = preventCheckboxesUnSelected($event, wired)
          "
        >
          <div class="caption-1">Dezentral</div>
        </CheckboxEl>
        <SwitchItem
          v-model="switchValue"
          label="Anteile Erneuerbare Energie und unvermeidbare Abwärme"
        />
      </div>
      <DoughnutChart
        title="nach Energieträger"
        class="col-start-1 col-end-2 h-[350px] rounded-lg bg-default"
        download-file-name="endenergieverbrauch-nach-energietraeger"
        download-title="Endenergieverbrauch nach Energieträger"
        :chart-data="energyBalance.chartDemandPercentData.map(getStyles)"
        :title-inside="energyBalance.chartDemandPercentTitleInside"
        :title-inside-l-h="20"
        title-top="85"
        chart-unit="GWh"
        :multiply="0.000001"
      />
      <BarChartStacked
        title="nach Wärmeerzeuger in GWh"
        class="col-start-2 col-end-3 h-[350px] rounded-lg bg-default"
        download-file-name="endenergieverbrauch_nach_waermeerzeuger"
        download-title="Endenergieverbrauch nach Wärmeerzeuger in GWh"
        :show-legend="false"
        :show-x-axis-label="false"
        :chart-data="energyBalance.chartDemandAbsoluteData"
        :multiply="0.000001"
        :show-stack-label="true"
        :custom-min-height="38"
        y-axis-label-width="250"
        :grid="{
          left: 'center',
          top: '0px',
          bottom: '0px',
          containLabel: true,
          width: '70%',
        }"
      />
    </div>
    <DashboardEnergyBalanceByBlock
      :districts
      :selected-municipality="selectedMunicipality"
      :selected-district="selectedDistrict"
      :scenario-id="scenarioId"
      :year="year"
    />
  </div>
</template>
<script setup>
import { reactive, ref, watch } from 'vue';
import DoughnutChart from '@/apps/analysis-dashboard/charts/DoughnutChart.vue';
import BarChart from '@/apps/analysis-dashboard/charts/barChart.vue';
import DashboardEnergyBalanceBySector from '@/apps/analysis-dashboard/components/DashboardEnergyBalance/DashboardEnergyBalanceBySector.vue';
import CheckboxEl from '@/components/storybook/src/stories/CheckboxEl/CheckboxEl.vue';
import SwitchItem from '@/components/storybook/src/stories/SwitchItem/SwitchItem.vue';
import { preventCheckboxesUnSelected } from '@/apps/analysis-dashboard/utils';
import BarChartStacked from '@/apps/analysis-dashboard/charts/barChartStacked.vue';
import DashboardEnergyBalanceByBlock from '@/apps/analysis-dashboard/components/DashboardEnergyBalance/DashboardEnergyBalanceByBlock.vue';
import EnergyBalance from '@/apps/analysis-dashboard/classes/EnergyBalance';
import MapLink from '@/apps/analysis-dashboard/components/MapLink.vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  dataByYear: {
    type: Object,
    default: () => {},
  },
  scenarioId: Number,
  districts: Array,
  selectedMunicipality: String,
  selectedDistrict: Number,
  year: Number,
});

const wired = ref(true);
const decentralized = ref(true);
const switchValue = ref(false);

const energyBalance = reactive(new EnergyBalance());

watch(
  [() => props.dataByYear, switchValue, wired, decentralized],
  ([newData, newSwitch, newWired, newDecentralized]) => {
    energyBalance.updateData(newData, newSwitch, newWired, newDecentralized);
  },
  { immediate: true },
);

function getStyles(item) {
  if (!item.name.includes('Anteil')) return item;
  return {
    ...item,
    pattern: true,
    itemStyle: {
      borderColor: '#7C4DFF',
      borderWidth: 3,
    },
  };
}
</script>

<style lang="scss" scoped>
.by-buildings-container {
  display: grid;
  grid-template: 100px 60px 290px / 1fr 1fr 1fr;
}

.by-energy-container {
  display: grid;
  grid-template: 100px 60px 350px / 1fr 1fr;
  gap: 40px;
}

:deep(.section-title) {
  @apply flex items-end justify-center border-b border-line-neutral pb-3 pt-10;
}
</style>
