<template>
  <div class="flex w-[460px] flex-col gap-5">
    <ReleaseInfo
      :project-data
      :release-status-label
      :can-change-release="userPermissions.canChangeRelease"
    />

    <ReleaseRequestInputs
      :project-data
      :user-permissions
      :release-status-label
      @update:data="updateLocalWiReData"
    />
  </div>
</template>
<script setup>
import ReleaseInfo from '@/apps/economic-efficiency/edit-tab-items/freigabe/ReleaseInfo.vue';
import ReleaseRequestInputs from '@/apps/economic-efficiency/edit-tab-items/freigabe/ReleaseRequestInputs.vue';
import { computed } from 'vue';
import { statusMapping } from '@/apps/economic-efficiency/status-data';

const props = defineProps({
  projectData: {
    type: Object,
    default: () => ({}),
  },
  userPermissions: {
    type: Object,
  },
});

const releaseStatusLabel = computed(() => {
  return statusMapping[props.projectData.release_status].label;
});

const emit = defineEmits(['update:data']);

function updateLocalWiReData(data) {
  emit('update:data', data);
}
</script>
