export default class EnergyBalance {
  chartBuildingsByHCData = null;
  chartBuildingsByHCTitleInside = '';

  chartBuildingsByHTData = null;
  chartBuildingsSavingByHTData = null;

  choices = null;
  chartDemandPercentData = null;
  chartDemandPercentTitleInside = null;

  chartDemandAbsoluteData = null;

  updateData(dataByYear, switchValue, wired, decentralized) {
    if (!dataByYear) return;

    this.chartBuildingsByHCData = dataByYear['buildings_by_hc'];
    this.chartBuildingsByHCTitleInside = this.getTitleInside();

    this.chartBuildingsByHTData = dataByYear['buildings_by_ht'];
    this.chartBuildingsSavingByHTData = dataByYear['buildings_savings_by_ht'];

    this.choices = this.getchoices(switchValue, wired, decentralized);
    this.chartDemandPercentData = dataByYear[this.choices.hCDataKey];
    this.chartDemandPercentTitleInside = this.choices.hCTitle;

    this.chartDemandAbsoluteData = this.getDemandAbsoluteData(dataByYear);
  }

  getTitleInside() {
    const TRANSFER_STATIONS_KEYS = ['Wärmenetz'];
    const { sumBuildings, sumTransferStations } =
      this.chartBuildingsByHCData.reduce(
        (acc, cv) => {
          if (TRANSFER_STATIONS_KEYS.includes(cv.name)) {
            acc.sumTransferStations += Math.round(cv.value);
          } else {
            acc.sumBuildings += Math.round(cv.value);
          }
          return acc;
        },
        { sumBuildings: 0, sumTransferStations: 0 },
      );

    const buildingValue = `{a|${sumBuildings.toLocaleString(navigator.language)}}`;
    const transferStationValue = `{a|\n\n${sumTransferStations.toLocaleString(navigator.language)}}`;

    return `${buildingValue}{b|\nGebäude}${transferStationValue}{b|\nÜbergabestationen}`;
  }

  getChartDemandPercentData(dataByYear, switchValue, wired, decentralized) {}

  getchoices(switchValue, wired, decentralized) {
    const mappings = {
      default: {
        hCTitle: 'Dezentrale erneuerbare\nEnergieträger',
        hCDataKey: 'demand_by_decentralized_renewable_heating_carrier',
        hTDataKey: 'demand_by_decentralized_renewable_ht',
      },
      withoutSwitch: {
        all: {
          hCTitle: 'Alle Energieträger',
          hCDataKey: 'demand_by_heating_carrier',
          hTDataKey: 'demand_by_ht',
        },
        gridbound: {
          hCTitle: 'Leitungsgebundene\nEnergieträger',
          hCDataKey: 'demand_by_gridbound_heating_carrier',
          hTDataKey: 'demand_by_gridbound_ht',
        },
        decentralized: {
          hCTitle: 'Dezentrale Energieträger',
          hCDataKey: 'demand_by_decentralized_heating_carrier',
          hTDataKey: 'demand_by_decentralized_ht',
        },
      },
      withSwitch: {
        all: {
          hCTitle: 'Erneuerbare\nEnergieträger und\nunvermeidbare\nAbwärme',
          hCDataKey: 'demand_by_renewable_heating_carrier',
          hTDataKey: 'demand_by_renewable_ht',
        },
        gridbound: {
          hCTitle:
            'Leitungsgebundene\nerneuerbare Energie-\nträger und\nunvermeidbare\nAbwärme',
          hCDataKey: 'demand_by_gridbound_renewable_heating_carrier',
          hTDataKey: 'demand_by_gridbound_renewable_ht',
        },
      },
    };

    if (!switchValue) {
      if (wired && decentralized) return mappings.withoutSwitch.all;
      if (wired && !decentralized) return mappings.withoutSwitch.gridbound;
      if (!wired && decentralized) return mappings.withoutSwitch.decentralized;
    } else {
      if (wired && decentralized) return mappings.withSwitch.all;
      if (wired && !decentralized) return mappings.withSwitch.gridbound;
    }
    return mappings.default;
  }

  getDemandAbsoluteData(dataByYear) {
    const entriesWithAnteil = [];
    const nonZeroEntries = [];

    for (let item of dataByYear[this.choices.hTDataKey]) {
      if (item.name.includes('Wärmenetz (Anteil EE)'))
        item = { ...item, pattern: true };
      if (item.value !== 0) nonZeroEntries.push(item);
      if (item.name.includes('Anteil')) entriesWithAnteil.push(item);
    }
    const createOtherItemsWithZeroValue = (current) =>
      nonZeroEntries
        .filter((item) => item.name !== current.name)
        .map((item) => ({ ...item, value: 0 }));
    const mergeUniqueByName = (entries) => [
      ...new Map(entries.map((item) => [item.name, item])).values(),
    ];

    return nonZeroEntries.reduce((acc, cv) => {
      const others = createOtherItemsWithZeroValue(cv);

      if (cv.name.includes('Anteil')) {
        acc['Wärmenetz'] = mergeUniqueByName([...others, ...entriesWithAnteil]);
      } else {
        acc[cv.name] = [...others, { ...cv }];
      }
      return acc;
    }, {});
  }
}
