<template>
  <div>
    <h2>Reports- und Dokumenten-Downloads</h2>
    <DropDown
      v-model="scenarioId"
      items-data-key="title"
      value="value"
      class="w-fit min-w-[250px]"
      placeholder="Szenario auswählen"
      :items-data="scenarios"
      :rules="{ required: true }"
    />
    <VuetifyDataTable
      :headers="headers"
      :item-data="items"
      :open-page-options-to-top="true"
      :show-expand="false"
      class="documents-table-custom"
    >
      <template #document="item">
        <div class="flex items-center">
          <div class="mr-4">
            {{ item.item.document.name }}
          </div>
          <div class="text-infra-lines">
            {{ item.item.document.description }}
          </div>
        </div>
      </template>
      <template #actions="item">
        <div class="flex cursor-pointer justify-center">
          <IconWrapper icon="download" @click="download(item.item.link)" />
        </div>
      </template>
    </VuetifyDataTable>
  </div>
</template>

<script>
import { axios, axiosInstance } from '@/utils/axiosHelper';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';
import VuetifyDataTable from '@/components/storybook/src/stories/vuetifyDataTable/VuetifyDataTable.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';

export default {
  components: { IconWrapper, VuetifyDataTable, DropDown },
  data() {
    return {
      scenarios: [],
      filesizeData: [],
      scenarioId: 0,
      headers: [
        {
          title: 'Dokumentenname',
          key: 'document',
        },
        {
          title: 'Dateityp',
          key: 'filetype',
          align: 'center',
          sortable: false,
          width: '125px',
        },
        {
          title: 'Aktionen',
          key: 'actions',
          align: 'center',
          sortable: false,
          width: '125px',
        },
      ],
      items: [
        {
          document: {
            name: 'Gebäude: Geometrien',
            description: '',
          },
          filetype: 'CSV',
          link: `/api/buildingmodel/export-building-geometries-csv/`,
        },
        {
          document: {
            name: 'Gebäude: Struktur',
            description: '',
          },
          filetype: 'CSV',
          link: `/api/buildingmodel/export-building-structure-csv/`,
        },
        {
          document: {
            name: 'Gebäude: Wärme',
            description: '',
          },
          filetype: 'CSV',
          link: `/api/buildingmodel/export-building-heat-csv/`,
        },
        {
          document: {
            name: 'Gebäude: Szenario-Ergebnisse',
            description: '',
          },
          filetype: 'CSV',
          link: `/api/buildingmodel/export-building-scenarios-csv/`,
        },
        {
          document: {
            name: 'Netzcluster: Geometrien',
            description: '',
          },
          filetype: 'CSV',
          link: `/api/buildingmodel/export-network-cluster-geometries/`,
        },
        {
          document: {
            name: 'Netzcluster: Struktur',
            description: '',
          },
          filetype: 'CSV',
          link: `/api/buildingmodel/export-network-cluster/`,
        },
        {
          document: {
            name: 'Netzcluster: Szenario-Ergebnisse',
            description: '',
          },
          filetype: 'CSV',
          link: `/api/buildingmodel/export-network-cluster-yearly/`,
        },
        {
          document: {
            name: 'Blöcke: Geometrien',
            description: '',
          },
          filetype: 'CSV',
          link: `/api/buildingmodel/export-buildingblock-geometry/`,
        },
        {
          document: {
            name: 'Stadtteile: Geometrien',
            description: '',
          },
          filetype: 'CSV',
          link: `/api/buildingmodel/export-district-geometry/`,
        },
      ],
    };
  },
  created() {
    this.getScenarioData();
  },
  methods: {
    getScenarioData() {
      axios({
        url: '/api/scenarios/',
        method: 'GET',
      }).then((resp) => {
        this.scenarios = [];
        resp.data.results.forEach((e) => {
          if (e.progress !== 0) return;
          this.scenarios.push({ value: e.scenario_id, title: e.name });
        });
      });
    },
    download(link) {
      const url = `${axiosInstance.defaults.baseURL}${link}${this.scenarioId}`;
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="scss">
.documentFiling {
  th {
    border: none !important;
  }

  .v-data-table__tr:last-child {
    td {
      border-bottom: thin solid #e0e0e0 !important;
    }
  }

  .v-data-table__tr:hover {
    td {
      @apply bg-infra-background;
    }
  }

  .v-data-table-footer {
    display: none;
  }

  .mdi-arrow-up {
    display: none !important;
  }
}
</style>
