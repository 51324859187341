<template>
  <div class="ml-auto">
    <span class="caption-1 ml-auto flex text-right">
      <div v-if="isLoading">
        <skeleton-loader-custom />
      </div>
      <div v-else>{{ findChoice ? findChoice.display_name : '-' }}</div>
    </span>
  </div>
</template>

<script>
import SkeletonLoaderCustom from './SkeletonLoaderCustom.vue';

export default {
  components: { SkeletonLoaderCustom },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    findChoice() {
      return this.item.choices.find((c) => c.value === this.item.value);
    },
  },
};
</script>

<style lang="scss"></style>
