<template>
  <div v-if="isTwoFaEnabled">
    2-Faktor-Authentifizierung ist aktiviert.
    <ButtonEl text="Deaktivieren" @click="showRemoveTwoFaDialog = true" />
    <RemoveTwofaDialog v-model="showRemoveTwoFaDialog"></RemoveTwofaDialog>
  </div>
  <div v-else>
    <ButtonEl text="2FA aktivieren" @click="showAddTwoFaDialog = true" />
    <AddTwofaDialog v-model="showAddTwoFaDialog"></AddTwofaDialog>
  </div>
</template>

<script setup>
import axios from 'axios';
import { ref, watch } from 'vue';
import RemoveTwofaDialog from '@/views/profile/RemoveTwofaDialog.vue';
import AddTwofaDialog from '@/views/profile/AddTwofaDialog.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';

async function fetchAuthState() {
  return (
    await axios({
      method: 'GET',
      url: '/api/auth-state/',
    })
  ).data.needs_verification;
}

const isTwoFaEnabled = ref(await fetchAuthState());

const showRemoveTwoFaDialog = ref(false);
const showAddTwoFaDialog = ref(false);

watch(showRemoveTwoFaDialog, async (val) => {
  if (val === false) {
    isTwoFaEnabled.value = await fetchAuthState();
  }
});

watch(showAddTwoFaDialog, async (val) => {
  if (val === false) {
    isTwoFaEnabled.value = await fetchAuthState();
  }
});
</script>

<style scoped></style>
