<template>
  <div
    v-if="activeProcess.active"
    class="pointer-events-auto flex h-[44px] gap-2"
  >
    <template v-for="process in mapActions.actions" :key="process">
      <template v-if="process.processId === activeProcess.id">
        <template v-for="actionWrapper in process.items" :key="actionWrapper">
          <div
            v-if="
              process.processId !== 'heatProject' ||
              actionWrapper.every(
                (item) => item.isEditMode === heatProjectEditActive,
              )
            "
            class="align-start standard-elevation-1 flex h-10 gap-0.5 rounded-[10px] bg-white"
          >
            <template v-for="(actionItem, index) in actionWrapper" :key="index">
              <MapActionSingle
                v-if="!actionItem.custom"
                :active="drawMode === actionItem.drawMode"
                :icon="actionItem.icon"
                :classes="customRounded(index, actionWrapper.length)"
                :icon-classes="actionItem.iconClasses"
                :tool-tip="actionItem.tooltipText"
                :tool-tip-detail="actionItem.tooltipDetail"
                :offset="actionItem.offset"
                @action="actionItem.action(store)"
                @click="currentIndex = actionItem"
              />
              <template v-else>
                <div
                  v-if="actionItem.id === 'radiusSettings'"
                  class="flex gap-0.5"
                >
                  <MapActionSingle
                    icon="architecture"
                    :classes="{
                      'rounded-r-none': mapActions.showRadiusSettings,
                    }"
                    :tool-tip="actionItem.tooltipText"
                    :tool-tip-detail="actionItem.tooltipDetail"
                    :active="mapActions.showRadiusSettings"
                    @action="mapActions.toggleRadiusSettings()"
                  />
                  <div
                    v-if="mapActions.showRadiusSettings"
                    class="standard-elevation-1 relative z-10 flex items-center gap-3 rounded-[10px] rounded-tl-none bg-white p-3"
                  >
                    <InputEl
                      v-model="snapRadius"
                      class="max-w-[100px]"
                      label="Radius"
                      suffix="km"
                    />
                    <IconWrapper
                      icon="menu_open"
                      class="cursor-pointer"
                      @click="mapActions.toggleRadiusSettings()"
                    />
                  </div>
                </div>
                <template v-if="actionItem.id === 'measure'">
                  <MapActionSingle icon="straighten" :active="true" />
                </template>
              </template>
            </template>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script setup>
import { getMap } from '@/mapbox/main';
import { SET_PROPERTIES, SNAP_RADIUS_KM } from '@/features/draw/properties';
import { computed, onMounted, ref, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import MapActionsData from './map-actions-config';
import MapActionSingle from '@/features/map/control-options/components/MapActionSingle.vue';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import MapboxGlDraw from '@mapbox/mapbox-gl-draw';
import { modes } from '@/features/draw/constants';
import { useMapStore } from '@/features/map/store/map-store';

const { showMuScSelector } = toRefs(useMapStore());

const mapActions = ref(new MapActionsData());

onMounted(() => {
  getMap().on(MapboxGlDraw.constants.events.MODE_CHANGE, function (e) {
    store.commit('map/SET_CURRENT_DRAW_MODE', null);
    drawMode.value = e.mode;
  });
});

const store = useStore();

const drawMode = ref();
const snapRadius = ref(SNAP_RADIUS_KM.radius);
const applyPipeToDraw = ref(false);
const currentIndex = ref();

const exceptionDrawMode = computed(
  () => store.getters['map/getCurrentDrawMode'],
);
const activeProcess = computed(() => store.getters['getActiveProcessOptions']);
const heatProjectEditActive = computed(
  () => store.getters.getHeatProjectEditing,
);
const pipes = computed(() => {
  const pipes = store.state.heatProject.pipeSelectables;
  return pipes.map((e) => ({
    id: e.id,
    title: `${e.inner_diameter}/${e.outer_diameter} mm`,
    inner_diameter: e.inner_diameter,
  }));
});
const selectedPipe = ref();
const selectedPipeParams = computed(() => {
  const pipe = pipes.value.find((p) => p.id === selectedPipe.value);
  return {
    pipe_edited_inner_diameter: pipe.inner_diameter,
    pipe_edited: pipe.id,
  };
});

function customRounded(index, length) {
  if (index === 0 && length > 1) {
    return 'rounded-r-none';
  } else if (index === length - 1 && length > 1) {
    return 'rounded-l-none';
  } else if (length === 1) {
    return '';
  }
  return 'rounded-none';
}

watch(activeProcess, (process) => {
  if (process.active) {
    showMuScSelector.value = false;
  } else {
    showMuScSelector.value = true;
  }
});

watch(exceptionDrawMode, (newDrawMode) => {
  if (newDrawMode === modes.DRAW_BOX_SELECT) {
    drawMode.value = modes.DRAW_BOX_SELECT;
  }
});

watch(snapRadius, (newValue) => {
  SNAP_RADIUS_KM.radius = parseFloat(newValue);
});
watch([selectedPipe, applyPipeToDraw], ([pipe, apply]) => {
  if (apply === true) {
    const pipeSelected = pipes.value.find((p) => p.id === pipe);
    SET_PROPERTIES.pipe_edited_inner_diameter = pipeSelected.inner_diameter;
    SET_PROPERTIES.pipe_edited = pipeSelected.id;
  } else {
    delete SET_PROPERTIES.pipe_edited_inner_diameter;
    delete SET_PROPERTIES.pipe_edited;
  }
});
</script>
