<template>
  <v-container class="d-flex align-center justify-center" fill-height>
    <v-card class="pa-4" max-width="800">
      <v-card-title class="justify-center">
        <strong>Enter TOTP</strong>
      </v-card-title>
      <v-card-text>
        <v-form ref="totpForm" v-model="isFormValid" @submit.prevent="onSubmit">
          <v-text-field
            v-model="totpToken"
            label="TOTP"
            type="text"
            :rules="totpRules"
            required
            outlined
            clearable
            style="min-width: 320px"
            maxlength="6"
          />

          <v-alert v-if="errorMessage" type="error" dismissible class="mt-2">
            {{ errorMessage }}
          </v-alert>

          <v-btn
            :disabled="!isFormValid"
            color="primary"
            block
            class="mt-4"
            @click="onSubmit"
          >
            Login
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import cookie from 'vue-cookies';

const router = useRouter();

const totpToken = ref('');
const isFormValid = ref(false);
const errorMessage = ref('');

const totpRules = [
  (v) => !!v || 'TOTP is required.',
  (v) => (v && v.length >= 6) || 'TOTP must be at least 6 numbers.',
  (v) => /^\d+$/.test(v) || 'TOTP must contain only numbers.',
];

async function onSubmit() {
  errorMessage.value = '';

  try {
    await axios({
      method: 'POST',
      url: '/api/totp/',
      headers: { 'X-CSRFToken': cookie.get('csrftoken') },
      data: { totpToken: totpToken.value },
    });
  } catch (e) {
    if (e.response?.status === 401) {
      errorMessage.value = 'Invalid token.';
    } else {
      errorMessage.value = 'Unexpected error';
      console.error('Unexpected login error: ', e);
    }
    return;
  }
  await router.push('/');
}
</script>

<style scoped>
/* Add custom styling if needed */
</style>
