<template>
  <div class="h-20 w-20 overflow-hidden rounded-lg">
    <img
      v-show="imageLoaded"
      class="h-full w-full overflow-hidden rounded-[8px]"
      :src="avatarURL"
      alt="avatar"
      @load="imageLoaded = true"
      @error="imageLoaded = false"
    />
    <img
      v-if="!imageLoaded"
      src="@/assets/img/default_avatar.png"
      alt="avatar"
      class="h-full w-full rounded-[8px] object-cover"
    />
  </div>
</template>

<script setup>
import { axiosInstance } from '@/utils/axiosHelper';
import { mapGetters } from 'vuex';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';

const store = useStore();
const imageLoaded = ref(false);

const profil = computed(() => store.getters['auth/profil']);
const avatarURL = computed(() => {
  return `${axiosInstance.defaults.baseURL}${profil.value.avatar}`;
});
</script>
