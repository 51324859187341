<template>
  <div class="flex items-center gap-5 rounded-[4px] bg-blue-grey-25 px-3">
    <h5 class="text-blue-grey-500">Gruppieren nach:</h5>
    <RadioButtonGroup
      v-model="groupBy"
      items-data-key="label"
      :reverse="true"
      :items-data="[
        { label: 'Energieträger', value: false },
        { label: 'Sektor', value: true },
      ]"
    />
  </div>
  <div v-if="data" class="flex flex-col justify-center gap-4">
    <div
      v-for="chart in barChartData"
      :key="chart.technologyFileName"
      class="data-row-dashboard"
    >
      <h3 class="data-row-title">
        {{ !groupBy ? chart.technologyText : chart.sectorText }}
        <MapLink
          custom-class="ml-1"
          :layer-activation="
            groupBy
              ? chart.layerActivationSector
              : chart.layerActivationEnergySource
          "
        />
        <IconWrapper
          icon="download"
          hover="hover:text-success"
          class="cursor-pointer"
          size="32"
          @click="download(chart)"
        />
      </h3>
      <div class="data-row-items grid-cols-1">
        <BarChartStackedVertical
          :show-total="chart.showTotal"
          :multiply="chart.multiply"
          :chart-data="chart.chartData"
          class="mb-10"
          @chart-ref="chart.chartRef = $event"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';
import RadioButtonGroup from '@/components/storybook/src/stories/RadioButtonGroup/RadioButtonGroup.vue';
import BarChartStackedVertical from '@/apps/analysis-dashboard/charts/BarChartStackedVertical.vue';
import { downloadChart } from '@/utils/chart-download';
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';
import MapLink from './MapLink.vue';

const store = useStore();
const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
});

const demandData = computed(() => {
  let key = 'demand_by_ht';
  if (groupBy.value) key = 'demand_by_sector';
  return getGroupData(key);
});
const emissionData = computed(() => {
  let key = 'emissions_by_ht';
  if (groupBy.value) key = 'emissions_by_sector';
  return getGroupData(key);
});
const buildingData = computed(() => {
  let key = 'buildings_by_ht';
  if (groupBy.value) key = 'buildings_by_sector';
  return getGroupData(key);
});
const groupBy = computed({
  get: () => store.getters['dashboard/getDashboard3GroupBy'],
  set: (val) => store.commit('dashboard/SET_DASHBOARD3_GROUP_BY', val),
});

const barChartData = reactive({
  building: {
    chartRef: null,
    technologyFileName: 'gebaeude_nach_heiztechnologie',
    sectorFileName: 'gebaeude_nach_sektor',
    technologyText: 'Gebäude nach Heiztechnologie',
    sectorText: 'Gebäude nach Sektor',
    showTotal: false,
    chartData: computed(() => buildingData.value),
    layerActivationEnergySource: [
      {
        switchId: 'LC_BUILDINGS',
        layerState: 'heating_technology',
      },
    ],
    layerActivationSector: [
      {
        switchId: 'LC_BUILDINGS',
        layerState: 'sector',
      },
    ],
  },
  heatDemand: {
    chartRef: null,
    technologyFileName: 'waermebedarf_nach_heiztechnologie',
    sectorFileName: 'waermebedarf_nach_sektor',
    technologyText: 'Wärmebedarf nach Heiztechnologie in MWh',
    sectorText: 'Wärmebedarf nach Sektor in MWh',
    showTotal: true,
    chartData: computed(() => demandData.value),
    multiply: 0.001,
    layerActivationEnergySource: [
      {
        switchId: 'LC_BUILDINGS',
        layerState: 'heating_technology',
      },
      {
        switchId: 'LC_BLOCK',
        layerState: 'heat_demand',
      },
    ],
    layerActivationSector: [
      {
        switchId: 'LC_BUILDINGS',
        layerState: 'sector',
      },
      {
        switchId: 'LC_BLOCK',
        layerState: 'heat_demand',
      },
    ],
  },
  emission: {
    chartRef: null,
    technologyFileName: 'emissionen_nach_heiztechnologie',
    sectorFileName: 'emissionen_nach_sektor',
    technologyText: 'Emissionen nach Heiztechnologie in t CO₂',
    sectorText: 'Emissionen nach Sektor in t CO₂',
    showTotal: true,
    chartData: computed(() => emissionData.value),
    layerActivationEnergySource: [
      {
        switchId: 'LC_BUILDINGS',
        layerState: 'heating_technology',
      },
      {
        switchId: 'LC_BLOCK',
        layerState: 'emission',
      },
    ],
    layerActivationSector: [
      {
        switchId: 'LC_BUILDINGS',
        layerState: 'sector',
      },
      {
        switchId: 'LC_BLOCK',
        layerState: 'emission',
      },
    ],
  },
});

function getGroupData(key) {
  if (!props.data) return null;
  return props.data.map((e) => ({ year: e.year, data: e[key] }));
}

function download(headingData) {
  const fileName = !groupBy.value
    ? headingData.technologyFileName
    : headingData.sectorFileName;
  const text = !groupBy.value
    ? headingData.technologyText
    : headingData.sectorText;

  downloadChart(headingData.chartRef, {
    downloadFileName: fileName,
    downloadTitleFontSize: 100,
    title: text,
  });
}
</script>
