<template>
  <h5
    :style="
      props.titleCustomSize ? { 'font-size': props.titleCustomSize + 'px' } : {}
    "
    class="subtitle-1 mb-7 flex items-center gap-0.5 text-title-color2"
  >
    {{ props.title }}
    <slot />
    <IconWrapper
      v-if="props.downloadFileName"
      icon="download"
      hover="hover:text-success"
      class="cursor-pointer"
      @click="emits('downloadClicked')"
    />
  </h5>
</template>
<script setup>
import IconWrapper from '@/components/storybook/src/stories/IconWrapper/IconWrapper.vue';

const props = defineProps({
  titleCustomSize: String,
  title: String,
  downloadFileName: String,
});

const emits = defineEmits(['downloadClicked']);
</script>
