<template>
  <div class="flex w-full flex-col items-center py-7">
    <ChartTitle
      :title
      :title-custom-size
      :download-file-name
      @download-clicked="downloadChart(chartRef, props)"
    />
    <v-chart ref="chartRef" :option="option" autoresize :style="chartHeight" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { downloadChart } from '@/utils/chart-download';
import ChartTitle from '@/apps/analysis-dashboard/charts/ChartTitle.vue';
import {
  BAR_CHART_X_AXIS,
  formatChartValue,
  getBarChartHeight,
} from '@/apps/analysis-dashboard/utils';

const props = defineProps({
  chartData: {
    type: Array,
    default: () => [],
  },
  title: {
    type: String,
    default: '',
  },
  titleCustomSize: String,
  multiply: {
    type: Number,
    default: null,
  },
  customMinHeight: {
    type: Number,
  },
  gridTop: {
    type: String,
    default: 'center',
  },
  gridBottom: {
    type: String,
    default: '',
  },
  filterZeroValues: {
    type: Boolean,
    default: true,
  },
  decimalPlaces: {
    type: Number,
    default: 1,
  },
  yAxisLabelWidth: {
    type: String,
    default: null,
  },
  downloadTitle: String,
  downloadTitleFontSize: {
    type: Number,
    default: 65,
  },
  downloadFileName: String,
});

const chartRef = ref(null);

const chartHeight = computed(() => {
  return getBarChartHeight(props.customMinHeight, sortedChartData.value);
});

const sortedChartData = computed(() => {
  let data = props.chartData.map((item) => ({
    ...item,
    value: props.multiply ? item.value * props.multiply : item.value,
  }));

  // filter 0 values and sort by name
  data = props.filterZeroValues
    ? data.filter((item) => item.value !== 0)
    : data;
  data.sort((b, a) => a.name.localeCompare(b.name));

  return data;
});

const option = computed(() => {
  return {
    grid: {
      left: 'center',
      top: '0px',
      bottom: '0px',
      containLabel: true,
      width: '70%',
    },
    xAxis: BAR_CHART_X_AXIS,
    yAxis: {
      type: 'category',
      data: sortedChartData.value.map((item) => item.name),
      axisLabel: {
        color: '#424242',
        fontWeight: '400',
        fontSize: 16,
        fontFamily: 'Inter, sans-serif',
        align: 'right',
        width: props.yAxisLabelWidth,
        overflow: 'truncate',
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        type: 'bar',
        data: sortedChartData.value.map((item) => ({
          ...item,
          emphasis: {
            itemStyle: {
              color: item.color,
            },
          },
        })),
        label: {
          show: true,
          position: 'right',
          offset: [0, -0.5],
          formatter: function (params) {
            const value = Math.round(params.data.value * 100) / 100;
            return formatChartValue(value);
          },
          color: '#424242',
          fontWeight: '400',
          fontSize: 16,
        },
        barWidth: 24,
        itemStyle: {
          color: function (params) {
            const colorList = sortedChartData.value.map((item) => item.color);
            return colorList[params.dataIndex];
          },
        },
      },
    ],
  };
});
</script>
