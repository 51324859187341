import { cloneDeep } from 'lodash';
import layers from '@/configs/layers';
import { layerPanelConfig } from '@/configs/layer-panel';
import {
  buildCustomLayerConfig,
  buildCustomLayerPanelConfig,
} from '@/configs/layer-panel/customLayers';

export default {
  RESET_LAYER_STATES: (state) => {
    for (const [key, value] of Object.entries(layers)) {
      state.layerConfigs[key].layoutState = value.layoutState;
    }
  },
  SET_DISABLE_SWITCHES: (state, payload) => {
    state.emptyModels = payload.empty_models;
  },
  CACHE_SWITCH: (state, switchItem) => {
    state.cachedSwitch = switchItem;
  },
  SET_PENDING_STYLE_REFRESH: (state, value) => {
    state.pendingStyleRefresh = value;
  },
  SET_MAP_INITIALIZED: (state, value) => {
    state.mapInitialized = value;
  },
  SET_RADIO_ACTIVATED: (
    state,
    { switchId, radioGroupIndex, activatedIndex },
  ) => {
    // Find the switch in the layerPanelConfig
    for (const section of state.layerPanelConfig.sections) {
      for (const group of section.groups) {
        if (group.items) {
          for (const switchItem of group.items) {
            if (switchItem.switchId === switchId) {
              // Make sure the radio group exists
              if (
                switchItem.radioGroups &&
                switchItem.radioGroups[radioGroupIndex]
              ) {
                const radioGroup = switchItem.radioGroups[radioGroupIndex];

                // Make sure the radio group is visible
                radioGroup.visible = true;

                // Update the activated index
                radioGroup.activated = activatedIndex;

                // Ensure the parent switchItem is active
                switchItem.active = true;

                return; // Exit once found and updated
              }
            }
          }
        }
      }
    }
    console.warn(
      `Switch ${switchId} or radio group not found when trying to set activated radio`,
    );
  },
  CHANGE_SWITCH: (state, payload) => {
    const switches = payload.switches || [];
    // simplify simplify layer-panel
    for (const section of state.layerPanelConfig.sections) {
      for (const group of section.groups) {
        if (group.items) {
          for (const switchItem of group.items) {
            if (switches.includes(switchItem.switchId) || payload.selectAll) {
              switchItem.active = payload.active;
            }
          }
        }
      }
    }
  },
  SET_LAYER_VIS: (state, payload) => {
    payload.onLayers.forEach((key) => {
      state.layerConfigs[key].visible = payload.active;
      if (payload.onActivateSetLayout) {
        state.layerConfigs[key].layoutState = payload.onActivateSetLayout;
      }
    });
  },
  SET_LAYER_STATE: (state, payload) => {
    payload.layerKeys.forEach((key) => {
      state.layerConfigs[key].layoutState = payload.layerState;
    });
  },
  ADD_FILTER: (state, payload) => {
    const filters = { ...state.filters };
    payload.layerKeys.forEach((key) => {
      let filterConfig = filters[key] || {};
      filterConfig[payload.filter.id] = payload.filter.filter;
      filters[key] = filterConfig;
    });
    state.filters = filters;
  },
  REMOVE_FILTER: (state, payload) => {
    removeFilter(state, payload, 'filters');
  },
  ADD_YEAR_DEPENDANT_FILTER_DATA: (state, payload) => {
    const { layerKeys, data } = payload;
    layerKeys.forEach((key) => {
      let filterConfig = state.yearDependantFilterData[key] || {};
      filterConfig[data.feature] = data;
      state.yearDependantFilterData[key] = filterConfig;
    });
  },
  REMOVE_YEAR_DEPENDANT_FILTER_DATA: (state, payload) => {
    removeFilter(state, payload, 'yearDependantFilterData');
  },
  SET_SCENARIO_YEAR: (state, payload) => {
    state.scenarioYear = payload;
  },
  SET_SLIDER: (state, show) => {
    state.showSlider = show;
  },
  TOGGLE_SATELLITE: (state) => {
    state.satelliteIsActive = !state.satelliteIsActive;
  },
  SET_CONTAINER_HEIGHT: (state, payload) => {
    state.containerHeight = payload;
  },
  RESET_CONFIGS: (state) => {
    const layerPanelConfigClone = cloneDeep(layerPanelConfig);
    const layerConfigsClone = cloneDeep(layers);
    if (state.customTilesets.length) {
      state.layerPanelConfig = buildCustomLayerPanelConfig(
        layerPanelConfigClone,
        state.customTilesets,
      );
      state.layerConfigs = buildCustomLayerConfig(
        layerConfigsClone,
        state.customTilesets,
      );
    } else {
      state.layerPanelConfig = layerPanelConfigClone;
      state.layerConfigs = layerConfigsClone;
    }
  },
  SHOW_INFO_PANEL: (state, show) => {
    state.showInfoPanel = show;
  },
  MAP_LOADED: (state, isLoaded) => {
    state.mapLoaded = isLoaded;
  },
  SET_CUSTOM_TILESETS: (state, tilesets) => {
    state.customTilesets = tilesets.filter((ts) => ts.status.id === 2);
  },
  SET_CURRENT_DRAW_MODE: (state, mode) => {
    state.currentDrawMode = mode;
  },
  SET_LAYER_PANEL_SECTIONS_VISIBILITY: (state, showProjectMenu) => {
    state.layerPanelConfig.sections.forEach((section) => {
      section.show = !showProjectMenu;
      if (section.id === 'heatProject') {
        section.show = showProjectMenu;
      }
    });
  },
  SET_MAP_DASHBOARD_LINK: (state, payload) => {
    state.returnToDashboard = payload.returnToDashboard;
  },
};

function removeFilter(state, payload, key) {
  const filters = { ...state[key] };
  payload.layerKeys.forEach((key) => {
    let filterConfig = filters[key];
    if (typeof filterConfig === 'undefined') return;
    if (filterConfig[payload.filterId]) {
      delete filterConfig[payload.filterId];
    }
  });
  state[key] = filters;
}
